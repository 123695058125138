import Select from "react-select";
import Async from "react-select/async";
import {useContext, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "../App.css";
import axios from "axios";
import {AuthUserContext} from "../context/AuthUser";

type TProps = {
    setFilter: (data: { [key: string]: TOptionType | Date | null }) => void,
    submitFilter: () => void
}

type TOptionType = {
    value: number | string,
    label: string
};

type TAutocomplete = {
    id: number,
    name: string
};

const WineHistoryFilter = (props: TProps) => {
    const authUser = useContext(AuthUserContext);
    const axiosConfig = {
        headers: {
            Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
        }
    };

    const [loading, setLoading] = useState(false);

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const [countries, setCountries] = useState<TOptionType[]>([]);
    const [users, setUsers] = useState<TOptionType[]>([]);
    const [competitions, setCompetitions] = useState<TOptionType[]>([]);
    const [events, setEvents] = useState<TOptionType[]>([]);

    const actionsOptions = [
        {label: 'MERGE_SELECTED', value: 1},
        {label: 'NEW_VINTAGE', value: 2},
        {label: 'NEW_WINE', value: 3},
    ];

    const loadWineryOptions = (inputText: string, callback: (options: TOptionType[]) => void): void => {
        if (!inputText) {
            return callback([]);
        }
        if (inputText.length < 2) {
            return callback([]);
        }
        setLoading(true);

        axios.post('/api/wines-history/filters/autocomplete', {q: inputText, component: 'winery'}, {
            headers: {
                Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
            }
        }).then((response) => {
            let data: TAutocomplete[] = [];
            setLoading(false);

            if (response.data.wineries) {
                return callback(response.data.wineries);
            }

            if (data.length) {
                return callback(data.map((result) => {
                    return labelFormatter(result);
                }))
            } else {
                return callback([]);
            }
        });
    };

    const labelFormatter = (i: TAutocomplete): TOptionType => {
        return {
            label: i.name,
            value: i.id,
        }
    };

    useEffect(() => {
        axios.get('/api/wines-history/filters', {
            ...axiosConfig
        }).then(r => {
            let result = r.data;
            if (result.hasOwnProperty('countries')) {
                setCountries(result.countries);
            }
            if (result.hasOwnProperty('users')) {
                setUsers(result.users);
            }
            if (result.hasOwnProperty('competitions')) {
                setCompetitions(result.competitions);
            }
        }).catch(r => {

        });

        let e: TOptionType[] = [];
        for (let i = new Date().getFullYear(); i >= 2014; i--) {
            e.push({label: i.toString(), value: i});
        }
        setEvents(e);

    }, []);

    return (
        <div>
            <h5>Filter</h5>
            <div className="row mb-3">
                <div className="col-md-6">
                    <div className="form-group mb-3">
                        <label>Competition</label>
                        <Select isClearable={true} options={competitions}
                                onChange={(item) => {
                                    props.setFilter({'competition': item});
                                }}/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group mb-3">
                        <label>Event</label>
                        <Select isClearable={true} options={events}
                                onChange={(item) => {
                                    props.setFilter({'event': item});

                                }}/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group mb-3">
                        <label>Country</label>
                        <Select isClearable={true} options={countries}
                                onChange={(item) => {
                                    props.setFilter({'country': item});
                                }}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group mb-3">
                        <label>Winery</label>
                        <Async isClearable={true} isLoading={loading}
                               loadOptions={loadWineryOptions}
                               onChange={(item) => {
                                   props.setFilter({'winery': item});
                               }}
                               placeholder={"Insert some text..."}/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group mb-3">
                        <label>User</label>
                        <Select isClearable={true} options={users}
                                onChange={(item) => {
                                    props.setFilter({'user_wine_action': item});
                                }}/>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group mb-3">
                        <label>Date</label>
                        <div className="row">
                            <div className="col-md-6">
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => {
                                        setStartDate(date);
                                        props.setFilter({'action_date_from': date})
                                    }}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="From"
                                    isClearable={true}
                                    className="form-control"
                                />
                            </div>
                            <div className="col-md-6">
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => {
                                        setEndDate(date);
                                        props.setFilter({'action_date_to': date})
                                    }}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="To"
                                    isClearable={true}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group mb-3">
                        <label>Gustos Vintage ID</label>
                        <input type="number" className="form-control filter-input" onChange={(t) => {
                            props.setFilter({'gustos_vintage_id': {label: t.target.value, value: t.target.value}})
                        }}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group mb-3">
                        <label>Action</label>
                        <Select options={actionsOptions} isClearable={true}
                                onChange={(item) => {
                                    props.setFilter({'wine_history_action': item});
                                }}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group mb-3 text-end mt-4">
                        <button className="btn btn-custom" onClick={props.submitFilter}>Filter</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WineHistoryFilter;