import Context, {AuthUser} from "./Context";
import {PropsWithChildren, useEffect, useState} from "react";

type Props = PropsWithChildren<{}>;

export default function Provider(props: Props) {
    const [authUser, setAuthUser] = useState<AuthUser | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const auth_data = localStorage.getItem('auth_user');
        if (auth_data !== null) {
            setAuthUser(JSON.parse(auth_data));
        }

        setLoading(false);
    }, []);

    return (
        <Context.Provider
            value={{
                authUser,
                setAuthUser: (authUser: AuthUser | null) => setAuthUser(authUser)
            }}
        >
            {!loading ? props.children : ''}
        </Context.Provider>
    )
}