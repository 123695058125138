import Moment from "moment/moment";
import React, { Fragment } from "react";
import { Table } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import { TWines } from "../pages/Wines";
import { TPagination } from "../properties";


type Props = {
  wines: TWines[],
  pagination: TPagination,
  onChangePage: (pageNumber: number) => void
}
const WinesMatchTable = (props: Props) => {

  const pageRangeDisplayed = 8;

  return (
    <>
      <Table striped bordered hover size="sm">
        <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Country</th>
          <th>Winery</th>
          <th>Status</th>
          <th>Created</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {props.wines.map(item => {
          return (
            <Fragment key={item.id}>
              <tr>
                <td>
                  {item.id}
                </td>
                <td>{item.name}</td>
                <td>{item.country_gustos ? item.country_gustos.name : '-'}</td>

                <td>{item.vineyard_gustos ? item.vineyard_gustos.name : '-'}</td>
                <td>{item.status}</td>
                <td>{Moment(item.created_at).fromNow()}</td>

                <td>
                  <div className="d-flex">
                    {item.matching
                      ? <Link to={'/name-matching/' + item.id}
                              className="mr-2 custom-default inherit">Matching</Link>
                      : ''
                    }
                    <Link to={"/wine/" + item.id}
                          className="btn btn-custom inherit">View</Link>
                    {item.wine_in_progress && (
                      <Link to={`/name-matching/${item.wine_in_progress.wine_id}`} className="btn btn-outline-info ml-2">Continue</Link>
                    )}

                  </div>
                </td>
              </tr>
            </Fragment>
          );
        })}
        </tbody>
      </Table>
        {props.pagination !== null ?
          <Pagination activePage={props.pagination.current_page}
                      itemsCountPerPage={props.pagination.per_page}
                      onChange={(pageNumber) => {
                        props.onChangePage(pageNumber)
                      }}
                      totalItemsCount={props.pagination.total}
                      pageRangeDisplayed={pageRangeDisplayed}
                      itemClass="page-item"
                      linkClass="page-link"
                      firstPageText="First Page"
                      lastPageText="Last Page"
                      innerClass="pagination mt-3"
          />
          : ''
        }
    </>
  )
}

export default WinesMatchTable;
