import React from "react";
import {Button, Modal} from "react-bootstrap";
import {BsModalSize} from "../properties";

type TModalProps = {
    showModal: boolean,
    hideModal?: () => void,
    title?: string,
    primaryButton?: TPrimaryButton,
    confirmButton?: boolean,
    bsError?: string,
    confirmButtonClick?: () => void
    size?: BsModalSize
}

type TPrimaryButton = {
    onClick?: () => void,
    title?: string,
    disabled?: boolean
}

const BsModal = (props: React.PropsWithChildren<TModalProps>) => {

    return (
        <>
            <Modal show={props.showModal} onHide={props.hideModal} centered {...(props.size ? {size: props.size}: {})}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title ? props.title : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.bsError
                        ?
                        <div className="alert error">
                            {props.bsError}
                        </div>
                        : ''
                    }
                    {props.children}
                </Modal.Body>

                <Modal.Footer>
                    {props.confirmButton
                        ?
                        <Button variant="custom" onClick={props.confirmButtonClick}>Confirm</Button>
                        :
                        <>
                            <Button variant="default" onClick={props.hideModal}>Close</Button>
                            {props.primaryButton &&
                              <Button variant="custom"
                                      onClick={props.primaryButton.onClick} {...(props.primaryButton.disabled ? {disabled: props.primaryButton.disabled} : {})}>{props.primaryButton.title}</Button>
                            }
                        </>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default BsModal;