import {EWineryStatuses} from "../properties";
import ReactTooltip from "react-tooltip";
import React from "react";

type TProps = {
  review_statuses: {
    [k: string]: {
      user: string,
      created: string,
    }
  },
  winery_id: number,
  current_status: EWineryStatuses,
  is_disabled: boolean,
  toggleWineryStatus: (status: EWineryStatuses, winery: number) => void,
}

const WineryReviewStatusInput = (props: TProps) => {

  if (props.review_statuses && props.review_statuses[props.current_status]) {
    return <>
      <ReactTooltip id={"row_status_" + props.winery_id + "_" + props.current_status}
                    className="adp-tooltip--wine">
        {props.review_statuses[props.current_status].user}
      </ReactTooltip>
      <span
        data-tip=""
        data-for={"row_status_" + props.winery_id + "_" + props.current_status}>
                                    <input type="checkbox" defaultChecked={true}
                                           disabled={props.is_disabled}
                                           onChange={() => props.toggleWineryStatus(props.current_status, props.winery_id)}/>
                                </span>
    </>;
  } else {
    return <input type="checkbox"
                  onChange={() => props.toggleWineryStatus(props.current_status, props.winery_id)}
                  disabled={props.is_disabled}/>;
  }
}

export default WineryReviewStatusInput;