import React, { useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthUserContext } from "../context/AuthUser";

type TProps = {
  accept: string[],
  multiple?: boolean,
  callback: (result: any) => void,
  api?: {
    url: string
  }
}
const DragDropFile = (props: TProps) => {
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef<HTMLInputElement>(null);

  const authUser = useContext(AuthUserContext);
  const axiosConfig = {
    headers: {
      Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : '',
      ContentType: "multipart/form-data"
    }
  };

  // handle drag events
  const handleDrag = function(e: React.DragEvent<HTMLFormElement & HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function(e: React.ChangeEvent<HTMLDivElement & HTMLInputElement>) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

// triggers the input when the button is clicked
  const onButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFile = (files: FileList) => {
    if (props.api && files.length) {
      const formData = new FormData();
      Array.from(files).forEach(file => {
        formData.append("file[]", file);
      });

      axios.post(props.api.url, formData, {
        ...axiosConfig,
      }).then(r => {
        let result = r.data;
        if (result.hasOwnProperty('error')) {
          toast.error(result.error);
          return false;
        }

        if (result.hasOwnProperty('callback')) {
          props.callback(result.callback);
        }
      }).catch(e => {

      });
    }
  }

  return (
    <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      <input ref={inputRef} type="file" id="input-file-upload" multiple={props.multiple ?? false} onChange={handleChange} accept={props.accept.join(",")} />
      <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
        <div>
          <p>Drag and drop your file here or</p>
          <button className="upload-button" onClick={onButtonClick}>Upload a file</button>
        </div>
      </label>
      { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
    </form>
  );
}

export default DragDropFile;