import {AlertError} from "../helpers";
import React, {useContext, useEffect, useState} from "react";
import {EWineProperties, TErrorObject, TTaxonomy} from "../properties";
import {Button, Container} from "react-bootstrap";
import {AuthUserContext} from "../context/AuthUser";
import axios from "axios";
import PresetTaxonomyInput from "../components/PresetTaxonomyInput";
import {useTranslation} from "react-i18next";
import {Check} from "react-bootstrap-icons";
import {useHistory} from "react-router";
import Preloader from "../components/Preloader";

type TPresets = {
    [key: string]: string
}

const Presets = () => {
    const authUser = useContext(AuthUserContext);
    const axiosConfig = {
        headers: {
            Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
        }
    };

    const [error, setError] = useState<string | TErrorObject>('');
    const [taxonomyProperties, setTaxonomyProperties] = useState<TTaxonomy>();
    const [presets, setPresets] = useState<TPresets>({});
    const [dataSaved, setDataSaved] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const {t} = useTranslation();
    const history = useHistory();

    const skip_properties = [EWineProperties.skip, EWineProperties.save, EWineProperties.image, EWineProperties.name, EWineProperties.production_units, EWineProperties.production_volume, EWineProperties.vineyard, EWineProperties.award, EWineProperties.save_grape, EWineProperties.save_trophy, EWineProperties.status, EWineProperties.beverage_type, EWineProperties.grape_type, EWineProperties.wine_id, EWineProperties.vintage_id, EWineProperties.series, EWineProperties.igp, EWineProperties.grape_percent, EWineProperties.url_image];

    const updatePreset = (property: EWineProperties, value: string) => {
        let list = Object.assign({}, presets);
        list[property] = value;

        if (property === EWineProperties.country) {
            if (list.hasOwnProperty(EWineProperties.region)) {
                delete list[EWineProperties.region];
            }
            getRegionsByCountry(parseInt(value));
        }

        setPresets(list);
    }

    const getRegionsByCountry = (countryId: number) => {
        let taxonomy = Object.assign({}, taxonomyProperties);
        if (countryId === 0) {
            if (taxonomy.hasOwnProperty(EWineProperties.region)) {
                taxonomy[EWineProperties.region].option_values = '';
                setTaxonomyProperties(taxonomy);
            }
            return false;
        }

        axios.get('/api/get-country-regions/' + countryId, {
            ...axiosConfig
        }).then((response) => {
            const result = response.data;

            if (result.hasOwnProperty('regions')) {

                if (taxonomy.hasOwnProperty(EWineProperties.region)) {
                    taxonomy[EWineProperties.region].option_values = result.regions;
                    setTaxonomyProperties(taxonomy);
                }
            }
        });
    }

    const submitData = () => {
        setError('');
        setSubmitDisabled(true);
        axios.post('/api/save-presets', presets, {
            ...axiosConfig
        }).then((response) => {
            const result = response.data;
            setSubmitDisabled(false);
            if (result.hasOwnProperty('err')) {
                setError(result.err);
                return false;
            }

            setDataSaved(true);
            setTimeout(() => setDataSaved(false), 1500);
        });
    }

    useEffect(() => {
        axios.get('/api/presets', {
            ...axiosConfig
        }).then((response) => {
            const result = response.data;

            if (result.hasOwnProperty('taxonomy_properties')) {
                setTaxonomyProperties(result.taxonomy_properties);
            }
            if (result.hasOwnProperty('default_presets')) {
                setPresets(result.default_presets);
            }
        }).catch(r => {
            if (r.response && r.response.status === 404) {
                history.push('/');
            }
        });
    }, []);

    return (
        <div className="App">
            <div className="w-100">
                <Container fluid>
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="mb-2 text-start">Presets</h4>
                            <hr/>
                        </div>
                        <div className="col-md-12">
                            <AlertError error={error} callback={() => setError('')}/>

                            {taxonomyProperties ?
                                (<>
                                    <div className="contest">
                                        {Object.entries(EWineProperties).map(([key, value]) => {
                                            if (skip_properties.indexOf(value) !== -1) {
                                                return false;
                                            }
                                            return (
                                                <div className={"property_" + key} key={key}>
                                                    <div className="row mb-2">
                                                        <div className="col-md-3 text-end">
                                                            <div
                                                                className="d-flex align-items-center justify-content-end h-39 fw-bold">
                                                                {t(value)}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <div className="d-flex align-items-center h-39 w-100">
                                                                {taxonomyProperties
                                                                    ? <PresetTaxonomyInput
                                                                        taxonomy_properties={taxonomyProperties}
                                                                        taxonomy={taxonomyProperties[value]}
                                                                        property={value as EWineProperties}
                                                                        presets={presets}
                                                                        onChange={updatePreset}/>
                                                                    : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 offset-md-3">
                                            <Button variant="custom" className="mt-3" onClick={submitData}
                                                    disabled={submitDisabled}>
                                                Save
                                            </Button>
                                            <div className={"msg-data-saved" + (!dataSaved ? ' d-none' : '')}>
                                                <Check/> Data saved
                                            </div>
                                        </div>
                                    </div>
                                </>) : <Preloader show={true}/>
                            }
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default Presets;