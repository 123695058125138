import axios from "axios";
import { useContext, useState } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { AuthUserContext } from "../context/AuthUser";
import { capitalize } from "../helpers";

type VintageProps = {
  aggregate: {
    Bronze_count: number,
    // ... and others
  },
  awards: {
    award_id: number,
    event_id: number,
    medal: string,
    // ... and others
  }[],
  competitions: Record<number, {
    name: string,
    event: number,
  }>,
  vivino: {
    rating_5: string,
    rating_100: string,
  }
}

const GwmrCalculator = () => {
  const authUser = useContext(AuthUserContext);
  const axiosConfig = {
    headers: {
      Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : "",
    },
  };
  const [firstVintageId, setFirstVintageId] = useState("");
  const [secondVintageId, setSecondVintageId] = useState("");

  const [firstVintageData, setFirstVintageData] = useState<VintageProps>();
  const [secondVintageData, setSecondVintageData] = useState<VintageProps>();

  const [activeAccordion, setActiveAccordion] = useState<number[]>([]);

  const [buttonSubmitted, setButtonSubmitted] = useState(false);

  const calculate = () => {
    if (firstVintageId != "" || secondVintageId != "") {
      setButtonSubmitted(true);
      axios.post("/api/gwmr-calculate", {
        first_vintage_id: firstVintageId,
        second_vintage_id: secondVintageId,
      }, {
        ...axiosConfig,
      }).then((response) => {
        const data = response.data;
        if (firstVintageId) {
          if (data[firstVintageId] !== undefined) {
            setFirstVintageData(data[firstVintageId]);
          } else {
            setFirstVintageData(undefined);
            toast.error(`Vintage ID ${firstVintageId} not found.`);
          }
        }
        if (secondVintageId) {
          if (data[secondVintageId] !== undefined) {
            setSecondVintageData(data[secondVintageId]);
          } else {
            setSecondVintageData(undefined);
            toast.error(`Vintage ID ${secondVintageId} not found.`);
          }
        }
      }).catch(() => {
        toast.error("Oops, something went wrong.")
      }).finally(() => {
        setButtonSubmitted(false);
      });
    }
  };

  return (
    <div className="App">
      <div className="w-100">
        <Container fluid>
          <div className="row gwmr-calculator">
            <div className="col-md-12">
              <h4 className="mb-2 text-start">GWMR Calculator</h4>
              <hr/>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <label>1. Vintage ID</label>
                    <input
                      type="number" min="1" className="form-control mt-1"
                      onChange={(e) => setFirstVintageId(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-2 text-center">
                  <button className="btn btn-custom mt-4" onClick={calculate} disabled={buttonSubmitted}>Calculate</button>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <label>2. Vintage ID</label>
                    <input
                      type="number" min="1" className="form-control mt-1"
                      onChange={(e) => setSecondVintageId(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-5">
                  {firstVintageData !== undefined && (
                    <div>
                      <h4 className="text-center py-3 px-4 result-title">Aggregate</h4>
                      <div className="panel open">
                        <div className="panel-body">
                          <div className="d-flex gap-1 flex-column px-4 py-3">
                            {Object.entries(firstVintageData.aggregate).map(([key, value], index) => (
                              <div key={index} className="list-row"><span>{key}:</span> <span>{value}</span></div>
                            ))}
                            {Object.entries(firstVintageData.vivino).map(([key, value], index) => (
                              <div key={index} className="list-row"><span>{key}:</span> <span>{value}</span></div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <h4 className="text-center py-3 px-4 result-title mt-5">Awards</h4>
                      <ul className="awards-list">
                        {firstVintageData.awards.map((award, index) => (
                          <li key={index}>
                            <div className={`panel ${activeAccordion.includes(award.award_id) ? "open" : ""}`}>
                              <div
                                className="panel-header" onClick={() => {
                                setActiveAccordion(activeAccordion.includes(award.award_id) ? activeAccordion.filter((i) => i !== award.award_id) : [...activeAccordion, award.award_id]);
                              }}>
                                {firstVintageData.competitions[award.event_id].name} / {firstVintageData.competitions[award.event_id].event} / {capitalize(award.medal)}
                              </div>
                              <div className="panel-body">
                                <div className="d-flex gap-1 flex-column px-4 py-3">
                                  {Object.entries(award).map(([key, value], index) => (
                                    <div key={index} className="list-row"><span>{key}:</span> <span>{value}</span></div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="col-md-2 text-center"></div>
                <div className="col-md-5">
                  {secondVintageData !== undefined && (
                    <div>
                      <h4 className="text-center py-3 px-4 result-title">Aggregate</h4>
                      <div className="panel open">
                        <div className="panel-body">
                          <div className="d-flex gap-1 flex-column px-4 py-3">
                            {Object.entries(secondVintageData.aggregate).map(([key, value], index) => (
                              <div key={index} className="list-row"><span>{key}:</span> <span>{value}</span></div>
                            ))}
                            {Object.entries(secondVintageData.vivino).map(([key, value], index) => (
                              <div key={index} className="list-row"><span>{key}:</span> <span>{value}</span></div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <h4 className="text-center py-3 px-4 result-title mt-5">Awards</h4>
                      <ul className="awards-list">
                        {secondVintageData.awards.map((award, index) => (
                          <li key={index}>
                            <div className={`panel ${activeAccordion.includes(award.award_id) ? "open" : ""}`}>
                              <div
                                className="panel-header" onClick={() => {
                                setActiveAccordion(activeAccordion.includes(award.award_id) ? activeAccordion.filter((i) => i !== award.award_id) : [...activeAccordion, award.award_id]);
                              }}>
                                {secondVintageData.competitions[award.event_id].name} / {secondVintageData.competitions[award.event_id].event} / {capitalize(award.medal)}
                              </div>
                              <div className="panel-body">
                                <div className="d-flex gap-1 flex-column px-4 py-3">
                                  {Object.entries(award).map(([key, value], index) => (
                                    <div key={index} className="list-row"><span>{key}:</span> <span>{value}</span></div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>

            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default GwmrCalculator;
