type TProps = {
    show?: boolean,
    hide?: boolean
}

const Preloader = (props: TProps) => {
    return (
        <div className={"preloader text-center" + (props.show ? " d-block" : "") + (props.hide ? " d-none" : "")}>
            <img src={`${process.env.PUBLIC_URL}/images/1495.gif`} alt=""/>
        </div>
    )
}

export default Preloader;