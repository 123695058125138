import axios, { AxiosRequestConfig } from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { toast } from "react-toastify";
import Preloader from "../components/Preloader";
import { AuthUserContext } from "../context/AuthUser";

enum report_types {
  MATCHING_DATA = "matching_data",
  MERGE_WINES = "merge_wines",
  PHOTO_VIVINO_REVIEW = "photo_vivino_review",
  ACCESS_CREATE_ACCOUNT = "access_create_account",
  WINERIES_ACTIONS = "wineries_actions",
}

type TReportData = {
  count: number,
  email: string
}

const DailyReports = () => {

  const authUser = useContext(AuthUserContext);

  const axiosConfig:{headers: {Authorization: string}, responseType: string} = {
    headers: {
      Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ""
    },
    responseType: 'json'
  };

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [reportType, setReportType] = useState("");
  const [searchDisabled, setSearchDisabled] = useState(true);
  const [reportData, setReportData] = useState<TReportData[]>();
  const [reportHtml, setReportHtml] = useState('');
  const [loading, setLoading] = useState(false);
  const [downloadCsv, setDownloadCsv] = useState(false);
  const [canDownloadReport, setCanDownloadReport] = useState(false);

  const ReportTypes = [{
    label: "Matching data", value: report_types.MATCHING_DATA,
  }, {
    label: "Merge wines", value: report_types.MERGE_WINES,
  }, {
    label: "Photo and vivino review", value: report_types.PHOTO_VIVINO_REVIEW,
  }, {
    label: "Access/Create account", value: report_types.ACCESS_CREATE_ACCOUNT,
  }, {
    label: "Wineries Actions", value: report_types.WINERIES_ACTIONS,
  }];

  const searchReport = () => {
    if (reportType !== "" && (startDate || endDate)) {

      if (!downloadCsv) {
        setReportData(undefined);
        setLoading(true);
      }

      let axiosParams = axiosConfig;
      if (downloadCsv) {
        axiosParams = { ...axiosParams, responseType: "arraybuffer" };
      }

      let formParams: { type: typeof reportType, start_date: string, end_date: string, download?: boolean } = {
        type: reportType,
        start_date: startDate ? startDate.getDate().toString().padStart(2, "0") + "/" + (startDate.getMonth() + 1).toString().padStart(2, "0") + "/" + startDate.getFullYear() : "",
        end_date: endDate ? endDate.getDate().toString().padStart(2, "0") + "/" + (endDate.getMonth() + 1).toString().padStart(2, "0") + "/" + endDate.getFullYear() : "",
      };
      if (downloadCsv) {
        formParams["download"] = true;
      }

      axios.post("/api/daily-reports", formParams, axiosParams as AxiosRequestConfig).then(r => {
        let response = r.data;
        if (response.data) {
          setReportData(response.data);

          if (response.data.length) {
            setCanDownloadReport(true);
          } else {
            setCanDownloadReport(false);
          }
        }
        if (response.html) {
          setReportHtml(response.html);
        }

        if (downloadCsv) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "daily_report_" + reportType + ".csv");
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      }).catch(error => {
        if (error.response) {
          const responseData = error.response.data;
          if (responseData.hasOwnProperty("error")) {
            toast.error(responseData.error);
          }
        }
      }).finally(() => {
        setLoading(false);
        setDownloadCsv(false);
      });
    }
  };

  useEffect(() => {
    setSearchDisabled(!(reportType !== "" && (startDate || endDate)));
  }, [reportType, startDate, endDate]);

  useEffect(() => {
    if (downloadCsv && !searchDisabled) {
      searchReport();
    }
  }, [downloadCsv]);

  return (<div className="App">
    <div className="w-100">
      <Container fluid>
        <div className="row">
          <div className="col-md-12">
            <h4 className="mb-2 text-start">Daily reports</h4>
            <hr/>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-3">
                <label>Date start</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  className="w-100"
                  dateFormat="dd/MM/yyyy"
                  calendarStartDay={1}
                />
              </div>
              <div className="col-md-3">
                <label>Date end</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  className="w-100"
                  dateFormat="dd/MM/yyyy"
                  calendarStartDay={1}
                />
              </div>
              <div className="col-md-3">
                <label>Type</label>
                <Select className="w-100" options={ReportTypes} onChange={e => {
                  setReportType(e ? e.value : "");
                }}/>
              </div>
              <div className="col-md-3">
                <div className="row">
                  <div className="col-md-6">
                    <label>&nbsp;</label>
                    <button className="btn btn-custom w-100" onClick={searchReport} disabled={searchDisabled}>
                      Search
                    </button>
                  </div>
                  <div className="col-md-6">
                    <label>&nbsp;</label>
                    <button className="btn btn-outline-custom inherit w-100" disabled={!canDownloadReport} onClick={() => setDownloadCsv(true)}>
                      Download CSV
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                {loading ?
                  <Preloader show={true}/>
                  : reportHtml !== '' &&
                    <div dangerouslySetInnerHTML={{__html: reportHtml}} />
                }
                {!!(reportData && !reportData.length) &&
                  <div className="text-center mt-4">No data for selected filter.</div>
                }
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  </div>);
};

export default DailyReports;