import React, { Fragment } from "react";
import { Table } from "react-bootstrap";
import { Clipboard } from "react-bootstrap-icons";
import { copyToClipboard } from "../helpers";
import { TAutoWines } from "../pages/Wines";
import { TPagination } from "../properties";

type Props = {
  wines: TAutoWines[],
  pagination: TPagination,
  onChangePage: (pageNumber: number) => void,
  onChangeRowAction: (wineId: number, action: string) => void,
}

const WinesAutomatchTable = (props: Props) => {

  const onChangeAction = (wineId: number, action: string) => {
    props.onChangeRowAction(wineId, action);
  }

  const highlightText = (text: string|number, highlight: string|number) => {
    if (text == highlight) {
      return 'green_indicator';
    }
    if (text!= '' && highlight !='' && text != highlight) {
      return 'red_indicator';
    }
  }

  return (
    <>
      <Table striped bordered hover size="sm" className="automatch-table">
        <thead>
        <tr>
          <th>Wine name</th>
          <th>Winery</th>
          <th>Year</th>
          <th>Color</th>
          <th>Co2</th>
          <th>Country</th>
          <th>Gustos wine</th>
          <th>Gustos winery</th>
          <th>Year</th>
          <th>Color</th>
          <th>Co2</th>
          <th>Country</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        {props.wines.map(item => {
          return (
            <Fragment key={item.parsedwine.id}>
              <tr>
                <td>
                  <a
                    href="/#" title="Copy to clipboard"
                    onClick={(e) => {
                      e.preventDefault();
                      copyToClipboard(item.parsedwine.name).then(r => r);
                    }}
                  ><Clipboard/></a>
                  <a href={`/wine/${item.parsedwine.id}`} target={"_blank"}>
                    {item.parsedwine.name}
                  </a>
                </td>
                <td>
                  <a href={`/wines-merge/list?winery=${item.parsedwine.winery_id}`} className={highlightText(item.parsedwine.winery, item.gustoswine.winery)} target={"_blank"}>
                    {item.parsedwine.winery}
                  </a>
                </td>
                <td>
                  <span
                    className={highlightText(item.parsedwine.year, item.gustoswine.year)}
                  >{item.parsedwine.year}</span>
                </td>
                <td>
                  <span
                    className={highlightText(item.parsedwine.color, item.gustoswine.color)}
                  >{item.parsedwine.color}</span>
                </td>
                <td>
                  <span
                    className={highlightText(item.parsedwine.co2, item.gustoswine.co2)}
                  >{item.parsedwine.co2}</span>
                </td>
                <td>
                  {item.parsedwine.country}
                </td>
                <td>
                  <a
                    href="/#" title="Copy to clipboard"
                    onClick={(e) => {
                    e.preventDefault();
                    copyToClipboard(item.gustoswine.name).then(r => r);
                  }}
                  ><Clipboard/></a>
                  {item.gustoswine.name}
                </td>
                <td>
                  <a href={`/wines-merge/list?winery=${item.gustoswine.winery_id}`} className={highlightText(item.parsedwine.winery, item.gustoswine.winery)} target={"_blank"}>
                    {item.gustoswine.winery}
                  </a>
                </td>
                <td>
                  <span
                    className={highlightText(item.parsedwine.year, item.gustoswine.year)}
                  >{item.gustoswine.year}</span>
                </td>
                <td>
                  <span
                    className={highlightText(item.parsedwine.color, item.gustoswine.color)}
                  >{item.gustoswine.color}</span>
                </td>
                <td>
                  <span
                    className={highlightText(item.parsedwine.co2, item.gustoswine.co2)}
                  >{item.gustoswine.co2}</span>
                </td>
                <td>
                  {item.gustoswine.country}
                </td>
                <td>
                  <label className="mr-3" title={"MERGE SELECTED"}>
                    <input type={"radio"} name={`action[${item.parsedwine.id}]`} value={"MERGE_SELECTED"} defaultChecked={item.action === 'MERGE_SELECTED'} onChange={(e) => {
                      onChangeAction(item.parsedwine.id, e.target.value);
                    }} /> M
                  </label>
                  <label className="mr-3" title={"NEW VINTAGE"}>
                    <input type={"radio"} name={`action[${item.parsedwine.id}]`} value={"NEW_VINTAGE"} defaultChecked={item.action === 'NEW_VINTAGE'} onChange={(e) => {
                      onChangeAction(item.parsedwine.id, e.target.value);
                    }} /> V
                  </label>
                  <label className="mr-3" title={"NEW WINE"}>
                    <input type={"radio"} disabled name={`action[${item.parsedwine.id}]`} value={"NEW_WINE"} defaultChecked={item.action === 'NEW_WINE'} onChange={(e) => {
                      onChangeAction(item.parsedwine.id, e.target.value);
                    }} /> W
                  </label>
                  <label title={"ESCALATE"}>
                    <input type={"radio"} name={`action[${item.parsedwine.id}]`} value={"ESCALATED"} defaultChecked={item.action === 'ESCALATED'} onChange={(e) => {
                      onChangeAction(item.parsedwine.id, e.target.value);
                    }} /> E
                  </label>
                  <div>
                    Score: {item.score}
                  </div>
                </td>
              </tr>
            </Fragment>
          );
        })}
        </tbody>
      </Table>
    </>
  );
};

export default WinesAutomatchTable;
