import React, {useContext} from 'react';
import './App.css';
import Footer from "./components/Footer";
import Header from './components/Header';
import CompetitionEdit from "./pages/CompetitionEdit";
import CompetitionReview from "./pages/CompetitionReview";
import DailyReports from "./pages/DailyReports";
import GwmrCalculator from "./pages/GwmrCalculator";
import JobBatches from "./pages/JobBatches";
import TargetRevision from "./pages/TargetRevision";
import Upload from './pages/Upload';
import DataMatching from "./pages/DataMatching";
import {Redirect, Route, Switch} from "react-router-dom";
import Wines from "./pages/Wines";
import WineProfile from "./pages/WineProfile";
import Login from "./pages/Login";
import {AuthUserContext} from "./context/AuthUser";
import NameMatching from "./pages/NameMatching";
import Presets from "./pages/Presets";
import Rules from "./pages/Rules";
import WinesMergeList from "./pages/WinesMergeList";
import WinesMergeCompare from "./pages/WinesMergeCompare";
import CompareVersions from "./pages/CompareVersions";
import WineHistory from "./pages/WineHistory";
import WineryEdit from "./pages/WineryEdit";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Grapes from "./pages/Grapes";
import Regions from "./pages/Regions";
import PhotoVivinoReview from "./pages/PhotoVivinoReview";
import WineriesReview from "./pages/WineriesReview";
import Search from "./pages/Search";
import AdminSettings from "./pages/AdminSettings";

type TPrivateRouteProps = {
    path: string,
    exact?: boolean,
    permission?: string
}

function App() {
    const authUser = useContext(AuthUserContext);

    const PrivateRoute: React.FC<TPrivateRouteProps> = ({children, ...react}) => {
        return (
            <Route
                {...react}
                render={({location}) =>
                    authUser.authUser !== null ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {from: location}
                            }}
                        />
                    )
                }
            />
        );
    }
    const AdminRoute: React.FC<TPrivateRouteProps> = ({children, ...react}) => {
        return (
            <Route
                {...react}
                render={({location}) =>
                    authUser.authUser !== null && (authUser.authUser.is_admin || (react.permission && authUser.authUser.permissions?.length && authUser.authUser.permissions.includes(react.permission))) ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: {from: location}
                            }}
                        />
                    )
                }
            />
        );
    }

    return (
        <div>
            <ToastContainer hideProgressBar={true}/>
            <Header/>
            <Switch>
                <PrivateRoute exact path="/">
                    <Upload/>
                </PrivateRoute>
                <PrivateRoute exact path="/search">
                    <Search/>
                </PrivateRoute>
                <PrivateRoute path="/photo-vivino-review" permission="photo_vivino_review view">
                    <PhotoVivinoReview/>
                </PrivateRoute>
                <PrivateRoute path="/data-import/:file_hash">
                    <Upload/>
                </PrivateRoute>
                <PrivateRoute path="/data-matching/:file_hash/:row_token?">
                    <DataMatching/>
                </PrivateRoute>
                <PrivateRoute path="/name-matching/:wine_id">
                    <NameMatching/>
                </PrivateRoute>
                <PrivateRoute path="/wines">
                    <Wines/>
                </PrivateRoute>
                <PrivateRoute path="/wineries-review">
                    <WineriesReview/>
                </PrivateRoute>
                <AdminRoute path="/presets">
                    <Presets/>
                </AdminRoute>
                <AdminRoute path="/wines-merge/list" permission="merge_wines view">
                    <WinesMergeList/>
                </AdminRoute>
                <AdminRoute path="/wines-merge/compare/:wine_first/:wine_second" permission="merge_wines merge">
                    <WinesMergeCompare/>
                </AdminRoute>
                <AdminRoute path="/wines-merge/edit-winery/:winery_id" permission="merge_wines edit_winery">
                    <WineryEdit/>
                </AdminRoute>
                <AdminRoute path="/rules" permission="rules view">
                    <Rules/>
                </AdminRoute>
                <AdminRoute path="/job-batches" permission="wines match">
                    <JobBatches/>
                </AdminRoute>
                <AdminRoute path="/compare-versions/:vintage_id">
                    <CompareVersions/>
                </AdminRoute>
                <AdminRoute path="/wines-history">
                    <WineHistory/>
                </AdminRoute>
                <PrivateRoute path="/wine/:wine_id">
                    <WineProfile/>
                </PrivateRoute>
                <AdminRoute path="/grapes">
                    <Grapes/>
                </AdminRoute>
                <AdminRoute path="/regions">
                    <Regions/>
                </AdminRoute>
                <AdminRoute path="/target-revision">
                    <TargetRevision/>
                </AdminRoute>
                <AdminRoute path="/daily-reports">
                    <DailyReports/>
                </AdminRoute>
                <AdminRoute path="/competitions">
                    <CompetitionReview />
                </AdminRoute>
                <AdminRoute path="/competition/:id/edit">
                    <CompetitionEdit />
                </AdminRoute>
                <AdminRoute path="/gwmr-calculator">
                    <GwmrCalculator />
                </AdminRoute>
              <AdminRoute path="/admin-settings">
                <AdminSettings/>
              </AdminRoute>
                <Route path="/login">
                    <Login/>
                </Route>
            </Switch>
            <Footer />
        </div>
    );
}

export default App;
