import React, {useState} from "react";

type TProps = {
  last_page: number,
  loadData: (page: number) => void,
}

const GotoPagination = (props: TProps) => {
  const [goToPage, setGoToPage] = useState<number | string>('');

  return (
    <div className="pagination-goto mt-3 ml-4">
      Go to page: <input type="number" min="1" max={props.last_page}
                         onChange={item => {
                           let value = Number(item.target.value);
                           if (value > props.last_page) {
                             value = props.last_page;
                           }
                           setGoToPage(value);
                         }}
                         value={goToPage}/>
      <button className="btn" disabled={!goToPage}
              onClick={() => {
                props.loadData(Number(goToPage))
                setGoToPage('');
              }}>Go
      </button>
    </div>
  )
}

export default GotoPagination;