import React from "react";

type Props = {
  winesExist: boolean,
  onConfirmAutomatch: () => void,
  className?: string,
}

const AutomatchConfirmButton = (props: Props) => {
  return (
    <button
      className={`btn btn-outline-custom ${props.className ?? ''}`} disabled={!props.winesExist} onClick={props.onConfirmAutomatch}
    >Confirm All
    </button>
  );
};

export default AutomatchConfirmButton;
