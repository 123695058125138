import axios from "axios";
import { useContext } from "react";
import { Save2, Trash } from "react-bootstrap-icons";
import Async from "react-select/async";
import { AuthUserContext } from "../context/AuthUser";
import { customStyles } from "../helpers";

type TOptionGrape = {
  id: number,
  name: string
}

type TGrapes = {
  tid: number,
  grape: string,
  percent: number,
  idx: number,
}

type TProps = {
  grape: TGrapes,
  newGrapes: TGrapes[],
  updateGrapes: (map: TGrapes[]) => void,
  submitNewGrape: (idx: number) => void
}

const NewGrapeRow = (props:TProps) => {

  const authUser = useContext(AuthUserContext);
  const axiosConfig = {
    headers: {
      Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
    }
  };

  const loadGrapesOptions = (inputText: string, callback: (options: { label: string; value: number }[]) => void): void => {
    if (!inputText) {
      return callback([]);
    }
    if (inputText.length < 2) {
      return callback([]);
    }

    axios.post("/api/grapes/search", {
      q: inputText,
    }, {
      ...axiosConfig,
    }).then((response) => {
      let data: TOptionGrape[] = response.data.grapes;
      if (data.length) {
        return callback(data.map((result) => {
          return labelFormatter(result);
        }));
      } else {
        return callback([]);
      }
    });
  };

  const labelFormatter = (i: TOptionGrape) => {
    return {
      label: i.name,
      value: i.id,
    }
  }

  return (
    <tr>
      <td>
        <Async isClearable={true} loadOptions={loadGrapesOptions}
               styles={{valueContainer: (provided: any) => ({ ...provided,  gridTemplateColumns: '1fr' }) }}
               onChange={(item) => {
          const grapes = props.newGrapes.map((value) => {
            if (value.idx === props.grape.idx) {
              value.tid = item ? item.value : 0;
              value.grape = item ? item.label : "";
            }
            return value;
          });
          props.updateGrapes(grapes.map(v => v));
        }}/>
      </td>
      <td style={{width: '20%'}}>
        <input type="number" className="form-control" min="1" max="100" value={props.grape.percent} onChange={(e) => {
          let value = parseInt(e.target.value)
          if (e.target.value === "" || (value >= 1 && value <= 100)) {
            if (e.target.value === "") {
              value = 1;
            }

            const grapes = props.newGrapes.map((v) => {
              if (v.idx === props.grape.idx) {
                v.percent = value;
              }
              return v;
            });

            props.updateGrapes(grapes.map(v => v));
          }
        }}/>
      </td>
      <td style={{width: '10%'}} className="actions">
        <span className={props.grape.grape === "" ? `disabled` : ""} onClick={() => props.submitNewGrape(props.grape.idx)}><Save2 /></span>
        <span className="ml-3 text-danger" onClick={() => {
           const filtered = props.newGrapes.filter((r) => r.idx !== props.grape.idx);
           props.updateGrapes(filtered.map(v=>v));
        }}><Trash /></span>
      </td>
    </tr>
  )
}

export default NewGrapeRow;