import {createContext} from "react";

export type AuthUser = {
    email: string,
    api_key: string,
    is_admin?: number,
    count_wines_today?: number,
    count_merged_today?: number,
    roles?: string[]
    permissions?: string[]
};

type Props = {
    authUser: AuthUser | null,
    setAuthUser: (authUser: AuthUser | null) => void
};

export const initialState: Props = {
    authUser: null,
    setAuthUser: () => {
    }
};

export default createContext(initialState);