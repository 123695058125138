import ReactTooltip from "react-tooltip";
import { useHistory, useLocation } from "react-router";
import DragDropFile from "../components/DragDropFile";
import {BsModalSize, OptionType, TErrorObject, TPagination} from "../properties";
import { AlertError, downloadFile } from "../helpers";
import React, {useContext, useEffect, useRef, useState} from "react";
import { Container, Dropdown, Table } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import {AuthUserContext} from "../context/AuthUser";
import Async from "react-select/async";
import {toast} from "react-toastify";
import Pagination from "react-js-pagination";
import { CardImage, Pencil } from "react-bootstrap-icons";
import VivinoIcon from "../assets/images/vivino.svg";
import BsModal from "../components/BsModal";
import PreviewMultipleImages from "../components/PreviewMultipleImages";
import Preloader from "../components/Preloader";
import ImageViewer from 'react-simple-image-viewer';
import GotoPagination from "../components/GotoPagination";

export type TWines = {
  id: number,
  vintage: number,
  photo: string,
  vivino_vintage_id: string,
  vivino_wine_id: string,
  forget_user: string|null,
  wine: string,
  winery: string,
  country: string,
  photo_status: string,
  vivino_status: string,
  forget: boolean,
  wine_id: number
}

type TWinery = {
  id: number,
  name: string
};

type TBadImages = {
  id: number,
  filter: string,
  file_name: string,
  new: number,
  created_at: string
}

const PhotoVivinoReview = () => {

  const history = useHistory();

  const [currentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState<string[]>([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | TErrorObject>('');
  const [pagination, setPagination] = useState<TPagination>(null);
  const [countries, setCountries] = useState<OptionType[]>([]);
  const [categories, setCategories] = useState<OptionType[]>([]);
  const [competitionYears, setCompetitionYears] = useState<OptionType[]>([]);
  const [filter, setFilter] = useState<{ [k: string]: string | number }>({});
  const [wines, setWines] = useState<TWines[]>();
  const [openModalEditField, setOpenModalEditField] = useState(false);
  const [openModalUploadImages, setOpenModalUploadImages] = useState(false);
  const [bsError] = useState('');
  const [editField, setEditField] = useState<{ id: number, field: string, value: string, label: string }>();

  const [badImagesLinks, setBadImagesLinks] = useState<TBadImages[]>([]);
  const [hasNewBadImagesLinks, setHasNewBadImagesLinks] = useState(false);

  const [downloadBadImageDisabled, setDownloadBadImageDisabled] = useState(false);
  const [editFieldLoading, setEditFieldLoading] = useState(false);
  const [loadWines, setLoadWines] = useState(false);
  const [uploadVivinoLoading, setUploadVivinoLoading] = useState(false);
  const location = useLocation();
  const vivinoRef = useRef<HTMLInputElement>(null);

  const pageRangeDisplayed = 8;

  const authUser = useContext(AuthUserContext);
  const axiosConfig = {
    headers: {
      Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
    }
  };

  const editableFields = {
    vivino_vintage_id: 'Vivino vintage ID',
    vivino_wine_id: 'Vivino wine ID',
    photo: 'Photo',
  };

  let ModalEditField = {
    showModal: openModalEditField,
    hideModal: () => {
      setOpenModalEditField(false);
    },
    title: "Edit field",
    primaryButton: {
      title: 'Save',
      onClick: () => {
        submitEditField();
      }
    },
    confirmButton: false,
    bsError: bsError
  }

  let ModalUploadImages = {
    showModal: openModalUploadImages,
    hideModal: () => {
      setOpenModalUploadImages(false);
    },
    title: "Upload images",
    confirmButton: false,
    bsError: bsError,
    size: 'lg' as BsModalSize
  }

  const getBadWinesLinks = () => {
    if(Object.keys(filter).length !=- 0) {
      axios.get('/api/bad-images/listing?' + Object.keys(filter).map(key => key + '=' + filter[key]).join('&'), {
        ...axiosConfig,
      }).then((response) => {
        let data = response.data;
        setBadImagesLinks(data);

        if (data.length) {
          const hasNew = data.filter((item: TBadImages) => {
            return item.new === 1;
          });
          setHasNewBadImagesLinks(hasNew.length > 0);
        } else {
          setHasNewBadImagesLinks(false);
        }
      });
    }
  }

  const getWines = (page?: number, filterData?: typeof filter) => {
    page = page || 1;
    let params = 'page=' + page;

    if (Object.keys(filter).length) {
      params += '&' + Object.keys(filter).map(key => key + '=' + filter[key]).join('&');
    } else if (filterData && Object.keys(filterData).length) {
      params += '&' + Object.keys(filterData).map(key => key + '=' + filterData[key]).join('&');
    }
    setLoadWines(true);
    axios.get('/api/photo-vivino-review/listing?' + params, {
      ...axiosConfig
    }).then((response) => {
      let data = response.data;

      if (data.hasOwnProperty('data')) {
        setWines(data.data.map((f: any) => f));

        let paginationData = {
          'current_page': data.current_page,
          'from': data.from,
          'last_page': data.last_page,
          'first_page_url': data.first_page_url,
          'last_page_url': data.last_page_url,
          'next_page_url': data.next_page_url,
          'path': data.path,
          'per_page': data.per_page,
          'prev_page_url': data.prev_page_url,
          'to': data.to,
          'total': data.total
        };
        setPagination(paginationData);

        getBadWinesLinks();
      }
    }).finally(() => {
      setLoadWines(false);
    });
  }

  const loadFilterData = () => {
    axios.get('/api/photo-vivino-review/init', {
      ...axiosConfig
    }).then(response => {
      let data = response.data;
      if (data.hasOwnProperty('countries')) {
        setCountries(data.countries);
      }

      if (data.hasOwnProperty('categories')) {
        setCategories(data.categories);
      }

      if (data.hasOwnProperty('competition_years')) {
        setCompetitionYears(data.competition_years);
      }
    }).catch(r => {
      if (r.response && r.response.status === 403) {
        history.push('/');
      }
    })
  }

  const wineryOptions = (inputText: string, callback: (options: OptionType[]) => void): void => {
    if (!inputText) {
      return callback([]);
    }
    if (inputText.length < 2) {
      return callback([]);
    }
    setLoading(true);

    axios.post('/api/search-winery', {q: inputText}, {
      ...axiosConfig
    }).then((response) => {
      let data: TWinery[] = response.data.wineries;
      setLoading(false);
      if (data.length) {
        return callback(data.map((result) => {
          return labelFormatter(result);
        }))
      } else {
        return callback([]);
      }
    }).catch(r => {

    });
  };

  const labelFormatter = (i: TWinery): OptionType => {
    return {
      label: i.name,
      value: i.id.toString(),
    }
  }

  const submitFilter = () => {
    if (!Object.keys(filter).length) {
      toast.error('Filter cant be empty');
      return false;
    }

    getWines(1);
  }

  const updateFilter = (key: string, value: string | number) => {
    if (value) {
      setFilter({...filter, [key]: value});
    } else {
      const {[key]: value, ...rest} = filter;
      if (key === 'only_with_medals' && filter.hasOwnProperty('competition_year')) {
        delete rest['competition_year'];
      }
      setFilter(rest);
    }
  }

  const validVivino = (wine_entity_id: number) => {
    axios.post('/api/photo-vivino-review/valid-vivino', {
      'wine_entity_id': wine_entity_id
    }, {
      ...axiosConfig
    }).then(r => {
      let result = r.data;

      if (result.hasOwnProperty('error')) {
        toast.error(result.error);
        return false;
      }

      const idx = wines?.findIndex(item => item.id === wine_entity_id);
      if (typeof idx !== "undefined" && wines) {
        wines[idx].vivino_status = result.vivino_status;
        setWines(wines.map(f => f));
      }

      if (result.hasOwnProperty('msg')) {
        toast.success(result.msg);
        return true;
      }
    });
  }

  const forgetVivino = (wine_entity_id: number) => {
    axios.get('/api/photo-vivino-review/forget-vivino?wine_entity_id=' + wine_entity_id, {
      ...axiosConfig
    }).then(r => {
      let result = r.data;

      if (result.hasOwnProperty('error')) {
        toast.error(result.error);
        return false;
      }

      if (result.hasOwnProperty('success')) {
        const idx = wines?.findIndex(item => item.id === wine_entity_id);
        if (typeof idx !== "undefined" && wines) {
          wines[idx].forget = true;
          setWines(wines.map(f => f));
        }
      }

      if (result.hasOwnProperty('msg')) {
        toast.success(result.msg);
        return true;
      }
    });
  }

  const rememberVivino = (wine_entity_id: number) => {
    axios.get('/api/photo-vivino-review/remember-vivino?wine_entity_id=' + wine_entity_id, {
      ...axiosConfig
    }).then(r => {
      let result = r.data;

      if (result.hasOwnProperty('error')) {
        toast.error(result.error);
        return false;
      }

      if (result.hasOwnProperty('success')) {
        const idx = wines?.findIndex(item => item.id === wine_entity_id);
        if (typeof idx !== "undefined" && wines) {
          wines[idx].forget = false;
          setWines(wines.map(f => f));
        }
      }

      if (result.hasOwnProperty('msg')) {
        toast.success(result.msg);
        return true;
      }
    });
  }

  const editVivino = (item: TWines, label: string) => {
    const key = (Object.keys(editableFields) as (keyof typeof editableFields)[]).find((key) => {
      return editableFields[key] === label;
    });

    setEditField({
      id: item.id,
      field: key || '',
      value: '',
      label: label
    });

    setOpenModalEditField(true);
  }

  const submitEditField = () => {
    if (editField) {
      setEditFieldLoading(true);
      axios.post('/api/photo-vivino-review/save-edit-field', editField, {
        ...axiosConfig
      }).then(r => {
        let result = r.data;

        if (result.hasOwnProperty('error')) {
          toast.error(result.error);
          return false;
        }

        if (result.hasOwnProperty('success')) {
          toast.success(result.msg);
          setOpenModalEditField(false);
          setEditField(undefined);

          const idx = wines?.findIndex(item => item.id === editField.id);
          if (typeof idx !== "undefined" && wines && editField.field) {

            let value = result.hasOwnProperty('data') && result.data.hasOwnProperty('value') && result.data.value !== '' ? result.data.value : editField.value;

            ((wines[idx][editField.field as keyof TWines]) as any as TWines) = value as any as TWines;

            if (result.hasOwnProperty('data') && result.data.hasOwnProperty('wine_id')) {
              wines[idx].vivino_wine_id = result.data.wine_id;
            }

            if (result.hasOwnProperty('data') && result.data.hasOwnProperty('status')) {
              if (editField.field === 'photo') {
                wines[idx].photo_status = result.data.status;
              }
              if (editField.field === 'vivino_vintage_id' || editField.field === 'vivino_wine_id') {
                wines[idx].vivino_status = result.data.status;
              }
            }

            setWines(wines.map(f => f));
          }
        }
      }).finally(() => {
        setEditFieldLoading(false);
      })
    }
  }

  const buildBadImages = () => {
    setDownloadBadImageDisabled(true);
    axios.post('/api/bad-images/build', filter, {
      ...axiosConfig,
    }).then(r => {
      const data = r.data;
      if (data.hasOwnProperty('error')) {
        toast.error(data.error);
        return false;
      }

      if (data.hasOwnProperty('msg')) {
        toast.success(data.msg);
        return true;
      }
    })
      .finally(() => {
        setDownloadBadImageDisabled(false);
      });
  }

  const downloadBadImages = (file_id: number) => {
    const filterData = { ...filter, ["file_id" as string]: file_id };
    const queryString = Object.keys(filterData).map(key => key + '=' + filterData[key]).join('&');
    window.open('/api/bad-images/download?' + queryString, '_blank');

    const updated = badImagesLinks.map((item) => {
      if (item.id === file_id) {
        item.new = 0;
      }
      return item;
    });
    setBadImagesLinks(updated);

    const hasNew = updated.filter((item) => {
      return item.new === 1;
    });
    setHasNewBadImagesLinks(hasNew.length > 0);
  }

  const downloadWithoutVivino = () => {
    axios.post('/api/photo-vivino-review/download-without-vivino', filter, {
      ...axiosConfig,
      responseType: 'arraybuffer'
    }).then((response) => {
      downloadFile(response.data, 'vintages_without_vivino.csv');
    });
  }

  const uploadVivinoId = (e: React.ChangeEvent<HTMLInputElement>) => {

    if (!e.target.files) return false

    if (typeof e.target.files === "undefined") return false;

    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    setUploadVivinoLoading(true);
    axios.post('/api/photo-vivino-review/upload-vivino', formData, {
      ...axiosConfig,
    }).then((r) => {
      let response = r.data;

      if (null !== vivinoRef.current) {
        vivinoRef.current.value = '';
      }

      if (response.hasOwnProperty('error')) {
        toast.error(response.error);
        return false;
      }

      if (response.hasOwnProperty('success')) {
        toast.success(response.msg);

        getWines(pagination ? pagination.current_page : 1);
      }

    }).catch(r => {

    }).finally(() => {
      setUploadVivinoLoading(false);
    });
  }

  const VivinoUrl = (props: { id: string }) => {
    return (
      <a
        href={`https://www.vivino.com/internal/widget/vrp?vintage_id=` + props.id}
        target="_blank">{props.id}</a>
    );
  }

  const openPreviewImage = (src: string) => {
    setPreviewImage([src]);
    setIsViewerOpen(true);
  }

  const closeImageViewer = () => {
    setPreviewImage([]);
    setIsViewerOpen(false);
  }

  const callbackWineImageUploaded = (callback: { url: string, status: string, wine_entity_id: number }) => {
    const idx = wines?.findIndex(item => item.id == callback.wine_entity_id);
    if (typeof idx !== "undefined" && wines) {
      wines[idx].photo_status = callback.status;
      wines[idx].photo = callback.url;
      setWines(wines.map(f => f));

      toast.success("Image uploaded successfully");
    }

    setOpenModalEditField(false);
    setEditField(undefined);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);

    loadFilterData();
    let filterData: { [k: string]: string | number } = {};
    filterData['only_with_medals'] = 1;
    if (location.search !== '' && query.get('wineNameLike')) {
      filterData['wineNameLike'] = query.get('wineNameLike') ?? '';
    }
    setFilter(filterData);
    if (Object.keys(filterData).length > 1) {
      getWines(1, filterData);
    }
  }, []);

  return (
    <div className="App">
      <div className="data-matching-page w-100">
        <Container fluid>
          <div className="row">
            <div className="col-md-12">
              <h4 className="mb-3 text-start">Photo and vivino
                review {pagination ? '(' + pagination.total + ')' : ''}</h4>
              <hr/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <AlertError error={error} callback={() => setError('')}/>
              <h5>Filter</h5>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <label>Country</label>
                    <Select options={countries} isClearable={true}
                            onChange={(item) => {
                              updateFilter('country', item ? item.value : '');
                            }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <label>Winery</label>
                    <Async isClearable={true} loadOptions={wineryOptions} isLoading={loading}
                           placeholder={"Insert some text..."}
                           onChange={(item) => {
                             updateFilter('vineyard', item ? item.value : '');
                           }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <label>Wine entity ID</label>
                    <input type="number" className="form-control" min="1" step="1"
                           value={filter['id'] || ''}
                           onChange={(item) => {
                             updateFilter('id', item.target.value);
                           }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <label>Wine name</label>
                    <input type="text" className="form-control"
                           value={filter['wineNameLike'] || ''}
                           onChange={(item) => {
                             updateFilter('wineNameLike', item.target.value);
                           }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label>Category</label>
                    <Select options={categories} isClearable={true}
                            onChange={(item) => {
                              updateFilter('category', item ? item.value : '');
                            }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label>GWMR rating start with</label>
                    <input type="number" min="1" max="100" className="form-control"
                           placeholder={"All ratings"}
                           value={filter['gwmr_rating'] || ''}
                           onChange={(item) => {
                             const value = Number(item.target.value);
                             if (item.target.value!=='' && value < 1 || value > 100) {
                               return false;
                             }
                             updateFilter('gwmr_rating', value);
                           }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label>Medal year</label>
                    <Select options={competitionYears} isClearable={true} placeholder={"All years"}
                            key={`competition_year_key_${filter['competition_year'] ?? ""}`}
                            isDisabled={!filter['only_with_medals']}
                            defaultValue={filter['competition_year'] ? {label: filter['competition_year'], value: filter['competition_year']} : null}
                            onChange={(item) => {
                              updateFilter('competition_year', item ? item.value : '');
                            }}
                    />
                  </div>
                </div>
                <div className="col-md-2 offset-md-5">
                  <div className="form-group mb-3">
                    <label>&nbsp;</label>
                    <button className="btn btn-custom w-100" onClick={submitFilter}
                            disabled={!Object.keys(filter).filter((v, k) => {
                              return v !== 'only_with_medals';
                            }).length || loadWines}>Filter
                    </button>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group mb-3 mt-4">
                    <label>
                      <input type="checkbox" checked={!!filter['only_with_medals']} onChange={(item) => {
                        updateFilter('only_with_medals', +item.target.checked);
                      }}/> - Only with medals
                    </label>
                  </div>
                </div>
              </div>
              <div className="contest mb-5">
                <div className="row">
                  <div className="col-md-12">
                    {wines?.length ?
                      <>
                        <div className="d-flex flex-row mt-4 justify-content-between">
                          <div className="btn-group mr-4">
                            <button className="btn btn-outline-custom"
                                    disabled={!wines?.length || downloadBadImageDisabled}
                                    onClick={buildBadImages}>Build BAD images
                            </button>
                            <Dropdown className="btn-group">
                              <Dropdown.Toggle variant="outline-custom" className="inherit" id="dropdown-basic"
                                                disabled={!wines?.length}>
                                {hasNewBadImagesLinks && (
                                  <span className="has-unread" />
                                )}
                                &nbsp;
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {badImagesLinks.map((item, key) => {
                                  return (
                                    <Dropdown.Item key={key} onClick={() => {
                                      downloadBadImages(item.id);
                                    }}>
                                      {item.new === 1 && (
                                        <span className="badge bg-primary">new</span>
                                      )} <span title={`Created at: ${new Date(item.created_at).toLocaleString()}`}>{item.file_name}</span></Dropdown.Item>
                                  )
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <button className="btn btn-outline-custom mr-4" disabled={!wines?.length}
                                  onClick={(e) => {
                                    e.currentTarget.blur();
                                    setOpenModalUploadImages(true);
                                  }}>Upload images
                          </button>
                          <button className="btn btn-outline-custom mr-4 justify-content-between"
                                  disabled={!wines?.length}
                                  onClick={(e) => {
                                    e.currentTarget.blur();
                                    downloadWithoutVivino();
                                  }}>
                            Download wines w/o vivino
                          </button>

                          <label className="upload-vivino">
                            <input type="file" accept="text/csv" onChange={uploadVivinoId}
                                   ref={vivinoRef}
                                   disabled={uploadVivinoLoading}/>
                            Upload Vivino ID
                          </label>

                        </div>
                        <Table striped bordered hover size="md" className="mt-4 table-fixed custom">
                          <thead>
                          <tr>
                            <th>ID</th>
                            <th>Winery</th>
                            <th>Wine name</th>
                            <th>Country</th>
                            <th>Year</th>
                            <th>Photo</th>
                            <th>
                              <div className="d-flex">
                                <img src={VivinoIcon} alt="Vivino Logo" className="mr-1"/> vintage ID
                              </div>
                            </th>
                            <th>Verified</th>
                            <th>
                              <div className="d-flex">
                                <img src={VivinoIcon} alt="Vivino Logo" className="mr-1"/> wine ID
                              </div>
                            </th>
                            <th>Forget</th>
                          </tr>
                          </thead>
                          <tbody>
                          {wines.map(item => {
                            return (
                              <tr key={item.id} className={`forget-` + Number(item.forget)}>
                                <td><a
                                  href={`${process.env.REACT_APP_GUSTOS_APP_URL}/wine/${item.wine_id}/show?vintage=${item.vintage}`}
                                  target={"_blank"}>{item.id}</a></td>
                                <td>{item.winery}</td>
                                <td>
                                  <div className="d-flex justify-content-between">
                                    {item.wine}
                                    <a
                                      href={`https://www.vivino.com/search/wines?q=` + item.winery + ' ' + item.wine + ' ' + item.vintage}
                                      rel={"nofollow"} target={"_blank"}>
                                      <img src={VivinoIcon} alt="Vivino Logo"/>
                                    </a>
                                  </div>
                                </td>
                                <td>{item.country}</td>
                                <td>{item.vintage}</td>
                                <td>
                                  <div className={`d-flex justify-content-center photo-status-` + item.photo_status}>
                                    {item.photo ? <a href='/#'
                                                     title={item.photo_status.charAt(0).toUpperCase() + item.photo_status.slice(1) + ' image'}
                                                     onClick={(e) => {
                                                       e.preventDefault();
                                                       openPreviewImage(item.photo)
                                                     }}><CardImage/></a> :
                                      ''}
                                    <button className="link-edit"
                                            onClick={() => editVivino(item, editableFields.photo)}>
                                      <Pencil/></button>
                                  </div>
                                </td>
                                <td>
                                  <div className={`d-flex justify-content-between vivino-row-` + item.vivino_status}>
                                    {item.vivino_vintage_id ?
                                      <VivinoUrl id={item.vivino_vintage_id}/>
                                      : ''}
                                    <button className="link-edit"
                                            onClick={() => editVivino(item, editableFields.vivino_vintage_id)}>
                                      <Pencil/>
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center">
                                    <input type="checkbox" checked={item.vivino_status === 'valid'}
                                           disabled={item.vivino_status === 'valid'}
                                           onChange={e => {
                                             if (e.target.checked) {
                                               validVivino(item.id);
                                             }
                                           }}/>
                                  </div>
                                </td>
                                <td>
                                  <div className={`d-flex justify-content-between vivino-row-` + item.vivino_status}>
                                    {item.vivino_wine_id ?
                                      <VivinoUrl id={item.vivino_wine_id}/>
                                      : ''}
                                    <button className="link-edit"
                                            onClick={() => editVivino(item, editableFields.vivino_wine_id)}><Pencil/>
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center">
                                    {item.forget && item.forget_user ?
                                      <ReactTooltip id={"wine-forget-" + item.id}
                                                    className="adp-tooltip--wine">
                                        {item.forget_user}
                                      </ReactTooltip>
                                      : ''
                                    }
                                    <input type="checkbox"
                                           data-tip=""
                                           data-for={"wine-forget-" + item.id}
                                           checked={item.forget} onChange={e => {
                                      if (e.target.checked) {
                                        forgetVivino(item.id);
                                      } else {
                                        rememberVivino(item.id);
                                      }
                                    }}/>
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                          }
                          </tbody>
                        </Table>
                        {pagination !== null ?
                          <>
                            <Pagination activePage={pagination.current_page}
                                        itemsCountPerPage={pagination.per_page}
                                        onChange={(pageNumber) => {
                                          getWines(pageNumber)
                                        }}
                                        totalItemsCount={pagination.total}
                                        pageRangeDisplayed={pageRangeDisplayed}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        firstPageText="First Page"
                                        lastPageText="Last Page"
                                        innerClass="pagination mt-3 inline float-left"
                            />
                            <GotoPagination last_page={pagination.last_page} loadData={getWines}/>
                          </>
                          : ''
                        }
                        <BsModal {...ModalEditField}>
                          <p>Insert URL for field <strong>{editField ? editField.label : ''}</strong>.</p>
                          <div className={`form-group mb-2 edit-field-box ` + (editFieldLoading ? `loading` : ``)}>
                            <label>URL*</label>
                            <input
                              type="text" className="w-100 custom-input-text" onChange={item => {
                              if (editField) {
                                let data = Object.assign(editField, {});
                                data.value = item.target.value;
                                setEditField(data);
                              }
                            }}
                            />
                            {editField && editField.field === 'photo' ?
                              <div className="upload-container">
                                <span className="separator">or</span>
                                <DragDropFile
                                  accept={["image/jpg", "image/jpeg", "image/png", "image/webp"]}
                                  api={{ url: `/api/photo-vivino-review/upload-wine-image/${editField.id}` }}
                                  callback={callbackWineImageUploaded}
                                />
                              </div>
                            : ''}
                          </div>
                        </BsModal>
                        <BsModal {...ModalUploadImages}>
                          <PreviewMultipleImages
                            wines={wines} format={"<VintageID>_<random_name>.<extension>"}
                            extensions={["jpg", "jpeg", "png"]} uploadUrl={"/api/photo-vivino-review/upload-images"}
                          />
                        </BsModal>
                        {isViewerOpen && (
                          <ImageViewer
                            src={previewImage}
                            currentIndex={currentImage}
                            disableScroll={false}
                            closeOnClickOutside={true}
                            onClose={closeImageViewer}
                          />
                        )}
                      </>
                      : (loadWines ? <Preloader/> : '')
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default PhotoVivinoReview;
