import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {useHistory} from "react-router";
import { toast } from "react-toastify";
import {AuthUserContext} from "../context/AuthUser";

type TFiles = {
    file_name: string,
    event?: number | null,
    competition?: number,
    hash: string,
    created_at: string,
    status: string,
    count_rows: number,
    total_free_rows: number
    count_unprocessed: number
}
const UploadedFilesList = () => {
    const [files, setFiles] = useState<TFiles[]>([]);
    const history = useHistory();
    const authUser = useContext(AuthUserContext);

    const [showProcessed, setShowProcessed] = useState(false);
    const [removeFileInProgress, setRemoveFileInProgress] = useState(false);

    const axiosConfig = {
        headers: {
            Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
        }
    };

    const getUploadedFiles = () => {
        let query = '';
        if (showProcessed) {
            query = '?processed=1';
        }
        axios.get('/api/uploaded-files-list' + query, {
            ...axiosConfig
        })
            .then(r => {
                let result = r.data

                if (result.hasOwnProperty('files')) {
                    setFiles(result.files)
                }
            })
            .catch(r => {
                if (r.response.status === 401) {
                    authUser.setAuthUser(null);
                    localStorage.removeItem('auth_user');
                }
            });
    }

    const removeUploadedFile = (file_hash: string) => {
        if (!window.confirm("Are you sure want to delete file?")) {
            return false;
        }

        setRemoveFileInProgress(true);

        axios.post("/api/remove-uploaded-file", {
            file_hash: file_hash,
        }, {
            ...axiosConfig,
        }).then(r => {
            let result = r.data;
            if (result.hasOwnProperty("success")) {
                toast.success(result.msg);

                let filtered = files.filter(item => item.hash !== file_hash);
                setFiles(filtered);

                return true;
            } else {
                toast.error(result.msg);
                return false;
            }
        }).catch(r => {
        }).finally(() => {
            setRemoveFileInProgress(false);
        });
    };

    useEffect(() => {
        getUploadedFiles();
    }, [showProcessed]);

    return (
        <div className="files-listing mt-5 col-md-8 offset-md-2">
            {authUser.authUser && authUser.authUser.is_admin
                ? (<div className="mt-2 mb-4"><label><input type="checkbox" value="1"
                                                            onChange={(e) => setShowProcessed(e.target.checked)}/> -
                    Show processed</label></div>)
                : ''
            }
            {files ?
                files.map((item, index) => {
                    return (
                        <div className="row mb-3 align-items-center d-flex" key={index}>
                            <div className="col-md-1">
                                <img src={`${process.env.PUBLIC_URL}/images/xls_icon.png`} alt="File icon"/>
                            </div>
                            <div className="col-md-8">
                                <div className="text-ellipsis">
                                    {item.file_name} <br/><small>Uploaded at {item.created_at} |
                                    Rows: {item.count_rows} |
                                    Unprocessed: {item.count_unprocessed}</small>
                                </div>
                            </div>
                            <div className="col-md-3 d-flex">
                                {!!(authUser.authUser && authUser.authUser.is_admin) &&
                                    <button className="custom-default inherit mr-2" disabled={removeFileInProgress} onClick={() => removeUploadedFile(item.hash)}>x</button>
                                }
                                {item.status === 'DONE'
                                    ? <button className="custom-default w-100" disabled>Done</button>
                                    : (item.event ?
                                        <button className="custom-default inherit w-100"
                                                onClick={() => history.push("/data-matching/" + item.hash)}
                                                disabled={item.total_free_rows === 0}>Matching
                                            data</button> : <button className="custom-default inherit w-100"
                                                                    onClick={() => history.push("/data-import/" + item.hash)}>Define
                                            data</button>)
                                }
                            </div>
                        </div>
                    )
                })
                : ''
            }
        </div>
    )
}

export default UploadedFilesList;
