import React, {useContext, useEffect, useState} from "react";
import { toast } from "react-toastify";
import GoogleLogo from "../assets/images/Google_G_Logo.svg";
import {AlertError, copyToClipboard, hasPermissionTo} from "../helpers";
import {Container, Form, Table} from "react-bootstrap";
import {AuthUserContext} from "../context/AuthUser";
import {
    BsModalSize,
    ENewFieldValueStatus,
    EWineProperties,
    TErrorObject, TNewRegion,
    TNewVineyard,
    TPagination,
    TRule
} from "../properties";
import DatePicker from "react-datepicker";
import axios from "axios";
import { useHistory, useLocation } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "react-i18next";
import Preloader from "../components/Preloader";
import Pagination from "react-js-pagination";
import BsModal from "../components/BsModal";
import {Clipboard, PersonFill} from "react-bootstrap-icons";
import SearchGoogleMini from "../components/SearchGoogleMini";
import RuleEditInput from "../components/RuleEditInput";
import {SingleValue} from "react-select";
import HelpIcon from "../assets/images/help-circle.svg";

type TUsers = {
    [key: number]: string
}

type TFilter = {
    [key: string]: string | number
}

enum EFilter {
    field = 'FIELD',
    search = 'SEARCH',
    user = 'USER',
    date = 'DATE',
    term = 'TERM'
}

type TTaxonomy = {
    [key: string]: object
}

type OptionType = {
    value: string;
    label: string;
    original_name?: string;
};

type TEditDataRule = {
    property: EWineProperties,
    option: SingleValue<OptionType> | null
} | null;

const Rules = () => {
    const authUser = useContext(AuthUserContext);
    const pageRangeDisplayed = 8;
    const history = useHistory();
    const location = useLocation();
    const {t} = useTranslation();
    const axiosConfig = {
        headers: {
            Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
        }
    };

    const [error, setError] = useState<string | TErrorObject>('');
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [users, setUsers] = useState<TUsers>();
    const [filter, setFilter] = useState<TFilter>();
    const [rules, setRules] = useState<TRule[]>([]);
    const [canSearch, setCanSearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<TPagination>(null);
    const [disabledButton, setDisabledButton] = useState<number[]>([]);
    const [bsError, setBsError] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [editRule, setEditRule] = useState<TRule>();
    const [taxonomyProperties, setTaxonomyProperties] = useState<TTaxonomy>();
    const [preselectedValue, setPreselectedValue] = useState<OptionType | null>(null);
    const [editData, setEditData] = useState<TEditDataRule>(null);
    const [submitDisabledSaveEditRule, setSubmitDisabledSaveEditRule] = useState(true);
    const [loadFrozen, setLoadFrozen] = useState(false);
    const [deleteVineyard, setDeleteVineyard] = useState(false);
    const [vineyardWithWines, setVineyardWithWines] = useState(false);

    const [openModalAddVineyard, setOpenModalAddVineyard] = useState<boolean>(false);
    const [newVineyard, setNewVineyard] = useState<TNewVineyard | null>(null);
    const [similarVineyards, setSimilarVineyards] = useState([]);
    const [newVineyardInputDisabled, setNewVineyardInputDisabled] = useState(false);
    const [newVineyardConfirmButton, setNewVineyardConfirmButton] = useState(false);
    const [submitCreateNewVineyard, setSubmitCreateNewVineyard] = useState(false);

    const [openModalSearchHelp, setOpenModalSearchHelp] = useState<boolean>(false);

    const [submitCreateNewRegion, setSubmitCreateNewRegion] = useState(false);
    const [similarRegions, setSimilarRegions] = useState([]);
    const [newRegion, setNewRegion] = useState<TNewRegion | null>(null);
    const [newRegionConfirmButton, setNewRegionConfirmButton] = useState(false);
    const [newRegionInputDisabled, setNewRegionInputDisabled] = useState(false);
    const [openModalAddRegion, setOpenModalAddRegion] = useState<boolean>(false);
    const [countries, setCountries] = useState<{ id: number, name: string }[]>([]);

    const fields = [EWineProperties.vineyard, EWineProperties.year, EWineProperties.color, EWineProperties.sugar, EWineProperties.alcohol, EWineProperties.co2, EWineProperties.country, EWineProperties.region, EWineProperties.award];

    const ModalEditRule = {
        showModal: showEditModal,
        hideModal: () => {
            hideModalEditRule();
        },
        title: "Edit rule",
        primaryButton: {
            title: 'Save',
            onClick: () => {
                updateRuleValue();
            },
            disabled: submitDisabledSaveEditRule
        },
        confirmButton: false,
        bsError: bsError,
        confirmButtonClick: () => {

        },
        size: 'lg' as BsModalSize
    }

    const ModalAddRegionProps = {
        showModal: openModalAddRegion,
        hideModal: () => {
            hideModalAddRegion()
        },
        title: "Add new region",
        primaryButton: {
            title: 'Create',
            onClick: () => {
                checkSimilarRegion()
            }
        },
        confirmButton: newRegionConfirmButton,
        bsError: bsError,
        confirmButtonClick: () => {
            storeRegion()
        }
    }

    const ModalAddVineyardProps = {
        showModal: openModalAddVineyard,
        hideModal: () => {
            hideModalAddVineyard()
        },
        title: "Add new vineyard",
        primaryButton: {
            title: 'Create',
            onClick: () => {
                checkSimilarVineyard()
            }
        },
        bsError: bsError,
        confirmButton: newVineyardConfirmButton,
        confirmButtonClick: () => {
            storeVineyard()
        }
    }

    const ModalSearchHelpProps = {
        showModal: openModalSearchHelp,
        hideModal: () => {
            setOpenModalSearchHelp(false)
        },
        title: "About search",
        bsError: bsError,
        confirmButton: false,
    }

    const checkSimilarRegion = () => {
        if (newRegion === null) {
            return false;
        }

        setBsError('');

        axios.post('/api/check-region', {
            region: newRegion.name
        }, {
            ...axiosConfig
        }).then(r => {
            let result = r.data;

            if (result.hasOwnProperty('err')) {
                setBsError(result.err);
                return false;
            }

            if (result.hasOwnProperty('similar') && result.similar.length) {
                setSimilarRegions(result.similar);
            } else {
                setSubmitCreateNewRegion(true);
                return true;
            }

            setNewRegionConfirmButton(true);
            setNewRegionInputDisabled(true);
        }).catch(r => {
            setBsError(r.toString());
        });
    }

    const storeRegion = () => {
        if (newRegion === null) {
            return false;
        }
        setSubmitCreateNewRegion(false);

        setBsError('');

        if (newRegion.status === ENewFieldValueStatus.EXISTING) {
            if (typeof newRegion.id !== "undefined") {
                let option: OptionType = {value: newRegion.id.toString(), label: newRegion.name}
                setPreselectedValue(option);
                updateEditRules(EWineProperties.region, option);
            }

            hideModalAddRegion();
        } else if (newRegion.status === ENewFieldValueStatus.NEW) {

            if (typeof newRegion.country === "undefined" || !newRegion.country) {
                setBsError('Country not selected');
                return false;
            }

            axios.post('/api/store-region', {
                region: newRegion.name,
                country: newRegion.country
            }, {
                ...axiosConfig
            }).then(r => {
                let result = r.data;

                if (result.hasOwnProperty('err')) {
                    setBsError(result.err);
                    return false;
                }

                if (result.hasOwnProperty('region')) {
                    let option: OptionType = {value: result.region.id.toString(), label: result.region.name}
                    setPreselectedValue(option);
                    updateEditRules(EWineProperties.region, option);
                }

                hideModalAddRegion();
            }).catch(r => {
                setBsError(r.toString());
            });
        }
    }

    const checkSimilarVineyard = () => {
        if (newVineyard === null) {
            return false;
        }

        setBsError('');
        axios.post('/api/check-vineyard', {
            vineyard: newVineyard.name
        }, {
            ...axiosConfig
        }).then(r => {
            let result = r.data;

            if (result.hasOwnProperty('err')) {
                setBsError(result.err);
                return false;
            }

            if (result.hasOwnProperty('similar') && result.similar.length) {
                setSimilarVineyards(result.similar);
            } else {
                setSubmitCreateNewVineyard(true);
                return true;
            }

            setNewVineyardConfirmButton(true);
            setNewVineyardInputDisabled(true);
        }).catch(r => {
            setBsError(r.toString());
        });
    }

    const storeVineyard = () => {
        if (newVineyard === null) {
            return false;
        }

        setSubmitCreateNewVineyard(false);
        setBsError('');

        if (newVineyard.status === ENewFieldValueStatus.EXISTING) {

            if (typeof newVineyard.id !== "undefined") {
                let option: OptionType = {value: newVineyard.id.toString(), label: newVineyard.name}
                setPreselectedValue(option);
                updateEditRules(EWineProperties.vineyard, option);
            }

            hideModalAddVineyard();
        } else if (newVineyard.status === ENewFieldValueStatus.NEW) {

            if (typeof newVineyard.country === "undefined" || !newVineyard.country) {
                setBsError('Country not selected');
                return false;
            }

            axios.post('/api/store-vineyard', {
                vineyard: newVineyard.name,
                country: newVineyard.country
            }, {
                ...axiosConfig
            }).then(r => {
                let result = r.data;

                if (result.hasOwnProperty('err')) {
                    setBsError(result.err);
                    return false;
                }

                if (result.hasOwnProperty('vineyard')) {
                    let option: OptionType = {value: result.vineyard.id.toString(), label: result.vineyard.name}
                    setPreselectedValue(option);
                    updateEditRules(EWineProperties.vineyard, option);
                }

                hideModalAddVineyard();
            });
        }
    }

    const hideModalAddRegion = () => {
        setSimilarRegions([]);
        setNewRegionConfirmButton(false);
        setNewRegionInputDisabled(false);
        setOpenModalAddRegion(false);
        setNewRegion(null);
        setBsError('');
    }

    const hideModalAddVineyard = () => {
        setSimilarVineyards([]);
        setNewVineyardConfirmButton(false);
        setNewVineyardInputDisabled(false);
        setOpenModalAddVineyard(false);
        setNewVineyard(null);
        setBsError('');
    }

    const hideModalEditRule = () => {
        setEditRule(undefined);
        setShowEditModal(false);
        setBsError('');
        setDeleteVineyard(false);
        setCountries([]);
    }

    const updateFilter = (key: string, value: string | number) => {
        let data = Object.assign({}, filter);
        data[key] = value;
        setFilter(data);
        setCanSearch(false);

        if (data[EFilter.field] && data[EFilter.term]) {
            setCanSearch(true);
        }
    }

    const filterData = (to_page?: number, filterData?:TFilter) => {
        let currentPage = to_page ? to_page : 1;

        setError('');
        setLoading(true);
        setRules([]);
        setPagination(null);

        axios.post('/api/listing?page=' + currentPage, filterData ?? filter, {
            ...axiosConfig
        }).then((response) => {
            let result = response.data;
            setLoading(false);
            if (result.hasOwnProperty('err')) {
                setError(result.err);
                return false;
            }

            if (result.hasOwnProperty('rules')) {
                applyRules(result.rules);
            }
        })
    }

    const freezeRow = (id: number) => {
        blockButton(id);
        axios.post('/api/freeze-rule', {
            id: id
        }, {
            ...axiosConfig
        }).then((response) => {
            let result = response.data;

            if (result.hasOwnProperty('err')) {
                setError(result.err);
                return false;
            }

            rules.forEach((item, index) => {
                if (item.id === id) {
                    rules[index].is_frozen = 1;
                }
            });
            setRules(rules.map(f => f));
            unblockButton(id);
        });
    }

    const unfreezeRow = (id: number) => {
        blockButton(id);
        axios.post('/api/unfreeze-rule', {
            id: id
        }, {
            ...axiosConfig
        }).then((response) => {
            let result = response.data;

            if (result.hasOwnProperty('err')) {
                setError(result.err);
                return false;
            }

            rules.forEach((item, index) => {
                if (item.id === id) {
                    rules[index].is_frozen = 0;
                }
            });
            setRules(rules.map(f => f));
            unblockButton(id);
        });
    }

    const blockButton = (id: number) => {
        disabledButton.push(id);
        setDisabledButton(disabledButton.map(f => f));
    }

    const unblockButton = (id: number) => {
        let list = disabledButton.filter(item => item !== id);
        setDisabledButton(list.map(f => f));
    }

    const openModalEditRule = (id: number) => {
        axios.get('/api/modal-edit-rule?id=' + id, {
            ...axiosConfig
        }).then((response) => {
            let result = response.data;

            if (result.hasOwnProperty('err')) {
                setError(result.err);
                return false;
            }

            if (result.hasOwnProperty('taxonomy')) {
                setTaxonomyProperties(result.taxonomy);
            }

            if (result.hasOwnProperty('wines_exists') && result.wines_exists) {
                setVineyardWithWines(true);
            }

            let rule = rules.find(item => item.id === id);

            if (result.hasOwnProperty('can_delete_vineyard') && rule) {
                rule.can_delete_vineyard = result.can_delete_vineyard;
            }

            if (result.hasOwnProperty('vineyard_country') && rule) {
                rule.vineyard_country = result.vineyard_country;
            }

            if (result.hasOwnProperty('region_country') && rule) {
                rule.region_country = result.region_country;
            }

            if (result.hasOwnProperty('file_country') && rule) {
                rule.file_country = result.file_country;
            }

            if (result.hasOwnProperty('country_locked') && rule) {
                rule.country_locked = result.country_locked;
            }

            if (result.hasOwnProperty('countries') && rule) {
                setCountries(result.countries);
            }

            setEditRule(rule);
            setShowEditModal(true);
        });
    }

    const removeRule = (id: number) => {
        if (!window.confirm('Are you sure want to delete rule?')) {
            return;
        }

        axios.post("/api/delete-rule", {id: id},{
            ...axiosConfig
        }).then((response) => {
            let result = response.data;

            if (result.hasOwnProperty('err')) {
                toast.error(result.err);
                return false;
            }

            if (result.hasOwnProperty('msg')) {
                toast.success(result.msg);

                let filtered = rules.filter(item => {
                   return item.id !== id;
                });

                setRules(filtered.map(f => f));
            }
        });
    }

    const updateEditRules = (property: EWineProperties, option: SingleValue<OptionType> | null) => {
        let params = {
            property: property,
            option: option
        };
        setEditData(params);
        setSubmitDisabledSaveEditRule(false);
    }

    const updateRuleValue = () => {
        // * update rule
        if (editRule && editData && editData.option) {
            // * update data in db
            let data: any = editData;
            data['id'] = editRule.id;
            data['delete_vineyard'] = deleteVineyard;

            axios.post('/api/update-rule', data, {
                ...axiosConfig
            }).then((response) => {
                let result = response.data;

                if (result.hasOwnProperty('err')) {
                    setError(result.err);
                    return false;
                }

                let index = rules.findIndex(item => item.id === editRule.id);
                if (rules.hasOwnProperty(index) && index !== -1 && editData.option) {

                    rules[index].value = editData.option.value;
                    rules[index].name = editData.option.original_name ? editData.option.original_name : editData.option.label;

                    setRules(rules.map(f => f));
                    setShowEditModal(false);

                }

                setEditRule(undefined);
                setEditData(null);
                setSubmitDisabledSaveEditRule(true);
                setPreselectedValue(null);
                setVineyardWithWines(false);
                setDeleteVineyard(false);
            });
        }
    }

    const clearFilter = () => {
        setFilter(undefined);
        setRules([]);
        setEditRule(undefined);
        setCanSearch(false);
        setStartDate(null);
        setPagination(null);
        setLoadFrozen(true);
    }

    const applyRules = (rules: any) => {
        if (rules.hasOwnProperty('data')) {
            setRules(rules.data);

            if (rules.data.length) {
                let paginationData = {
                    'current_page': rules.current_page,
                    'from': rules.from,
                    'last_page': rules.last_page,
                    'first_page_url': rules.first_page_url,
                    'last_page_url': rules.last_page_url,
                    'next_page_url': rules.next_page_url,
                    'path': rules.path,
                    'per_page': rules.per_page,
                    'prev_page_url': rules.prev_page_url,
                    'to': rules.to,
                    'total': rules.total
                };

                setPagination(paginationData);
            }
        }
    }

    const init = (page?: number) => {
        setError('');
        setLoading(true);
        setLoadFrozen(false);

        let query = page ? '?page=' + page : '';

        axios.get('/api/rules' + query, {
            ...axiosConfig
        }).then((response) => {
            const result = response.data;
            setLoading(false);
            if (result.hasOwnProperty('users')) {
                setUsers(result.users);
            }

            if (result.hasOwnProperty('rules')) {
                applyRules(result.rules);
            }
        }).catch(r => {
            if (r.response.status === 404) {
                history.push('/');
            }
            if (r.response.status === 401) {
                authUser.setAuthUser(null);
                localStorage.removeItem('auth_user');
            }
        });
    }

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (loadFrozen) {
            init();
        }
    }, [loadFrozen]);

    useEffect(() => {
        if (submitCreateNewVineyard) {
            storeVineyard();
        }
        if (submitCreateNewRegion) {
            storeRegion();
        }
    }, [submitCreateNewVineyard, submitCreateNewRegion]);

    useEffect(() => {
        if (location.search !== '') {
            const query = new URLSearchParams(location.search);
            let data: TFilter = {};

            if (query.get('term')) {
                data['TERM'] = query.get('term') ?? '';
            }

            if (query.get('field')) {
                data['FIELD'] = query.get('field') ?? '';
            }

            if (query.get('search')) {
                data['SEARCH'] = query.get('search') ?? '';
            }

            if (data[EFilter.field] && data[EFilter.term]) {
                setCanSearch(true);

                filterData(1, data);
            }

            setFilter(data);
        }
    }, []);

    return (
        <div className="App">
            <div className="w-100">
                <Container fluid>
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="mb-2 text-start">Rules
                                control {pagination ? '(' + pagination.total + ')' : ''}</h4>
                            <hr/>
                        </div>
                        <div className="col-md-12">
                            <AlertError error={error} callback={() => setError('')}/>

                            <div className="row">
                                <div className="col-md-2">
                                    <Form.Select className="custom-select w-100" onChange={(e) => {
                                        updateFilter(EFilter.field, e.target.value);
                                    }} value={filter && filter[EFilter.field] ? filter[EFilter.field] : ''}>
                                        <option value="">-Select category-</option>
                                        {fields.map(item => {
                                            return (
                                                <option value={item} key={item}>{t(item)}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </div>
                                <div className="col-md-2">
                                    <Form.Select className="custom-select w-100"
                                                 value={filter && filter[EFilter.term] ? filter[EFilter.term] : ''}
                                                 onChange={(e) => {
                                                     updateFilter(EFilter.term, e.target.value);
                                                 }}>
                                        <option value="">-Select term-</option>
                                        <option value="value">Gustos term</option>
                                        <option value="synonym">File term</option>
                                    </Form.Select>
                                </div>
                                <div className="col-md-4 with-help-button">
                                    <input type="text" className={"w-100 custom-input-text"} placeholder={"Type key"}
                                           value={filter && filter[EFilter.search] ? filter[EFilter.search] : ''}
                                           onChange={(e) => {
                                               updateFilter(EFilter.search, e.target.value);
                                           }}
                                    />
                                    <button
                                      className="help-button"
                                      title="About search"
                                      onClick={(e) => {
                                          setOpenModalSearchHelp(true);
                                      }}
                                    >
                                        <img src={HelpIcon} alt="About search"/>
                                    </button>
                                </div>
                                <div className="col-md-2">
                                    <Form.Select className="custom-select w-100"
                                                 onChange={(e) => {
                                                     updateFilter(EFilter.user, e.target.value);
                                                 }}
                                                 value={filter && filter[EFilter.user] ? filter[EFilter.user] : ''}>
                                        <option value="">- Choose user -</option>
                                        {users ?
                                            Object.entries(users).map(([key, value]) => {
                                                return (
                                                    <option value={key} key={key}>{value}</option>
                                                );
                                            })
                                            : ''
                                        }
                                    </Form.Select>
                                </div>
                                <div className="col-md-2">
                                    <DatePicker selected={startDate} onChange={(date) => {
                                        updateFilter(EFilter.date, date ? date.toLocaleDateString() : '');
                                        setStartDate(date);
                                    }}
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText={"Choose date"} className={"custom-input-text w-100"}/>
                                </div>
                                <div className="col-md-12 text-center">
                                    <button className="btn btn-custom mt-4 mb-3" onClick={() => filterData()}
                                            disabled={!canSearch}>Search
                                    </button>
                                    {canSearch
                                        ? <button onClick={clearFilter}
                                                  className="btn btn-outline-custom inherit mt-4 ml-2 position-absolute"
                                                  title="Reset filter">x</button>
                                        : ''
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {loading ?
                                        <Preloader show={true}/>
                                        :
                                        rules.length ?
                                            <Table striped hover className="mt-3">
                                                <thead>
                                                <tr>
                                                    <th>Id</th>
                                                    <th>Gustos term</th>
                                                    <th>File term</th>
                                                    <th>File</th>
                                                    <th>Author</th>
                                                    <th>Processed</th>
                                                    <th>Merged</th>
                                                    {hasPermissionTo(authUser, 'rules edit') &&
                                                      <th className="w-17pct"/>
                                                    }
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {rules.map(item => {
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>{item.id}</td>
                                                            <td>{item.name} {item.region_country ? `(${item.region_country})` : ``}</td>
                                                            <td>{item.synonym_data.name}</td>
                                                            <td>{item.file_initiator!=='' ?
                                                              item.file_initiator
                                                              : <em>File deleted</em>
                                                            }</td>
                                                            <td><div className="rule-user" title={item.user_data.email + ' @ ' + item.date} ><PersonFill/> {item.user_data.email.substring(0, item.user_data.email.lastIndexOf("@"))}</div></td>
                                                            <td>{item.counters.processed}</td>
                                                            <td>{item.counters.merged}</td>
                                                            {hasPermissionTo(authUser, 'rules edit') &&
                                                              <td>
                                                                  {item.is_frozen ?
                                                                    (<>
                                                                          <button
                                                                            className="btn btn-outline-info active mr-3"
                                                                            onClick={() => unfreezeRow(item.id)}>Frozen
                                                                          </button>
                                                                          <button
                                                                            onClick={() => openModalEditRule(item.id)}
                                                                            className="btn btn-outline-success">Edit
                                                                          </button>
                                                                      </>
                                                                    )
                                                                    : (
                                                                      <>
                                                                          <button className="btn btn-outline-info mr-3"
                                                                                  disabled={disabledButton.indexOf(item.id) !== -1}
                                                                                  onClick={() => freezeRow(item.id)}>Freeze
                                                                          </button>
                                                                          {!item.counters.processed && !item.counters.merged &&
                                                                            <button
                                                                              onClick={() => removeRule(item.id)}
                                                                              className="btn btn-outline-success">Delete
                                                                            </button>
                                                                          }
                                                                      </>
                                                                    )
                                                                  }
                                                              </td>
                                                            }
                                                        </tr>
                                                    );
                                                })}

                                                </tbody>
                                            </Table>
                                            : 'No data.'

                                    }
                                    {pagination !== null ?
                                        <Pagination activePage={pagination.current_page}
                                                    itemsCountPerPage={pagination.per_page}
                                                    onChange={(pageNumber) => {
                                                        if (canSearch) {
                                                            filterData(pageNumber);
                                                        } else {
                                                            init(pageNumber);
                                                        }
                                                    }}
                                                    totalItemsCount={pagination.total}
                                                    pageRangeDisplayed={pageRangeDisplayed}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    firstPageText="First Page"
                                                    lastPageText="Last Page"
                                                    innerClass="pagination mt-3"
                                        />
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <BsModal {...ModalEditRule}>
                        {editRule
                            ?
                            (<>
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        <h4>{t(editRule.property)}</h4>
                                    </div>
                                    <div className="col-md-4">
                                        <label>File term</label>
                                        <div
                                            className={"single-cell position-relative property-" + editRule.property.toLowerCase()}>
                                            <div className="text-ellipsis">
                                                {editRule.synonym_data.name}
                                            </div>
                                            {editRule.property === EWineProperties.vineyard
                                                ? <SearchGoogleMini content={editRule.synonym_data.name}/>
                                                : ''
                                            }
                                            <a href="/#" title="Copy to clipboard"
                                               className="copy-clipboard" onClick={(e) => {
                                                e.preventDefault();
                                                copyToClipboard(editRule.synonym_data.name).then(r => r);
                                            }}><Clipboard/></a>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <label>Gustos
                                            term
                                            {editRule.property === EWineProperties.vineyard && editRule.vineyard_country
                                                ? (<>(<strong>{editRule.vineyard_country}</strong>)</>)
                                                : ''
                                            }
                                            {editRule.property === EWineProperties.region && editRule.region_country
                                                ? (<>(<strong>{editRule.region_country}</strong>)</>)
                                                : ''
                                            }
                                        </label>
                                        <div
                                            className={"single-cell position-relative one-line property-" + editRule.property.toLowerCase()}>
                                            <div className="text-ellipsis">
                                                {editRule.name}
                                            </div>
                                            {editRule.property === EWineProperties.vineyard
                                                ? <SearchGoogleMini content={editRule.name}/>
                                                : ''
                                            }
                                            <a href="/#" title="Copy to clipboard"
                                               className="copy-clipboard" onClick={(e) => {
                                                e.preventDefault();
                                                copyToClipboard(editRule.name).then(r => r);
                                            }}><Clipboard/></a>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <label>&nbsp;</label>
                                        <RuleEditInput rule={editRule} selected={preselectedValue}
                                                       taxonomy={taxonomyProperties}
                                                       onChange={updateEditRules}/>
                                    </div>
                                    <div className="col-md-1">
                                        <label>&nbsp;</label>
                                        {editRule.property === EWineProperties.vineyard
                                            ? <button className="additional-button mr-3"
                                                      onClick={(e) => setOpenModalAddVineyard(true)}
                                                      title="Add new vineyard">+</button>
                                            : ''}
                                        {editRule.property === EWineProperties.region && (!editRule.hasOwnProperty('country_locked') || !editRule.country_locked)
                                            ? <button className="additional-button mr-3"
                                                      onClick={(e) => {
                                                          setOpenModalAddRegion(true);
                                                      }}
                                                      title="Add new region">+</button>
                                            : ''}
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-12">
                                        {editRule.hasOwnProperty('can_delete_vineyard') ?
                                            editRule.can_delete_vineyard ?
                                                vineyardWithWines ?
                                                    <div className="alert alert-info">Cant delete vineyard because
                                                        already have wines</div>
                                                    :
                                                    (<>
                                                        <strong>Delete also {editRule.name}?</strong>
                                                        <label className="ml-4 mr-3"><input type="radio" value="1"
                                                                                            onChange={(e) => {
                                                                                                setDeleteVineyard(true);
                                                                                            }}
                                                                                            name="delete"/> Yes</label>
                                                        <label><input type="radio" value="0" name="delete"
                                                                      onChange={(e) => {
                                                                          setDeleteVineyard(false);
                                                                      }}/> No</label>
                                                    </>)
                                                : <div className="alert alert-info">{t(editRule.property)} cannot be
                                                    deleted
                                                    because have assigned multiple rules.</div>
                                            :
                                            ''
                                        }
                                        {editRule.hasOwnProperty('file_country') ?
                                            <div className="alert alert-success">
                                                Matched country from file: <strong>{editRule.file_country}</strong>
                                            </div>
                                            : ''
                                        }
                                    </div>
                                </div>
                            </>)
                            : ''
                        }
                    </BsModal>
                    <BsModal {...ModalAddVineyardProps}>
                        <p>Create new vineyard. Use this form only if vineyard is not listed.</p>
                        <div className="form-group mb-2">
                            <label>Country*</label>
                            <select className="w-100 custom-select" onChange={(e) => {
                                let objVineyard = Object.assign({}, newVineyard)
                                objVineyard.country = parseInt(e.target.value)
                                setNewVineyard(objVineyard)
                            }}>
                                <option value="0">- select country -</option>
                                {taxonomyProperties && taxonomyProperties.hasOwnProperty(EWineProperties.country)
                                    ?
                                    Object.entries(taxonomyProperties[EWineProperties.country]).map(([key, value]) => {
                                        return (
                                            <option value={key} key={key}>{value}</option>
                                        )
                                    })
                                    : ''
                                }
                            </select>
                        </div>
                        <div className="form-group mb-2">
                            <label>Vineyard name*</label>
                            <input type="text" className="w-100 custom-input-text"
                                   onChange={(e) => {
                                       let objVineyard = Object.assign({}, newVineyard)
                                       objVineyard.name = e.target.value
                                       objVineyard.status = ENewFieldValueStatus.NEW
                                       setNewVineyard(objVineyard)
                                   }} disabled={newVineyardInputDisabled}/>
                            <small>Max length 60 characters.</small>
                        </div>
                        {similarVineyards.length ?
                            <div className="mt-3 similar-regions">
                                <h6>Similar vineyards</h6>
                                {similarVineyards.map((item: { id: number, name: string, country: number | string }) => {
                                    return (
                                        <div className="mt-3">
                                            <input type="radio" name="existing_region" onChange={(e) => setNewVineyard({
                                                id: item.id,
                                                name: item.name,
                                                status: ENewFieldValueStatus.EXISTING
                                            })}/> {item.name} ({item.country})
                                        </div>
                                    )
                                })}
                                <div className="small mt-3">Select similar vineyards to catch existing region or skip to
                                    create new one.
                                </div>
                            </div>
                            : ''
                        }
                    </BsModal>
                    <BsModal {...ModalAddRegionProps}>
                        <p>Create new region. Use this form only if region is not listed.</p>
                        <div className="form-group mb-2">
                            <label>Country*</label>
                            <select className="w-100 custom-select" onChange={(e) => {
                                let objRegion = Object.assign({}, newRegion)
                                objRegion.country = parseInt(e.target.value)
                                setNewRegion(objRegion)
                            }}>
                                <option value="0">- select country -</option>
                                {countries ? countries.map(item => {
                                    return (
                                        <option value={item.id} key={item.id}>{item.name}</option>
                                    )
                                }) : ''}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Region name*</label>
                            <input type="text" className="w-100 custom-input-text"
                                   onChange={(e) => {
                                       let objRegion = Object.assign({}, newRegion)
                                       objRegion.name = e.target.value
                                       objRegion.status = ENewFieldValueStatus.NEW
                                       setNewRegion(objRegion)
                                   }} disabled={newRegionInputDisabled}/>
                        </div>
                        {similarRegions.length ?
                            <div className="mt-3 similar-regions">
                                <h6>Similar regions</h6>
                                {similarRegions.map((item: { tid: number, name: string }) => {
                                    return (
                                        <div className="mt-3">
                                            <input type="radio" name="existing_region" onChange={(e) => setNewRegion({
                                                id: item.tid,
                                                name: item.name,
                                                status: ENewFieldValueStatus.EXISTING
                                            })}/> {item.name}
                                        </div>
                                    )
                                })}
                                <div className="small mt-3">Select similar regions to catch existing region or skip to
                                    create new one.
                                </div>
                            </div>
                            : ''
                        }
                    </BsModal>
                    <BsModal {...ModalSearchHelpProps}>
                        <p>Поиск позволяет использовать операторы для более точного поиска желаемого текста.</p>
                        <p>Список достыпных операторов:</p>

                        <table className="table-bordered">
                            <thead>
                                <tr>
                                    <th>Оператор</th>
                                    <th>Описание</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>"sample"</td>
                                    <td>Точное совпадение</td>
                                </tr>
                                <tr>
                                    <td>sample%</td>
                                    <td>Начинается с</td>
                                </tr>
                                <tr>
                                    <td>%sample</td>
                                    <td>Заканчивается на</td>
                                </tr>
                                <tr>
                                    <td>%sample%</td>
                                    <td>Содержит</td>
                                </tr>
                                <tr>
                                    <td>\bsample\b</td>
                                    <td>Граница слова (c)ChatGTP</td>
                                </tr>
                                <tr>
                                    <td>s*ing</td>
                                    <td>Подстановочный знак</td>
                                </tr>
                            </tbody>
                        </table>
                    </BsModal>
                </Container>
            </div>
        </div>
    );
}

export default Rules;
