import Select from "react-select";
import Async from 'react-select/async';
import axios from "axios";
import {useContext, useEffect, useState} from "react";
import { toast } from "react-toastify";
import wines from "../pages/Wines";
import {EWineStatuses, TCompetitions, TCountries, TEvents, TUserWinesFilter} from "../properties";
import {AuthUserContext} from "../context/AuthUser";
import {Check} from "react-bootstrap-icons";
import {useLocation} from "react-router";

type OptionType = {
    value: number | string,
    label: string
};

type TWinery = {
    id: number,
    name: string
};

type TProps = {
    competitions: TCompetitions[],
    countries: TCountries[],
    events: TEvents[],
    filterData: (data: object) => void,
    submitFilter: () => void
    error: (msg: string) => void,
    userFilter: TUserWinesFilter,
    resetUserFilter: () => void
    saveUserFilter: () => void
    totalWines: number,
    onClearReservedWines: (wine_id?: number) => void
};

type TObj = {
    [key: string]: string | number | number[]
}

type TFilterProps = {
    competition: string|number,
    event_year: string|number,
    country: string|number,
    winery: string|number,
    status: string|number,
    nameLike: string
}

const WinesFilter = (props: TProps) => {

    let statusOptions: OptionType[] = [];
    let competitionOptions: OptionType[] = [];
    let countriesOptions: OptionType[] = [];
    let eventsOptions: OptionType[] = [];

    const [loading, setLoading] = useState(false);
    const [showSavedIcon, setShowSavedIcon] = useState(false);
    const [showLoadingIcon, setShowLoadingIcon] = useState(false);
    const [filter, setFilter] = useState<TFilterProps>({} as TFilterProps);
    const [selectedFilter, setSelectedFilter] = useState(props.userFilter);
    const authUser = useContext(AuthUserContext);
    const location = useLocation();
    const [clearReservedProgress, setClearReservedProgress] = useState(false);

    const axiosConfig = {
        headers: {
            Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
        }
    };

    /*    useEffect(() => {
            setSelectedFilter(props.userFilter);
            let obj: TObj = {};
            Object.entries(props.userFilter).forEach(([key, option]) => {
                obj[key] = key === 'event_year' ? option.label : option.value;
            });
            setFilter(obj);
            props.filterData(obj);
        }, [props.userFilter]);*/

    useEffect(() => {
        if (location.search !== '') {
            const query = new URLSearchParams(location.search);
            let filterData: TUserWinesFilter = {};

            axios.get('/api/listing-wines/filter-init' + location.search, {
                ...axiosConfig
            }).then(r => {
                let result = r.data;
                if (Object.keys(result).length) {
                    let f:TFilterProps = {} as TFilterProps;
                    Object.entries(result).map(data => {
                        let item = data[1] as { label: string, value: never };
                        filterData[data[0] as keyof TUserWinesFilter] = {
                            label: item.label,
                            value: item.value,
                        };

                        f[data[0] as keyof TFilterProps] = item.value;
                    });

                    setSelectedFilter(filterData);
                    setFilter(f);
                    props.filterData(f);
                }
            });
        }
    }, [location]);


    Object.entries(EWineStatuses).forEach(([key, value]) => {
        statusOptions.push({value: key, label: value});
    });

    if (props.competitions.length) {
        props.competitions.forEach(item => {
            competitionOptions.push({value: item.id, label: item.name});
        });
    }

    countriesOptions.push({value: 0, label: 'All with countries'});
    countriesOptions.push({value: -1, label: 'Without countries'});
    if (props.countries.length) {
        props.countries.forEach(item => {
            countriesOptions.push({value: item.id, label: item.name});
        });
    }

    if (props.events.length) {
        props.events.forEach(item => {
            eventsOptions.push({value: item.id, label: item.year.toString()});
        });
    }

    const loadOptions = (
      inputText: string,
      callback: (options: OptionType[]) => void
    ): void => {
        if (!inputText) {
            return callback([]);
        }
        if (inputText.length < 2) {
            return callback([]);
        }
        setLoading(true);

        axios.post('/api/search-winery', {q: inputText}, {
            headers: {
                Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
            }
        }).then((response) => {
            let data: TWinery[] = response.data.wineries;
            setLoading(false);
            if (data.length) {
                return callback(data.map((result) => {
                    return labelFormatter(result);
                }))
            } else {
                return callback([]);
            }
        });
    };

    const labelFormatter = (i: TWinery): OptionType => {
        return {
            label: i.name,
            value: i.id,
        }
    }

    const saveFilter = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        if (showLoadingIcon) {
            return false;
        }

        setShowLoadingIcon(true);
        axios.post('/api/save-wines-filter', filter, {
            headers: {
                Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
            }
        }).then(r => {
            setShowLoadingIcon(false);
            setShowSavedIcon(true);
            let result = r.data;

            if (result.hasOwnProperty('err')) {
                props.error(result.err);
                return false;
            }

            props.saveUserFilter();
        }).catch(r => {

        });
    }

    const resetUserFilter = (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        axios.post('/api/reset-wines-filter', filter, {
            headers: {
                Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
            }
        }).then(r => {
            let result = r.data;

            if (result.hasOwnProperty('err')) {
                props.error(result.err);
                return false;
            }
            props.resetUserFilter();
        }).catch(r => {

        });
    }

    const renderSavedIcon = () => {
        if (showSavedIcon) {
            setTimeout(() => setShowSavedIcon(false), 1500);
            return (<Check className="saved-icon"/>);
        }
        return '';
    }

    const renderUserFilter = (label: string) => {
        return (
          <>
              (User filter: <strong>{label}</strong>)
          </>
        );
    }

    const clearReservedWines = () => {
        setClearReservedProgress(true);

        axios.post("/api/clear-reserved-wines", filter, {
            headers: {
                Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : "",
            },
        }).then(r => {
            let result = r.data;

            if (result.hasOwnProperty("msg")) {
                toast.success(result.msg);
                props.onClearReservedWines();
                return true;
            }
        }).finally(() => {
            setClearReservedProgress(false);
        });
    }

    return (
      <div>
          <h5>Filter {Object.keys(props.userFilter).length ?
            <small><strong>(Found {props.totalWines} cards)</strong></small> : ''}</h5>
          <div className="row">
              <div className="col-md-6">
                  <div className="form-group mb-3">
                      <label>Competition {props.userFilter.competition ? renderUserFilter(props.userFilter.competition.label) : ''}</label>
                      <Select isClearable={true} options={competitionOptions}
                              onChange={(item) => {
                                  setFilter({...filter, competition: item ? item.value : ''});
                                  props.filterData({'competition': item ? item.value : ''});
                              }}/>
                  </div>
              </div>
              <div className="col-md-6">
                  <div className="form-group mb-3">
                      <label>Event {props.userFilter.event_year ? renderUserFilter(props.userFilter.event_year.label) : ''}</label>
                      <Select isClearable={true} options={eventsOptions}
                              onChange={(item) => {
                                  setFilter({...filter, event_year: item ? item.value : ''});
                                  props.filterData({'event_year': item ? item.value : ''});
                              }}/>
                  </div>
              </div>
              <div className="col-md-6">
                  <div className="form-group mb-3">
                      <label>Country {props.userFilter.country ? renderUserFilter(props.userFilter.country.label) : ''}</label>
                      <Select isClearable={true} options={countriesOptions}
                              value={selectedFilter.country ? selectedFilter.country as OptionType : null}
                              onChange={(item) => {

                                  let items = Object.assign({}, selectedFilter);
                                  if (item) {
                                      items['country'] = {
                                          label: item.label,
                                          value: Number(item.value)
                                      };
                                      setSelectedFilter(items);
                                  } else {
                                      delete items['country'];
                                      setSelectedFilter(items);
                                  }

                                  setFilter({...filter, country: item ? item.value : ''});
                                  props.filterData({'country': item ? item.value : ''});
                              }}/>
                  </div>
              </div>
              <div className="col-md-6">
                  <div className="form-group mb-3">
                      <label>Winery {props.userFilter.winery ? renderUserFilter(props.userFilter.winery.label) : ''}</label>
                      <Async isClearable={true} loadOptions={loadOptions} isLoading={loading}
                             placeholder={"Insert some text..."}
                             value={selectedFilter.winery ? selectedFilter.winery as OptionType : null}
                             onChange={(item) => {
                                 let items = Object.assign({}, selectedFilter);
                                 if (item) {
                                     items['winery'] = {
                                         label: item.label,
                                         value: Number(item.value)
                                     };
                                     setSelectedFilter(items);
                                 } else {
                                     delete items['winery'];
                                     setSelectedFilter(items);
                                 }
                                 setFilter({...filter, winery: item ? item.value : ''});
                                 props.filterData({'winery': item ? item.value : ''});
                             }}/>
                  </div>
              </div>
              <div className="col-md-6">
                  <div className="form-group mb-3">
                      <label>Status {props.userFilter.status ? renderUserFilter(props.userFilter.status.label) : ''}</label>
                      <Select isClearable={true} options={statusOptions}
                              value={selectedFilter.status ? selectedFilter.status as OptionType : null}
                              onChange={(item) => {
                                  let items = Object.assign({}, selectedFilter);
                                  if (item) {
                                      items['status'] = {
                                          label: item.label,
                                          value: item.value.toString()
                                      };
                                      setSelectedFilter(items);
                                  } else {
                                      delete items['status'];
                                      setSelectedFilter(items);
                                  }
                                  setFilter({...filter, status: item ? item.value : ''});
                                  props.filterData({'status': item ? item.value : ''})
                              }}/>
                  </div>
              </div>
              <div className="col-md-6">
                  <div className="form-group mb-3">
                      <label>Wine name {props.userFilter.nameLike ? renderUserFilter(props.userFilter.nameLike.label) : ''}</label>
                      <input type="text" className="form-control" placeholder="Wine name"
                             value={selectedFilter.nameLike ? selectedFilter.nameLike.value : ""}
                             onChange={(e) => {
                                 let items = Object.assign({}, selectedFilter);
                                 items['nameLike'] = {
                                     label: 'Wine name',
                                     value: e.target.value
                                 };
                                 setSelectedFilter(items);

                                 setFilter({ ...filter, nameLike: e.target.value });
                                 props.filterData({ "nameLike": e.target.value });
                             }}/>
                  </div>
              </div>
              <div className="col-md-6">
                  <div className="form-group mb-3">
                      <label>&nbsp;</label><br/>
                      <button className="btn btn-outline-custom" onClick={clearReservedWines} disabled={clearReservedProgress}>
                          <span className={`spinner mr-2 ${!clearReservedProgress ? "d-none":""}`}>
                              <span className="spinner-border spinner-border-xs" role="status" aria-hidden="true"></span>
                              <span className="visually-hidden">Loading...</span>
                          </span>
                          Clear reserved wines
                      </button>
                  </div>
              </div>
              <div className="col-md-6">
                  <div className="form-group mb-3">
                      <label>&nbsp;</label><br/>

                      <div className="d-flex flex-wrap flex-row w-100">
                          {Object.keys(props.userFilter).length
                            ?
                            <div className="d-flex flex-row align-items-center flex-grow-1">
                                <a href="/#" className={"link-custom"} onClick={resetUserFilter}>Reset user
                                    filter</a>
                            </div>
                            : ''
                          }

                          <div className="d-flex flex-row justify-content-end align-items-center flex-grow-1 ">
                              {showLoadingIcon
                                ? <img src={`${process.env.PUBLIC_URL}/images/spinner-1s-20px.gif`} alt=""/>
                                : ''}
                              {showSavedIcon ? renderSavedIcon() : ''}
                              <a href="/#" className={"link-custom mr-4 " + (showLoadingIcon ? 'disabled' : '')}
                                 onClick={saveFilter}>Save</a>
                              <button className="btn btn-custom" onClick={props.submitFilter}>Filter</button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    )
}

export default WinesFilter;