import React, {Fragment, useContext, useEffect, useState} from "react";
import {AuthUserContext} from "../context/AuthUser";
import { useHistory, useLocation, useParams } from "react-router";
import axios from "axios";
import {AlertError, parseGrapePercent} from "../helpers";
import {Container} from "react-bootstrap";
import {EWineProperties, TErrorObject} from "../properties";
import {TWineObject} from "./NameMatching";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

type TUrlParams = {
    wine_first: string,
    wine_second: string
};

type TMatchingWine = {
    [key in EWineProperties]: TWineObject
};

type TChecked = {
    [key: number]: TCheckValues | null
}

type TCheckValues = { [key: string]: string | number | null }

const WinesMergeCompare = () => {
    const authUser = useContext(AuthUserContext);
    const {wine_first, wine_second} = useParams<TUrlParams>();
    const {t} = useTranslation();
    const history = useHistory();
    const location = useLocation();

    const axiosConfig = {
        headers: {
            Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
        }
    };

    const EFieldsWine = [
        EWineProperties.wine_id,
        EWineProperties.name, EWineProperties.series, EWineProperties.country, EWineProperties.vineyard, EWineProperties.beverage_type, EWineProperties.color, EWineProperties.grape_category,
        EWineProperties.co2, EWineProperties.method, EWineProperties.extra_sweetness, EWineProperties.under_film_of_yeast
    ];

    const EFieldsVintage = [
        EWineProperties.vintage_id,
        EWineProperties.image, EWineProperties.year, EWineProperties.stage_of_production, EWineProperties.alcohol,
        EWineProperties.sugar, EWineProperties.region, EWineProperties.igp, EWineProperties.grape_type, EWineProperties.award, EWineProperties.production_volume, EWineProperties.production_units
    ];

    const [error, setError] = useState<string | TErrorObject>('');
    const [firstWine, setFirstWine] = useState<TMatchingWine>();
    const [secondWine, setSecondWine] = useState<TMatchingWine>();
    const [checkedFields, setCheckedFields] = useState<TChecked>();
    const [mergeDisabled, setMergeDisabled] = useState(false);

    const checkField = (vintage_id: number, data: EWineProperties, elem: TWineObject) => {
        let obj = Object.assign({}, checkedFields);

        let filtered: TChecked = [];
        Object.entries(obj).forEach(([key, item]) => {
            filtered[Number(key)] = {};
            if (item) {
                delete item[data];
                filtered[Number(key)] = item;
            }
        });

        if (data === EWineProperties.award && Array.isArray(elem) && elem.length) {
            let a = elem.map(item => {
                return item.value;
            });
            (obj[vintage_id] as TCheckValues)[data] = a.join(',');
        } else {
            (obj[vintage_id] as TCheckValues)[data] = elem.value;
        }

        setCheckedFields(obj);
    }

    const isChecked = (vintage_id: number, data: EWineProperties) => {
        return !!(checkedFields && checkedFields[vintage_id] && Object.keys(checkedFields[vintage_id] as TCheckValues).indexOf(data) !== -1 && data !== EWineProperties.award);
    }

    const checkAll = (vintage_id: number) => {
        let data: TChecked = Object.assign({}, checkedFields),
            first = Number(wine_first),
            second = Number(wine_second),
            findIn;

        data[first] = {};
        data[second] = {};

        if (first === vintage_id) {
            findIn = firstWine;
        } else {
            findIn = secondWine;
        }

        if (findIn) {
            Object.entries(findIn).forEach(([key, item]) => {
                if (!EFieldsVintage.includes(key as EWineProperties) || (EFieldsVintage.includes(key as EWineProperties) && firstWine && secondWine && firstWine[EWineProperties.year].value === secondWine[EWineProperties.year].value)) {
                    if (Array.isArray(item)) {
                        let v = item.map(i => {
                            return i.value;
                        });
                        (data[vintage_id] as TCheckValues)[key] = v.join(',');
                    } else {
                        (data[vintage_id] as TCheckValues)[key] = item.value;
                    }
                }
            });
        }

        setCheckedFields(data);
    }

    const renderImageUrl = (path: string) => {
        if (path === '') {
            return (<></>);
        }
        let url = `${process.env.REACT_APP_GUSTOS_APP_URL}/files/${(path.replace('public://', ''))}`;

        if (url) {
            return (
                <a
                    href={url}
                    rel="noreferrer"
                    target="_blank">
                    <img src={url} height={"100px"} alt={""}/>
                </a>
            );
        }
        return (<></>);
    }

    const highlightCell = (data: EWineProperties) => {
        if (firstWine && secondWine && firstWine[data] && secondWine[data] && firstWine[data].value !== secondWine[data].value) {
            return ' highlight-diff';
        }
        return '';
    }

    const mergeSelected = () => {
        if (!window.confirm('Are you sure want to merge wines?')) {
            return false;
        }

        if (checkedFields) {
            let fields = {
                ...checkedFields[Number(wine_first)],
                ...checkedFields[Number(wine_second)]
            };

            let checkedGrape = getCheckedWineProperty(EWineProperties.grape_type);
            fields[EWineProperties.grape_percent] = checkedGrape ? parseGrapePercent(checkedGrape.label) : '';

            axios.post('/api/wines-merge/merge', {
                'fields': fields,
                'vintages': [wine_first, wine_second]
            }, {
                ...axiosConfig
            }).then(r => {
                let result = r.data;
                if (result.hasOwnProperty('error')) {
                    setError(result.error);
                    return false;
                }

                if (result.hasOwnProperty('success')) {
                    toast.success('Wines merged successfully');
                    setMergeDisabled(true);

                    if (typeof location.key !== "undefined") {
                        history.goBack();
                    } else {
                        history.push("/wines-merge/list");
                    }
                    return true;
                }

            }).catch(error => {
                if (error.response) {
                    const responseData = error.response.data;
                    if (responseData.hasOwnProperty('message')) {
                        toast.error(responseData.message);
                    }
                }
            });
        }
    }

    const getCheckedWineProperty = (property: EWineProperties) => {
        if (checkedFields) {
            if (firstWine && (checkedFields[Number(firstWine[EWineProperties.vintage_id].value)] as TCheckValues)[property]) {
                return firstWine[property];
            }
            if (secondWine && (checkedFields[Number(secondWine[EWineProperties.vintage_id].value)] as TCheckValues)[property]) {
                return secondWine[property];
            }
        }
        return null;
    }

    useEffect(() => {
        axios.get('/api/wines-merge/compare?wines=' + wine_first + ',' + wine_second, {
            ...axiosConfig
        }).then(r => {
            let result = r.data;
            if (result.hasOwnProperty('error')) {
                setError(result.error);
                return false;
            }

            if (result.hasOwnProperty('wines')) {
                if (result.wines.hasOwnProperty(wine_first)) {
                    setFirstWine(result.wines[wine_first]);
                }
                if (result.wines.hasOwnProperty(wine_second)) {
                    setSecondWine(result.wines[wine_second]);
                }
            }
        }).catch(r => {
            setError(r.toString());
        });

    }, [wine_first, wine_second]);

    useEffect(() => {
        let data: TChecked = {},
            first = Number(wine_first),
            second = Number(wine_second);

        data[first] = {};
        data[second] = {};

        if (secondWine) {
            Object.entries(secondWine).forEach(([key, item]) => {
                if (!EFieldsVintage.includes(key as EWineProperties) || (EFieldsVintage.includes(key as EWineProperties) && firstWine && firstWine[EWineProperties.year].value === secondWine[EWineProperties.year].value)) {
                    if (Array.isArray(item)) {
                        let v = item.map(i => {
                            return i.value;
                        });
                        (data[second] as TCheckValues)[key] = v.join(',');
                    } else {
                        (data[second] as TCheckValues)[key] = item.value;
                    }
                }
            });
            setCheckedFields(data);
        }
    }, [secondWine]);

    return (
        <div className="App MergeCompare">
            <div className="w-100">
                <Container fluid>
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="mb-2 text-start">Merge wines</h4>
                            <hr/>
                        </div>
                        <div className="col-md-12">
                            <AlertError error={error} callback={() => setError('')}/>
                            {firstWine && secondWine
                                ? (<>
                                    <div className="contest mb-5">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-10 offset-md-2">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1 container-check-fields">
                                                <div className="select-all-title">
                                                    <h6>
                                                        Select all
                                                    </h6>
                                                    <div className="row row-check">
                                                        <div className="col-md-6">
                                                            <div className="check">
                                                                <input
                                                                    type="radio" name="check_all"
                                                                    value="original"
                                                                    onClick={() => checkAll(firstWine[EWineProperties.vintage_id].value as number)}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="check">
                                                                <input
                                                                    type="radio" name="check_all"
                                                                    onClick={() => checkAll(secondWine[EWineProperties.vintage_id].value as number)}
                                                                    value="matching" defaultChecked/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-5 text-end">
                                                <button className="btn btn-custom mt-4 mb-3" disabled={mergeDisabled}
                                                        onClick={mergeSelected}>Merge selected
                                                </button>
                                            </div>
                                            {EFieldsWine.map((data) => {
                                                return (<Fragment key={"compare-" + data}>
                                                    <div className="col-md-1">
                                                        <div
                                                            className={"column-name text-end d-flex justify-content-end align-items-top text-nowrap property-" + data.toLowerCase()}>
                                                            {t(data)}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div
                                                            className={"cell position-relative cell-property-" + data.toLowerCase() + highlightCell(data)}>
                                                            {firstWine[data] ? firstWine[data].label : ''}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-1 container-check-fields">
                                                        <div className={"row row-check property-" + data.toLowerCase()}>
                                                            <div className="col-md-6">
                                                                <div className="check">
                                                                    <input type="radio"
                                                                           {...{'name': 'field[' + data + ']'}}
                                                                           onChange={() => checkField(firstWine[EWineProperties.vintage_id].value as number, data, firstWine[data])}
                                                                           checked={isChecked(firstWine[EWineProperties.vintage_id].value as number, data)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="check">
                                                                    <input type="radio"
                                                                           {...{'name': 'field[' + data + ']'}}
                                                                           onChange={() => checkField(secondWine[EWineProperties.vintage_id].value as number, data, secondWine[data])}
                                                                           checked={isChecked(secondWine[EWineProperties.vintage_id].value as number, data)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div
                                                            className={"cell position-relative cell-property-" + data.toLowerCase() + highlightCell(data)}>
                                                            {secondWine[data] ? secondWine[data].label : ''}
                                                        </div>
                                                    </div>
                                                </Fragment>);
                                            })}

                                            {EFieldsVintage.map((data) => {
                                                return (<Fragment key={"compare-" + data}>
                                                    <div className="col-md-1">
                                                        <div
                                                            className={"column-name text-end d-flex justify-content-end align-items-top text-nowrap property-" + data.toLowerCase()}>
                                                            {t(data)}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div
                                                            className={"cell position-relative cell-property-" + data.toLowerCase() + highlightCell(data)}>
                                                            {firstWine[data] ?
                                                                (
                                                                    data === EWineProperties.image
                                                                        ? renderImageUrl(firstWine[data].label)
                                                                        : firstWine[data].label
                                                                )
                                                                : ''}
                                                            {firstWine && data === EWineProperties.award && Array.isArray(firstWine[data])
                                                                ?
                                                                (<ul className="mb-0">
                                                                    {Object.entries(firstWine[data]).map(([index, value]) => {
                                                                        let item: any = value;
                                                                        return (<li key={item.value}>
                                                                                {item.label}
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>)
                                                                : ''
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-1 container-check-fields">
                                                        <div className={"row row-check property-" + data.toLowerCase()}>
                                                            {firstWine[EWineProperties.year].value === secondWine[EWineProperties.year].value ?
                                                                (<>
                                                                    <div className="col-md-6">
                                                                        <div className="check">
                                                                            <input
                                                                                type="radio"
                                                                                onChange={() => checkField(firstWine[EWineProperties.vintage_id].value as number, data, firstWine[data])}
                                                                                checked={isChecked(firstWine[EWineProperties.vintage_id].value as number, data)}
                                                                                disabled={data === EWineProperties.award}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="check">
                                                                            <input
                                                                                type="radio"
                                                                                onChange={() => checkField(secondWine[EWineProperties.vintage_id].value as number, data, secondWine[data])}
                                                                                checked={isChecked(secondWine[EWineProperties.vintage_id].value as number, data)}
                                                                                disabled={data === EWineProperties.award}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>) : ''
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div
                                                            className={"cell position-relative cell-property-" + data.toLowerCase() + highlightCell(data)}>
                                                            {secondWine[data] ?
                                                                (
                                                                    data === EWineProperties.image
                                                                        ? renderImageUrl(secondWine[data].label)
                                                                        : secondWine[data].label
                                                                )
                                                                : ''
                                                            }
                                                            {secondWine && data === EWineProperties.award && Array.isArray(secondWine[data])
                                                                ?
                                                                (<ul className="mb-0">
                                                                    {Object.entries(secondWine[data]).map(([index, value]) => {
                                                                        let item: any = value;
                                                                        return (<li key={item.value}>
                                                                                {item.label}
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>)
                                                                : ''
                                                            }
                                                        </div>
                                                    </div>
                                                </Fragment>);
                                            })}
                                        </div>
                                    </div>
                                </>)
                                : ''
                            }
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default WinesMergeCompare;