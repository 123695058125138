import latinize from "latinize";
import {Alert} from "react-bootstrap";
import React, {useEffect, useLayoutEffect, useRef} from "react";
import {TErrorObject} from "./properties";
import {AuthUser} from "./context/AuthUser/Context";

export const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1).toLowerCase()) || ""

type TAlertProps = {
    error: TErrorObject | string,
    callback: () => void
}

export function AlertError(props: TAlertProps): JSX.Element {
    if (typeof props.error !== 'undefined' && props.error !== '') {
        if (typeof props.error === "object") {
            let key = Object.keys(props.error)[0] as string,
                message = props.error[key]

            return (
                <Alert variant="danger" onClose={() => props.callback()} dismissible className="col-md-7 offset-md-2">
                    {message}
                </Alert>
            );
        } else {
            return (
                <Alert variant="danger" onClose={() => props.callback()} dismissible className="col-md-7 offset-md-2">
                    {props.error}
                </Alert>
            );
        }
    } else {
        return <></>;
    }
}

export const changeFile = (callback: () => void) => {
    if (localStorage.getItem('parsed_file')) {
        localStorage.removeItem('parsed_file');
    }
    callback();
}

export function useInterval(callback: () => void, delay: number | null) {
    const savedCallback = useRef(callback)

    // Remember the latest callback if it changes.
    useLayoutEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        // Don't schedule if no delay is specified.
        // Note: 0 is a valid value for delay.
        if (!delay && delay !== 0) {
            return
        }

        const id = setInterval(() => savedCallback.current(), delay)

        return () => clearInterval(id)
    }, [delay])
}

export const customStyles = {
    control: (provided: any, state: { isFocused: any, isDisabled: any }) => ({
        ...provided,
        background: state.isDisabled ? '#eee' : '#fff',
        borderColor: '#333',
        minHeight: '30px',
        height: '30px',
        boxShadow: state.isFocused ? null : null,
        fontSize: '13px',
        "&:hover": {
            borderColor: '#333'
        }
    }),

    valueContainer: (provided: any, state: any) => ({
        ...provided,
        height: '30px',
        padding: '0 6px',
        gridTemplateColumns: '1fr',
    }),

    input: (provided: any, state: any) => ({
        ...provided,
        margin: '0px',
        padding: '0px'
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    indicatorsContainer: (provided: any, state: any) => ({
        ...provided,
        height: '30px'
    }),
    inputContainer: (provided: any) => ({
        ...provided,
        padding: '0px'
    }),
    dropdownIndicator: (provider: any) => ({
        ...provider,
        padding: '8px 2px',
        "&:hover": {
            color: '#333'
        }
    }),
    menuList: (provider: any) => ({
        ...provider,
        fontSize: '13px',
        padding: '3px'
    }),
    option: (provider: any, state: any) => ({
        ...provider,
        padding: '3px',
        background: state.isSelected ? '#ffadad' : '#fff',
        color: '#333',
        "&:hover": {
            background: '#f7ebeb'
        }
    }),
    placeholder: (provider: any) => ({
        ...provider,
        color: '#333',
    })
}

export const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const copyToClipboard = (textToCopy: string) => {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // here the magic happens
            document.execCommand('copy');
            textArea.remove();
        });
    }
}

export const isValidHttpUrl = (string: string): boolean => {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export const isNumeric = (value: any) => {
    return /^-?\d+$/.test(value);
}

export const parseGrapePercent = (data: string) => {
    let items = data.split(','),
        percents: string[] = [];

    if (items.length && (data.match(/:/g) || []).length) {
        items.map(item => {
            let grapeParts = item.split(':');
            if (grapeParts.length) {
                percents.push(grapeParts[1].replace('%', '').trim());
            }
        });
    }
    return percents.join(',');
}

export const renderImageUrl = (path: string, storage: "local" | "external" | "internal", wine_id?: number) => {
    if (path === '') {
        return (<></>);
    }
    let url = '';
    if (storage === 'local' && wine_id) {
        url = '/api/wine-image/' + wine_id;
    }
    if (storage === 'internal') {
        url = `${process.env.REACT_APP_GUSTOS_APP_URL}/files/${path.replace('public://', '')}`;
    }
    if (storage === 'external') {
        url = path;
    }
    if (url) {
        return (
            <a
                href={url}
                className={`${storage}-wine-image`}
                target="_blank">
                <img src={url} alt="Wine image"
                     height={"100px"}/>
            </a>
        );
    }
    return (<></>);
}

export const shallowEqual = (object1: { [key: string]: string | number; }, object2: { [key: string]: string | number; }) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }
    return true;
}

export const hasPermissionTo = (authUser: { authUser: AuthUser|null }, permission: string) : boolean => {
    return !!(authUser.authUser && (authUser.authUser.is_admin || (authUser.authUser.permissions && authUser.authUser.permissions.length && authUser.authUser.permissions.includes(permission))));
}

export const stringToColor = function (str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        color += ("00" + value.toString(16)).slice(-2);
    }
    return color;
};

export const colorContrast = (hexcolor: string) => {
    const r = parseInt(hexcolor.substring(1, 3), 16);
    const g = parseInt(hexcolor.substring(3, 5), 16);
    const b = parseInt(hexcolor.substring(5, 7), 16);

    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? "black" : "white";
};

export const removeHtmlTags = (text: string) => {
    return text.replace(/<[^>]*>?/gm, "");
};

export const highlightText = (needle: string, haystack: string) => {
    const cyrillicPattern = /^[\u0400-\u04FF]+$/;

    needle = escapeRegExp(needle);

    if (cyrillicPattern.test(needle)) {
        let regEx = new RegExp(`(${needle})`, "gi");

        return haystack.replace(regEx, "<span class='highlight'>$1</span>");
    } else {
        let regEx = new RegExp(`(${latinize(needle)})`, "gi");

        let normalizeText = latinize(haystack);
        let positions = [];
        let match;
        while (match = regEx.exec(normalizeText)) {
            positions.push(match.index);
        }

        let newText = haystack;
        positions.forEach((position, k) => {
            let part = haystack.substring(position, position + needle.length);
            let highlight = "<span class=\"highlight\">" + part + "</span>";

            let additionalLength = k ? (k * highlight.length) - (k * needle.length) : 0;

            newText = newText.substring(0, position + additionalLength) + highlight + newText.substring(position + needle.length + additionalLength);
        });

        return newText;
    }
};

const escapeRegExp = (text: string) => {
    return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

export const downloadFile = (data: any, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
}
