import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Alert, Button, Container, Dropdown, DropdownButton } from "react-bootstrap";
import { ArrowRepeat, ChatLeftText, Clipboard, FileEarmarkRuled, FileEarmarkRuledFill, Google, Search, ShieldFillCheck, ThreeDotsVertical, Trash } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import BsModal from "../components/BsModal";
import EditMatchingField from "../components/EditMatchingField";
import Preloader from "../components/Preloader";
import SearchGoogle from "../components/SearchGoogle";
import WineryCommentModal from "../components/WineryCommentModal";
import { AuthUserContext } from "../context/AuthUser";
import { AlertError, copyToClipboard, isNumeric, parseGrapePercent, renderImageUrl, useInterval } from "../helpers";
import { EditHistoryRow, EWineProperties, EWineStatuses, TErrorObject, TTaxonomy } from "../properties";
import CopyIcon from "../assets/images/copy.webp";
import GoogleIcon from "../assets/images/google_icon.png";
import SearchIcon from "../assets/images/search_local.webp";
import { TWinery } from "./WineriesReview";

type TParams = {
    wine_id: string
}

type TCheckedFields = {
    [key: string]: string[]
}

type TCheckedValues = {
    [key: number]: string
}

type TSelectedMatchingWine = {
    wine_id: number,
    vintage_id: number,
    description?: string
} | null

export type TWineObject = {
    label: string,
    value: string | number,
    is_preset?: boolean
}

type TMatchingWine = {
    [key in EWineProperties]: TWineObject
};

type TPresets = {
    [key in EWineProperties]: {
        label: string,
        value: string | number
    }
}

type TWineProp = {
    [key: number]: EWineProperties
}

type TSynonyms = {
    [key in EWineProperties]: string
}

const enum ENoRegionActions {
    NEW_WINE = 'new_wine',
    NEW_VINTAGE = 'new_vintage'
}

const enum EPossibleActions {
    MERGE_SELECT = 'MERGE_SELECT',
    NEW_VINTAGE = 'NEW_VINTAGE',
    NEW_WINE = 'NEW_WINE'
}

type PossibleWineActions = {
    action: EPossibleActions,
    wine_id: number
}

type Scores = {
    score: number,
    wine_id: number
}

const enum RowMenuItems {
    COPY = 'Скопировать текст',
    SEARCH = 'Поиск по Parsedwine',
    GOOGLE = 'Поиск в Google',
}

type TRowMenuItem = {
    [k in EWineProperties]?: RowMenuItems[];
};

type MatchingWinesProps = {
    matching_data: {
        wine_add_info: object,
        selected_wine: TSelectedMatchingWine,
        possible_actions: object,
        scores: object,
        edit_field_history: object,
        verified_wineries: number[],
        presets: object,
        wine: TMatchingWine,
        verified_winery: boolean,
        wineries_with_comments: number[]
    },
    err: string
}

const NameMatching = () => {
    const authUser = useContext(AuthUserContext);
    const axiosConfig = {
        headers: {
            Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
        }
    };

    const EFields: TWineProp = [
        EWineProperties.name, EWineProperties.vineyard, EWineProperties.year, EWineProperties.country,
        EWineProperties.region, EWineProperties.color, EWineProperties.co2,
        EWineProperties.alcohol, EWineProperties.sugar,
        EWineProperties.method, EWineProperties.grape_type, EWineProperties.award, EWineProperties.image, EWineProperties.url_image
    ];

    const [error, setError] = useState<string | TErrorObject>('');
    const {t} = useTranslation();
    const [wine, setWine] = useState<TMatchingWine>();
    const [competition, setCompetition] = useState('');
    const [event, setEvent] = useState('');
    const [matchingWine, setMatchingWine] = useState<TMatchingWine>();
    const [matchingIds, setMatchingIds] = useState<TSelectedMatchingWine[]>([]);
    const [selectedMatchingWine, setSelectedMatchingWine] = useState<TSelectedMatchingWine>(null);
    const [checkedFields, setCheckedFields] = useState<TCheckedFields>();
    const [paginationPreloader, setPaginationPreloader] = useState<TSelectedMatchingWine>(null);
    const [showEditInput, setShowEditInput] = useState<string[]>([]);
    const [editedFields, setEditedFields] = useState<string[]>([]);
    const [presets, setPresets] = useState<TPresets>();
    const [taxonomyProperties, setTaxonomyProperties] = useState<TTaxonomy>();
    const [wineAddInfo, setWineAddInfo] = useState<object>({});
    const [showRefreshMatching, setShowRefreshMatching] = useState(false);
    const [openModalConfirmMerge, setOpenModalConfirmMerge] = useState(false);
    const [openModalConfirmNoRegion, setOpenModalConfirmNoRegion] = useState(false);
    const [noRegionAction, setNoRegionAction] = useState<ENoRegionActions>();
    const [bsError, setBsError] = useState('');
    const [synonyms, setSynonyms] = useState<TSynonyms>();
    const [verifiedWineries, setVerifiedWineries] = useState<number[]>([]);
    const [possibleActions, setPossibleActions] = useState<PossibleWineActions[]>([]);
    const [possibleSelectedWineAction, setPossibleSelectedWineAction] = useState<EPossibleActions>();
    const [scores, setScores] = useState<Scores[]>([]);
    const [selectedScore, setSelectedScore] = useState<number>(0);
    const [editRowHistory, setEditRowHistory] = useState<EditHistoryRow[]>([]);
    const [activeRowMenu, setActiveRowMenu] = useState<EWineProperties>();
    const [wineriesWithComments, setWineriesWithComments] = useState<Record<number, number>>();

    const [openModalComments, setOpenModalComments] = useState(false);
    const [openCommentsForWinery, setOpenCommentsForWinery] = useState<{id: number, name: string }>();

    const allow_edit_text = [EWineProperties.name, EWineProperties.year, EWineProperties.vineyard, EWineProperties.country, EWineProperties.region, EWineProperties.color, EWineProperties.co2, EWineProperties.extra_sweetness, EWineProperties.under_film_of_yeast, EWineProperties.alcohol, EWineProperties.sugar, EWineProperties.grape_category, EWineProperties.stage_of_production, EWineProperties.method, EWineProperties.grape_type, EWineProperties.url_image];

    const {wine_id} = useParams<TParams>();

    const history = useHistory();

    const rowMenuItems:TRowMenuItem = {
        [EWineProperties.name]: [RowMenuItems.COPY, RowMenuItems.SEARCH, RowMenuItems.GOOGLE],
        [EWineProperties.vineyard]: [RowMenuItems.COPY, RowMenuItems.SEARCH],
        [EWineProperties.country]: [RowMenuItems.COPY, RowMenuItems.SEARCH],
        [EWineProperties.region]: [RowMenuItems.COPY, RowMenuItems.SEARCH],
    };

    const RowMenuItemsIcons = {
        [RowMenuItems.COPY]: <Clipboard />,
        [RowMenuItems.SEARCH]: <Search />,
        [RowMenuItems.GOOGLE]: <Google />,
    };

    const RowMenuItemsActions = {
        [RowMenuItems.COPY]: (field: EWineProperties) => {
            if (wine && wine[field]) {
                copyToClipboard(wine[field].label).then(r => r);
                toast.success("Copied to clipboard!");
            }
            setActiveRowMenu(undefined);
        },
        [RowMenuItems.SEARCH]: (field: EWineProperties) => {
            if (wine && wine[field]) {
                window.open('/search?term=' + wine[field].label, '_blank');
            }
            setActiveRowMenu(undefined);
        },
        [RowMenuItems.GOOGLE]: (field: EWineProperties) => {
            if (wine && wine[field]) {
                window.open('https://www.google.com/search?q=' + wine[field].label, '_blank');
            }
            setActiveRowMenu(undefined);
        }
    }

    let ModalComments = {
        showModal: openModalComments,
        hideModal: () => {
            hideModalComments()
        },
    }

    const hideModalComments = () => {
        setOpenModalComments(false);
        setOpenCommentsForWinery(undefined);
    }

    const openWineryChatModal = (winery: {id: number, name: string }) => {
        setOpenCommentsForWinery(winery);
        setOpenModalComments(true);
    }

    const onUpdateMessages = (winery_id: number, count_messages: number) => {
        let list = Object.assign({}, wineriesWithComments);
        if (count_messages) {
            list[winery_id] = count_messages;
            console.log(list);
            setWineriesWithComments(list);
        } else {
            delete list[winery_id];
            setWineriesWithComments(list);
        }
    };

    const relatedFields = [
        {
            field: EWineProperties.region,
            depend_by: EWineProperties.country
        }, {
            field: EWineProperties.country,
            depend_by: EWineProperties.region
        }, {
            field: EWineProperties.method,
            depend_by: EWineProperties.co2
        }, {
            field: EWineProperties.co2,
            depend_by: EWineProperties.method
        }, {
            field: EWineProperties.image,
            depend_by: EWineProperties.url_image
        }, {
            field: EWineProperties.url_image,
            depend_by: EWineProperties.image
        }
    ]

    const disabledFields: string | EWineProperties[] = [EWineProperties.award];

    const CheckedValues: TCheckedValues = {
        1: 'original',
        0: 'matching'
    }

    let TOUCH_INTERVAL = 60000; //milliseconds

    const ModalConfirmMergeProps = {
        showModal: openModalConfirmMerge,
        hideModal: () => {
            setOpenModalConfirmMerge(false);
        },
        title: "Merge with selected",
        primaryButton: {
            title: 'Yes',
            onClick: () => {
                mergeSelected(true);
                setOpenModalConfirmMerge(false);
            }
        },
        confirmButton: false,
        bsError: bsError,
    }

    const ModalConfirmNoRegionProps = {
        showModal: openModalConfirmNoRegion,
        hideModal: () => {
            closeNoRegionModal()
        },
        title: "Confirmation",
        primaryButton: {
            title: 'Yes',
            onClick: () => {
                if (noRegionAction === ENoRegionActions.NEW_WINE) {
                    createNewWine(true);
                } else if (noRegionAction === ENoRegionActions.NEW_VINTAGE) {
                    createNewVintage(true);
                }
            }
        },
        confirmButton: false,
        bsError: bsError,
    }

    const getWineData = () => {
        if (wine_id) {
            const searchParams = new URLSearchParams(window.location.search);
            const queryString = wine_id + (searchParams.get('new') ? '?new=' + searchParams.get('new') : '');
            axios.get('/api/wine-data/' + queryString , {
                ...axiosConfig
            }).then((response) => {
                let result = response.data;

                if (result.hasOwnProperty('err')) {
                    setError(result.err);
                    return false;
                }

                if (result.hasOwnProperty('presets')) {
                    setPresets(result.presets)
                }

                if (result.hasOwnProperty('taxonomy_properties')) {
                    let properties = Object.assign({}, result.taxonomy_properties);
                    setTaxonomyProperties(properties);
                }

                if (result.hasOwnProperty('wine')) {

                    let wineData = result.wine;
                    if (result.hasOwnProperty('presets') && Object.keys(result.presets).length) {
                        wineData = getPresetsValuesWineData(result.presets, result.wine);
                    }
                    setWine(wineData);

                    // * default checked original
                    let checks: Array<string> = [];
                    Object.entries(EFields).forEach(([, value]) => {
                        if (disabledFields.indexOf(value) !== -1) {
                            return false;
                        }
                        checks.push(value);
                    });

                    let ch = Object.assign({}, checkedFields);
                    ch.matching = checks;
                    if (ch.original) {
                        delete ch.original;
                    }

                    setCheckedFields(ch);
                }

                if (result.hasOwnProperty('competition')) {
                    setCompetition(result.competition);
                }

                if (result.hasOwnProperty('event')) {
                    setEvent(result.event);
                }

                if (result.hasOwnProperty('wine_add_info')) {
                    setWineAddInfo(result.wine_add_info);
                }

                if (result.hasOwnProperty('matching_data') && Object.keys(result.matching_data).length) {
                    if (result.matching_data.wine) {
                        setMatchingWine(result.matching_data.wine);
                    }

                    if (result.matching_data.ids.length) {
                        setMatchingIds(result.matching_data.ids);
                    }

                    if (result.matching_data.selected_wine) {
                        setSelectedMatchingWine(result.matching_data.selected_wine);
                    }

                    if (result.matching_data.possible_actions) {
                        setPossibleActions(result.matching_data.possible_actions);
                        setPossibleSelectedWineAction(getPossibleAction(parseInt(result.matching_data.selected_wine.wine_id), result.matching_data.possible_actions));
                    }

                    if (result.matching_data.scores) {
                        setScores(result.matching_data.scores);
                        setSelectedScore(getScore(parseInt(result.matching_data.selected_wine.wine_id), result.matching_data.scores));
                    }
                }

                if (result.hasOwnProperty('edit_field_history')) {
                    setEditRowHistory(result.edit_field_history);
                }

                if (result.hasOwnProperty('synonyms')) {
                    setSynonyms(result.synonyms);
                }

                if (result.hasOwnProperty('verified_wineries')) {
                    setVerifiedWineries(result.verified_wineries);
                }

                if (result.hasOwnProperty("wineries_with_comments")) {
                    setWineriesWithComments(result.wineries_with_comments);
                }

            }).catch((error) => {
                if (error.hasOwnProperty('response') && error.response.status === 401) {
                    authUser.setAuthUser(null);
                    localStorage.removeItem('auth_user');
                }
                setError(error.toString());
            });
        }
    }

    const getPresetsValuesWineData = (presets: TPresets, wineData: TMatchingWine) => {
        Object.entries(wineData).forEach(([key, data]) => {
            let k = key as EWineProperties;

            if (!data.value && presets.hasOwnProperty(key) && presets[k].value) {
                data.value = presets[k].value;
                data.label = presets[k].label;
                data.is_preset = true;
            }
        });
        return wineData;
    }

    const checkAll = (value: string) => {
        let ch = Object.assign({}, checkedFields),
            list: string[] = [];

        if (ch.original) {
            list = list.concat(ch.original);
            delete ch.original;
        }

        if (ch.matching) {
            list = list.concat(ch.matching);
            delete ch.matching;
        }

        ch[value] = list;

        setCheckedFields(ch);
    }

    const changeCheckField = (field: string, value: number) => {
        let ch = Object.assign({}, checkedFields), index;

        // * find
        if (ch.original && ch.original.indexOf(field) !== -1) {
            index = ch.original.indexOf(field);
            delete ch.original[index];
        }
        if (ch.matching && ch.matching.indexOf(field) !== -1) {
            index = ch.matching.indexOf(field);
            delete ch.matching[index];
        }

        if (!ch[CheckedValues[value]]) {
            ch[CheckedValues[value]] = [];
        }

        ch[CheckedValues[value]].push(field);

        setCheckedFields(ch);

        moveRelatedFields(field, value, ch);
    }

    const moveRelatedFields = (field: string, value: number, ch: TCheckedFields) => {

        relatedFields.forEach(item => {
            if (item.depend_by === field) {
                // * move region
                let countryIndex;
                if (ch.original && ch.original.indexOf(item.field) !== -1) {
                    countryIndex = ch.original.indexOf(item.field);
                    delete ch.original[countryIndex];
                }
                if (ch.matching && ch.matching.indexOf(item.field) !== -1) {
                    countryIndex = ch.matching.indexOf(item.field);
                    delete ch.matching[countryIndex];
                }

                if (!ch[CheckedValues[value]]) {
                    ch[CheckedValues[value]] = [];
                }

                ch[CheckedValues[value]].push(item.field);
            }
        });

        setCheckedFields(ch);
    }

    const loadMatchingWine = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, wineId: number, vintageId: number) => {
        event.preventDefault();

        setPaginationPreloader({wine_id: wineId, vintage_id: vintageId});

        axios.post('/api/matching-wine-data', {
            wine_id: wineId,
            vintage_id: vintageId
        }, {
            ...axiosConfig
        }).then((response) => {
            let data:MatchingWinesProps = response.data;
            setPaginationPreloader(null);
            if (data.hasOwnProperty('matching_data')) {
                setMatchingWine(data.matching_data.wine);
                setSelectedMatchingWine(data.matching_data.selected_wine);

                if (data.matching_data.wine[EWineProperties.vineyard] && data.matching_data.wine[EWineProperties.vineyard].value) {
                    applyVerifiedWinery(Number(data.matching_data.wine[EWineProperties.vineyard].value), data.matching_data.verified_winery);
                }

                if (data.matching_data.selected_wine) {
                    setPossibleSelectedWineAction(getPossibleAction(data.matching_data.selected_wine.wine_id));
                    setSelectedScore(getScore(data.matching_data.selected_wine.wine_id));
                }
            }

            if (data.hasOwnProperty('err')) {
                setError(data.err);
                return false;
            }
        }).catch(r => {
            setPaginationPreloader(null);
            setError(r.toString());
        })
    }

    const renderMatchingPaginationClasses = (item: TSelectedMatchingWine) => {
        let classes = '';

        if (item && selectedMatchingWine && item.wine_id === selectedMatchingWine.wine_id && item.vintage_id === selectedMatchingWine.vintage_id) {
            classes += ' active ';
        }

        if (item && paginationPreloader && item.wine_id === paginationPreloader.wine_id && item.vintage_id === paginationPreloader.vintage_id) {
            classes += ' preloader ';
        }

        return classes;
    }

    const skipWine = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setError('');

        if (!(wine) || wine[EWineProperties.status].value !== EWineStatuses.escalated) {
            toast.error('You can skip only escalated wines');
            return false;
        }

        if (!window.confirm('Are you sure want to skip wine?')) {
            return false;
        }

        axios.post('/api/skip-wine', {
            id: parseInt(wine_id)
        }, {
            ...axiosConfig
        }).then((response) => {
            let data = response.data;
            if (data.hasOwnProperty('err')) {
                setError(data.err);
                return false;
            }

            cleanData();

            if (data.hasOwnProperty('wine_id') && data.wine_id) {
                history.replace('/name-matching/' + data.wine_id);
            } else {
                history.replace('/wines');
            }
        });
    }

    const escalateWine = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setError('');

        if (!window.confirm('Are you sure want to escalate wine?')) {
            return false;
        }

        axios.post('/api/escalate-wine', {
            id: parseInt(wine_id)
        }, {
            ...axiosConfig
        }).then((response) => {
            let data = response.data;
            if (data.hasOwnProperty('err')) {
                setError(data.err);
                return false;
            }

            cleanData();

            if (data.hasOwnProperty('wine_id') && data.wine_id) {
                history.replace('/name-matching/' + data.wine_id);
            } else {
                history.replace('/wines');
            }
        });
    }

    const rejectWine = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setError('');

        if (!window.confirm('Are you sure want to reject wine?')) {
            return false;
        }

        axios.post('/api/reject-wine', {
            id: parseInt(wine_id)
        }, {
            ...axiosConfig
        }).then((response) => {
            let data = response.data;
            if (data.hasOwnProperty('err')) {
                setError(data.err);
                return false;
            }

            cleanData();

            if (data.hasOwnProperty('wine_id') && data.wine_id) {
                history.replace('/name-matching/' + data.wine_id);
            } else {
                history.replace('/wines');
            }
        });
    }

    const restoreWine = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setError('');

        if (!window.confirm('Are you sure want to restore wine?')) {
            return false;
        }

        axios.post('/api/restore-wine', {
            id: parseInt(wine_id)
        }, {
            ...axiosConfig
        }).then((response) => {
            let data = response.data;
            if (data.hasOwnProperty('err')) {
                setError(data.err);
                return false;
            }

            cleanData();

            if (data.hasOwnProperty('wine_id') && data.wine_id) {
                history.replace('/name-matching/' + data.wine_id);
            } else {
                history.replace('/wines');
            }
        });
    }

    const backToList = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setError('');

        if (!window.confirm('Are you sure want to back to list of wines?')) {
            return false;
        }
        axios.post('/api/free-wine-in-progress', {
            id: parseInt(wine_id)
        }, {
            ...axiosConfig
        }).then((response) => {
            let data = response.data;
            if (data.hasOwnProperty('err')) {
                setError(data.err);
                return false;
            }

            history.replace('/wines');
        });
    }

    const matchingIndicatorCss = (wineData: TWineObject | string, matchingData: TWineObject | string, section: string) => {
        if (section === 'original' && typeof wineData === "object" && wineData.is_preset) {
            return 'yellow_indicator';
        }

        if (typeof wineData === "object" && typeof matchingData === 'object' && wineData.value === matchingData.value) {
            return 'green_indicator';
        }
        return '';
    }

    const createNewVintage = (confirmed = false) => {
        setError('');

        if (!confirmed && !window.confirm('Are you sure want to create new vintage?')) {
            return false;
        }

        let checkedPropertyGrape = getCheckedPropertyData(EWineProperties.grape_type);

        let params = {
            wine: selectedMatchingWine ? selectedMatchingWine.wine_id : '',
            region: getCheckedPropertyValue(EWineProperties.region),
            image: getCheckedPropertyValue(EWineProperties.image),
            alcohol: getCheckedPropertyValue(EWineProperties.alcohol),
            sugar: getCheckedPropertyValue(EWineProperties.sugar),
            vintage: getCheckedPropertyValue(EWineProperties.year),
            award: getCheckedPropertyValue(EWineProperties.award),
            grape_type: getCheckedPropertyValue(EWineProperties.grape_type),
            grape_percent: checkedPropertyGrape ? parseGrapePercent(checkedPropertyGrape.label) : '',
            url_image: getCheckedPropertyValue(EWineProperties.url_image),
            original_wine_id: wine_id
        };

        axios.post('/api/store-vintage', params, {
            ...axiosConfig
        }).then((response) => {
            let data = response.data;
            if (data.hasOwnProperty('err')) {
                setError(data.err);
                return false;
            }

            cleanData();
            if (data.hasOwnProperty('wine_id') && data.wine_id) {
                history.push('/name-matching/' + data.wine_id);
            } else {
                history.replace('/wines');
            }
        }).catch(r => {
            setError(r.toString());
        }).finally(() => {
            closeNoRegionModal();
        });
    }

    const createNewWine = (confirmed = false) => {
        setError('');

        if (!confirmed && !window.confirm( 'Are you sure want to create new wine with vintage?')) {
            return false;
        }

        let params = getAllCheckedValues(),
            checkedPropertyGrape = getCheckedPropertyData(EWineProperties.grape_type);
        params['wine_id'] = wine_id;
        params[EWineProperties.grape_percent] = checkedPropertyGrape ? parseGrapePercent(checkedPropertyGrape.label) : '';

        axios.post('/api/store-wine-vintage', params, {
            ...axiosConfig
        }).then((response) => {
            let data = response.data;
            if (data.hasOwnProperty('err')) {
                setError(data.err);
                return false;
            }
            cleanData();
            if (data.hasOwnProperty('wine_id') && data.wine_id) {
                history.push('/name-matching/' + data.wine_id);
            } else {
                history.replace('/wines');
            }
        }).catch(r => {
            setError(r.toString());
        }).finally(() => {
            closeNoRegionModal();
        });
    }

    const selectedVineyardWithoutRegion = () => {
        const vineyard = getCheckedPropertyValue(EWineProperties.vineyard);
        const region = getCheckedPropertyValue(EWineProperties.region);
        return !!(vineyard && verifiedWineries.findIndex(item => item === vineyard) !== -1 && !region);
    }

    const cleanData = () => {
        setMatchingWine(undefined);
        setSelectedMatchingWine(null);
        setMatchingIds([]);
        setEditedFields([]);
        setShowEditInput([]);

        setWine(undefined);

        closeNoRegionModal();
    }

    const closeNoRegionModal = () => {
        setNoRegionAction(undefined);
        setOpenModalConfirmNoRegion(false);
    }

    const getCheckedPropertyValue = (field: string) => {
        let checkedProperty = getCheckedPropertyData(field);

        if (checkedProperty) {
            return checkedProperty.value;
        }

        return '';
    }

    const getCheckedPropertyData = (field: string) => {
        let searchIn: any = '';
        // * find
        if (checkedFields) {
            if (checkedFields.original && checkedFields.original.indexOf(field) !== -1) {
                if (wine && wine.hasOwnProperty(field)) {
                    searchIn = wine;
                }
            }
            if (checkedFields.matching && checkedFields.matching.indexOf(field) !== -1) {
                if (matchingWine && matchingWine.hasOwnProperty(field)) {
                    searchIn = matchingWine;
                }
            }

            if (searchIn) {
                let propertyData: any = {};
                Object.entries(searchIn).forEach(([key, value]) => {
                    if (key === field) {
                        propertyData = value;
                    }
                });
                return propertyData;
            }
        }
        return '';
    }

    const getAllCheckedValues = () => {
        let params: any = {}

        Object.entries(EFields).forEach(([, value]) => {
            params[value] = getCheckedPropertyValue(value);
        });

        return params;
    }

    const changeEditRow = (key: EWineProperties, item: { label: string, value: string }, restore?: boolean) => {
        if (allow_edit_text.indexOf(key) !== -1) {

            let wineData = Object.assign({}, wine);
            if (typeof wineData === "object" && wineData.hasOwnProperty(key) && typeof wineData[key] !== "undefined") {
                axios.post('/api/matching-submit-edit-row', {
                    wine_id: wine_id,
                    field: key,
                    old_label: wineData[key].label,
                    old_value: wineData[key].value,
                    new_value: item.value,
                    new_label: item.label,
                    restore: restore ? 1 : 0
                }, {
                    ...axiosConfig,
                }).then((response) => {
                    let data = response.data;
                    const old_label = wineData[key].label;
                    const old_value = wineData[key].value;

                    if (data.hasOwnProperty('error')) {
                        setError(data.error);
                        return false;
                    }

                    wineData[key].label = item.label;
                    wineData[key].value = isNumeric(item.value) ? parseInt(item.value) : item.value;
                    setWine(wineData);

                    // * reset region if country selected
                    if (key === EWineProperties.country) {
                        if (wineData && wineData[EWineProperties.region]) {
                            wineData[EWineProperties.region].label = '';
                            wineData[EWineProperties.region].value = '';
                            setWine(wineData);
                        }
                    }

                    let listEditedFields = editedFields;
                    if (!restore && editedFields.indexOf(key) === -1) {
                        listEditedFields.push(key);
                        setEditedFields(listEditedFields.map(f => f));
                    }

                    editedFields.forEach((item, index) => {
                        if ([EWineProperties.year, EWineProperties.name, EWineProperties.vineyard].indexOf(item as EWineProperties) !== -1) {
                            setShowRefreshMatching(true);
                            return;
                        } else {
                            setShowRefreshMatching(false);
                        }
                    });

                    // Check if winery verified
                    if (key === EWineProperties.vineyard) {
                        verifyWinery(parseInt(item.value));
                        wineryReviewComments(parseInt(item.value));
                    }

                    if (restore) {
                        const filtered = editRowHistory.filter((item, index) => {
                            if (key === EWineProperties.country && item.field === EWineProperties.region) {
                                return false;
                            }

                            return item.field !== key;
                        });
                        setEditRowHistory(filtered);

                        let filteredEditedFields = editedFields.filter((item, index) => item !== key);
                        setEditedFields(filteredEditedFields.map(f => f));
                    } else {
                        let historyItem: EditHistoryRow = {
                            user: "0",
                            field: key,
                            old_value: old_value ? old_value.toString() : '',
                            old_label: old_label,
                            new_value: item.value,
                            created_at: new Date().toISOString()
                        };
                        setEditRowHistory([...editRowHistory, historyItem]);
                    }
                });
            }
        }
    }

    const cancelEditRow = (key: EWineProperties) => {
        let filteredEditedFields = editedFields.filter((item, index) => item !== key);
        setEditedFields(filteredEditedFields.map(f => f));

        let filteredShowEditInput = showEditInput.filter((item, index) => item !== key);
        setShowEditInput(filteredShowEditInput.map(f => f));
    }

    const verifyWinery = (id: number) => {
        axios.get("/api/verify-winery/" + id, {
            ...axiosConfig
        }).then(r=> {
           let result = r.data;
           if (result.hasOwnProperty('verified')) {
               applyVerifiedWinery(id, result.verified);
           }
        });
    }

    const applyVerifiedWinery = (id: number, verified: boolean) => {
        if (verified) {
            if (verifiedWineries.findIndex(item => item === id) === -1) {
                verifiedWineries.push(id);
                setVerifiedWineries(verifiedWineries.map(f=>f));
            }
        } else {
            let list = verifiedWineries.filter(item => item !== id);
            setVerifiedWineries(list.map(f=>f));
        }
    }

    const wineryReviewComments = (id: number) => {
        axios.get("/api/wineries-review/count-comments/" + id, {
            ...axiosConfig
        }).then(r => {
            let result = r.data;
            onUpdateMessages(id, result);
        });
    }

    const mergeSelected = (confirmed = false) => {
        setError('');

        if (!confirmed && !window.confirm('Are you sure want to merge with selected?')) {
            return false;
        }

        let params = getAllCheckedValues(),
            checkedPropertyGrape = getCheckedPropertyData(EWineProperties.grape_type);
        params = {...params, ...selectedMatchingWine};
        params['original_wine_id'] = wine_id;
        params[EWineProperties.grape_percent] = checkedPropertyGrape ? parseGrapePercent(checkedPropertyGrape.label) : '';

        axios.post('/api/merge-with-selected', params, {
            ...axiosConfig
        }).then((response) => {
            let data = response.data;
            if (data.hasOwnProperty('err')) {
                setError(data.err);
                return false;
            }

            cleanData();

            if (data.hasOwnProperty('wine_id') && data.wine_id) {
                history.push('/name-matching/' + data.wine_id);
            } else {
                history.replace('/wines');
            }
        }).catch(r => {
            setError(r.toString());
        });
    }

    const reloadMatchingData = () => {
        if (wine) {
            axios.post('/api/refresh-matching-wines', {
                name: wine[EWineProperties.name] ? wine[EWineProperties.name].value : '',
                vineyard: wine[EWineProperties.vineyard] ? wine[EWineProperties.vineyard].value : '',
                year: wine[EWineProperties.year] ? wine[EWineProperties.year].value : ''
            }, {
                ...axiosConfig
            }).then(response => {
                let result = response.data;

                if (result.hasOwnProperty('matching_data')) {

                    setMatchingWine(undefined);
                    setSelectedMatchingWine(null);
                    setMatchingIds([]);

                    if (result.matching_data) {
                        if (result.matching_data.wine) {
                            setMatchingWine(result.matching_data.wine);
                        }

                        if (result.matching_data.ids.length) {
                            setMatchingIds(result.matching_data.ids);
                        }

                        if (result.matching_data.selected_wine) {
                            setSelectedMatchingWine(result.matching_data.selected_wine);

                            if (result.matching_data.hasOwnProperty('verified_winery') && result.matching_data.wine[EWineProperties.vineyard].value) {
                                applyVerifiedWinery(result.matching_data.wine[EWineProperties.vineyard].value, result.matching_data.verified_winery);
                            }
                        }
                    }
                }
            })
        }
    }

    const getPossibleAction = (wineId: number, actionsList?: PossibleWineActions[]) => {
        const list = actionsList ? actionsList : possibleActions;
        if (list.length) {
            const action = list.filter((item: {
                action: EPossibleActions,
                wine_id: number
            }) => {
                return item.wine_id == wineId;
            });

            if (action.length) {
                return action[0].action;
            }
        }
        return undefined;
    };

    const getScore = (wineId: number, scoresList?: Scores[]) => {
        const list = scoresList ? scoresList : scores;
        if (list.length) {
            const score = list.filter((item: {
                wine_id: number,
                score: number
            }) => {
                return item.wine_id == wineId;
            });
            if (score.length) {
                return score[0].score;
            }
        }

        return 0;
    };

    const getEditRowHistory = (field: EWineProperties) => {
        if (editRowHistory.length) {
            const history = editRowHistory.filter((item) => {
                return item.field == field;
            });
            if (history.length) {
                return history[0];
            }
        }
    };

    const renderWineValue = (data: EWineProperties) => {
        if (wine && wine.hasOwnProperty(data) && wine[data]) {
            if (data === EWineProperties.image) {
                if (wine[data].label) {
                    const render = renderImageUrl(wine[data].label, "local", Number(wine_id));
                    if (render.type === "a") {
                        return <>
                            {render}
                            <a href={"#"} className="remove-image" onClick={removeWineBadImage}><Trash/></a>
                        </>;
                    }
                    return render;
                } else {
                    if (wine[EWineProperties.url_image] && wine[EWineProperties.url_image].label) {
                        return renderImageUrl(wine[EWineProperties.url_image].label, "external", Number(wine_id));
                    }
                }
            } else if (data === EWineProperties.vineyard) {
                if (wine[data].label && wine[data].label.length) {
                    return (<a href={'/wines-merge/list?winery=' + wine[data].value} target="_blank">{wine[data].label}</a>);
                } else {
                    return (<>{wine[data].label}</>);
                }
            } else {
                if (wine[data].label && wine[data].label.length && wine[data].label.substring(0, 4) === "http") {
                    return (<a href={wine[data].label} target="_blank">{wine[data].label} </a>);
                } else {
                    return (
                      <>{wine[data].label}</>
                    );
                }
            }
        } else {
            if (presets && presets.hasOwnProperty(data) && presets[data].value) {
                return (<span className="yellow_indicator">{presets[data].label}</span>);
            }
        }

        return (
          <></>
        );
    };

    const removeWineBadImage = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();

        if (window.confirm("Are you sure want to remove image?")) {
            axios.post("/api/remove-wine-image", {
                wine_id: wine_id,
            }, {
                ...axiosConfig,
            }).then(response => {
                let result = response.data;
                if (result.hasOwnProperty("error")) {
                    setError(result.error);
                    return false;
                }

                if (wine) {
                    let wineData = Object.assign({}, wine);
                    wineData[EWineProperties.image].label = "";
                    wineData[EWineProperties.image].value = "";
                    setWine(wineData);
                }
            });
        }
    };

    /**
     * Use interval to touch working wine
     */
    useInterval(async () => {
        if (wine_id) {
            axios.get('/api/touch-wine-row/' + wine_id, {
                ...axiosConfig
            }).then(response => {
                let result = response.data;
                if (result.hasOwnProperty('error')) {
                    TOUCH_INTERVAL = 0;
                    console.log(result.error);
                    return false;
                }
            })
        }
    }, TOUCH_INTERVAL)

    useEffect(() => {
        getWineData();
    }, [wine_id]);

    return (
      <div className="App">
          <div className="data-matching-page w-100">
              <Container fluid>
                  <div className="row">
                        <div className="col-md-12">
                            <h4 className="mb-3 text-start">Import data</h4>
                            <hr/>
                            <div className="row">
                                <div className="col-md-8">
                                    <h4>Line 3 -
                                        matching {wine && wine[EWineProperties.status] ? '(' + wine[EWineProperties.status].value + ')' : ''}</h4>
                                </div>
                                <div className="col-md-4 text-end">
                                    <SearchGoogle
                                        wineName={wine && wine[EWineProperties.name] ? wine[EWineProperties.name].label : null}
                                        vineyardName={wine && wine[EWineProperties.vineyard] ? 'vivino ' + wine[EWineProperties.vineyard].label : null}
                                        vintage={wine && wine[EWineProperties.year] ? wine[EWineProperties.year].label : null}
                                    />
                                </div>
                            </div>
                            <hr/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <AlertError error={error} callback={() => setError('')}/>
                            <div className="contest mb-5">
                                <div className="row">
                                    {wine
                                        ? (<>
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-10 offset-md-2">
                                                        <p><strong>{competition}</strong></p>
                                                        <p><strong>{event}
                                                            {wine && wine[EWineProperties.award] ? ' (' + wine[EWineProperties.award].label + ')' : ''}</strong>
                                                        </p>
                                                        <Button variant="custom" className={`mb-3 mt-3 inherit mr-4 ${possibleSelectedWineAction === EPossibleActions.NEW_WINE ? 'possible_match' : ''}`}
                                                            onClick={() => {
                                                                if (selectedVineyardWithoutRegion()) {
                                                                    setNoRegionAction(ENoRegionActions.NEW_WINE);
                                                                    setOpenModalConfirmNoRegion(true);
                                                                } else {
                                                                    createNewWine();
                                                                }
                                                            }}>
                                                            Create new
                                                        </Button>

                                                        <a href='/#' onClick={backToList}
                                                           className="link-custom ml-3 mr-4 ">Back to the list</a>

                                                        {wine && wine[EWineProperties.status] && wine[EWineProperties.status].value !== EWineStatuses.done
                                                            ?
                                                            (<DropdownButton title="Actions"
                                                                             className="d-inline-block ml-3 float-end mt-3"
                                                                             variant="custom inherit">
                                                                {wine[EWineProperties.status].value === EWineStatuses.escalated ?
                                                                    <Dropdown.Item href="/#"
                                                                                   onClick={skipWine}>Skip</Dropdown.Item> : ''}
                                                                {wine[EWineProperties.status].value !== EWineStatuses.escalated ?
                                                                    <Dropdown.Item href="/#"
                                                                                   onClick={escalateWine}>Escalate</Dropdown.Item> : ''}
                                                                {wine[EWineProperties.status].value !== EWineStatuses.rejected ?
                                                                    <Dropdown.Item href="/#"
                                                                                   onClick={rejectWine}>Reject</Dropdown.Item> : ''}
                                                            </DropdownButton>)
                                                            : ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1 container-check-fields">
                                                <div className="select-all-title">
                                                    <h6>
                                                        Select all
                                                    </h6>
                                                    <div className="row row-check">
                                                        <div className="col-md-6">
                                                            <div className="check">
                                                                <input
                                                                    type="radio" name="check_all"
                                                                    onChange={(e) => checkAll(e.target.value)}
                                                                    value="original"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="check">
                                                                <input
                                                                    type="radio" name="check_all"
                                                                    onChange={(e) => checkAll(e.target.value)}
                                                                    value="matching" defaultChecked/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                          <div className="col-md-5">
                                              <div className="d-flex mb-4 justify-content-between">
                                                  <div><strong>Matches found</strong>
                                                  {showRefreshMatching ?
                                                    <ArrowRepeat
                                                      onClick={reloadMatchingData}
                                                      className="reload-matching-arrow"
                                                    /> : ""}
                                                  </div>
                                                  <div>
                                                      <strong>Matching score: {selectedScore}</strong>
                                                  </div>
                                              </div>
                                              <ul className="matches-pagination">
                                                  {matchingIds ?
                                                    matchingIds.map((item, i) => {
                                                        return (
                                                          <Fragment key={"matching-id-" + i}>
                                                              <ReactTooltip
                                                                id={"matching-page-" + i}
                                                                className="adp-tooltip--wine"
                                                              >
                                                                  {item ? item.description : ""}
                                                              </ReactTooltip>
                                                              <li
                                                                className={renderMatchingPaginationClasses(item)}
                                                                key={i}
                                                                data-tip=""
                                                                data-for={"matching-page-" + i}
                                                              >
                                                                  <a
                                                                    href="/#"
                                                                    onClick={(e) => loadMatchingWine(e, item ? item.wine_id : 0, item ? item.vintage_id : 0)}
                                                                  >{++i}</a>
                                                              </li>
                                                          </Fragment>);
                                                    })
                                                    : ""}
                                              </ul>
                                              {wine[EWineProperties.status].value === EWineStatuses.rejected ?
                                                <Button
                                                  variant="custom" className="mb-3 mt-3 inherit float-end"
                                                  onClick={restoreWine}
                                                >
                                                    Restore wine
                                                </Button> : ""
                                              }
                                              {matchingWine
                                                ? (<>
                                                    <Button
                                                      variant="custom"
                                                      className={`mb-3 mt-3 inherit mr-4 ${possibleSelectedWineAction === EPossibleActions.MERGE_SELECT ? "possible_match" : ""}`}
                                                      onClick={() => {
                                                          wine.YEAR.value !== matchingWine.YEAR.value ? setOpenModalConfirmMerge(true) : mergeSelected();
                                                      }}
                                                    >
                                                        Merge with selected
                                                    </Button>
                                                    <button
                                                      className={`mb-3 mt-3 inherit custom-default float-end ${possibleSelectedWineAction === EPossibleActions.NEW_VINTAGE ? "possible_match" : ""}`}
                                                      onClick={() => {
                                                          if (selectedVineyardWithoutRegion()) {
                                                              setNoRegionAction(ENoRegionActions.NEW_VINTAGE);
                                                              setOpenModalConfirmNoRegion(true);
                                                          } else {
                                                              createNewVintage();
                                                          }
                                                      }}
                                                    >
                                                        Create new vintage
                                                    </button>
                                                </>)
                                                : ""}
                                          </div>
                                          {Object.entries(EFields).map(([, data]) => {
                                                return (
                                                    <Fragment key={"matching-" + data}>
                                                        <div className="col-md-1">
                                                            <div
                                                                className={"column-name text-end d-flex justify-content-end align-items-top text-nowrap property-" + data}>
                                                                {t(data)}
                                                                <span className="synonym" title={synonyms && synonyms.hasOwnProperty(data) ? synonyms[data] : ''}>
                                                                  {synonyms && synonyms.hasOwnProperty(data) ?
                                                                    <FileEarmarkRuledFill onClick={() => {
                                                                        copyToClipboard(synonyms[data]).then(r => r);
                                                                        toast.success('Copied to clipboard!');
                                                                    }}/>
                                                                    : <FileEarmarkRuled/>
                                                                  }
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <div
                                                                className={"cell position-relative justify-content-between " + "cell-property-" + data.toLowerCase() + " " + matchingIndicatorCss(wine.hasOwnProperty(data) ? wine[data] : '', matchingWine && matchingWine[data] ? matchingWine[data] : '', 'original') + (allow_edit_text.indexOf(data) !== -1 ? ' with-action ' : '') + (editedFields.indexOf(data) !== -1 ? ' edited' : '') + (showEditInput.indexOf(data) !== -1 ? ` editing-cell` : "")}>
                                                                <div className="content d-flex w-100">
                                                                    {wine && wine[data] && data === EWineProperties.vineyard && verifiedWineries.findIndex(item => item === wine[data].value) !== -1 &&
                                                                      <div title="TAKE CARE! THE WINERY MUST BE CLEAN AND NICE" className="d-flex align-items-center">
                                                                          <ShieldFillCheck className="green_indicator"/> &nbsp;
                                                                      </div>
                                                                    }
                                                                    <span
                                                                        className={(showEditInput.indexOf(data) !== -1 ? ' d-none' : 'word-break-word')}>
                                                                        {renderWineValue(data)}
                                                                    </span>
                                                                </div>
                                                                <div className={`row-actions`}>
                                                                {allow_edit_text.indexOf(data) !== -1
                                                                    ? <EditMatchingField
                                                                        wine={wine.hasOwnProperty(data) ? wine[data] : ''}
                                                                        item={data} showEditInput={showEditInput}
                                                                        taxonomy_properties={taxonomyProperties}
                                                                        setShowEditInput={setShowEditInput}
                                                                        onChange={changeEditRow}
                                                                        onCancel={cancelEditRow}
                                                                        wineData={wine}
                                                                        edit_history={getEditRowHistory(data)}
                                                                        allow_edit_text={allow_edit_text}/>
                                                                    : ''
                                                                }
                                                                {rowMenuItems && rowMenuItems.hasOwnProperty(data) && wine[data] && wine[data].label && rowMenuItems[data]!.map((item, index) => {
                                                                    return (
                                                                      <a
                                                                        href={"#"}
                                                                        title={item}
                                                                        key={index} onClick={(e) => {
                                                                            e.preventDefault();
                                                                            RowMenuItemsActions[item](data);
                                                                        }}>
                                                                          {RowMenuItemsIcons.hasOwnProperty(item) && (
                                                                            RowMenuItemsIcons[item]
                                                                          )}
                                                                      </a>
                                                                    );
                                                                })}
                                                                {wine && wine[data] && data === EWineProperties.vineyard && wine[data].value && (
                                                                  <a href={"#"} title={"Winery comments"} className="winery-comments" onClick={(e) => {
                                                                      e.preventDefault();
                                                                      openWineryChatModal({id: parseInt(wine[data].value.toString()), name: wine[data].label});
                                                                  }}>
                                                                      <ChatLeftText />
                                                                      {wineriesWithComments && wineriesWithComments[wine[data].value as number] && (
                                                                        <span className="winery-has-comment" />
                                                                      )}
                                                                  </a>
                                                                )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1 container-check-fields">
                                                            <div
                                                                className={"row row-check property-" + data.toLowerCase()}>
                                                                <div className="col-md-6">
                                                                    <div className="check">
                                                                        <input
                                                                            type="radio" {...{'name': 'field[' + data + ']'}}
                                                                            value="1"
                                                                            disabled={disabledFields.indexOf(data) !== -1}
                                                                            onChange={(e) => changeCheckField(data, parseInt(e.target.value))}
                                                                            checked={!!(checkedFields && checkedFields.original && checkedFields.original.indexOf(data) !== -1)}/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="check">
                                                                        <input
                                                                            type="radio" {...{'name': 'field[' + data + ']'}}
                                                                            value="0"
                                                                            disabled={disabledFields.indexOf(data) !== -1}
                                                                            onChange={(e) => changeCheckField(data, parseInt(e.target.value))}
                                                                            checked={!!(checkedFields && checkedFields.matching && checkedFields.matching.indexOf(data) !== -1)}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <div
                                                                className={"cell " + "cell-property-" + data.toLowerCase() + " " + matchingIndicatorCss(wine.hasOwnProperty(data) ? wine[data] : '', matchingWine && matchingWine[data] ? matchingWine[data] : '', 'matching')}>
                                                                <div>
                                                                    {matchingWine && matchingWine[data] && data === EWineProperties.vineyard && verifiedWineries.findIndex(item => item === matchingWine[data].value) !== -1 &&
                                                                      <div title="TAKE CARE! THE WINERY MUST BE CLEAN AND NICE" className="d-flex align-items-center">
                                                                        <ShieldFillCheck className="green_indicator"/> &nbsp;
                                                                      </div>
                                                                    }
                                                                </div>
                                                                {data === EWineProperties.image && matchingWine && matchingWine[data] && matchingWine[data].label
                                                                    ? renderImageUrl(matchingWine[data].label, 'internal')
                                                                    : (matchingWine && matchingWine[data] && matchingWine[data].label ? (
                                                                        data === EWineProperties.vineyard ? <a href={`/wines-merge/list?winery=${matchingWine[data].value}`} target={"_blank"}>{matchingWine[data].label}</a> : matchingWine[data].label
                                                                      ) : '')
                                                                }
                                                                {matchingWine && matchingWine[data] && data === EWineProperties.vineyard && matchingWine[data].value && (
                                                                  <a href={"#"} title={"Winery comments"} className="winery-comments" onClick={(e) => {
                                                                      e.preventDefault();
                                                                      openWineryChatModal({id: parseInt(matchingWine[data].value.toString()), name: matchingWine[data].label});
                                                                  }}>
                                                                      <ChatLeftText />
                                                                      {wineriesWithComments && wineriesWithComments[matchingWine[data].value as number] && (
                                                                        <span className="winery-has-comment" />
                                                                      )}
                                                                  </a>
                                                                )}
                                                                {matchingWine && matchingWine[data] && data === EWineProperties.award && Array.isArray(matchingWine[data])
                                                                    ?
                                                                    (<ul className="mb-0">
                                                                        {Object.entries(matchingWine[data]).map(([index, value]) => {
                                                                            let item: any = value;
                                                                            return (<li key={item.value}>
                                                                                    {item.label}
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>)
                                                                    : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            })}

                                            <div className="col-md-5 offset-md-1 mt-3">
                                                <p><small><span className="green_indicator">Green text</span> -
                                                    Identical
                                                    values</small></p>
                                                <p><small><span className="yellow_indicator">Blue text</span> -
                                                    Default
                                                    presets values</small></p>
                                            </div>
                                            <div className="col-md-5 offset-md-1 mt-3">
                                                {selectedMatchingWine ?
                                                    <>
                                                        <p><small>Wine ID: {selectedMatchingWine.wine_id}</small></p>
                                                        <p><small>Vintage ID: {selectedMatchingWine.vintage_id}</small></p>
                                                    </>
                                                    : ''
                                                }
                                            </div>
                                            <div className="col-md-11 offset-md-1 mt-3">
                                                {wineAddInfo
                                                    ? (
                                                        <Alert variant="info">
                                                            <Alert.Heading>Additional info</Alert.Heading>
                                                            {Object.entries(wineAddInfo).map(([key, items]) => {
                                                                return (<p key={key}>
                                                                    <small><strong>{key}</strong>: {items}</small>
                                                                </p>)
                                                            })}
                                                        </Alert>
                                                    )
                                                    : ''}
                                            </div>
                                        </>)
                                        : <Preloader show={!wine}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <WineryCommentModal winery={openCommentsForWinery} modalParams={ModalComments} onUpdateMessages={onUpdateMessages}/>
                    <BsModal {...ModalConfirmMergeProps}>
                        <p style={{"color": "red"}}>ВЫ УВЕРЕНЫ ЧТО ХОТИТЕ ИЗМЕНИТЬ ГОД ЭТОГО ВИНТАЖА???? ТОЧНО_ТОЧНО? А ЕЩЕ РАЗ ПОДУМАЙТЕ! А ТЕПЕРЬ?</p>
                    </BsModal>
                    <BsModal {...ModalConfirmNoRegionProps}>
                        <p style={{"color": "red"}}>No region in a clean and nice winery. Are you sure?</p>
                    </BsModal>
                </Container>
            </div>
        </div>
    )
}

export default NameMatching;
