import {statuses} from "./PreviewMultipleImages";
import {CheckCircleFill, ClockFill, XCircleFill} from "react-bootstrap-icons";

const ImageGallery = (props: { images: {status: statuses, url: string}[] }) => {
  const StatusIcon = (props: {status: statuses}) => {
    switch (props.status) {
      case 'new': return <ClockFill />
      case 'failed': return <XCircleFill />
      case 'ok': return <CheckCircleFill />
      default: return <></>;
    }
  }
  return (
    <ul className="image-gallery mx-2">
      {
        props.images.map((image) => {
          return (
            <li key={image.url}>
              <span className={`status ` + image.status} title={image.status}>
                <StatusIcon status={image.status} />
              </span>
              <img src={image.url} alt=""/>
            </li>
          )
        })
      }
    </ul>
  )
}

export default ImageGallery;