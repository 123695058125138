import {Card} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {AuthUserContext} from "../context/AuthUser";

type TCounters = {
    total_unprocessed: number,
    total_processed: number,
    processed_yesterday: number,
}

const Counters = () => {

    const authUser = useContext(AuthUserContext);
    const [counters, setCounters] = useState<TCounters>();

    const axiosConfig = {
        headers: {
            Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
        }
    };

    useEffect(() => {
        axios.get('/api/counters', {
            ...axiosConfig
        }).then(r => {
            let result = r.data

            if (result.hasOwnProperty('counters')) {
                setCounters(result.counters);
            }
        });
    }, []);

    return (
        <div className="text-center mt-4">
            <Card style={{width: '15rem', display: 'inline-block', marginRight: '1rem'}}>
                <Card.Body>
                    <Card.Title>{counters && counters.total_unprocessed ? counters.total_unprocessed : 0}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Total unprocessed rows</Card.Subtitle>
                </Card.Body>
            </Card>
            <Card style={{width: '15rem', display: 'inline-block', marginRight: '1rem'}}>
                <Card.Body>
                    <Card.Title>{counters && counters.total_processed ? counters.total_processed : 0}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Total processed rows</Card.Subtitle>
                </Card.Body>
            </Card>
            <Card style={{width: '15rem', display: 'inline-block'}}>
                <Card.Body>
                    <Card.Title>{counters && counters.processed_yesterday ? counters.processed_yesterday : 0}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Processed Yesterday</Card.Subtitle>
                </Card.Body>
            </Card>
        </div>
    );
}

export default Counters;