import {EWineProperties, TTaxonomy} from "../properties";
import React from "react";
import Select from "react-select";
import {customStyles} from "../helpers";

type TTaxonomySettings = {
    form_input?: string,
    taxonomy_vocabulary?: string,
    option_values: {} | null
}
type TPresets = {
    [key: string]: string
}

type TProps = {
    property: EWineProperties,
    taxonomy: TTaxonomySettings,
    taxonomy_properties: TTaxonomy,
    presets: TPresets
    onChange: (property: EWineProperties, value: string) => void,
}

type OptionType = {
    value: string;
    label: string;
}

const PresetTaxonomyInput = (props: TProps) => {

    let disabled = false,
        defaultValue = '';

    if (props.presets.hasOwnProperty(props.property)) {
        defaultValue = props.presets[props.property];
    }

    if (props.taxonomy) {
        switch (props.taxonomy.form_input) {
            case 'dropdown':
            case 'boolean':
                let optionValues: OptionType[] = [];
                optionValues.push({value: '0', label: '- Select value -'});
                if (props.taxonomy.option_values) {
                    Object.entries(props.taxonomy.option_values).map(([key, value]) => {
                        optionValues.push({value: key, label: value} as OptionType);
                        return true;
                    })
                }
                return (
                  <div className={"w-100"}>
                      <Select
                        value={defaultValue ? optionValues.find(option => option.value === defaultValue) : null}
                        options={optionValues}
                        classNamePrefix="react-select" styles={customStyles}
                        placeholder={"- Select value -"}
                        isDisabled={disabled}
                        onChange={(item) => props.onChange(props.property, item ? item.value : '')}
                      />
                  </div>
                );
            case 'number':
            case 'float':
                return (
                  <div className={"w-100"}>
                      <input type="number" className="w-200p"
                             value={defaultValue}
                             onWheel={event => event.currentTarget.blur()}
                             onChange={(item) => props.onChange(props.property, item.target.value)}/>
                      {props.property === EWineProperties.alcohol ? '%' : ''}
                      {props.property === EWineProperties.sugar ? 'g/L' : ''}
                  </div>
                )
            case 'text':
                return (
                  <div className={"w-100"}>
                      <input type="text" className="w-200p"
                             value={defaultValue}
                             onChange={(item) => props.onChange(props.property, item.target.value)}/>
                  </div>
                )
            case 'checkbox':
                return (
                  <div className={"w-100"}>
                      <input type="checkbox"/>
                  </div>
                )
            default:
                return (
                  <>
                  </>
                );
        }
    } else {
        return (
          <>
          </>
        );
    }
}

export default PresetTaxonomyInput;