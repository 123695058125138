import {CUSTOM_INPUTS, EWineProperties, initialWineProperty, TRule} from "../properties";
import Async from "react-select/async";
import {capitalize, customStyles} from "../helpers";
import React, {useContext, useState} from "react";
import axios from "axios";
import Select, {SingleValue} from "react-select";
import {AuthUserContext} from "../context/AuthUser";

type TProps = {
    rule: TRule,
    selected: OptionType | null,
    onChange: (property: EWineProperties, option: SingleValue<OptionType> | null) => void,
    taxonomy: {
        [key: string]: object
    } | undefined
}

type OptionType = {
    value: string;
    label: string;
    original_name?: string
};

type TWinery = {
    id: string,
    name: string,
    country?: string
};

const RuleEditInput = (props: TProps) => {
    const wineProperty = initialWineProperty.find(item => item.key === props.rule.property);
    const authUser = useContext(AuthUserContext);
    const [vineyardValue, setVineyardValue] = useState<SingleValue<OptionType | null>>(null);

    const loadOptions = (
        inputText: string,
        callback: (options: OptionType[]) => void
    ): void => {
        if (!inputText) {
            setVineyardValue(null);
            return callback([]);
        }
        if (inputText.length < 2) {
            setVineyardValue(null);
            return callback([]);
        }

        let url = '/api/search-winery';
        if (props.rule.property === EWineProperties.region) {
            url = '/api/search-region';
        }

        axios.post(url, {q: inputText}, {
            headers: {
                Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
            }
        }).then((response) => {
            let responseData = [];
            if (props.rule.property === EWineProperties.vineyard) {
                responseData = response.data.wineries;
            }
            if (props.rule.property === EWineProperties.region) {
                responseData = response.data.regions;
            }

            let data: TWinery[] = responseData;

            if (data.length) {
                return callback(data.map((result) => {
                    return labelFormatter(result);
                }))
            } else {
                setVineyardValue(null);
                return callback([]);
            }
        });
    };

    const labelFormatter = (i: TWinery): OptionType => {
        return {
            label: i.name + (i.country ? ' / ' + i.country : ''),
            original_name: i.name,
            value: i.id,
        }
    }

    if (wineProperty) {
        switch (wineProperty.custom_input) {
            case CUSTOM_INPUTS.Dropdown:
                let optionValues: OptionType[] = [];

                if (props.taxonomy && props.taxonomy.hasOwnProperty(props.rule.property)) {
                    let taxonomy = props.taxonomy[props.rule.property];
                    if (taxonomy) {
                        optionValues.push({value: 'EMPTY', label: 'EMPTY VALUE'});

                        Object.entries(taxonomy).forEach(([key, value]) => {
                            optionValues.push({value: key, label: value} as OptionType);
                        })
                    }
                }
                return (<>
                    {props.rule.property === EWineProperties.vineyard || props.rule.property === EWineProperties.region
                        ?
                        <Async
                            loadOptions={loadOptions}
                            className="custom-select w-100"
                            classNamePrefix="react-select"
                            styles={customStyles}
                            value={props.selected && EWineProperties.vineyard ? props.selected : vineyardValue}
                            placeholder={"Insert some text..."}
                            onChange={(item) => {
                                setVineyardValue(item);
                                props.onChange(props.rule.property, item);
                            }}
                        />
                        : <Select
                            className="w-100"
                            classNamePrefix="react-select"
                            styles={customStyles}
                            options={optionValues}
                            onChange={(item) => {
                                props.onChange(props.rule.property, {
                                    label: item ? item.label : '',
                                    value: item ? item.value : ''
                                });
                            }}
                            placeholder={"- Select " + capitalize(props.rule.property.replace('_', ' ')) + " -"}/>
                    }
                </>);
            case CUSTOM_INPUTS.Number:
            case CUSTOM_INPUTS.Float:
                return (
                    <div>
                        <input type="number" className="w-100 custom-input-text"
                               onWheel={event => event.currentTarget.blur()}
                               onChange={(e) => props.onChange(props.rule.property, {
                                   label: e.target.value,
                                   value: e.target.value
                               })}/>
                    </div>
                )
            default:
                return (
                    <></>
                );
        }
    }
    return (
        <></>
    );

}

export default RuleEditInput;