import React from "react";
import { FileEarmarkRuled, FileEarmarkRuledFill } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { copyToClipboard } from "../helpers";
import { EWineProperties } from "../properties";

type TProps = {
  synonyms?: {
    [k in EWineProperties]: string
  }

  property: EWineProperties
}
const SynonymIcon = (props: TProps) => {

  return (
    <span className="d-flex" {...(props.synonyms && props.synonyms[props.property] ? { title: props.synonyms[props.property] } : "")}>
        {props.synonyms && props.synonyms[props.property] ? <FileEarmarkRuledFill onClick={() => {
          copyToClipboard(props.synonyms ? props.synonyms[props.property] : "").then(r => r);
          toast.success("Copied to clipboard!");
        }}/> : <FileEarmarkRuled/>}
    </span>
  );
};

export default SynonymIcon;