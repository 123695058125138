const Footer = () => {

  {/*>*/}
  if (process.env.REACT_APP_ENVIRONMENT === 'stage') {
    return (
      <div className="footer">
        <div className="test-server-line">
            <span>Warning: Test server!</span>
        </div>
      </div>
    )
  }

  return null;
}

export default Footer;