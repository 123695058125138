import React, {Fragment, useContext, useEffect, useState} from "react";
import {AuthUserContext} from "../context/AuthUser";
import {EWineProperties, TErrorObject} from "../properties";
import {AlertError, renderImageUrl} from "../helpers";
import {Button, Container} from "react-bootstrap";
import {useHistory, useParams} from "react-router";
import axios from "axios";
import {TWineObject} from "./NameMatching";
import {useTranslation} from "react-i18next";

type TParams = {
    vintage_id: string
}

type TWine = {
    [key in EWineProperties]: TWineObject | Array<TWineObject>
};

type TWineProp = {
    [key: number]: EWineProperties
}

type TSelectedWine = {
    wine_id: number,
    vintage_id: number
}
const CompareVersions = () => {
    const authUser = useContext(AuthUserContext);
    const [error, setError] = useState<string | TErrorObject>('');
    const [originalWine, setOriginalWine] = useState<TWine>();
    const [historyWine, setHistoryWine] = useState<TWine>();
    const [historyId, setHistoryId] = useState(0);
    const [selectedMatchingWine, setSelectedMatchingWine] = useState<TSelectedWine | null>(null);
    const [competition, setCompetition] = useState<string>('');
    const [event, setEvent] = useState<number>(0);

    const axiosConfig = {
        headers: {
            Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
        }
    };

    const EFields: TWineProp = [
        EWineProperties.name, EWineProperties.vineyard, EWineProperties.year, EWineProperties.country,
        EWineProperties.region, EWineProperties.beverage_type, EWineProperties.color, EWineProperties.co2, EWineProperties.extra_sweetness,
        EWineProperties.under_film_of_yeast, EWineProperties.grape_category, EWineProperties.stage_of_production, EWineProperties.alcohol, EWineProperties.sugar,
        EWineProperties.production_volume, EWineProperties.production_units, EWineProperties.method, EWineProperties.grape_type, EWineProperties.award, EWineProperties.image
    ]

    const {vintage_id} = useParams<TParams>();
    const {t} = useTranslation();
    const history = useHistory();

    const cellWithDiff = (data: EWineProperties) => {
        if (originalWine !== undefined && historyWine !== undefined) {

            if (!Array.isArray(originalWine[data]) && (originalWine[data] as TWineObject).value !== (historyWine[data] as TWineObject).value) {
                return ' diff-values';
            }

            if (data === EWineProperties.award) {
                let originalAwardsValues: number[] = [],
                    historyAwardsValues: number[] = [];
                const b = originalWine[data] as TWineObject[];
                const h = historyWine[data] as TWineObject[];
                if (b.length) {
                    b.forEach((item) => {
                        originalAwardsValues.push(item.value as number);
                    });
                }
                if (h.length) {
                    h.forEach((item) => {
                        historyAwardsValues.push(item.value as number);
                    });
                }

                const same = originalAwardsValues.sort().join(',') === historyAwardsValues.sort().join(',');
                if (!same) {
                    return ' diff-values';
                }
            }

            if (data === EWineProperties.grape_type && (originalWine[data] as TWineObject).value) {
                const originalWineObject = (originalWine[data] as TWineObject);
                const historyWineObject = (historyWine[data] as TWineObject);

                // * extract %
                let originalGrapes = originalWineObject.label.split(','),
                    historyGrapes = historyWineObject.label.split(','),
                    originalValue: { id: number, percent: number }[] = [],
                    historyValue: { id: number, percent: number }[] = [];

                if (originalGrapes.length) {
                    originalGrapes.forEach((item, key) => {
                        let percent = item.split(':')[1].replace('%', '').trim(),
                            id = originalWineObject.value.toString().split(',')[key];
                        originalValue.push({id: Number(id), percent: Number(percent)});
                    });

                }

                if (historyGrapes.length) {
                    historyGrapes.forEach((item, key) => {
                        let percent = item.split(':')[1].replace('%', '').trim(),
                            id = historyWineObject.value.toString().split(',')[key];
                        historyValue.push({id: Number(id), percent: Number(percent)});
                    })
                }

                originalValue = originalValue.length ? originalValue.sort((a, b) => (a.id > b.id ? 1 : -1)) : [];
                historyValue = historyValue.length ? historyValue.sort((a, b) => (a.id > b.id ? 1 : -1)) : [];

                let originalCount = originalValue.length,
                    historyCount = historyValue.length;
                if (originalCount !== historyCount) {
                    return ' diff-values';
                }

                let res = false;
                originalValue.forEach((item, key) => {
                    if (historyValue[key].id !== item.id || historyValue[key].percent !== item.percent) {
                        res = true;
                    }
                });
                if (res) {
                    return ' diff-values';
                }
            }
        }

        return '';
    }

    const rollbackHistory = () => {
        setError('');

        if (!window.confirm('Are you sure want to rollback wine and vintage?')) {
            return false;
        }

        axios.post('/api/rollback-wine-vintage', {
            history_id: historyId
        }, {
            ...axiosConfig
        }).then((response) => {
            let result = response.data;
            if (result.hasOwnProperty('error')) {
                setError(result.error);
                return false;
            }
            if (result.hasOwnProperty('success')) {
                history.replace('/wines');
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                authUser.setAuthUser(null);
                localStorage.removeItem('auth_user');
            }
            setError(error.toString());
        });
    }

    useEffect(() => {
        axios.get('/api/compare-versions/' + vintage_id, {
            ...axiosConfig
        }).then((response) => {
            let result = response.data;
            if (result.hasOwnProperty('error')) {
                setError(result.error);
                return false;
            }
            if (result.hasOwnProperty('original_wine')) {
                setOriginalWine(result.original_wine);
            }
            if (result.hasOwnProperty('history_wine')) {
                setHistoryWine(result.history_wine);
            }
            if (result.hasOwnProperty('selected_wine')) {
                setSelectedMatchingWine(result.selected_wine);
            }
            if (result.hasOwnProperty('history')) {
                setHistoryId(result.history);
            }
            if (result.hasOwnProperty('event')) {
                setEvent(result.event);
            }
            if (result.hasOwnProperty('competition')) {
                setCompetition(result.competition);
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                authUser.setAuthUser(null);
                localStorage.removeItem('auth_user');
            }
            setError(error.toString());
        });
    }, [vintage_id]);

    return (
        <div className="App">
            <div className="data-matching-page w-100">
                <Container fluid>
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="mb-3 text-start">Compare versions</h4>
                            <hr/>
                            <div className="row">
                                <div className="col-md-8">
                                    <h4>Line 4 - matching</h4>
                                </div>
                            </div>
                            <hr/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <AlertError error={error} callback={() => setError('')}/>
                            <div className="contest mb-5">
                                <div className="row">
                                    <div className="col-md-5 offset-md-2">
                                        <div className="mb-3">
                                            <p><strong>Gustos wine</strong></p>
                                            <p className="mt-4"><strong>{competition}</strong></p>
                                            <p><strong>{event}</strong></p>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <p><strong>Last modified version</strong></p>
                                        {historyWine && historyWine.hasOwnProperty(EWineProperties.name) && (historyWine[EWineProperties.name] as TWineObject).label !== "" &&
                                          <Button variant="custom" className="mt-4 inherit mr-4"
                                                  onClick={rollbackHistory}>
                                              Rollback and Restore
                                          </Button>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    {Object.entries(EFields).map(([, data]) => {
                                        return (
                                            <Fragment key={"compere-version-" + data}>
                                                <div className="col-md-2">
                                                    <div
                                                        className={"column-name text-end d-flex justify-content-end align-items-top text-nowrap property-" + data}>
                                                        {t(data)}
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div
                                                        className={"cell position-relative cell-property-" + data.toLowerCase() + cellWithDiff(data)}>
                                                        {originalWine && originalWine.hasOwnProperty(data) && !Array.isArray(originalWine[data]) && (originalWine[data] as TWineObject).value
                                                            ? (
                                                                data === EWineProperties.image
                                                                    ? renderImageUrl((originalWine[data] as TWineObject).label, 'internal')
                                                                    : (originalWine[data] as TWineObject).label
                                                            )
                                                            : ''
                                                        }
                                                        {originalWine && originalWine[data] && data === EWineProperties.award && Array.isArray(originalWine[data])
                                                            ?
                                                            (<ul className="mb-0">
                                                                {Object.entries(originalWine[data]).map(([index, value]) => {
                                                                    let item: TWineObject = value as TWineObject;
                                                                    return (<li key={index}>
                                                                            {item.label}
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>)
                                                            : ''
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div
                                                        className={"cell position-relative cell-property-" + data.toLowerCase() + cellWithDiff(data)}>
                                                        {historyWine && historyWine.hasOwnProperty(data) && !Array.isArray(historyWine[data]) && (historyWine[data] as TWineObject).value
                                                            ? (
                                                                data === EWineProperties.image
                                                                    ? renderImageUrl((historyWine[data] as TWineObject).label, 'internal')
                                                                    : (historyWine[data] as TWineObject).label
                                                            )
                                                            : ''
                                                        }

                                                        {historyWine && historyWine[data] && data === EWineProperties.award && Array.isArray(historyWine[data])
                                                            ?
                                                            (<ul className="mb-0">
                                                                {Object.entries(historyWine[data]).map(([index, value]) => {
                                                                    let item: TWineObject = value as TWineObject;
                                                                    return (<li key={index}>
                                                                            {item.label}
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>)
                                                            : ''
                                                        }
                                                    </div>
                                                </div>
                                            </Fragment>
                                        );
                                    })}
                                    <div className="col-md-5 offset-md-2 mt-3">
                                        {selectedMatchingWine ?
                                            <>
                                                <p><small>Wine ID: {selectedMatchingWine.wine_id}</small></p>
                                                <p><small> Vintage ID: {selectedMatchingWine.vintage_id}</small>
                                                </p>
                                            </>
                                            : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default CompareVersions;
