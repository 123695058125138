import React from "react";
import {Button, InputGroup} from "react-bootstrap";
import {
    TCompetitions,
    TEvents,
    TAdditionalRow,
    TTaxonomy,
    TFieldsContentTypes,
    EWineProperties,
    TFields
} from "../properties";
import {useLocation} from "react-router";

type TProps = {
    rowsContent?: TFieldsContentTypes[],
    fields?: TFields[],
    additionalFields?: TAdditionalRow[],
    taxonomy?: TTaxonomy | undefined,
    selectedCompetition?: number,
    selectedEvent?: number,
    competitions?: TCompetitions[],
    events?: TEvents[],
    competitionName?: string,
    eventName?: string,
    wineName?: string | null
    vineyardName?: string | null
    vintage?: string | null
}

const SearchGoogle = ({
                          additionalFields,
                          fields,
                          rowsContent,
                          taxonomy,
                          selectedCompetition,
                          selectedEvent,
                          competitions,
                          events,
                          competitionName, eventName, wineName, vineyardName, vintage
                      }: TProps) => {

    const location = useLocation();

    const getBasePath = () => {
        return location.pathname.slice(0, location.pathname.indexOf('/', 1))
    }

    const doGoogleSearch = () => {
        let params = [],
            url = 'https://www.google.com/search?q=',
            query = '',
            basePath = getBasePath();

        if (competitionName) {
            params.push(competitionName);
        }
        if (eventName) {
            params.push(eventName);
        }

        if (basePath === '/data-matching') {
            if (fields) {
                let property_name = fields.find(item => item.property === EWineProperties.name),
                    property_vineyard = fields.find(item => item.property === EWineProperties.vineyard)

                if (property_name) {
                    params.push(property_name.value);
                }

                if (property_vineyard) {
                    params.push(property_vineyard.value);
                }
            }
        } else if (basePath === '/data-import') {
            if (rowsContent) {
                let property_name = rowsContent.filter(item => {
                        return item.db_columns.find(column => column === EWineProperties.name)
                    }),
                    property_vineyard = rowsContent.filter(item => {
                        return item.db_columns.find(column => column === EWineProperties.vineyard)
                    });

                if (property_name.length) {
                    params.push(property_name[0].row_value);
                }

                if (property_vineyard.length) {
                    params.push(property_vineyard[0].row_value);
                }
            }

            // * search in custom fields
            if (additionalFields) {
                let field_name = additionalFields.find(item => item.key === EWineProperties.name),
                    field_vineyard = additionalFields.find(item => item.key === EWineProperties.vineyard)

                if (field_name) {
                    params.push(field_name.value);
                }

                if (field_vineyard) {
                    let vineyard_value = field_vineyard.value || 0
                    if (taxonomy !== undefined
                        && taxonomy.hasOwnProperty(EWineProperties.vineyard)) {
                        if (taxonomy[EWineProperties.vineyard].option_values) {

                            let taxonomy_vineyard = taxonomy[EWineProperties.vineyard];

                            if (taxonomy_vineyard.option_values) {
                                vineyard_value = taxonomy_vineyard.option_values[vineyard_value as number];
                            }
                        }
                    }
                    if (vineyard_value) {
                        params.push(vineyard_value);
                    }
                }
            }

            // * search competition/event
            if (selectedCompetition && competitions && competitions.length) {
                let competition = competitions.find(item => item.id === selectedCompetition)

                if (competition) {
                    params.push(competition.name)
                }
            }

            if (selectedEvent && events && events.length) {
                let event = events.find(item => item.id === selectedEvent)

                if (event) {
                    params.push(event.year)
                }
            }
        } else if (basePath === '/name-matching') {
            if (vineyardName) {
                params.push(encodeURIComponent(vineyardName));
            }
            if (wineName) {
                params.push(encodeURIComponent(wineName));
            }
            if (vintage) {
                params.push(vintage);
            }
        }

        query = params.filter(f => f).join(' ');
        if (query) {
            window.open(url + query, "_blank")
        }
    }
    return (
        <>
            <InputGroup className="search-google justify-content-end">
                <Button variant="custom" onClick={doGoogleSearch}>
                    Find in
                    <img src={`${process.env.PUBLIC_URL}/images/google_logo.svg`} alt="Google logo"
                         className="google-logo"/>
                </Button>
            </InputGroup>
        </>
    )
}

export default SearchGoogle;