import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { XCircleFill } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { AuthUserContext } from "../context/AuthUser";
import { TWinery } from "../pages/WineriesReview";
import { BsModalSize } from "../properties";
import BsModal from "./BsModal";

type TChatData = {
  winery: number,
  messages: {
    id: number,
    user: string,
    created: string,
    message: string,
    is_current: boolean,
  }[]
}

type Props = {
  modalParams: any;
  winery?: {id: number, name: string }
  onUpdateMessages?: (winery_id: number, count_messages: number) => void
}

const WineryCommentModal = (props: Props) => {
  const authUser = useContext(AuthUserContext);
  const chatMessagesRef = useRef<null | HTMLDivElement>(null);

  const [chatData, setChatData] = useState<TChatData>();
  const [modalChatTitle, setModalChatTitle] = useState("Winery Comments");
  const [chatMessage, setChatMessage] = useState('');
  const [canSubmitChatMessage, setCanSubmitChatMessage] = useState(false);

  const axiosConfig = {
    headers: {
      Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : "",
    },
  };

  const loadComments = () => {
    if (props.winery) {
      axios.get("/api/wineries-review/chat-messages/" + props.winery.id, {
        ...axiosConfig,
      }).then(r => {
        let result = r.data;
        if (result.hasOwnProperty("messages") && props.winery) {
          setModalChatTitle(props.winery.name + " Comments" + "(" + result.messages.length + ")");
          setChatData({ winery: props.winery.id, messages: result.messages });

          let elem = document.getElementById("chat-listing-messages");
          if (elem) {
            elem.scrollTop = elem.scrollHeight;
          }
        }
      }).catch(r => {
        if (r.response && r.response.status === 401) {
          toast.error("You are not authorized");
        }
      });
    }
  };

  const submitChatMessage = () => {
    if (chatMessage.length && chatData) {
      setCanSubmitChatMessage(false);
      axios.post('/api/wineries-review/send-chat-message', {
        message: chatMessage,
        winery: chatData.winery
      }, {
        ...axiosConfig
      }).then(r => {
        let result = r.data;

        if (result.hasOwnProperty('error')) {
          toast.error(result.error);
          return false;
        }

        if (result.hasOwnProperty('message')) {
          let chat = Object.assign({}, chatData);

          chat.messages.push({
            id: Number(result.message.id),
            user: result.message.user as string,
            created: result.message.created as string,
            message: result.message.message as string,
            is_current: true
          });

          setChatData(chat);
          setChatMessage('');
          scrollChatToBottom();

          // Set the number of comments for the winery.
          if (typeof props.onUpdateMessages === 'function' && props.winery) {
            props.onUpdateMessages(props.winery.id, chat.messages.length);
          }
        }
      }).finally(() => {
        setCanSubmitChatMessage(true);
      }).catch(r => {
        if (r.response && r.response.status === 401) {
          toast.error('You are not authorized');
        }
      });
    }
  }

  const removeChatMessage = (message_id: number) => {
    if (!window.confirm('Are you sure want to delete message?')) {
      return false;
    }

    if (chatData) {
      const message = chatData.messages.find(item => {
        return item.id === message_id;
      });

      if (!message) {
        toast.error('Message not found!');
        return false;
      }

      axios.post('/api/wineries-review/remove-chat-message', {
        message_id: message_id
      }, {
        ...axiosConfig
      }).then(r => {
        let result = r.data;
        if (result.hasOwnProperty('error')) {
          toast.error(result.error);
          return false;
        }

        if (result.hasOwnProperty('success')) {
          let chat = Object.assign({}, chatData);
          chat.messages = chat.messages.filter(item => {
            return item.id != message_id;
          });

          setChatData(chat);
          scrollChatToBottom();

          // Set the number of comments for the winery.
          if (typeof props.onUpdateMessages === 'function' && props.winery) {
            props.onUpdateMessages(props.winery.id, chat.messages.length);
          }
        }
      }).catch(r => {
        if (r.response && r.response.status === 401) {
          toast.error('You are not authorized');
        }
      });
    }
  }

  const scrollChatToBottom = () => {
    if (chatMessagesRef.current) {
      chatMessagesRef.current.scrollIntoView({behavior: "smooth"})
    }
  }

  useEffect(() => {
    if (props.winery) {
      loadComments();
    }
  }, [props.winery]);

  return (
    <BsModal {...props.modalParams} title={modalChatTitle} size={'lg'} primaryButton={{ onClick: submitChatMessage, disabled: !canSubmitChatMessage, title: "Send" }}>
      <div className="form-group mb-2">
        <ul className="chat-list" id="chat-listing-messages">
          {chatData ?
            chatData.messages.length ?
              chatData.messages.map(item => {
                return (
                  <li key={item.id} {...(item.is_current ? { className: "personal" } : "")}>
                    <div className="author">
                      {item.is_current ?
                        <>
                          <span>{item.created}</span>
                          {item.user}
                        </>
                        :
                        <>
                          {item.user}
                          <span>{item.created}</span>
                        </>
                      }
                    </div>
                    <div className="message">
                      {item.message}
                    </div>
                    {authUser.authUser?.is_admin || item.is_current ?
                      <button
                        className="btn-remove-message"
                        onClick={() => removeChatMessage(item.id)}
                      >
                        <XCircleFill/>
                      </button>
                      : ""
                    }
                  </li>
                );
              })
              : <li className="empty-list">No comments.</li>
            : ""
          }
          <div ref={chatMessagesRef}/>
        </ul>
      </div>
      <div className="form-group chat-send-area">
        <textarea
          className="chat-message" placeholder="Type your comment here..."
          value={chatMessage}
          onChange={(e) => {
            setChatMessage(e.target.value);
            setCanSubmitChatMessage(e.target.value !== '');
          }}
        />
      </div>
    </BsModal>
  );
};

export default WineryCommentModal;
