import {EWineProperties, TFields} from "../properties";
import {copyToClipboard, isValidHttpUrl} from "../helpers";
import SearchGoogleMini from "./SearchGoogleMini";
import {Clipboard} from "react-bootstrap-icons";
import React from "react";
import {useTranslation} from "react-i18next";

type TProps = {
    item: TFields,
    copy_clipboard_fields: EWineProperties[]
}
const DataMatchingItemValue = (props: TProps) => {

    const {t} = useTranslation();

    const renderValue = () => {
        if (props.item.value) {
            if (isValidHttpUrl(props.item.value)) {
                return (<>
                    <a href={props.item.value}
                       target="_blank">{props.item.value}</a>
                </>);
            } else {
                if (props.item.custom_field) {
                    return (
                        <><em>Custom field</em></>
                    );
                }
                return (
                    <>{props.item.value}</>
                );
            }
        } else if (props.item.parent_value) {
            return (
                <><em>{t('parent_value_' + props.item.parent_value.toLowerCase())}</em></>
            );
        }
        return (
            <></>
        );
    }

    const renderSearchGoogle = () => {
        if (props.item.value && [EWineProperties.vineyard, EWineProperties.name].indexOf(props.item.property) !== -1) {
            return <SearchGoogleMini content={props.item.value}/>;
        }
        return (
            <></>
        );
    }

    const renderClipboard = () => {
        if (props.item.value && props.copy_clipboard_fields.indexOf(props.item.property) !== -1) {
            return (<>
                <a href="/#" title="Copy to clipboard"
                   className="copy-clipboard" onClick={(e) => {
                    e.preventDefault();
                    copyToClipboard(props.item.value).then(r => r);
                }}><Clipboard/></a>
            </>);
        }
        return (
            <></>
        );
    }

    return (<>
            {renderValue()}
            {renderSearchGoogle()}
            {renderClipboard()}
        </>
    );
}

export default DataMatchingItemValue;