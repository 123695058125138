import {AlertError} from "../helpers";
import React, {Fragment, useContext, useState} from "react";
import {TErrorObject, TPagination} from "../properties";
import {Container, Table} from "react-bootstrap";
import {AuthUserContext} from "../context/AuthUser";
import axios from "axios";
import * as qs from "query-string";
import WineHistoryFilter from "../components/WineHistoryFilter";
import Moment from "moment";
import {Link} from "react-router-dom";
import Pagination from "react-js-pagination";
import Preloader from "../components/Preloader";

type TWines = {
    id: number,
    name: string,
    country_gustos: {
        name?: string
    },
    vineyard_gustos: {
        name?: string
    },
    created_at: string,
    vintage: {
        year: number,
        id: number
    },
    wine_action: {
        action: string,
        created_at: string,
        gustos_wine_entity: {
            vintage: number,
            gustos_wine: {
                name: string,
                vineyard_gustos: {
                    name: string
                }
            }
        }
    }
}

type TOptionType = {
    value: number | string,
    label: string
}

type TFilter = {
    competition?: TOptionType,
    event?: TOptionType,
    winery?: TOptionType,
    country?: TOptionType,
    user_wine_action?: TOptionType,
    action_date_from?: Date,
    action_date_to?: Date
}

const WineHistory = () => {
    const authUser = useContext(AuthUserContext);

    const parsed = qs.parse(window.location.search);
    const pageRangeDisplayed = 8;

    const [error, setError] = useState<string | TErrorObject>('');
    const [wines, setWines] = useState<TWines[]>([]);
    const [filter, setFilter] = useState<TFilter>({});
    const [showPreloader, setShowPreloader] = useState(false);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState<TPagination>(null);

    const axiosConfig = {
        headers: {
            Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
        }
    };

    const getWinesData = (to_page?: number) => {
        let currentPage = to_page ? to_page : (parsed.page ? parseInt(parsed.page as string) : 1),
            queryFilter = '';

        setPage(currentPage)
        setShowPreloader(true);

        if (Object.keys(filter).length) {
            Object.entries(filter).forEach(([key, item]) => {
                if (item) {
                    queryFilter += '&' + key + '=' + (item instanceof Date ? item.toLocaleDateString() : item.value);
                }
            });
        }
        axios.get('/api/wines-history?page=' + currentPage + queryFilter, {
            ...axiosConfig
        }).then(r => {
            let result = r.data;
            setError('');
            setShowPreloader(false);

            if (result.hasOwnProperty('wines')) {
                if (result.wines.hasOwnProperty('data')) {
                    if (result.wines.data) {
                        let wines = result.wines;
                        setWines(wines.data);

                        let paginationData = {
                            'current_page': wines.current_page,
                            'from': wines.from,
                            'last_page': wines.last_page,
                            'first_page_url': wines.first_page_url,
                            'last_page_url': wines.last_page_url,
                            'next_page_url': wines.next_page_url,
                            'path': wines.path,
                            'per_page': wines.per_page,
                            'prev_page_url': wines.prev_page_url,
                            'to': wines.to,
                            'total': wines.total
                        };

                        setPagination(paginationData);
                    }
                }
            }

        }).catch(r => {
            if (r.hasOwnProperty('response') &&  r.response.status === 401) {
                authUser.setAuthUser(null);
                localStorage.removeItem('auth_user');
            }
            setError('Failed on fetch wines data');
        });
    }

    const updateFilter = (data: { [key: string]: TOptionType | Date | null }) => {
        setFilter({...filter, ...data});
    }

    const submitFilter = () => {
        getWinesData(1);
    }

    const getWinesByPage = (pageNumber: number) => {
        if (page === pageNumber) {
            return false;
        }

        setPage(pageNumber);

        // * get  wines
        getWinesData(pageNumber);
    }

    return (
        <div className="App">
            <div className="w-100">
                <Container fluid>
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="mb-2 text-start">Wines history</h4>
                            <hr/>
                        </div>
                        <div className="col-md-12">
                            <AlertError error={error} callback={() => setError('')}/>
                            {authUser.authUser !== null
                                ? <WineHistoryFilter setFilter={updateFilter} submitFilter={submitFilter}/>
                                : ''
                            }
                            {wines.length
                                ? (<>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Vintage</th>
                                            <th>Winery</th>
                                            <th>Gustos wine name</th>
                                            <th>Gustos wine vintage</th>
                                            <th>Gustos wine winery</th>
                                            <th>Processed</th>
                                            <th>Action</th>
                                            <th/>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {wines.map(item => {
                                            return (
                                                <Fragment key={item.id}>
                                                    <tr>
                                                        <td>{item.id}</td>

                                                        <td>{item.name}</td>

                                                        <td>{item.vintage.year}</td>

                                                        <td>{item.vineyard_gustos ? item.vineyard_gustos.name : '-'}</td>

                                                        <td>{item.wine_action && item.wine_action.gustos_wine_entity && item.wine_action.gustos_wine_entity.gustos_wine ? item.wine_action.gustos_wine_entity.gustos_wine.name : ""}</td>

                                                        <td>{item.wine_action && item.wine_action.gustos_wine_entity? item.wine_action.gustos_wine_entity.vintage : ""}</td>

                                                        <td>{item.wine_action && item.wine_action.gustos_wine_entity && item.wine_action.gustos_wine_entity.gustos_wine ? item.wine_action.gustos_wine_entity.gustos_wine.vineyard_gustos.name : ""}</td>

                                                        <td>{item.wine_action ? Moment(item.wine_action.created_at).fromNow() : ""}</td>

                                                        <td>{item.wine_action ? item.wine_action.action : ""}</td>

                                                        <td>
                                                            <div className="d-flex">
                                                                {item.wine_action && item.wine_action.action === 'MERGE_SELECTED'
                                                                    ? <Link to={"/compare-versions/" + item.vintage.id}
                                                                            className="btn btn-custom inherit">Compare</Link>
                                                                    : ''
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            );
                                        })}
                                        </tbody>
                                    </Table>
                                    {pagination !== null ?
                                        <Pagination activePage={pagination.current_page}
                                                    itemsCountPerPage={pagination.per_page}
                                                    onChange={(pageNumber) => {
                                                        getWinesByPage(pageNumber)
                                                    }}
                                                    totalItemsCount={pagination.total}
                                                    pageRangeDisplayed={pageRangeDisplayed}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    firstPageText="First Page"
                                                    lastPageText="Last Page"
                                                    innerClass="pagination mt-3"
                                        />
                                        : ''
                                    }
                                </>)
                                : (!showPreloader
                                        ? 'No data.'
                                        : <Preloader hide={!showPreloader}/>
                                )
                            }
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default WineHistory;