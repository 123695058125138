import GoogleLogo from "../assets/images/Google_G_Logo.svg";

type TProps = {
    content: string
}
const SearchGoogleMini = (props: TProps) => {

    const search = (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        let url = 'https://www.google.com/search?q=';

        if (props.content === '') {
            return false;
        }

        window.open(url + props.content, "_blank")
    }

    return (
        <div className="search-google-mini">
            <a href="/#" title="Search in Google" onClick={search}>
               <img src={GoogleLogo} alt="Search in google"/>
            </a>
        </div>
    );
}

export default SearchGoogleMini;