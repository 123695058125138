import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import "../App.css";
import {AlertError, customStyles} from "../helpers";
import CustomInput from "../components/CustomInput";
import UploadedFilesList from "../components/UploadedFilesList";
import Preloader from "../components/Preloader";
import SearchGoogle from "../components/SearchGoogle";
import {
    EWineProperties,
    initialWineProperty,
    TAdditionalRow,
    TCompetitions,
    TErrorObject,
    TEvents,
    TFieldsContentTypes,
    TParsedFile,
    TTaxonomy,
    TWineProperties
} from "../properties";
import {useHistory, useLocation, useParams} from "react-router";
import Select, {SingleValue} from "react-select";
import {useTranslation} from "react-i18next";
import {AuthUserContext} from "../context/AuthUser";
import Counters from "../components/Counters";

type TParams = {
    file_hash: string
}

export default function Upload() {
    const defaultDbFieldKey = EWineProperties.skip;
    const skipCustomDbFields: string[] = [EWineProperties.save, EWineProperties.image, EWineProperties.save_trophy, EWineProperties.save_grape, EWineProperties.url_image, EWineProperties.award_points];
    const skipDbFields: string[] = [EWineProperties.grape_type];
    const wineProperty: TWineProperties[] = initialWineProperty;
    const history = useHistory();
    const location = useLocation();
    const {file_hash} = useParams<TParams>();
    const {t} = useTranslation();

    const [fileSelected, setFileSelected] = useState('');
    const [selectedDbField, setSelectedDbField] = useState<string[]>([]);
    const [rowsContent, setRowsContent] = useState<TFieldsContentTypes[]>([]);
    const [customDbFields, setCustomDbFields] = useState<TAdditionalRow[]>([]);
    const [competitions, setCompetitions] = useState<TCompetitions[]>([]);
    const [events, setEvents] = useState<TEvents[]>([]);
    const [error, setError] = useState<string | TErrorObject>('');
    const [selectedCompetition, setSelectedCompetition] = useState<number>(0);
    const [selectedEvent, setSelectedEvent] = useState<number>(0);
    const [showUploadList, setShowUploadList] = useState<boolean | null>(null);
    const [showCounters, setShowCounters] = useState<boolean | null>(null);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [startImportBtn, setStartImportBtn] = useState(false);
    const [taxonomyProperties, setTaxonomyProperties] = useState<TTaxonomy>();
    const [file, setFile] = useState<TParsedFile>(null);
    const authUser = useContext(AuthUserContext);

    const axiosConfig = {
        headers: {
            Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
        }
    };

    const onFileChange = (event: any) => {
        setFileSelected('');

        let file = event.target.files[0];

        if (typeof file === 'undefined') {
            return;
        }
        setFileSelected(file);
        setStartImportBtn(true);
    }

    const submitFile = () => {
        const formData = new FormData();
        formData.append("file", fileSelected);

        setUploadInProgress(true)
        setStartImportBtn(false)

        axios
            .post("/api/upload-file",
                formData, {
                    ...axiosConfig
                })
            .then((response) => {
                const data = response.data;

                setUploadInProgress(false)
                setStartImportBtn(true)

                if (data.hasOwnProperty('error')) {
                    setError(data.error);
                    return false;
                }

                if (data.hasOwnProperty('hash')) {
                    if (data.hasOwnProperty('file_exists') && data.file_exists) {
                        history.push('/data-matching/' + data.hash)
                    } else {
                        history.push('/data-import/' + data.hash);
                    }
                }
            })
            .catch((error) => {
                setError(error.toString());
                setUploadInProgress(false)
                setStartImportBtn(false)
            });
    }

    const changeEvent = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedEvent(event.target.value === "" ? 0 : parseInt(event.target.value))

        if (event.target.value !== "") {
            getAwardsByEvent(parseInt(event.target.value));
        } else {
            // * reset award options
            resetPropertyAwards();
        }
    }

    const resetPropertyAwards = () => {
        let properties = Object.assign({}, taxonomyProperties);
        if (properties && properties.hasOwnProperty(EWineProperties.award) && properties[EWineProperties.award].option_values) {
            properties[EWineProperties.award].option_values = null;
            setTaxonomyProperties(properties);

            // * remove award from selected
            if (customDbFields.length) {
                let fieldAward = customDbFields.findIndex(item => item.key === EWineProperties.award);
                if (fieldAward !== -1) {
                    customDbFields[fieldAward].key = defaultDbFieldKey;
                    customDbFields[fieldAward].value = '';
                    setCustomDbFields(customDbFields.map(f => f));
                }
            }

            removeSelectedDbFieldByValue(EWineProperties.award);
        }
        return true;
    }

    const getParsedFileRandomRow = (file_hash: string) => {

        setRowsContent([]);
        setCompetitions([]);
        setTaxonomyProperties({});

        axios.get('/api/matching-random-row/' + file_hash, {
            ...axiosConfig
        })
            .then((response) => {
                const result = response.data;

                if (result.hasOwnProperty('fields')) {

                    let data: TFieldsContentTypes[] = [];
                    result.fields.map((element: TFieldsContentTypes) => {
                        element.db_columns = [''];
                        data.push(element);
                        return true;
                    });

                    setRowsContent(data);
                }

                if (result.hasOwnProperty('competitions')) {
                    setCompetitions(result.competitions);
                }

                if (result.hasOwnProperty('taxonomy_properties')) {
                    let properties = Object.assign({}, result.taxonomy_properties)
                    setTaxonomyProperties(properties);
                }

                if (result.hasOwnProperty('file')) {
                    let file = result.file;
                    const data = {
                        file_name: file.file_name as string,
                        hash: file.hash as string,
                        status: file.status as string
                    };
                    setFile(data);
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    authUser.setAuthUser(null);
                    localStorage.removeItem('auth_user');
                }
                setError(error.toString());
            });

    }

    const getEventByCompetition = (item: SingleValue<TCompetitions>) => {
        if (item) {
            let competitionId = item.id;
            setEvents([]);
            setSelectedEvent(0);
            setSelectedCompetition(competitionId);

            resetPropertyAwards();

            axios.get('/api/competition-events/' + competitionId, {
                ...axiosConfig
            })
                .then((response) => {
                    let result = response.data;
                    if (result.hasOwnProperty('error')) {
                        setError(result.error);
                    }
                    if (result.hasOwnProperty('events')) {
                        setEvents(result.events);
                    }
                })
        }
    }

    const getAwardsByEvent = (event_id: number) => {
        axios.get('/api/event-awards/' + event_id, {
            ...axiosConfig
        })
            .then(r => {
                let result = r.data;
                if (result.hasOwnProperty('awards') && result.awards) {
                    let properties = Object.assign({}, taxonomyProperties);
                    properties[EWineProperties.award].option_values = result.awards;
                    setTaxonomyProperties(properties);
                }
            })
            .catch(r => {
                setError(r.toString());
            });
    }

    const addInputDbField = (fieldIndex: number) => {
        rowsContent[fieldIndex].db_columns.push(defaultDbFieldKey)
        setRowsContent(rowsContent.map(f => f));
    }

    const updateInputDbField = (e: React.ChangeEvent<HTMLSelectElement>, currentDbFieldSelectIndex?: number, rowIndex?: number) => {
        let selectedFields = selectedDbField,
            value = e.target.value;

        if (typeof rowIndex === "number" && typeof currentDbFieldSelectIndex === "number") {
            let previousValue = rowsContent[rowIndex].db_columns[currentDbFieldSelectIndex];

            if (previousValue !== '') {
                selectedFields = selectedDbField.filter((element, index) => element !== previousValue);
            }

            rowsContent[rowIndex].db_columns[currentDbFieldSelectIndex] = value;
        }

        let property: TWineProperties[] = wineProperty.filter(item => item.key === value)
        // * update selected fields
        if (property.length && !property[0].multiple_select) {
            if (value !== '') {
                selectedFields.push(value);
            }
        }
        setSelectedDbField(selectedFields);

        // * update when input key updated
        setRowsContent(rowsContent.map(f => f));
    }

    const deleteInputDbField = (currentDbFieldSelectIndex: number, rowIndex: number) => {
        let db_columns = rowsContent[rowIndex].db_columns,
            value = db_columns[currentDbFieldSelectIndex],
            selectedFields = selectedDbField,
            selectedFieldsPosition = selectedFields.indexOf(value);

        db_columns = db_columns.filter((ar) => {
            return ar !== value;
        })

        if (selectedFieldsPosition !== -1) {
            selectedFields = selectedDbField.filter((element, index) => element !== value);
            setSelectedDbField(selectedFields);
        }

        rowsContent[rowIndex].db_columns = db_columns;
        setRowsContent(rowsContent.map(f => f));
    }

    const updateCustomInputDbField = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        let value = event.target.value,
            selectedFields = selectedDbField,
            previousValue = customDbFields[index].key;

        //* update custom db fields
        customDbFields[index].key = value;
        setCustomDbFields(customDbFields.map(f => f));

        // * update selected values
        let property: TWineProperties[] = wineProperty.filter(item => item.key === value)
        if (property.length && !property[0].multiple_select) {
            selectedFields.push(value);
        }
        selectedFields = selectedFields.filter(item => item !== previousValue);
        setSelectedDbField(selectedFields);
    }

    const removeCustomDbField = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
        event.preventDefault();

        let value = customDbFields[index].key;

        delete customDbFields[index];
        setCustomDbFields(customDbFields.map(f => f));

        removeSelectedDbFieldByValue(value);
    }

    const removeSelectedDbFieldByValue = (value: string) => {
        let selectedFields = selectedDbField,
            selectedFieldsPosition = selectedFields.indexOf(value);
        if (selectedFieldsPosition !== -1) {
            selectedFields = selectedDbField.filter((element, index) => element !== value);
            setSelectedDbField(selectedFields);
        }
    }

    const addCustomInputDbField = () => {
        setCustomDbFields(customDbFields.concat({
            key: defaultDbFieldKey,
            value: ''
        }));
    }

    const submitOrganizedFields = () => {
        let params = {
                competition: selectedCompetition,
                event: selectedEvent,
                existing_fields: rowsContent,
                custom_fields: customDbFields,
                file_hash: file_hash
            },
            uncompleted = [],
            error_text = '';

        uncompleted = rowsContent.filter(item => {
            return !item.db_columns.length || (item.db_columns.length && item.db_columns[0] === '');
        })

        if (!selectedCompetition) {
            setError('Not selected Competition');
            return false;
        }

        if (!selectedEvent) {
            setError('Not selected Event');
            return false;
        }

        if (uncompleted.length) {
            error_text = t('errors.unselected_field') + '"' + uncompleted[0].file_column + '"';

            setError(error_text);
            return false;
        }

        axios.post('/api/save-organized-fields', params, {
            ...axiosConfig
        })
            .then((response) => {
                let result = response.data;
                if (result.hasOwnProperty('error')) {
                    setError(result.error);
                    return false;
                }

                if (result.hasOwnProperty('msg')) {
                    history.push("/data-matching/" + file_hash);
                }
            })
            .catch((error) => {
                setError(error.toString());
            });
    }

    const customFieldsRules = (key: string): boolean => {
        if (key === EWineProperties.award) {
            if (taxonomyProperties
                && taxonomyProperties.hasOwnProperty(EWineProperties.award)
                && !taxonomyProperties[EWineProperties.award].option_values) {
                return false;
            }
        }

        return true;
    }

    useEffect(() => {
        if (authUser.authUser !== null && authUser.authUser.is_admin) {
            setShowCounters(true);
        }

        setShowUploadList(true);

        setError('');
        setSelectedDbField([]);
        setCustomDbFields([]);

        if (file_hash) {
            setShowUploadList(false)
            setShowCounters(false)
            getParsedFileRandomRow(file_hash);
        }

    }, [location]);

    /**
     * Return
     */
    return (
        <div className="App">
            {
                location.pathname === '/'
                    ? <div className="upload-page w-100">
                        <Container fluid>
                            <div className="row">
                                <div className="col-md-12">
                                    <Form>
                                        <Form.Group>
                                            <h4 className="mb-2 text-start">Import data</h4>
                                            <hr/>
                                            <Button type="button" variant="custom" disabled={!startImportBtn}
                                                    onClick={submitFile}>
                                                Start import
                                                <img src={`${process.env.PUBLIC_URL}/images/1488_1.gif`} alt="Loading"
                                                     className={"position-absolute " + (uploadInProgress ? "" : "d-none")}/>
                                            </Button>
                                            <input type="file" onChange={onFileChange} className="pb-3 pt-2"
                                                   accept=".xls,.xlsx" id="input-upload"/>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div className="col-md-12">
                                    <AlertError error={error} callback={() => setError('')}/>
                                    {showCounters
                                        ? <Counters/>
                                        : ''
                                    }
                                    {showUploadList
                                        ? <UploadedFilesList/>
                                        : ''
                                    }
                                </div>
                            </div>
                        </Container>
                    </div>
                    : ''
            }

            {file !== null && file_hash
                ? <div className="matching-page w-100">
                    <Container fluid>
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="mb-3 text-start">Import data</h4>
                                <hr/>
                                <div className="row">
                                    <div className="col-md-9">
                                        <Button variant="custom"
                                                onClick={() => history.push("/")}>
                                            Change file
                                        </Button>
                                        <span className="selected-file">
                                        <strong>File:</strong> {file.file_name}
                                    </span>
                                    </div>
                                    <div className="col-md-3">
                                        <SearchGoogle rowsContent={rowsContent} additionalFields={customDbFields}
                                                      taxonomy={taxonomyProperties}
                                                      selectedCompetition={selectedCompetition}
                                                      selectedEvent={selectedEvent} competitions={competitions}
                                                      events={events}/>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="contest">
                                    <div className="row mb-3 mt-4">
                                        <div className="col-md-2 text-end">
                                            <strong>Contest name</strong>
                                        </div>
                                        <div className="col-md-7">
                                            <Select options={competitions}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id.toString()}
                                                    className="w-100 mr-3"
                                                    classNamePrefix="react-select" styles={customStyles}
                                                    onChange={item => getEventByCompetition(item)}
                                                    placeholder="- select context -"/>
                                        </div>
                                        <div className="col-md-3">
                                            <button className="btn btn-custom"
                                                    onClick={submitOrganizedFields}>Start import
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 text-end">
                                            <strong>Contest year</strong>
                                        </div>
                                        <div className="col-md-7">
                                            <Form.Select className="w-25" disabled={!events.length}
                                                         onChange={(e) => changeEvent(e)}>
                                                <option value="">- select year-</option>
                                                {events ?
                                                    events.map(item => {
                                                        return (
                                                            <option value={item.id} key={item.id}>{item.year}</option>
                                                        );
                                                    })
                                                    : ''
                                                }
                                            </Form.Select>
                                        </div>
                                    </div>
                                </div>
                                <div className="contest-separator"/>
                                <AlertError error={error} callback={() => setError('')}/>
                                <Preloader hide={!!rowsContent.length}/>
                                <div className="contest">
                                    {rowsContent.length
                                        ?
                                        rowsContent.map((content: TFieldsContentTypes, index: number) => {
                                            return (
                                                <div className="row" key={index}>
                                                    <div
                                                        className="col-md-2 column-name text-end d-flex justify-content-end align-items-top">
                                                        {content.file_column}
                                                    </div>
                                                    <div className="col-md-7">
                                                        <div className="cell">
                                                            {content.row_value}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="h-100">
                                                            {content.db_columns.map((select, currentDbFieldSelectIndex) => {
                                                                return <div className="db-fields"
                                                                            key={currentDbFieldSelectIndex}>
                                                                    <Form.Select className="w-75"
                                                                                 value={select}
                                                                                 onChange={(e) => updateInputDbField(e, currentDbFieldSelectIndex, index)}>
                                                                        <option value="">- select property -</option>
                                                                        {Object.keys(wineProperty).map((element, index) => {
                                                                            if (skipDbFields.indexOf(wineProperty[index].key) !== -1) {
                                                                                return false;
                                                                            }
                                                                            if (selectedDbField.indexOf(wineProperty[index].key) !== -1
                                                                                && select !== wineProperty[index].key) {
                                                                                return false;
                                                                            }
                                                                            return <option
                                                                                value={wineProperty[index].key}
                                                                                key={wineProperty[index].key}>
                                                                                {wineProperty[index].key}
                                                                            </option>
                                                                        })}
                                                                    </Form.Select>
                                                                    {currentDbFieldSelectIndex === 0 ?
                                                                        <button className="add-input-fields"
                                                                                onClick={(e) => addInputDbField(index)}>+</button> : ''}
                                                                    {currentDbFieldSelectIndex > 0 ? <button
                                                                        className="remove-input-fields"
                                                                        onClick={(e) => deleteInputDbField(currentDbFieldSelectIndex, index)}>x</button> : ''}
                                                                </div>;
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : ''
                                    }
                                </div>
                                <div className="contest-separator"/>
                                <div className="contest">
                                    {customDbFields.map((field, index) => {
                                        return (
                                            <div className="row" key={index}>
                                                <div className="col-md-2">
                                                    <Form.Select className="w-100 mb-2" value={field.key}
                                                                 onChange={(e) => updateCustomInputDbField(e, index)}>
                                                        {Object.keys(wineProperty).map((element, i) => {
                                                            if (selectedDbField.indexOf(wineProperty[i].key) !== -1
                                                                && wineProperty[i].key !== field.key) {
                                                                return false;
                                                            }
                                                            if (!customFieldsRules(wineProperty[i].key)) {
                                                                return false;
                                                            }
                                                            if (skipCustomDbFields.indexOf(wineProperty[i].key) !== -1) {
                                                                return false;
                                                            }

                                                            return <option value={wineProperty[i].key}
                                                                           key={wineProperty[i].key}>
                                                                {wineProperty[i].key}
                                                            </option>
                                                        })}
                                                    </Form.Select>
                                                </div>
                                                <div className="col-md-7">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <CustomInput
                                                                item={initialWineProperty.find(item => item.key === field.key)}
                                                                taxonomy={taxonomyProperties}
                                                                onChange={(e) => {
                                                                    field.value = e.target.value
                                                                    setCustomDbFields(customDbFields.map(f => f))
                                                                }}
                                                            />

                                                        </div>
                                                        <div className="col-md-6 text-end">
                                                            <a href="/#"
                                                               onClick={(e) => removeCustomDbField(e, index)}><img
                                                                src={`${process.env.PUBLIC_URL}/images/u791_b.png`}
                                                                alt="Remove field"/></a>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className="col-md-3">

                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                    {rowsContent.length
                                        ?
                                        <div className="row">
                                            <div className="col-md-2">
                                            </div>
                                            <div className="col-md-7">
                                                <button type="button" className="btn btn-custom mt-3 mb-3"
                                                        onClick={addCustomInputDbField}>+ add field
                                                </button>
                                            </div>
                                        </div>
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
                : ''
            }
        </div>
    );
}