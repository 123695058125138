import i18n from "i18next";
import {initReactI18next} from "react-i18next";
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "Welcome to React": "Welcome to React and react-i18next",
            "SKIP": '- не учитывать -',
            "SAVE": 'Сохранить',
            "NAME": 'Название',
            "AWARD": 'Медаль',
            "REGION": 'Регион',
            "COUNTRY": 'Страна',
            "YEAR": 'Год',
            "COLOR": 'Цвет',
            "VINEYARD": 'Производитель',
            "WINERY": 'Производитель',
            "CO2": 'Co2',
            "ALCOHOL": 'Алкоголь',
            "SUGAR": 'Сахар',
            "GRAPE_CATEGORY": 'Сорт винограда',
            "UNDER_FILM_OF_YEAST": 'Дрожевая пленка',
            "EXTRA_SWEETNESS": 'EXTRA_SWEETNESS',
            "METHOD": 'Метод',
            "GRAPE": "Виноград",
            "ISUNDERFILMOFYEAR": "Дрожевая пленка",
            "SERIES": "Серия",
            "SAVE_TROPHY": "Трофей",
            "SAVE_GRAPE": "Виноград",
            "BEVERAGE_TYPE": "Тип напитка",
            "PRODUCTION_VOLUME": "Об. партии",
            "PRODUCTION_UNITS": "Ед. изм. партии",
            "STAGE_OF_PRODUCTION": "Этап производства",
            "GRAPE_TYPE": "Состав винограда",
            "parent_value_region": "Зависимое значение для поле Регион",
            "VINTAGE_ID": "Vintage ID",
            "WINE_ID": "Wine ID",
            "URL_IMAGE": "Ссылка изображения",
            "IMAGE": "Бутылка",
            "AWARD_POINTS": "Очки",
            "errors":
                {
                    "uncompleted_field": "You have uncompleted field ",
                    "unselected_field": "You have unselected field "
                }
        }
    },
    fr: {
        translation: {
            "Welcome to React": "Bienvenue à React et react-i18next"
        }
    }
};
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });