import React, { useEffect, useState } from "react";
import Select from "react-select";
import { OptionType, TAutomatchUserFilter } from "../properties";
import AutomatchConfirmButton from "./AutomatchConfirmButton";

type Props = {
  onSubmitFilter: () => void,
  maxBatch: number,
  filterData: (data: TAutomatchUserFilter) => void,
  onConfirmAutomatch: () => void,
  winesExist: boolean,
  filter: TAutomatchUserFilter,
}
const AutomatchFilter = (props: Props) => {

  const [isFilterButtonDisabled, setIsFilterButtonDisabled] = useState<boolean>(true);

  const wineryStatusesList: OptionType[] = [
    {label: 'Checked', value: '1'},
    {label: 'Unchecked', value: '0'}
  ];

  useEffect(() => {
    if (props.filter && Object.keys(props.filter).length > 0) {
      setIsFilterButtonDisabled(false);
    }
  }, [props.filter]);

  return (
    <div>
      <h5>Automatch</h5>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group mb-3">
            <label>Batch</label>
            <input
              type="number" min={1} max={props.maxBatch} className="form-control"
              value={props.filter && props.filter.batch ? props.filter.batch : ''}
              placeholder={`Number of wines. Max ${props.maxBatch} records.`} onChange={(e) => {
              const value = e.target.value;
              props.filterData({ ...props.filter, batch: value ? parseInt(value) : undefined });
            }}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group mb-3">
            <label>Match above</label>
            <input
              type="number" min={1} className="form-control" placeholder="Score above" onChange={(e) => {
              const value = e.target.value;
              props.filterData({ ...props.filter, match_above: value ? parseInt(value) : undefined });
            }}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group mb-3">
            <label>Match below</label>
            <input
              type="number" min={1} className="form-control" placeholder="Score below" onChange={(e) => {
              const value = e.target.value;
              props.filterData({ ...props.filter, match_below: value ? parseInt(value) : undefined });
            }}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Problematic wineries</label>
            <Select
              options={wineryStatusesList}
              isClearable={true}
              onChange={(item) => {
                props.filterData({ ...props.filter, problematic: item ? Number(item.value) : undefined });
              }}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Valid wineries</label>
            <Select
              options={wineryStatusesList}
              isClearable={true}
              onChange={(item) => {
                props.filterData({ ...props.filter, valid: item ? Number(item.value) : undefined });
              }}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Merged wineries</label>
            <Select
              options={wineryStatusesList}
              isClearable={true}
              onChange={(item) => {
                props.filterData({ ...props.filter, merged: item ? Number(item.value) : undefined });
              }}
            />
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Finished wineries</label>
            <Select
              options={wineryStatusesList}
              isClearable={true}
              onChange={(item) => {
                props.filterData({ ...props.filter, finished: item ? Number(item.value) : undefined });
              }}
            />
          </div>
        </div>
        <div className="col-md-12 mt-3">
          <div className="form-group mb-3">
            <label className="mt-2">
              <input
                type="checkbox" onChange={(e) => {
                props.filterData({ ...props.filter, match_selected: e.target.checked });
              }}
              /> Match selected
            </label>
            <label className="mt-2 ml-4">
              <input
                type="checkbox" disabled onChange={(e) => {
                props.filterData({ ...props.filter, new_vintage: e.target.checked });
              }}
              /> New vintage
            </label>
            <label className="mt-2 ml-4">
              <input
                type="checkbox" disabled onChange={(e) => {
                props.filterData({ ...props.filter, new_wine: e.target.checked });
              }}
              /> New wine
            </label>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group mb-3">
            <label>&nbsp;</label><br/>
            <div className="d-flex flex-row justify-content-between align-items-center flex-grow-1 ">
              <button
                disabled={isFilterButtonDisabled} className="btn btn-custom" onClick={() => {
                props.onSubmitFilter();
              }}
              >Automatch
              </button>
              <AutomatchConfirmButton winesExist={props.winesExist} onConfirmAutomatch={props.onConfirmAutomatch}/>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default AutomatchFilter;
