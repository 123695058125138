import React, {useContext, useEffect, useRef, useState} from "react";
import {Container, Table} from "react-bootstrap";
import Select from "react-select";
import DragDropFile from "../components/DragDropFile";
import PreviewMultipleImages from "../components/PreviewMultipleImages";
import WineryCommentModal from "../components/WineryCommentModal";
import {BsModalSize, EWineryStatuses, EWineStatuses, OptionType, TPagination} from "../properties";
import axios from "axios";
import {AuthUserContext} from "../context/AuthUser";
import Pagination from "react-js-pagination";
import {useHistory, useLocation} from "react-router";
import * as qs from "query-string";
import {CardImage, ChatLeftText, ExclamationTriangleFill, Pencil, XCircleFill} from "react-bootstrap-icons";
import ReactTooltip from "react-tooltip";
import {toast} from "react-toastify";
import WineryReviewStatusInput from "../components/WineryReviewStatusInput";
import BsModal from "../components/BsModal";
import { customStyles, isValidHttpUrl, hasPermissionTo, capitalize, downloadFile } from "../helpers";
import SearchGoogleMini from "../components/SearchGoogleMini";
import ImageViewer from 'react-simple-image-viewer';
import GotoPagination from "../components/GotoPagination";

type TWinesCounts = {
  N: number,
  D: number,
  E: number,
  S: number,
  R: number
}
export type TWinery = {
  id: number,
  name: string,
  country: string,
  country_id: number,
  wines_counts: TWinesCounts,
  website: string,
  review_statuses: {
    [k: string]: {
      user: string,
      created: string,
    }
  },
  row_color: string,
  row_status: string,
  count_comments: number,
  logo: {
    fid: number,
    status: string,
    url: string
  } | null
}

type TChatData = {
  winery: number,
  messages: {
    id: number,
    user: string,
    created: string,
    message: string,
    is_current: boolean,
  }[]
}

type TMergeFields = {
  name: {
    label: string,
    value: number
  }[],
  country: {
    label: string,
    value: string | number
  }[],
  region: {
    label: string,
    value: string
  }[],
  phone: {
    label: string,
    value: string | number
  }[],
  description: {
    label: string,
    value: string
  }[],
  logo: {
    label: string,
    value: string | number
  }[],
  website: {
    label: string,
    value: string
  }[],
  slug: {
    label: string,
    value: string | number
  }[],
}

const WineriesReview = () => {

  const wineryStatusesList: OptionType[] = [
    {label: 'Checked', value: '1'},
    {label: 'Unchecked', value: '0'}
  ];

  const [wineStatuses, setWineStatuses] = useState<OptionType[]>([]);
  const [wineryStatuses, setWineryStatuses] = useState<{ [k: string]: number }>({});
  const [countries, setCountries] = useState<OptionType[]>([]);
  const [filter, setFilter] = useState<{ [k: string]: string | number }>({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [wineries, setWineries] = useState<TWinery[]>();
  const [pagination, setPagination] = useState<TPagination>(null);
  const [page, setPage] = useState(1);
  const [updateStatusDisabled, setUpdateStatusDisabled] = useState(false);
  const [openModalComments, setOpenModalComments] = useState(false);

  const [openModalEditField, setOpenModalEditField] = useState(false);
  const [editField, setEditField] = useState<{ id: number, key: string, value: string }>();
  const [loadingEditField, setLoadingEditField] = useState(false);

  const [mergeSelected, setMergeSelected] = useState<number[]>([]);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [mergeIn, setMergeIn] = useState(0);
  const [mergeWineriesModalConfirmBtn, setMergeWineriesModalConfirmBtn] = useState(false);
  const [mergeWineriesFieldsDisabled, setMergeWineriesFieldsDisabled] = useState(true);
  const [mergeFields, setMergeFields] = useState<TMergeFields>();
  const [selectedMergeFields, setSelectedMergeFields] = useState<{ [key: string]: string | number }>();
  const [previewImage, setPreviewImage] = useState<string[]>([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [openOnGwmrOptions, setOpenOnGwmrOptions] = useState<OptionType[]>();
  const [showOpenOnGwmr, setShowOpenOnGwmr] = useState(false);
  const [defaultValueOpenOnGwmr, setDefaultValueOpenOnGwmr] = useState<OptionType>();
  const [disabledSubmitOpenOnGwmr, setDisabledSubmitOpenOnGwmr] = useState(false);

  const [downloadBadImageDisabled, setDownloadBadImageDisabled] = useState(false);
  const [openModalUploadImages, setOpenModalUploadImages] = useState(false);

  const [openCommentsForWinery, setOpenCommentsForWinery] = useState<TWinery>();

  const pageRangeDisplayed = 8;
  const parsed = qs.parse(window.location.search);
  const history = useHistory();
  const location = useLocation();
  const authUser = useContext(AuthUserContext);
  const axiosConfig = {
    headers: {
      Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
    }
  };

  const chatMessagesRef = useRef<null | HTMLDivElement>(null);

  let ModalComments = {
    showModal: openModalComments,
    hideModal: () => {
      hideModalComments()
    },
  }

  let ModalEditField = {
    showModal: openModalEditField,
    hideModal: () => {
      hideModalEditField();
    },
    title: 'Edit field',
    primaryButton: {
      title: 'Submit',
      onClick: () => {
        submitEditField();
      },
      disabled: loadingEditField
    },
  }

  let ModalMergeWineries = {
    showModal: showMergeModal,
    hideModal: () => {
      hideModalMerge();
    },
    title: 'Merge wineries',
    primaryButton: {
      title: 'Merge',
      onClick: () => {
        //getMergeWineriesFieldsData();
        submitMergeWineries();
      },
      disabled: !mergeIn
    },
    size: 'lg' as BsModalSize,
  }

  let ModalUploadImages = {
    showModal: openModalUploadImages,
    hideModal: () => {
      setOpenModalUploadImages(false);
    },
    title: "Upload images",
    confirmButton: false,
    bsError: "",
    size: 'lg' as BsModalSize
  }

  const hideModalComments = () => {
    setOpenModalComments(false);
    setOpenCommentsForWinery(undefined);
  }

  const hideModalMerge = (unselect?: boolean) => {
    setShowMergeModal(false);
    setMergeIn(0);
    setMergeWineriesModalConfirmBtn(false);
    setMergeFields(undefined);
    setMergeWineriesFieldsDisabled(true);

    if (unselect) {
      setMergeSelected([]);
    }
  }

  const updateFilter = (key: string, value: string | number) => {
    if (value) {
      setFilter({...filter, [key]: value});
    } else {
      const {[key]: value, ...rest} = filter;
      setFilter(rest);
    }
  }

  const winesStatusesFilterList = () => {
    let data: OptionType[] = [];
    let statuses: string[];

    if (authUser.authUser?.is_admin) {
      statuses = [EWineStatuses.new, EWineStatuses.done, EWineStatuses.escalated, EWineStatuses.skipped, EWineStatuses.rejected];
    } else {
      statuses = [EWineStatuses.new];
    }

    statuses.forEach(item => {
      data.push({label: item, value: item});
    });

    setWineStatuses(data);
  }

  const loadFilterData = () => {
    axios.get('/api/taxonomy-countries-with-wineries', {
      ...axiosConfig
    }).then(r => {
      let result = r.data;

      if (result.hasOwnProperty('data')) {
        setCountries(result.data);
      }
    }).catch(r => {
      if (r.response && r.response.status === 401) {
        toast.error('You are not authorized');
      }
    });
  }

  const submitFilter = (to_page?: number) => {
    const query = new URLSearchParams(location.search);

    let currentPage = to_page ? to_page : (parsed.page ? parseInt(parsed.page as string) : 1),
      queryFilter = '';

    setPage(currentPage);

    if (Object.keys(filter).length) {
      Object.entries(filter).forEach(([key, value]) => {
        if (value !== '') {
          queryFilter += '&' + key + '=' + value;
        }
      });
    } else {
      if (query.get('country')) {
        queryFilter += '&country=' + query.get('country');
      }
      if (query.get('wineryLike')) {
        queryFilter += '&wineryLike=' + query.get('wineryLike');
      }
      if (query.get('wine_status')) {
        queryFilter += '&wine_status=' + query.get('wine_status');
      }

      Object.entries(EWineryStatuses).forEach(([key, value]) => {
        if (query.get('winery_status[' + key + ']')) {
          queryFilter += '&winery_status[' + key + ']=' + query.get('winery_status[' + key + ']');
        }
      });
    }

    if (Object.keys(wineryStatuses).length) {
      Object.entries(wineryStatuses).forEach(([key, value]) => {
        queryFilter += '&winery_status[' + key + ']=' + value;
      });
    }

    if (queryFilter !== '') {
      setSubmitDisabled(true);
      axios.get('/api/wineries-review/listing?page=' + currentPage + queryFilter, {
        ...axiosConfig
      }).then(r => {
        let result = r.data;

        history.push({
          pathname: location.pathname,
          search: "?page=" + currentPage + queryFilter
        },);

        if (result.hasOwnProperty('data')) {
          if (result.data.data) {
            let wineries = result.data;
            setWineries(wineries.data);

            let paginationData = {
              'current_page': wineries.current_page,
              'from': wineries.from,
              'last_page': wineries.last_page,
              'first_page_url': wineries.first_page_url,
              'last_page_url': wineries.last_page_url,
              'next_page_url': wineries.next_page_url,
              'path': wineries.path,
              'per_page': wineries.per_page,
              'prev_page_url': wineries.prev_page_url,
              'to': wineries.to,
              'total': wineries.total
            };

            setPagination(paginationData);
          } else {
            setWineries([]);
          }
        }
      }).finally(() => {
        setSubmitDisabled(false);
        setDisabledSubmitOpenOnGwmr(false);
        setDownloadBadImageDisabled(false);
      }).catch(r => {
        if (r.response && r.response.status === 401) {
          toast.error('You are not authorized');
        }
      });
    }
  }

  const getWineriesByPage = (pageNumber: number, force_search?: boolean) => {
    force_search = force_search || false;
    if (page === pageNumber && !force_search) {
      return false;
    }

    setPage(pageNumber);

    // * get  wines
    submitFilter(pageNumber);
  }

  const toggleWineryStatus = (status: EWineryStatuses, winery: number) => {

    setUpdateStatusDisabled(true);

    axios.post('/api/wineries-review/toggle-status', {
      status: status,
      winery_id: winery
    }, {
      ...axiosConfig
    }).then(r => {
      let result = r.data;
      if (result.hasOwnProperty('error')) {
        toast.error(result.error);
        return false;
      }

      if (wineries && wineries.length) {
        const w: TWinery[] = wineries.map(item => {
          if (item.id === winery) {
            if (result.hasOwnProperty('color')) {
              item.row_color = result.color;
            }

            if (result.hasOwnProperty('user')) {
              const today = new Date(),
                currentDatetime = today.getDate() + '.' + ((today.getMonth().toString().length === 1 ? '0' : '') + (today.getMonth() + 1)) + '.' + today.getFullYear() + ', ' + today.getHours() + ':' + today.getMinutes();

              item.review_statuses[status] = {
                user: result.user,
                created: currentDatetime,
              }
            } else {
              delete item.review_statuses[status];
            }
          }

          return item;
        });

        setWineries(w);
      }

      if (result.hasOwnProperty('msg')) {
        toast.success(result.msg);
        return true;
      }
    }).finally(() => {
      setUpdateStatusDisabled(false);
    }).catch(r => {
      if (r.response && r.response.status === 401) {
        toast.error('You are not authorized');
      }
    });
  }

  const openChatModal = (winery: TWinery) => {
    setOpenCommentsForWinery(winery);
    setOpenModalComments(true);
  }

  const onUpdateMessages = (winery_id: number, count_messages: number) => {
    if (wineries) {
      const w: TWinery[] = wineries.map(item => {
        if (item.id === winery_id) {
          item.count_comments = count_messages;
        }
        return item;
      });

      setWineries(w);
    }
  };

  const showModalEditField = (field: string, value: string, item: TWinery) => {
    setEditField({id: item.id, key: field, value: value});
    setOpenModalEditField(true);
  }

  const hideModalEditField = () => {
    setOpenModalEditField(false);
    setEditField(undefined);
    setLoadingEditField(false);
  }

  const submitEditField = () => {
    if (typeof editField !== "undefined" && Object.keys(editField).length) {
      setLoadingEditField(true);
      axios.post('/api/wineries-review/submit-edit-field', editField, {
        ...axiosConfig
      }).then(r => {
        let result = r.data;
        if (result.hasOwnProperty('error')) {
          toast.error(result.error);
          return false;
        }

        if (result.hasOwnProperty('success')) {

          toast.success(result.msg);

          if (wineries) {
            const w: TWinery[] = wineries.map(item => {
              if (item.id === editField.id) {
                if (editField.key === 'country') {
                  const country = countries.find(option => {
                    return Number(option.value) === Number(editField.value);
                  });
                  if (country) {
                    item.country_id = Number(editField.value);
                    item.country = country.label;
                  }
                } else if (editField.key === 'logo') {
                  if (editField.value !== '') {
                    item.logo = {
                      fid: 0,
                      status: result.hasOwnProperty("data") && result.data.status ? result.data.status : "VALID",
                      url: '/api/wineries-review/winery-image/' + item.id
                    };
                  } else {
                    item.logo = null;
                  }
                } else {
                  if (item.hasOwnProperty(editField.key)) {
                    item[editField.key as keyof TWinery] = editField.value as never;
                  }
                }
              }
              return item;
            });

            setWineries(w);
          }

          hideModalEditField();
        }
      }).finally(() => {
        setLoadingEditField(false);
      }).catch(r => {
        if (r.response && r.response.status === 401) {
          toast.error('You are not authorized');
        }
      });
    }
  }

  const selectForMerge = (checked: boolean, winery_id: number) => {
    if (checked) {
      setMergeSelected(mergeSelected.concat(winery_id));
    } else {
      let filtered = mergeSelected.filter(id => id !== winery_id);
      setMergeSelected(filtered);
    }
  }

  const submitMergeWineries = () => {
    if (mergeSelected.length > 1 && mergeIn) {
      axios.post('/api/wineries-review/submit-merge-wineries', {
        merge_in: mergeIn,
        wineries: mergeSelected,
        fields: selectedMergeFields
      }, {
        ...axiosConfig
      }).then(r => {
        let result = r.data;
        if (result.hasOwnProperty('error')) {
          toast.error(result.error);
          return false;
        }

        if (result.hasOwnProperty('success')) {
          toast.success(result.msg);

          // exclude
          if (wineries) {
            let countStatuses: TWinesCounts = countMergedWineriesStatuses();

            const filtered = wineries.filter(item => {
              if (item.id === mergeIn || !mergeSelected.includes(item.id)) {
                if (item.id === mergeIn && selectedMergeFields) {
                  if (selectedMergeFields.hasOwnProperty('country')) {
                    item.country_id = Number(selectedMergeFields['country']);

                    const country = countries.find(item => item.value == selectedMergeFields['country']);
                    if (country) {
                      item.country = country.label;
                    }
                  }
                  if (selectedMergeFields.hasOwnProperty('website')) {
                    item.website = selectedMergeFields['website'].toString();
                  }

                  item.wines_counts = countStatuses;
                }
                return item;
              }
            });

            setWineries(filtered);
          }

          hideModalMerge(true);
        }
      }).catch(r => {
        if (r.response && r.response.status === 401) {
          toast.error('You are not authorized');
        }
      });
    }
  }

  const countMergedWineriesStatuses = () => {
    let countStatuses: TWinesCounts = {
      N: 0,
      D: 0,
      E: 0,
      S: 0,
      R: 0
    };
    if (wineries?.length) {

      wineries.filter(item => {
        if (mergeSelected.includes(item.id)) {
          countStatuses['N'] += item.wines_counts.N;
          countStatuses['D'] += item.wines_counts.D;
          countStatuses['S'] += item.wines_counts.S;
          countStatuses['R'] += item.wines_counts.R;

          return item;
        }
      });
    }

    return countStatuses;
  }

  const openMergeModal = () => {
    axios.post('/api/wineries-review/merge-wineries-fields-data', {
      wineries: mergeSelected
    }, {
      ...axiosConfig
    }).then(r => {
      let result = r.data;

      if (result.hasOwnProperty('error')) {
        toast.error(result.error);
        return false;
      }

      if (result.hasOwnProperty('fields')) {
        setMergeFields(result.fields);

        const selected: { [key: string]: string | number } = {};

        Object.entries(result.fields as OptionType[][]).forEach(([key, value]) => {
          if (value.length === 1) {
            selected[key] = value[0].value;
          }
        });

        setSelectedMergeFields(selected);
      }

      setShowMergeModal(true);
    });
  }

  const openPreviewImage = (src: string) => {
    setPreviewImage([src]);
    setIsViewerOpen(true);
  }

  const closeImageViewer = () => {
    setPreviewImage([]);
    setIsViewerOpen(false);
  };

  const loadOpenOnGwmrData = (country?: number) => {
    const selected = country || filter.country
    axios.get('/api/wineries-review/open-gwmr?country=' + selected, {
      ...axiosConfig
    }).then(r => {
      let result = r.data;
      if (result.hasOwnProperty('listing')) {
        setOpenOnGwmrOptions(result.listing);
      }
      if (result.hasOwnProperty('selected')) {
        setDefaultValueOpenOnGwmr(result.selected);
      }
    });
  }

  const saveOpenGwmrOption = () => {
    setDisabledSubmitOpenOnGwmr(true);
    if (defaultValueOpenOnGwmr) {
      axios.post('/api/wineries-review/submit-open-gwmr', {
        value: defaultValueOpenOnGwmr.value,
        country: filter.country
      }, {
        ...axiosConfig
      }).then(r => {
        let result = r.data;
        if (result.hasOwnProperty('error')) {
          toast.error(result.error);
          return false;
        }

        if (result.hasOwnProperty('success')) {
          toast.success(result.msg);
          return true;
        }
      }).finally(() => {
        setDisabledSubmitOpenOnGwmr(false);
      });
    }
  }

  const updateWineryStatus = (field: string, value: string | number) => {
    if (typeof value === "number") {
      setWineryStatuses({...wineryStatuses, [field]: Number(value)});
    } else {
      const {[field]: value, ...rest} = wineryStatuses;
      setWineryStatuses(rest);
    }
  }

  const uploadLogoCallback = (callback: {url: string, status: string, winery_id: number, fid: number}) => {

    if (wineries) {
      const w: TWinery[] = wineries.map(item => {
        if (item.id === Number(callback.winery_id)) {
          item.logo = {
            fid: callback.fid,
            status: callback.status,
            url: callback.url
          };
        }
        return item;
      });
      setWineries(w);
    }

    toast.success('File successfully uploaded.');

    setTimeout(function(){
      hideModalEditField();
    }, 200);
  }

  const downloadBadImages = () => {
    setDownloadBadImageDisabled(true);
    axios.post('/api/wineries-review/download-bad-images', filter, {
      ...axiosConfig,
      responseType: 'arraybuffer'
    }).then((response) => {
      let filename = response.headers['x-file-name'];
      downloadFile(response.data, filename);
    }).catch(e => {
      if (e.response.status === 404) {
        const response = JSON.parse(new TextDecoder('utf-8').decode(new Uint8Array(e.response.data)));
        toast.error(response.message);
      }
    }).finally(() => {
      setDownloadBadImageDisabled(false);
    });
  }

  useEffect(() => {
    loadFilterData();
  }, []);

  useEffect(() => {
    winesStatusesFilterList();

    setSubmitDisabled(!Object.keys(filter).length);

    setShowOpenOnGwmr(!!(authUser.authUser?.is_admin && Object.keys(filter).length && filter.hasOwnProperty('country') && !filter.hasOwnProperty('wineryLike')));

    if (location.search) {
      let query = new URLSearchParams(location.search), f: { [k: string]: string | number } = {};
      if (query.get('country')) {
        f['country'] = Number(query.get('country'))
      }

      const disabled =!!(Object.keys(filter).length && filter.hasOwnProperty('country') && query.get('country') && Number(query.get('country')) !== Number(filter.country));
      setDisabledSubmitOpenOnGwmr(disabled);
      setDownloadBadImageDisabled(disabled);
    }

  }, [filter]);

  useEffect(() => {
    if (location.search) {
      let query = new URLSearchParams(location.search), f: { [k: string]: string | number } = {};
      if (query.get('country')) {
        f['country'] = Number(query.get('country'))
      }
      if (query.get('wineryLike')) {
        f['wineryLike'] = query.get('wineryLike') || '';
      }
      if (query.get('wine_status')) {
        f['wine_status'] = query.get('wine_status') || '';
      }

      let wStatuses: { [k: string]: number } = {};
      Object.entries(EWineryStatuses).forEach(([key, value]) => {
        if (query.get('winery_status[' + key + ']')) {
          wStatuses[key] = Number(query.get('winery_status[' + key + ']'));
        }
      });
      if (Object.keys(wStatuses).length) {
        setWineryStatuses(wStatuses);
      }

      if (Object.keys(f).length) {
        setFilter(f);
        submitFilter(query.get('page') ? Number(query.get('page')) : 1);

        if (f['country'] && !f['wineryLike']) {
          loadOpenOnGwmrData(Number(f['country']));
        }
      }
    }
  }, []);

  return (
    <div className="App">
      <div className="w-100">
        <Container fluid>
          <div className="row">
            <div className="col-md-12">
              <h4 className="mb-2 text-start">Wineries review {pagination ? '(' + pagination.total + ')' : ''}</h4>
              <hr/>
            </div>
            <div className="col-md-12">
              <h5>Filter</h5>
              <div className="row flex-row align-items-end">
                <div className={`col-md-4`}>
                  <div className="form-group">
                    <label>Country</label>
                    <Select options={countries} isClearable={true}
                            value={countries.filter(option => {
                              return option.value === filter.country;
                            })}
                            onChange={(item) => {
                              updateFilter('country', item ? item.value : '');
                            }}/>
                  </div>
                </div>
                {!!(authUser.authUser?.is_admin) &&
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Winery</label>
                      <input type="text" className="form-control"
                             value={filter.wineryLike || ''}
                             onChange={(item) => {
                               updateFilter('wineryLike', item ? item.target.value : '');
                             }}/>
                    </div>
                  </div>
                }
                <div className={`col-md-4`}>
                  <div className="form-group">
                    <label>Wine status</label>
                    <Select options={wineStatuses} isClearable={true}
                            isMulti={true}
                            value={wineStatuses.filter(option => {
                              let selectedValues = filter.wine_status ? filter.wine_status.toString().split(',') : [];
                              return selectedValues.indexOf(option.value) !== -1;
                            })}
                            onChange={(item) => {
                              if (item) {
                                let items = item.map(i => i.value).join(',');
                                updateFilter('wine_status', items);
                              }
                            }}/>
                  </div>
                </div>
                {!!(authUser.authUser?.is_admin) &&
                  <>
                    <div className="col-md-3 mt-3">
                      <div className="form-group">
                        <label>Problematic wineries</label>
                        <Select options={wineryStatusesList}
                                isClearable={true}
                                value={wineryStatusesList.filter(option => {
                                  let selectedValues = wineryStatuses[EWineryStatuses.PROBLEMATIC] ?? '';
                                  return option.value === selectedValues.toString();
                                })}
                                onChange={(item) => {
                                  updateWineryStatus(EWineryStatuses.PROBLEMATIC, item ? Number(item.value) : '');
                                }}/>
                      </div>
                    </div>
                    <div className="col-md-3 mt-3">
                      <div className="form-group">
                        <label>Valid wineries</label>
                        <Select options={wineryStatusesList}
                                isClearable={true}
                                value={wineryStatusesList.filter(option => {
                                  let selectedValues = wineryStatuses[EWineryStatuses.VALID] ?? '';
                                  return option.value === selectedValues.toString();
                                })}
                                onChange={(item) => {
                                  updateWineryStatus(EWineryStatuses.VALID, item ? Number(item.value) : '');
                                }}/>
                      </div>
                    </div>
                    <div className="col-md-3 mt-3">
                      <div className="form-group">
                        <label>Merged wineries</label>
                        <Select options={wineryStatusesList}
                                isClearable={true}
                                value={wineryStatusesList.filter(option => {
                                  let selectedValues = wineryStatuses[EWineryStatuses.MERGED] ?? '';
                                  return option.value === selectedValues.toString();
                                })}
                                onChange={(item) => {
                                  updateWineryStatus(EWineryStatuses.MERGED, item ? Number(item.value) : '');
                                }}/>
                      </div>
                    </div>
                    <div className="col-md-3 mt-3">
                      <div className="form-group">
                        <label>Finished wineries</label>
                        <Select options={wineryStatusesList}
                                isClearable={true}
                                value={wineryStatusesList.filter(option => {
                                  let selectedValues = wineryStatuses[EWineryStatuses.FINISHED] ?? '';
                                  return option.value === selectedValues.toString();
                                })}
                                onChange={(item) => {
                                  updateWineryStatus(EWineryStatuses.FINISHED, item ? Number(item.value) : '');
                                }}/>
                      </div>
                    </div>
                  </>
                }
                <div className="col-md-12">
                  <div className="row justify-content-center mt-4">
                    <div className="col-md-2">
                      <button className="btn btn-custom w-100" onClick={() => {
                        setMergeSelected([]);
                        submitFilter(1);
                        loadOpenOnGwmrData();
                      }}
                              disabled={submitDisabled}>Search
                      </button>
                    </div>
                    {!!(authUser.authUser?.is_admin) &&
                      <div className="col-md-2">
                        <button className="btn btn-outline-custom" disabled={!(mergeSelected.length > 1)}
                                onClick={() => openMergeModal()}>Merge ({mergeSelected.length})
                        </button>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contest mb-5">
            <div className="row">
              <div className="col-md-12">
                {wineries ?
                  <>
                    {wineries.length ?
                      <>
                        <div className="row justify-content-between">
                        {showOpenOnGwmr &&
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>
                                Open on GWMR
                              </label>
                              <div className="d-flex">
                                <div className="w-100">
                                  <Select options={openOnGwmrOptions}
                                          value={defaultValueOpenOnGwmr}
                                          classNamePrefix="react-select"
                                          onChange={item => {
                                            if (item) {
                                              setDefaultValueOpenOnGwmr(item);
                                            } else {
                                              setDefaultValueOpenOnGwmr(undefined);
                                            }
                                          }}
                                          styles={customStyles}/>
                                </div>
                                <button className="btn btn-custom inherit ml-2 pt-1 pb-1"
                                        disabled={disabledSubmitOpenOnGwmr}
                                        onClick={saveOpenGwmrOption}>Save
                                </button>
                              </div>
                            </div>
                          </div>
                        }
                        <div className="col-md-6">
                          <div className="form-group text-end">
                            <button className="btn btn-outline-custom mr-3 mt-3" onClick={(e) => {
                              e.currentTarget.blur();
                              setOpenModalUploadImages(true);
                            }}>
                              Upload images
                            </button>
                            <button className="btn btn-outline-custom mt-3" onClick={downloadBadImages} disabled={downloadBadImageDisabled}>
                              Download BAD images
                            </button>
                          </div>
                        </div>
                        </div>
                        <Table bordered hover size="md" className="mt-4 fixed-thead custom">
                          <thead>
                          <tr>
                            {!!(authUser.authUser?.is_admin) &&
                              <th/>
                            }
                            <th>ID</th>
                            <th>Name</th>
                            <th>Country</th>
                            <th>Logo</th>
                            <th className="text-center">
                              <ReactTooltip id={"wine_status_" + EWineStatuses.new.toLowerCase()}
                                            className="adp-tooltip--wine">
                                {EWineStatuses.new}
                              </ReactTooltip>
                              <span
                                data-tip=""
                                data-for={"wine_status_" + EWineStatuses.new.toLowerCase()}>
                                {EWineStatuses.new[0]}
                              </span>
                            </th>
                            <th className="text-center">
                              <ReactTooltip id={"wine_status_" + EWineStatuses.done.toLowerCase()}
                                            className="adp-tooltip--wine">
                                {EWineStatuses.done}
                              </ReactTooltip>
                              <span
                                data-tip=""
                                data-for={"wine_status_" + EWineStatuses.done.toLowerCase()}>
                                {EWineStatuses.done[0]}
                              </span>
                            </th>
                            <th className="text-center">
                              <ReactTooltip id={"wine_status_" + EWineStatuses.escalated.toLowerCase()}
                                            className="adp-tooltip--wine">
                                {EWineStatuses.escalated}
                              </ReactTooltip>
                              <span
                                data-tip=""
                                data-for={"wine_status_" + EWineStatuses.escalated.toLowerCase()}>
                                {EWineStatuses.escalated[0]}
                              </span>
                            </th>
                            <th className="text-center">
                              <ReactTooltip id={"wine_status_" + EWineStatuses.skipped.toLowerCase()}
                                            className="adp-tooltip--wine">
                                {EWineStatuses.skipped}
                              </ReactTooltip>
                              <span
                                data-tip=""
                                data-for={"wine_status_" + EWineStatuses.skipped.toLowerCase()}>
                                {EWineStatuses.skipped[0]}
                              </span>
                            </th>
                            <th className="text-center">
                              <ReactTooltip id={"wine_status_" + EWineStatuses.rejected.toLowerCase()}
                                            className="adp-tooltip--wine">
                                {EWineStatuses.rejected}
                              </ReactTooltip>
                              <span
                                data-tip=""
                                data-for={"wine_status_" + EWineStatuses.rejected.toLowerCase()}>
                                {EWineStatuses.rejected[0]}
                              </span>
                            </th>
                            <th>Website</th>
                            <th>
                              <ReactTooltip id={"problematic"}
                                            className="adp-tooltip--wine">
                                Problematic
                              </ReactTooltip>
                              <span
                                data-tip=""
                                data-for={"problematic"}>
                                <ExclamationTriangleFill/>
                              </span>
                            </th>
                            <th>Valid</th>
                            <th>Merged</th>
                            <th>Finished</th>
                            <th/>
                          </tr>
                          </thead>
                          <tbody>
                          {wineries.map(item => {
                            return <tr key={item.id} style={{backgroundColor: item.row_color}}>
                              {!!(authUser.authUser?.is_admin) &&
                                <td><input type="checkbox"
                                           checked={!!(mergeSelected.length && mergeSelected.includes(item.id))}
                                           onChange={(e) => selectForMerge(e.target.checked, item.id)}/></td>
                              }
                              <td>
                                <a href={`/target-revision?parsed_winery_id=${item.id}`} target={"_blank"} rel={"noreferrer"}>
                                  {item.id}
                                </a>
                              </td>
                              <td>
                                <div className="d-flex justify-content-between align-items-start">
                                  <div className="d-flex">
                                    <SearchGoogleMini content={item.name + ' winery'}/>
                                    <div className="ml-2">
                                      <a href={`/wines-merge/list?winery=${item.id}`} target={"_blank"} rel={"noreferrer"}>
                                        {item.name}
                                      </a>
                                    </div>
                                  </div>
                                  {hasPermissionTo(authUser, "wineries_review edit_field") &&
                                    <button className="link-edit"
                                            onClick={() => showModalEditField('name', item.name, item)}>
                                      <Pencil/>
                                    </button>
                                  }
                                </div>
                              </td>
                              <td>
                                <div className="d-flex justify-content-between align-items-start">
                                  {item.country}
                                  {hasPermissionTo(authUser, "wineries_review edit_field") &&
                                    <button className="link-edit"
                                            onClick={() => showModalEditField('country', item.country_id.toString(), item)}>
                                      <Pencil/>
                                    </button>
                                  }
                                </div>
                              </td>
                              <td>
                                <div className="d-flex justify-content-between align-items-center">
                                  {(() => {
                                    if (item.logo) {
                                      switch (item.logo.status) {
                                        case 'VALID':
                                          return (
                                            <a href='/#'
                                               title={`Logo ${item.logo.status}`}
                                               onClick={(e) => {
                                                 e.preventDefault();
                                                 openPreviewImage(item && item.logo ? item.logo.url : '#')
                                               }}><CardImage/></a>
                                          );
                                        case 'BROKEN':
                                          return (
                                            <div className={`photo-status-${item.logo.status.toLowerCase()}`} title={`Logo ${item.logo.status}`}>
                                              <CardImage/>
                                            </div>
                                          );
                                        case 'BAD':
                                          return (
                                            <div className={`photo-status-${item.logo.status.toLowerCase()}`}>
                                              <a href='/#'
                                                 title={`Logo ${item.logo.status}`}
                                                 onClick={(e) => {
                                                   e.preventDefault();
                                                   openPreviewImage(item && item.logo ? item.logo.url : '#')
                                                 }}><CardImage/></a>
                                            </div>
                                          );
                                      }
                                    }
                                  })()}
                                {hasPermissionTo(authUser, "wineries_review edit_field") &&
                                  <button className="link-edit"
                                          onClick={() => showModalEditField('logo', '', item)}>
                                    <Pencil/>
                                  </button>
                                }
                                </div>
                              </td>
                              <td className="text-center">
                                <a href={"/wines?page=1&status=" + EWineStatuses.new + "&winery=" + item.id}
                                   target={"_blank"} rel="noreferrer">
                                  {item.wines_counts.N}
                                </a>
                              </td>
                              <td className="text-center">
                                <a href={"/wines?page=1&status=" + EWineStatuses.done + "&winery=" + item.id}
                                   target={"_blank"} rel="noreferrer">
                                  {item.wines_counts.D}
                                </a>
                              </td>
                              <td className="text-center">
                                <a href={"/wines?page=1&status=" + EWineStatuses.escalated + "&winery=" + item.id}
                                   target={"_blank"} rel="noreferrer">
                                  {item.wines_counts.E}
                                </a>
                              </td>
                              <td className="text-center">
                                <a href={"/wines?page=1&status=" + EWineStatuses.skipped + "&winery=" + item.id}
                                   target={"_blank"} rel="noreferrer">
                                  {item.wines_counts.S}
                                </a>
                              </td>
                              <td className="text-center">
                                <a href={"/wines?page=1&status=" + EWineStatuses.rejected + "&winery=" + item.id}
                                   target={"_blank"} rel="noreferrer">
                                  {item.wines_counts.R}
                                </a>
                              </td>
                              <td>
                                <div className="d-flex justify-content-between align-items-start">
                                  {isValidHttpUrl(item.website) ?
                                    <a href={item.website} target={"_blank"} rel={"noreferrer"}>
                                      {item.website}
                                    </a>
                                    :
                                    item.website
                                  }
                                  {hasPermissionTo(authUser, "wineries_review edit_field") &&
                                    <button className="link-edit"
                                            onClick={() => showModalEditField('website', item.website, item)}>
                                      <Pencil/>
                                    </button>
                                  }
                                </div>
                              </td>
                              <td className="text-center">
                                <WineryReviewStatusInput toggleWineryStatus={toggleWineryStatus}
                                                         review_statuses={item.review_statuses} winery_id={item.id}
                                                         current_status={EWineryStatuses.PROBLEMATIC}
                                                         is_disabled={updateStatusDisabled}/>
                              </td>
                              <td className="text-center">
                                <WineryReviewStatusInput toggleWineryStatus={toggleWineryStatus}
                                                         review_statuses={item.review_statuses} winery_id={item.id}
                                                         current_status={EWineryStatuses.VALID}
                                                         is_disabled={updateStatusDisabled || !authUser.authUser?.is_admin}/>
                              </td>
                              <td className="text-center">
                                <WineryReviewStatusInput toggleWineryStatus={toggleWineryStatus}
                                                         review_statuses={item.review_statuses} winery_id={item.id}
                                                         current_status={EWineryStatuses.MERGED}
                                                         is_disabled={updateStatusDisabled}/>
                              </td>
                              <td className="text-center">
                                <WineryReviewStatusInput toggleWineryStatus={toggleWineryStatus}
                                                         review_statuses={item.review_statuses} winery_id={item.id}
                                                         current_status={EWineryStatuses.FINISHED}
                                                         is_disabled={updateStatusDisabled || !authUser.authUser?.is_admin}/>
                              </td>
                              <td>
                                <button className={"btn-chat " + (item.count_comments ? 'with-messages' : '')}
                                        onClick={() => openChatModal(item)}>
                                  <ChatLeftText/>
                                </button>
                              </td>
                            </tr>;
                          })
                          }
                          </tbody>
                        </Table>
                        {pagination !== null ?
                          <>
                            <Pagination activePage={pagination.current_page}
                                        itemsCountPerPage={pagination.per_page}
                                        onChange={(pageNumber) => {
                                          getWineriesByPage(pageNumber)
                                        }}
                                        totalItemsCount={pagination.total}
                                        pageRangeDisplayed={pageRangeDisplayed}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        firstPageText="First Page"
                                        lastPageText="Last Page"
                                        innerClass="pagination mt-3 inline float-left"
                            />
                            <GotoPagination last_page={pagination.last_page} loadData={getWineriesByPage}/>
                          </>
                          : ''
                        }

                        <WineryCommentModal winery={openCommentsForWinery} modalParams={ModalComments} onUpdateMessages={onUpdateMessages}/>

                        <BsModal {...ModalEditField}>
                          <div
                            className={"form-group mb-2" + (loadingEditField ? ' loading disabled' + (editField && editField.key === 'country' ? ' select' : '') : '')}>
                            <p>Value for field <strong>{editField && editField.key ? capitalize(editField.key) : ''}</strong></p>
                            <fieldset {...(loadingEditField ? {disabled: true} : '')}>
                              {editField && editField.key === 'country' ?
                                <Select options={countries}
                                        value={countries.filter(option => {
                                          return Number(option.value) === Number(editField.value);
                                        })}
                                        onChange={e => {
                                          if (e) {
                                            const fieldData = Object.assign({}, editField);
                                            fieldData.value = e.value;
                                            setEditField(fieldData);
                                          }
                                        }}/>
                                : ''
                              }
                              {editField && (editField.key === 'website' || editField.key === 'name' || editField.key === 'logo') ?
                                <input type="text" className="custom-input-text w-100" value={editField?.value || ''}
                                       onChange={e => {
                                         if (e) {
                                           const fieldData = Object.assign({}, editField);
                                           fieldData.value = e.target.value;
                                           setEditField(fieldData);
                                         }
                                       }}/>
                                : ''
                              }
                              {editField && editField.key === 'logo' ?
                                <div className="upload-container">
                                  <span className="separator">or</span>
                                  <DragDropFile accept={["image/jpg", "image/jpeg", "image/png", "image/webp"]} api={{ url: `/api/wineries-review/upload-logo/${editField.id}` }} callback={uploadLogoCallback}/>
                                </div>
                                : ""}

                            </fieldset>
                          </div>
                        </BsModal>

                        <BsModal {...ModalMergeWineries}>
                          <div className="form-group mb-2 wineries-merge-in">
                            <div className="row">
                              <div className="col-md-6">
                                <p>Select where you want to merge wineries in.</p>
                                <ul>
                                  {mergeSelected.map(id => {
                                    let winery = mergeFields && mergeFields.hasOwnProperty('name') ? mergeFields.name.find(item => item.value === Number(id)) : null;
                                    return <li key={"merge-" + id} className={mergeIn === id ? 'selected' : ''}>
                                      <label><input type="radio" name="merge_in"
                                                    onClick={() => setMergeIn(id)}
                                                    disabled={!mergeWineriesFieldsDisabled}
                                      /> {winery ? winery.label : id}
                                      </label>
                                    </li>;
                                  })
                                  }
                                </ul>
                              </div>
                              <div className="col-md-6 merge-fields">
                                <p>Merge winery fields data:</p>
                                <div className="form-group">
                                  <label>Country</label>
                                  <Select isDisabled={false}
                                          isClearable={true}
                                          defaultValue={mergeFields && mergeFields.hasOwnProperty('country') && mergeFields.country.length === 1 ? mergeFields.country[0] : null}
                                          onChange={item => {
                                            let selected = Object.assign({}, selectedMergeFields);
                                            if (item) {
                                              selected['country'] = item.value;
                                            } else {
                                              delete selected['country'];
                                            }
                                            setSelectedMergeFields(selected);
                                          }}
                                          options={mergeFields && mergeFields.hasOwnProperty('country') ? mergeFields.country : undefined}
                                          classNamePrefix="react-select" styles={customStyles}/>
                                </div>
                                <div className="form-group">
                                  <label>Region</label>
                                  <Select isDisabled={false}
                                          isClearable={true}
                                          defaultValue={mergeFields && mergeFields.hasOwnProperty('region') && mergeFields.region.length === 1 ? mergeFields.region[0] : null}
                                          onChange={item => {
                                            let selected = Object.assign({}, selectedMergeFields);
                                            if (item) {
                                              selected['region'] = item.value;
                                            } else {
                                              delete selected['region'];
                                            }
                                            setSelectedMergeFields(selected);
                                          }}
                                          options={mergeFields && mergeFields.hasOwnProperty('region') ? mergeFields.region : undefined}
                                          classNamePrefix="react-select" styles={customStyles}/>
                                </div>
                                <div className="form-group">
                                  <label>Phone*</label>
                                  <Select isDisabled={false}
                                          isClearable={true}
                                          defaultValue={mergeFields && mergeFields.hasOwnProperty('phone') && mergeFields.phone.length === 1 ? mergeFields.phone[0] : null}
                                          onChange={item => {
                                            let selected = Object.assign({}, selectedMergeFields);
                                            if (item) {
                                              selected['phone'] = item.value;
                                            } else {
                                              delete selected['phone'];
                                            }
                                            setSelectedMergeFields(selected);
                                          }}
                                          options={mergeFields && mergeFields.hasOwnProperty('phone') ? mergeFields.phone : undefined}
                                          classNamePrefix="react-select" styles={customStyles}/>
                                </div>
                                <div className="form-group">
                                  <label>Description</label>
                                  <Select isDisabled={false}
                                          isClearable={true}
                                          defaultValue={mergeFields && mergeFields.hasOwnProperty('description') && mergeFields.description.length === 1 ? mergeFields.description[0] : null}
                                          onChange={item => {
                                            let selected = Object.assign({}, selectedMergeFields);
                                            if (item) {
                                              selected['description'] = item.value;
                                            } else {
                                              delete selected['description'];
                                            }
                                            setSelectedMergeFields(selected);
                                          }}
                                          options={mergeFields && mergeFields.hasOwnProperty('description') ? mergeFields.description : undefined}
                                          classNamePrefix="react-select" styles={customStyles}/>
                                </div>
                                <div className="form-group">
                                  <label>Logo</label>
                                  <div className="d-flex">
                                    <Select isDisabled={false}
                                            isClearable={true}
                                            defaultValue={mergeFields && mergeFields.hasOwnProperty('logo') && mergeFields.logo.length === 1 ? mergeFields.logo[0] : null}
                                            onChange={item => {
                                              let selected = Object.assign({}, selectedMergeFields);
                                              if (item) {
                                                selected['logo'] = item.value;
                                              } else {
                                                delete selected['logo'];
                                              }
                                              setSelectedMergeFields(selected);
                                            }}
                                            options={mergeFields && mergeFields.hasOwnProperty('logo') ? mergeFields.logo : undefined}
                                            className={"w-100"}
                                            classNamePrefix="react-select" styles={customStyles}/>
                                    <div className="preview-logo ml-2">
                                      <a href="/#"
                                         {...(mergeFields && selectedMergeFields && mergeFields.hasOwnProperty('logo') && mergeFields.logo.find(i => i.value === selectedMergeFields['logo']) ? '' : {disabled: true})}
                                         onClick={(e) => {
                                           e.preventDefault();
                                           let logoField = mergeFields && selectedMergeFields && mergeFields.hasOwnProperty('logo') ? mergeFields.logo.find(i => i.value === selectedMergeFields['logo']) : null;
                                           if (logoField) {
                                             const src = `${process.env.REACT_APP_GUSTOS_APP_URL}/files/${logoField.label}`;
                                             openPreviewImage(src);
                                           }
                                         }}
                                      >
                                        <CardImage/>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label>Website</label>
                                  <Select isDisabled={false}
                                          isClearable={true}
                                          defaultValue={mergeFields && mergeFields.hasOwnProperty('website') && mergeFields.website.length === 1 ? mergeFields.website[0] : null}
                                          onChange={item => {
                                            let selected = Object.assign({}, selectedMergeFields);
                                            if (item) {
                                              selected['website'] = item.value;
                                            } else {
                                              delete selected['website'];
                                            }
                                            setSelectedMergeFields(selected);
                                          }}
                                          options={mergeFields && mergeFields.hasOwnProperty('website') ? mergeFields.website : undefined}
                                          classNamePrefix="react-select" styles={customStyles}/>
                                </div>
                                <div className="form-group">
                                  <label>Slug</label>
                                  <Select isDisabled={false}
                                          isClearable={true}
                                          defaultValue={mergeFields && mergeFields.hasOwnProperty('slug') && mergeFields.slug.length === 1 ? mergeFields.slug[0] : null}
                                          onChange={item => {
                                            let selected = Object.assign({}, selectedMergeFields);
                                            if (item) {
                                              selected['slug'] = item.value;
                                            } else {
                                              delete selected['slug'];
                                            }
                                            setSelectedMergeFields(selected);
                                          }}
                                          options={mergeFields && mergeFields.hasOwnProperty('slug') ? mergeFields.slug : undefined}
                                          classNamePrefix="react-select" styles={customStyles}/>
                                </div>
                              </div>
                            </div>
                          </div>
                        </BsModal>
                        <BsModal {...ModalUploadImages}>
                          <PreviewMultipleImages wineries={wineries} format={'<Winery_id>_<random_name>.<extension>'} extensions={['png']} uploadUrl={'/api/wineries-review/upload-images'}/>
                        </BsModal>
                        {isViewerOpen && (
                          <ImageViewer
                            src={previewImage}
                            currentIndex={0}
                            disableScroll={false}
                            closeOnClickOutside={true}
                            onClose={closeImageViewer}
                          />
                        )}
                      </>
                      : <div className="text-center mt-4">Wineries not found.</div>
                    }
                  </>
                  : ''
                }
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default WineriesReview;
