import {AlertError} from "../helpers";
import React, {useContext, useEffect, useState} from "react";
import {Container, Table} from "react-bootstrap";
import {AuthUserContext} from "../context/AuthUser";
import {EWineProperties, OptionType, TErrorObject} from "../properties";
import axios from "axios";
import Select from "react-select";
import {toast} from "react-toastify";
import {ArrowsCollapse, Pencil, Plus} from "react-bootstrap-icons";
import BsModal from "../components/BsModal";

const Regions = () => {
    const authUser = useContext(AuthUserContext);
    const axiosConfig = {
        headers: {
            Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
        }
    };
    const [error, setError] = useState<string | TErrorObject>('');
    const [countries, setCountries] = useState<null | OptionType[]>(null);
    const [regions, setRegions] = useState<null | OptionType[]>(null);
    const [selectedCountry, setSelectedCountry] = useState<OptionType | null>(null);
    const [selectedRegions, setSelectedRegions] = useState<OptionType[]>([]);
    const [disabledEdit, setDisabledEdit] = useState(true);
    const [disabledMerge, setDisabledMerge] = useState(true);
    const [disabledNewRegion, setDisabledNewRegion] = useState(true);
    const [checkboxDisabled, setCheckboxDisabled] = useState(false);
    const [openModalEditRegion, setOpenModalEditRegion] = useState(false);
    const [openModalAddRegion, setOpenModalAddRegion] = useState(false);
    const [mergeIn, setMergeIn] = useState<number>(0);
    const defaultMergeInIndex = 0;
    const [editRegionName, setEditRegionName] = useState('');
    const [newRegionName, setNewRegionName] = useState('');
    const [canLockCountry, setCanLockCountry] = useState(false);
    const [lockCountryChecked, setLockCountryChecked] = useState(false);
    const [winesCounter, setWinesCounter] = useState<{ [key: number]: number }>();

    const searchRegions = () => {
        resetAll();

        if (selectedCountry) {
            axios.get('/api/taxonomy-property?property=' + EWineProperties.region + '&country=' + selectedCountry.value, {
                ...axiosConfig
            }).then(r => {
                let result = r.data;
                if (result.hasOwnProperty('taxonomy') && result.taxonomy.hasOwnProperty(EWineProperties.region)) {
                    let sorted = result.taxonomy[EWineProperties.region].sort((a: { label: number; }, b: { label: number; }) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
                    setRegions(sorted);
                } else {
                    toast.error('No data');
                }
            }).catch(error => {

            }).finally(() => {
                setDisabledNewRegion(false);
            });

            axios.post('/api/lock-country-status', {country_id: selectedCountry.value}, {
                ...axiosConfig
            }).then(r => {
                let result = r.data;
                if (result.hasOwnProperty('status')) {
                    setCanLockCountry(true);
                    setLockCountryChecked(result.status);
                }
            });
        }
    }

    const resetAll = () => {
        setSelectedRegions([]);
        setDisabledEdit(true);
        setDisabledMerge(true);
        setDisabledNewRegion(true);
        setMergeIn(0);
        setRegions(null);
        setCheckboxDisabled(false);
        setCanLockCountry(false);
    }

    const toggleActions = (item: OptionType, is_checked: boolean) => {
        let selectedCount;
        if (is_checked) {
            selectedRegions.push(item);
            setSelectedRegions(selectedRegions.map(f => f));
            selectedCount = selectedRegions.length;
        } else {
            let filtered = selectedRegions.filter(e => e.value !== item.value);
            setSelectedRegions(filtered.map(f => f));
            selectedCount = filtered.length;

            if (mergeIn && mergeIn === parseInt(item.value)) {
                setMergeIn(0);
            }
        }

        switch (selectedCount) {
            case 0:
                setDisabledEdit(true);
                setDisabledMerge(true);
                break;
            case 1:
                setDisabledEdit(false);
                setDisabledMerge(true);
                break;
            default:
                setDisabledEdit(true);
                setDisabledMerge(false);
        }
    }

    const ModalEditRegionProps = {
        showModal: openModalEditRegion,
        hideModal: () => {
            hideEditRegionModal();
        },
        title: "Edit region",
        primaryButton: {
            title: 'Save',
            onClick: () => {
                saveEditRegion();
            }
        },
        confirmButton: false,
        bsError: ''
    }

    const ModalAddRegionProps = {
        showModal: openModalAddRegion,
        hideModal: () => {
            hideAddRegionModal();
        },
        title: "Add new region",
        primaryButton: {
            title: 'Save',
            onClick: () => {
                saveAddRegion();
            }
        },
        confirmButton: false,
        bsError: ''
    }

    const hideEditRegionModal = () => {
        setEditRegionName('');
        setOpenModalEditRegion(false);
    }

    const hideAddRegionModal = () => {

        setOpenModalAddRegion(false);
    }

    const saveEditRegion = () => {
        if (editRegionName && selectedRegions.length) {
            axios.post('/api/update-region', {
                'id': selectedRegions[0].value,
                'name': editRegionName
            }, {
                ...axiosConfig
            }).then(r => {
                let result = r.data;

                if (result.hasOwnProperty('error')) {
                    toast.error(result.error);
                    return false;
                }

                if (result.hasOwnProperty('success')) {

                    setOpenModalEditRegion(false);

                    if (regions) {
                        let regionsData = regions.map(item => {
                            if (item.value === selectedRegions[0].value) {
                                item.label = editRegionName;
                            }
                            return item;
                        });

                        setEditRegionName('');
                        setRegions(regionsData);
                        setSelectedRegions([]);
                    }

                    toast.success(result.msg);
                    return true;
                }
            }).catch(r => {

            })
        }
    }

    const saveAddRegion = () => {
        if (newRegionName !== '' && selectedCountry) {
            axios.post('/api/store-region', {
                region: newRegionName,
                country: selectedCountry.value
            }, {
                ...axiosConfig
            }).then(r => {
                let result = r.data;

                if (result.hasOwnProperty('err')) {
                    toast.error(result.err);
                    return false;
                }

                if (result.hasOwnProperty('region')) {
                    setOpenModalAddRegion(false);

                    let data = regions === null ? ([] as OptionType[]) : regions;

                    data.push({value: result.region.id, label: result.region.name});
                    data.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);

                    setRegions(data.map(f => f));
                    setNewRegionName('');
                }
            });
        }
    }

    const mergeRegions = () => {
        if (!window.confirm('Are you sure want to merge regions?')) {
            return false;
        }

        if (selectedRegions.length && selectedRegions.length >= 2) {
            const mergeId = mergeIn || selectedRegions[defaultMergeInIndex].value,
                regionsIds = selectedRegions.map(item => {
                    return item.value
                });

            axios.post('/api/merge-regions', {
                'merge_in': mergeId,
                'regions': regionsIds
            }, {
                ...axiosConfig
            }).then(r => {
                let result = r.data;
                if (result.hasOwnProperty('error')) {
                    toast.error(result.error);
                    return false;
                }
                if (result.hasOwnProperty('success')) {

                    setMergeIn(0);
                    setCheckboxDisabled(false);
                    setSelectedRegions([]);
                    setDisabledMerge(true);

                    if (regions) {
                        let regionsWithoutMergeId = regionsIds.filter(item => {
                            return item !== mergeId;
                        })
                        let filtered = regions.filter(item => {
                            return regionsWithoutMergeId.indexOf(item.value) === -1;
                        });

                        setRegions(filtered);
                    }

                    toast.success(result.msg);
                    return true;
                }

            }).catch(error => {
                console.log(error.toString());
            })
        }
    }

    const lockCountry = (lock: boolean, country: string | undefined) => {
        setCanLockCountry(false);
        axios.post('/api/lock-country', {
            lock: lock,
            country_id: country
        }, {
            ...axiosConfig
        }).then(r => {
            let result = r.data;
            if (result.hasOwnProperty('error')) {
                toast.error(result.error);
                return false;
            }

            if (result.hasOwnProperty('success')) {
                let msg = 'Country successfully ' + (lock ? 'locked' : 'unlocked');
                setLockCountryChecked(lock);
                toast.success(msg);
                return true;
            }
        }).catch(error => {
            console.log(error.toString());
        }).finally(() => {
            setCanLockCountry(true);
        });
    }

    useEffect(() => {
        axios.get('/api/taxonomy-property?property=' + EWineProperties.country, {
            ...axiosConfig
        }).then(r => {
            let result = r.data;
            if (result.hasOwnProperty('taxonomy') && result.taxonomy.hasOwnProperty(EWineProperties.country)) {
                setCountries(result.taxonomy[EWineProperties.country]);
            } else {
                toast.error('No data');
            }
        }).catch(error => {
            console.log(error.toString());
        })
    }, []);

    useEffect(() => {
        if (regions && regions.length) {
            let ids = regions.map(row => {
                return row.value;
            })
            axios.post('/api/count-wines-in-regions', {regions: ids}, {
                ...axiosConfig
            }).then(r => {
                let result = r.data;
                if (result.hasOwnProperty('counters') && result.counters) {
                    setWinesCounter(result.counters);
                }
            });
        }
    }, [regions]);

    return (
        <div className="App regions-page">
            <div className="w-100">
                <Container fluid>
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="mb-2 text-start">Regions list</h4>
                            <hr/>
                        </div>
                        <div className="col-md-12">
                            <AlertError error={error} callback={() => setError('')}/>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group mb-3">
                                        <label>Country</label>
                                        <Select isClearable={true} options={countries ? countries : []}
                                                onChange={item => setSelectedCountry(item)}/>
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex align-items-center justify-content-between">
                                    <button className="btn btn-custom" onClick={searchRegions}>Search</button>
                                    <button className="btn btn-outline-custom inherit" disabled={disabledEdit}
                                            onClick={() => setOpenModalEditRegion(true)}><Pencil/> Edit
                                    </button>
                                    <button className="btn btn-outline-custom inherit" onClick={mergeRegions}
                                            disabled={disabledMerge}>
                                        <ArrowsCollapse/> Merge
                                    </button>
                                    <button className="btn btn-outline-custom inherit" onClick={() => setOpenModalAddRegion(true)}
                                            disabled={disabledNewRegion}>
                                        <Plus/> New region
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {regions ?
                                        (
                                            <>
                                                <div className="selected-header">
                                                    <h4>Selected
                                                        regions: {selectedRegions.length ? selectedRegions.length : 0}</h4>
                                                    <label>
                                                        <input type="checkbox" disabled={!canLockCountry} checked={lockCountryChecked} onChange={(e) => lockCountry(e.target.checked, selectedCountry?.value)}/> - Lock country
                                                    </label>
                                                </div>
                                                <Table striped bordered hover size="sm">
                                                    <thead>
                                                    <tr>
                                                        <th style={{width: "5%"}}>#</th>
                                                        <th>Name</th>
                                                        <th style={{width: "20%"}}>Number of wines</th>
                                                        <th style={{width: "8%"}}>Merge in</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {regions.map(item => {
                                                        return (
                                                            <tr key={'region-' + item.value}>
                                                                <td className="text-center">
                                                                    <input type="checkbox"
                                                                           onChange={e => toggleActions(item, e.target.checked)}
                                                                           checked={!!(selectedRegions.length && selectedRegions.findIndex(e => e.value === item.value) !== -1)}
                                                                           disabled={checkboxDisabled}/>
                                                                </td>
                                                                <td>{item.label}</td>
                                                                <td>{winesCounter && winesCounter[parseInt(item.value)] ? winesCounter[parseInt(item.value)] : 0}</td>
                                                                <td className="text-center">
                                                                    <input type="radio" name="merge_id"
                                                                           value={item.value}
                                                                           disabled={!!(!selectedRegions.length || (selectedRegions.length && selectedRegions.length <= 1) || (selectedRegions.length && selectedRegions.length >= 2 && selectedRegions.findIndex(e => e.value === item.value) === -1))}
                                                                           checked={!!((selectedRegions.length && selectedRegions.length >= 2 && !mergeIn && selectedRegions[defaultMergeInIndex].value === item.value) || (mergeIn && mergeIn === parseInt(item.value)))}
                                                                           onChange={() => setMergeIn(parseInt(item.value))}/>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    </tbody>
                                                </Table>
                                            </>
                                        )
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <BsModal {...ModalEditRegionProps}>
                        <div className="form-group mb-2">
                            <label>Name</label>
                            <input type="text" className="w-100 custom-input-text"
                                   defaultValue={selectedRegions.length ? selectedRegions[0].label : ''}
                                   onChange={e => setEditRegionName(e.target.value)}/>
                        </div>
                    </BsModal>

                    <BsModal {...ModalAddRegionProps}>
                        <div className="form-group mb-2">
                            <label>Name</label>
                            <input type="text" className="w-100 custom-input-text"
                                   onChange={e => setNewRegionName(e.target.value)}/>
                        </div>
                    </BsModal>
                </Container>
            </div>
        </div>
    );
}

export default Regions;