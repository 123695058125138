import {AlertError, customStyles} from "../helpers";
import React, {useContext, useState} from "react";
import {OptionType, TErrorObject} from "../properties";
import {Container} from "react-bootstrap";
import Async from "react-select/async";
import axios from "axios";
import {AuthUserContext} from "../context/AuthUser";
import {ArrowsCollapse, Pencil, Plus, Save, XSquareFill} from "react-bootstrap-icons";
import {toast} from "react-toastify";

type TGrapes = {
  id: number,
  name: string
};

const Grapes = () => {

  const authUser = useContext(AuthUserContext);
  const axiosConfig = {
    headers: {
      Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
    }
  };
  const [error, setError] = useState<string | TErrorObject>('');
  const [loadingMainGrape, setLoadingMainGrape] = useState(false);
  const [loadingMergeGrape, setLoadingMergeGrape] = useState(false);
  const [selectedGrape, setSelectedGrape] = useState<OptionType | null>(null);
  const [disabledActions, setDisabledActions] = useState(true);
  const [editGrape, setEditGrape] = useState<OptionType | null>(null);
  const [newGrape, setNewGrape] = useState<string>('');
  const [showNewGrape, setShowNewGrape] = useState(false);
  const [btnNewGrapeDisabled, setBtnNewGrapeDisabled] = useState(true);
  const [showMergeGrapes, setShowMergeGrapes] = useState(false);
  const [btnMergeGrapesDisabled, setBtnMergeGrapesDisabled] = useState(true);
  const [mergeGrape, setMergeGrape] = useState<OptionType | null>(null);

  const searchGrapes = (inputText: string, callback: (options: OptionType[]) => void): void => {
    if (!inputText) {
      return callback([]);
    }
    if (inputText.length < 2) {
      return callback([]);
    }
    setLoadingMainGrape(true);

    axios.post('/api/grapes/search', {q: inputText}, {
      ...axiosConfig
    }).then((response) => {
      let data: TGrapes[] = response.data.grapes;
      setLoadingMainGrape(false);
      if (data.length) {
        return callback(data.map((result) => {
          return labelFormatter(result);
        }))
      } else {
        return callback([]);
      }
    }).catch(error => {
      console.log(error.toString());
    });
  };

  const labelFormatter = (i: TGrapes): OptionType => {
    return {
      label: i.name,
      value: i.id.toString(),
    }
  }

  const saveEditGrape = () => {
    if (editGrape) {
      axios.post('/api/grapes/update', editGrape, {
        ...axiosConfig
      }).then(r => {
        let result = r.data;
        if (result.hasOwnProperty('error')) {
          toast.error(result.error);
          return false;
        }
        if (result.hasOwnProperty('success')) {
          toast.success(result.msg);

          if (selectedGrape) {
            let obj = Object.assign({}, selectedGrape);
            obj.label = editGrape.label;
            setSelectedGrape(obj);
          }

          return true;
        }
      }).catch(error => {
        console.log(error.toString());
      });
    }
  }

  const saveNewGrape = () => {
    setBtnNewGrapeDisabled(true);
    if (showNewGrape && newGrape) {
      axios.post('/api/grapes/save', {name: newGrape}, {
        ...axiosConfig
      }).then(r => {
        setBtnNewGrapeDisabled(false);
        let result = r.data;
        if (result.hasOwnProperty('error')) {
          toast.error(result.error);
          return false;
        }
        if (result.hasOwnProperty('success')) {
          toast.success(result.msg);
          return true;
        }
      }).catch(error => {
        console.log(error.toString());
      })
    }
  }

  const saveMergeGrapes = () => {

    setBtnMergeGrapesDisabled(true);
    if (selectedGrape && mergeGrape && showMergeGrapes) {
      axios.post('/api/grapes/merge', {
        to: parseInt(mergeGrape.value),
        from: parseInt(selectedGrape.value)
      }, {
        ...axiosConfig
      }).then(r => {
        setBtnMergeGrapesDisabled(false);
        let result = r.data;
        if (result.hasOwnProperty('error')) {
          toast.error(result.error);
          return false;
        }

        if (result.hasOwnProperty('success')) {
          setMergeGrape(null);
          setBtnMergeGrapesDisabled(true);
          setShowMergeGrapes(false);
          setSelectedGrape(null);
          setDisabledActions(true);

          toast.success(result.msg);
          return true;
        }
      }).catch(error => {
        console.log(error.toString());
      })
    }
  }

  return (
    <div className="App">
      <div className="w-100">
        <Container fluid>
          <div className="row">
            <div className="col-md-12">
              <h4 className="mb-2 text-start">Grapes list</h4>
              <hr/>
            </div>
            <div className="col-md-12">
              <AlertError error={error} callback={() => setError('')}/>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label>Grape</label>
                    <Async isClearable={true} isLoading={loadingMainGrape} loadOptions={searchGrapes}
                           placeholder={"Insert some text..."} value={selectedGrape}
                           onChange={(item) => {
                             setSelectedGrape(item);
                             setDisabledActions(false);
                             if (item === null) {
                               setDisabledActions(true);
                               setEditGrape(null);
                             }
                           }}/>
                  </div>
                </div>
                <div className="col-md-6 d-flex align-items-center justify-content-between">
                  <button className="btn btn-outline-custom" disabled={disabledActions} onClick={() => {
                    let data = Object.assign({}, selectedGrape);
                    setEditGrape(data);
                    setShowNewGrape(false);
                    setNewGrape('');
                    setShowMergeGrapes(false);
                    setMergeGrape(null);
                    setBtnMergeGrapesDisabled(true);
                  }}><Pencil/> Edit
                  </button>
                  <button className="btn btn-outline-custom" disabled={disabledActions} onClick={() => {
                    setShowMergeGrapes(true);
                    setShowNewGrape(false);
                    setNewGrape('');
                    setEditGrape(null);
                  }}><ArrowsCollapse/> Merge
                  </button>
                  <button className="btn btn-custom" onClick={() => {
                    setShowNewGrape(true);
                    setEditGrape(null);
                    setNewGrape('');
                    setShowMergeGrapes(false);
                    setMergeGrape(null);
                    setBtnMergeGrapesDisabled(true);
                  }}><Plus/> New
                  </button>
                </div>
              </div>
              {editGrape ?
                <div className="row">
                  <div className="col-md-12">
                    <fieldset className="mt-4 styled">
                      <legend className="d-flex align-items-center mb-4">
                        <Pencil className="mr-2"/> Edit grape #{editGrape.value}</legend>
                      <div className="form-group">
                        <label>Name</label><br/>
                        <input type="text" defaultValue={editGrape.label} className="custom-input-text w-100"
                               placeholder="Grape name..."
                               onChange={(e) => {
                                 let data = Object.assign({}, editGrape);
                                 data.label = e.target.value
                                 setEditGrape(data);
                               }}
                        />
                        <div className="d-flex justify-content-center mt-3">
                          <button className="btn btn-custom" onClick={saveEditGrape}
                                  disabled={!!(selectedGrape && editGrape.label === selectedGrape.label)}>
                            <Save/> Save
                          </button>
                          <button className="btn btn-outline-custom inherit ml-3"
                                  onClick={() => {
                                    setEditGrape(null);
                                  }}><XSquareFill/> Close
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                : ''}
              {showNewGrape ?
                <div className="row">
                  <div className="col-md-12">
                    <fieldset className="mt-4 styled">
                      <legend className="d-flex align-items-center mb-4">
                        <Plus className="mr-2"/> New grape
                      </legend>
                      <div className="form-group">
                        <label>Name</label><br/>
                        <input type="text" defaultValue={newGrape} className="custom-input-text w-100"
                               placeholder="Grape name..."
                               onChange={(e) => {
                                 setNewGrape(e.target.value);
                                 setBtnNewGrapeDisabled(!e.target.value)
                               }}
                        />
                        <div className="d-flex justify-content-center mt-3">
                          <button className="btn btn-custom" onClick={saveNewGrape} disabled={btnNewGrapeDisabled}>
                            <Save/> Create
                          </button>
                          <button className="btn btn-outline-custom inherit ml-3"
                                  onClick={() => {
                                    setNewGrape('');
                                    setShowNewGrape(false);
                                  }}><XSquareFill/> Close
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                : ''
              }
              {showMergeGrapes ?
                <div className="row">
                  <div className="col-md-12">
                    <fieldset className="mt-4 styled">
                      <legend className="d-flex align-items-center mb-4">
                        <ArrowsCollapse className="mr-2"/> Merge in
                      </legend>
                      <div className="form-group">
                        <label>Grape</label> <br/>
                        <Async isClearable={true} isLoading={loadingMergeGrape} loadOptions={searchGrapes}
                               styles={customStyles}
                               onChange={(item) => {
                                 setMergeGrape(item)
                                 setBtnMergeGrapesDisabled(false);
                               }
                               }/>
                        <div className="alert alert-info mt-3">
                          After merge the main grape will be deleted.
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                          <button className="btn btn-custom" onClick={saveMergeGrapes}
                                  disabled={btnMergeGrapesDisabled}>
                            <Save/> Merge
                          </button>
                          <button className="btn btn-outline-custom inherit ml-3"
                                  onClick={() => {
                                    setShowMergeGrapes(false);
                                    setMergeGrape(null);
                                    setBtnMergeGrapesDisabled(true);
                                  }}><XSquareFill/> Close
                          </button>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                : ''}
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Grapes;