import React, {useContext, useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import axios from "axios";
import * as qs from 'query-string';
import { Funnel, Robot } from "react-bootstrap-icons";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { toast } from "react-toastify";
import AutomatchConfirmButton from "../components/AutomatchConfirmButton";
import AutomatchFilter from "../components/AutomatchFilter";
import BsModal from "../components/BsModal";
import Preloader from "../components/Preloader";
import {useHistory, useLocation} from "react-router";
import "../App";
import WinesAutomatchTable from "../components/WinesAutomatchTable";
import WinesMatchTable from "../components/WinesMatchTable";
import { TCompetitions, TCountries, TErrorObject, TEvents, TUserWinesFilter, TPagination, TAutomatchUserFilter } from "../properties";
import { AlertError, hasPermissionTo } from "../helpers";
import {AuthUserContext} from "../context/AuthUser";
import WinesFilter from "../components/WinesFilter";

enum EWineStatuses {
    new = 'NEW',
    skipped = 'SKIPPED',
    reserved = 'RESERVED',
    escalated = 'ESCALATED',
    processed = 'PROCESSED',
    rejected = 'REJECTED',
    done = 'DONE'
}

export type TWines = {
    country: null | string;
    id: number,
    name: string,
    country_gustos: {
        name?: string
    },
    vineyard_gustos: {
        name?: string
    },
    winery?: number,
    status: string,
    matching?: boolean,
    created_at: string,
    parsed_file_row: {
        parsed_file: {
            competition_id: number,
            event_id: number,
            hash: string
        }
    },
    wine_in_progress: null | {
        wine_id: number,
    }
}

export type TAutoWines = {
    parsedwine: {
        id: number,
        name: string,
        winery: string,
        winery_id: number,
        year: number,
        color: string,
        country: string,
        co2: string,
    },
    gustoswine: {
        id: number,
        name: string,
        winery: string,
        winery_id: number,
        year: number,
        color: string,
        country: string,
        co2: string,
    },
    action: string,
    score: number,
}

type TFilter = {
    competition?: number,
    event?: number,
    country?: number,
    winery?: number,
    status?: string
}

function Wines() {

    const parsed = qs.parse(window.location.search);
    const history = useHistory();
    const location = useLocation();
    const authUser = useContext(AuthUserContext);
    let filterParams: TUserWinesFilter = {};

    const [wines, setWines] = useState<TWines[]>([]);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState<TPagination>(null);
    const [error, setError] = useState<string | TErrorObject>('');
    const [showPreloader, setShowPreloader] = useState(true);
    const [countries, setCountries] = useState<TCountries[]>([]);
    const [competitions, setCompetitions] = useState<TCompetitions[]>([]);
    const [events, setEvents] = useState<TEvents[]>([]);
    const [filter, setFilter] = useState<TFilter>({});
    const [userFilter, setUserFilter] = useState<TUserWinesFilter>({});
    const [isSubmitAutomatch, setIsSubmitAutomatch] = useState<boolean>(false);
    const [isSubmitAutomatchConfirm, setIsSubmitAutomatchConfirm] = useState<boolean>(false);
    const [autoWines, setAutoWines] = useState<TAutoWines[]>([]);
    const [automatchFilter, setAutomatchFilter] = useState<TAutomatchUserFilter>({});
    const [totalWines, setTotalWines] = useState<number>(0);
    const [totalAutoWines, setTotalAutoWines] = useState<number>(0);
    const [automatchRowsActions, setAutomatchRowsActions] = useState({});
    const [activeTab, setActiveTab] = useState('filter');
    const [showReautomachModal, setShowReautomachModal] = useState(false);

    const axiosConfig = {
        headers: {
            Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
        }
    };

    const ReautomachModalProps = {
        title: 'Automatch',
        hideModal: () => {
            setShowReautomachModal(false);
        },
        showModal: showReautomachModal,
        primaryButton: {
            title: 'Automatch',
            onClick: () => {
                setShowReautomachModal(false);
                submitAutomatchFilter();
            }
        }
    }

    const getWinesData = (to_page?: number) => {
        let currentPage = to_page ? to_page : (parsed.page ? parseInt(parsed.page as string) : 1),
            queryFilter = '';

        setPage(currentPage);
        setAutoWines([]);

        if (Object.keys(filter).length) {
            Object.entries(filter).forEach(([key, value]) => {
                if (value !== '') {
                    queryFilter += '&' + key + '=' + value;
                }
            });
        } else {
            if (location.search !== '') {
                const query = new URLSearchParams(location.search);
                if (query.get('winery')) {
                    queryFilter += '&winery=' + query.get('winery');
                }
                if (query.get('status')) {
                    queryFilter += '&status=' + query.get('status');
                }
                if (query.get('country')) {
                    queryFilter += '&country=' + query.get('country');
                }
            }
        }

        axios.get('/api/listing-wines?page=' + currentPage + queryFilter, {
            ...axiosConfig
        }).then(r => {
            let result = r.data;
            setError('');
            setShowPreloader(false);

            if (result.hasOwnProperty('user_filter')) {
                filterParams = result.user_filter;
                setUserFilter(result.user_filter);
            }

            if (result.hasOwnProperty('wines')) {
                if (result.wines.hasOwnProperty('data')) {
                    if (result.wines.data) {
                        let wines = result.wines;
                        for (let i in wines.data) {
                            wines.data[i].matching = criteriaBtnMatching(filterParams, wines.data[i]) || false;
                        }
                        setWines(wines.data);

                        let paginationData = {
                            'current_page': wines.current_page,
                            'from': wines.from,
                            'last_page': wines.last_page,
                            'first_page_url': wines.first_page_url,
                            'last_page_url': wines.last_page_url,
                            'next_page_url': wines.next_page_url,
                            'path': wines.path,
                            'per_page': wines.per_page,
                            'prev_page_url': wines.prev_page_url,
                            'to': wines.to,
                            'total': wines.total
                        };

                        setPagination(paginationData);
                        setTotalWines(paginationData.total);
                    }
                }
            }

            if (result.hasOwnProperty('third_party_properties')) {
                if (result.third_party_properties.hasOwnProperty('countries') && result.third_party_properties.countries) {
                    setCountries(result.third_party_properties.countries);
                }
                if (result.third_party_properties.hasOwnProperty('competitions') && result.third_party_properties.competitions) {
                    setCompetitions(result.third_party_properties.competitions);
                }
                if (result.third_party_properties.hasOwnProperty('events') && result.third_party_properties.events) {
                    setEvents(result.third_party_properties.events);
                }
            }

        }).catch(r => {
            if (r.response.status === 401) {
                authUser.setAuthUser(null);
                localStorage.removeItem('auth_user');
            }
            setError('Failed on fetch wines data');
        }).finally(() => {
            if (isSubmitAutomatchConfirm) {
                setIsSubmitAutomatchConfirm(false);
                setShowReautomachModal(true);
            }
        });
    }

    const criteriaBtnMatching = (filterParams: TUserWinesFilter, item: TWines) => {
        let count = 0,
            filterCount = Object.keys(filterParams).length;

        if (item.wine_in_progress !== null) {
            return false;
        }
        if (item.status === EWineStatuses.done) {
            return false;
        }
        if ([EWineStatuses.escalated, EWineStatuses.skipped].indexOf(item.status as EWineStatuses) !== -1 && authUser.authUser && !authUser.authUser.is_admin) {
            return false;
        }

        if (filterCount) {
            if (filterParams.country) {
                if (item.country && filterParams.country.value === parseInt(item.country)) {
                    count += 1;
                } else if (!item.country && filterParams.country.value === -1) {
                    count += 1;
                } else if (item.country && filterParams.country.value === 0) {
                    count += 1;
                }
            }
            if (filterParams.competition && filterParams.competition.value === item.parsed_file_row.parsed_file.competition_id) {
                count += 1;
            }

            if (filterParams.event_year && Array.isArray(filterParams.event_year.value) && filterParams.event_year.value.indexOf(item.parsed_file_row.parsed_file.event_id) !== -1) {
                count += 1;
            }

            if (filterParams.winery && item.winery && filterParams.winery.value === item.winery) {
                count += 1;
            }

            if (filterParams.status && item.status && filterParams.status.value.toLowerCase() === item.status.toLowerCase()) {
                count += 1;
            }

            if (filterParams.nameLike && filterParams.nameLike.value !== "") {
                count += 1;
            }

            if (count === filterCount) {
                return true;
            }
        }

        return false;
    }

    const buildPage = (pageNumber: number) => {
        if (page === pageNumber) {
            return false;
        }

        setPage(pageNumber);

        const queryParams = new URLSearchParams(location.search);
        let queryData = '?page=' + pageNumber;

        Array.from(queryParams.keys()).forEach((k, i) => {
            if (k !== 'page') {
                queryData += '&' + k + '=' + queryParams.get(k);
            }
        });

        // * update url history
        history.replace({
            pathname: location.pathname,
            search: queryData
        });
    };

    const getWinesByPage = (pageNumber: number) => {
        buildPage(pageNumber);

        // * get  wines
        getWinesData(pageNumber);
    }

    const filterData = (data: object) => {
        setFilter({...filter, ...data})
    }

    const submitFilter = () => {
        setWines([]);
        setAutoWines([]);
        setIsSubmitAutomatch(false);
        setShowPreloader(true);
        getWinesByPage(1);
    }

    const saveUserFilter = () => {
        getWinesByPage(1);
    }

    const resetFilterData = () => {
        setUserFilter({});
        getWinesByPage(1);
    }

    const onClearReservedWines = (wine_id?: number) => {
        if (wines.length) {
            wines.map((wine: TWines) => {
                if (wine.wine_in_progress !== null) {
                    wine.wine_in_progress = null;
                    wine.matching = true;
                }
                return wine;
            });

            setWines([...wines]);
        }
    };

    const canAutomatch = () => {
        return !!(Object.keys(userFilter).length && wines.length && authUser.authUser !== null && hasPermissionTo(authUser, "wines match") && userFilter.status && userFilter.status.value == EWineStatuses.new.toLowerCase());
    };

    const submitAutomatchFilter = () => {
        setAutoWines([]);
        setAutomatchRowsActions({});
        setShowPreloader(true);
        setIsSubmitAutomatch(true);
        getAutoMatchWinesByPage(1);
    };

    const confirmAutomatch = () => {
        if (!window.confirm('Are you sure?')) {
            return;
        }

        setIsSubmitAutomatchConfirm(true);
    };

    const filterAutomatchData = (data: object) => {
        setAutomatchFilter({...automatchFilter, ...data})
    }

    const fillAutomatchRowsActions = (wine_id: number, action: string) => {
        setAutomatchRowsActions({...automatchRowsActions, [wine_id]: action});
    }

    const getAutomatchWines = (to_page?: number) => {
        let currentPage = to_page ? to_page : (parsed.page ? parseInt(parsed.page as string) : 1),
          queryFilter = '';

        setPage(currentPage);

        if (Object.keys(automatchFilter).length) {
            Object.entries(automatchFilter).forEach(([key, value]) => {
                if (typeof value !== "undefined") {
                    queryFilter += '&' + key + '=' + value;
                }
            });
        }

        axios.get('/api/listing-automatches?page=' + currentPage + queryFilter, {
            ...axiosConfig
        }).then(r => {
            let result = r.data;
            setError('');
            setShowPreloader(false);

            if (result.hasOwnProperty('wines')) {
                if (result.wines.hasOwnProperty('data')) {
                    if (result.wines.data) {
                        let wines = result.wines;
                        setAutoWines(wines.data);

                        let paginationData = {
                            'current_page': wines.current_page,
                            'from': wines.from,
                            'last_page': wines.last_page,
                            'first_page_url': wines.first_page_url,
                            'last_page_url': wines.last_page_url,
                            'next_page_url': wines.next_page_url,
                            'path': wines.path,
                            'per_page': wines.per_page,
                            'prev_page_url': wines.prev_page_url,
                            'to': wines.to,
                            'total': wines.total
                        };

                        setPagination(paginationData);
                        setTotalAutoWines(paginationData.total);
                    }
                }
            }
        });
    };

    const getAutoMatchWinesByPage = (pageNumber: number) => {
        buildPage(pageNumber);
        getAutomatchWines(pageNumber);
    }

    useEffect(() => {
        if (location.search === '' || history.action === "POP") {
            getWinesData();
        }
    }, [location]);

    useEffect(() => {
        if (isSubmitAutomatchConfirm) {

            let data = {
                user_filter: userFilter,
                automatch_filter: automatchFilter,
                rows_actions: automatchRowsActions,
                wines: autoWines,
            };

            setAutoWines([]);
            setAutomatchRowsActions({});

            axios.post('/api/process-automatches', data, {
                ...axiosConfig
            }).then(r => {
                const result = r.data;

                if (result.hasOwnProperty('msg')) {
                    submitFilter();

                    toast.success(result.msg);
                    return true;
                }
            });
        }
    }, [isSubmitAutomatchConfirm]);

    return (
        <div className="App">
            <div className="w-100">
                <Container fluid>
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="mb-2 text-start">Wines {!Object.keys(userFilter).length && pagination !== null ? "(" + pagination.total + ")" : ''}</h4>
                            <hr/>
                        </div>
                        <div className="col-md-12">
                            <AlertError error={error} callback={() => setError('')}/>

                            <Tabs
                              defaultActiveKey="filter"
                              className="mb-3"
                            >
                                <Tab eventKey={"filter"} title={<span><Funnel/>{" Filter"}</span>} onClick={() => setActiveTab('filter')}>
                                    {authUser.authUser !== null
                                      ?
                                      <WinesFilter
                                        countries={countries} competitions={competitions} events={events}
                                        filterData={filterData}
                                        submitFilter={submitFilter} error={(msg) => setError(msg)}
                                        userFilter={userFilter} resetUserFilter={resetFilterData}
                                        totalWines={totalWines}
                                        onClearReservedWines={onClearReservedWines}
                                        saveUserFilter={saveUserFilter}
                                      />
                                      : ""
                                    }
                                </Tab>
                                {canAutomatch() &&
                                  <Tab eventKey={"automatch"} title={<span><Robot/>{" Automatch"}</span>} onClick={() => setActiveTab('automatch')}>
                                      <AutomatchFilter
                                        filterData={filterAutomatchData}
                                        onSubmitFilter={submitAutomatchFilter}
                                        filter={automatchFilter}
                                        maxBatch={totalWines}
                                        onConfirmAutomatch={confirmAutomatch}
                                        winesExist={autoWines.length > 0}
                                      />
                                  </Tab>
                                }
                            </Tabs>

                            {isSubmitAutomatch ? (
                              autoWines.length ?
                                <WinesAutomatchTable wines={autoWines} pagination={pagination} onChangePage={getAutoMatchWinesByPage} onChangeRowAction={fillAutomatchRowsActions}/>
                                : (!showPreloader
                                    ? 'No data.'
                                    : <Preloader hide={!showPreloader}/>
                                )
                            ): (
                              wines.length ? (
                                <WinesMatchTable wines={wines} pagination={pagination} onChangePage={getWinesByPage}/>
                              ) : (!showPreloader
                                ? 'No data.'
                                : <Preloader hide={!showPreloader}/>
                              )
                            )}

                            {isSubmitAutomatch && autoWines.length > 0 && (
                              <div className="d-flex flex-row-reverse">
                                <AutomatchConfirmButton winesExist={autoWines.length > 0} onConfirmAutomatch={confirmAutomatch} className="mb-5"/>
                              </div>
                            )}
                            {showReautomachModal && (
                              <BsModal {...ReautomachModalProps}>
                                  <div className="form-group mb-2">
                                      <label>Automatch another rows</label>
                                      <input
                                        type="number" className="form-control mt-2"
                                        placeholder={`Number of wines. Max ${totalWines} records.`} min={1} max={totalWines}
                                        value={automatchFilter.batch}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setAutomatchFilter({...automatchFilter, batch: value ? parseInt(value) : undefined})
                                        }}
                                      />
                                  </div>
                              </BsModal>
                            )}
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Wines;
