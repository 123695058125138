import React, {useContext, useState} from "react";
import {TFormData} from "../pages/DataMatching";
import {capitalize, customStyles} from "../helpers";
import Select, {SingleValue} from 'react-select';
import {EWineProperties, TFields, TTaxonomy} from "../properties";
import Async from "react-select/async";
import axios from "axios";
import {AuthUserContext} from "../context/AuthUser";

type TTaxonomySettings = {
    form_input?: string,
    taxonomy_vocabulary?: string,
    option_values: {} | null
}

type Props = {
    taxonomy: TTaxonomySettings,
    item: TFields,
    taxonomy_properties: TTaxonomy,
    form_fields: TFormData[],
    onChange: (e: string, property: string) => void
}

type OptionType = {
    value: string;
    label: string;
};

type TWinery = {
    id: string,
    name: string,
    country?: string
};

const TaxonomyInput = (props: Props) => {

    const authUser = useContext(AuthUserContext);
    const [vineyardValue, setVineyardValue] = useState<SingleValue<OptionType | null>>(null);

    let form_field = props.form_fields.find(item => item.property === props.item.property),
        selectedValue = form_field ? form_field.content : '',
        disabled = false;

    if (props.item.value === '' && !props.item.parent_value) {
        return (
            <></>
        )
    }

    if (props.item.parent_dependency) {
        let dependency_property = props.form_fields.find(item => item.property === props.item.parent_dependency);
        if (dependency_property && dependency_property.content === '') {
            disabled = true;
            if (props.taxonomy.option_values) {
                props.taxonomy.option_values = null;
            }
        }
    } else {
        disabled = props.item.disabled;

        if (props.item.property === 'VINEYARD') {
            if (!selectedValue && vineyardValue) {
                setVineyardValue(null);
            }
            if (selectedValue) {
                if (props.taxonomy_properties.hasOwnProperty(EWineProperties.vineyard)) {
                    if (props.taxonomy_properties[EWineProperties.vineyard].option_values) {
                        let data = props.taxonomy_properties[EWineProperties.vineyard].option_values;
                        if (typeof data === "object" && data !== null) {
                            if (data.hasOwnProperty(selectedValue) && data[parseInt(selectedValue)] && !vineyardValue) {
                                let name = data[parseInt(selectedValue)];
                                setVineyardValue({value: selectedValue, label: name});
                            }
                        }
                    }
                }
            }
        }
    }

    const labelFormatter = (i: TWinery): OptionType => {
        return {
            label: i.name + (i.country ? ' / ' + i.country : ''),
            value: i.id,
        }
    }

    const loadOptions = (
        inputText: string,
        callback: (options: OptionType[]) => void
    ): void => {
        if (!inputText) {
            setVineyardValue(null);
            return callback([]);
        }
        if (inputText.length < 2) {
            setVineyardValue(null);
            return callback([]);
        }

        axios.post('/api/search-winery', {q: inputText}, {
            headers: {
                Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
            }
        }).then((response) => {
            let data: TWinery[] = response.data.wineries;
            if (data.length) {
                return callback(data.map((result) => {
                    return labelFormatter(result);
                }))
            } else {
                setVineyardValue(null);
                return callback([]);
            }
        });
    };

    switch (props.taxonomy.form_input) {
        case 'dropdown':
        case 'boolean':
            let optionValues: OptionType[] = [];
            if (props.taxonomy.option_values) {
                if (!props.item.parent_value && !props.item.parent_dependency) {
                    optionValues.push({value: 'EMPTY', label: 'EMPTY VALUE'})
                }
                Object.entries(props.taxonomy.option_values).forEach(([key, value]) => {
                    optionValues.push({value: key, label: value} as OptionType);
                })

                optionValues.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
            }
            return (
                <div>
                    {props.item.property === 'VINEYARD'
                        ? (<>
                            <Async loadOptions={loadOptions}
                                   placeholder={"Insert some text..."}
                                   className={"w-157p mr-1"}
                                   classNamePrefix="react-select"
                                   styles={customStyles}
                                   isDisabled={disabled}
                                   value={vineyardValue}
                                   onChange={(item) => {
                                       setVineyardValue(item);
                                       props.onChange(item ? item.value.toString() : '', props.item.property)
                                   }}/></>)

                        : <Select
                            value={selectedValue ? optionValues.find(option => parseInt(option.value) === parseInt(selectedValue)) : null}
                            options={optionValues} onChange={item => {
                            props.onChange(item ? item.value : '', props.item.property)
                        }}
                            className={props.item.property === 'REGION' ? "w-157p mr-1" : "w-200p mr-3"}
                            classNamePrefix="react-select" styles={customStyles}
                            placeholder={"- Select " + capitalize(props.item.property.replace('_', ' ')) + " -"}
                            isDisabled={disabled}
                        />
                    }
                </div>
            )
        case 'number':
        case 'float':
            return (
                <div>
                    <input type="number" className="w-200p" value={selectedValue}
                           onWheel={event => event.currentTarget.blur()}
                           onChange={(e) => props.onChange(e.target.value, props.item.property)}/>
                </div>
            )
        case 'text':
            return (
                <div>
                    <input type="text" className="w-200p" value={selectedValue}
                           onChange={(e) => props.onChange(e.target.value, props.item.property)}/>
                </div>
            )
        case 'checkbox':
            return (
                <div>
                    <input type="checkbox"/>
                </div>
            )
        default:
            return (
                <>
                </>
            )
    }
}

export default TaxonomyInput;