import { EWineProperties } from "../properties";

type Props = {
    rule: {
        value: string,
        parent_label?: string,
        property?: EWineProperties
    },
    taxonomy: TTaxonomySettings | null
}


type TTaxonomySettings = {
    form_input?: string,
    taxonomy_vocabulary?: string,
    option_values: {} | null
}

const TaxonomyValue = (props: Props) => {
    let value = props.rule.value;

    if (props.taxonomy !== null && props.taxonomy.option_values) {
        let filtered = Object.entries(props.taxonomy.option_values).filter(([key, value]) => {
            return key === props.rule.value;
        }) as Array<string[]>;

        if (filtered.length) {
            value = filtered[0][filtered.length];
        }
    }

    // Append region country.
    if (props.rule.parent_label && props.rule.property === EWineProperties.region && value !== "EMPTY") {
        value += " (" + props.rule.parent_label + ")";
    }

    return (
        <>
            {value === 'EMPTY'
                ? <em>{value}</em>
                : value
            }
        </>
    )
}

export default TaxonomyValue;