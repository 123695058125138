import axios from "axios";
import React, { useContext, useState } from "react";
import { ClockHistory, Pencil, PlusSquare, Save, XSquare } from "react-bootstrap-icons";
import Select from "react-select";
import Async from "react-select/async";
import { toast } from "react-toastify";
import { AuthUserContext } from "../context/AuthUser";
import { customStyles } from "../helpers";
import { TWineObject } from "../pages/NameMatching";
import { CUSTOM_INPUTS, EditHistoryRow, EWineProperties, initialWineProperty, TTaxonomy } from "../properties";

type TProps = {
    item: EWineProperties,
    wine: TWineObject | string,
    showEditInput: string[],
    setShowEditInput: React.Dispatch<React.SetStateAction<string[]>>,
    onChange: (key: EWineProperties, item: { label: string, value: string }, restore?: boolean) => void,
    allow_edit_text: EWineProperties[],
    taxonomy_properties: TTaxonomy | undefined,
    wineData: {
        [key in EWineProperties]: TWineObject
    },
    edit_history?: EditHistoryRow
    onCancel?: (key: EWineProperties) => void
}

type TSelectOptions = {
    [key: string]: OptionType[]
}

type TSelectedValue = {
    [key: string]: {
        label: string,
        value: string,
    }
}

type OptionType = {
    value: number | string,
    label: string,
    weight: number
}

type TWinery = {
    id: number,
    name: string,
    country?: string
}

type TGrape = {
    grape: OptionType,
    percent: number | string
}

type TCalculateGrapes = {
    row: number,
    percent: number
    fixed: boolean
}

enum CalculatorGrapesAction {
    ADD = "add",
    REMOVE = "remove"
}

const EditMatchingField = (props: TProps) => {
    const authUser = useContext(AuthUserContext);
    const [selectOptions, setSelectOptions] = useState<TSelectOptions>();
    const [selectedValue, setSelectedValue] = useState<TSelectedValue>();
    const [vineyardValue, setVineyardValue] = useState<OptionType | null>(null);
    const [taxonomy, setTaxonomy] = useState<TSelectOptions>();

    const defaultGrapes: TGrape = {grape: {label: '', value: '', weight: 0}, percent: ''};
    const defaultCalculateGrapes: TCalculateGrapes = {row: 0, percent: 100, fixed: false};
    const [grapesItems, setGrapesItems] = useState<TGrape[]>([defaultGrapes]);
    const [calculatePercentGrapes, setCalculatePercentGrapes] = useState<TCalculateGrapes[]>([defaultCalculateGrapes]);

    const editField = (key: EWineProperties) => {
        if (props.allow_edit_text.indexOf(key) === -1) {
            return false;
        }

        if (props.showEditInput.indexOf(key) === -1) {
            let list = props.showEditInput;
            list.push(key);
            props.setShowEditInput(list.map(f => f));
        }

        // * try to autocomplete grapes
        if (key === EWineProperties.grape_type) {
            // grapes format - "grape_type:percent%" (Cabernet:100%)
            if (props.wine && typeof props.wine === "object" && props.wine.label !== '' && props.wine.value !== '' && (props.wine.label.match(/:/g) || []).length) {
                let grapeLabels = props.wine.label.split(','),
                    grapeValues = props.wine.value.toString().split(','),
                    grapesList: TGrape[] = [];

                grapeLabels.forEach((item, index) => {
                    let data = item.split(':');

                    if (grapeValues.hasOwnProperty(index)) {
                        grapesList.push({
                            grape: {label: data[0], value: grapeValues[index], weight: 0},
                            percent: data[1].replace('%', '').trim()
                        })
                    }
                });
                if (grapesList.length) {
                    setGrapesItems(grapesList);
                    setCalculatePercentGrapes(grapesList.map((item, index) => {
                        return {row: index, percent: Number(item.percent), fixed: true} as TCalculateGrapes;
                    }));
                } else {
                    setCalculatePercentGrapes([defaultCalculateGrapes]);
                }
            }
            else {
                setCalculatePercentGrapes([defaultCalculateGrapes]);
            }
        }
    }

    const closeEditRow = (key: EWineProperties) => {
        if (props.allow_edit_text.indexOf(key) !== -1) {

            if (key === EWineProperties.grape_type) {
                if (grapesItems.length) {
                    let grapesLabel = '',
                      grapesValue = '',
                      sumPercentage = 0;

                    grapesItems.forEach((option, index) => {
                        if (option.grape.value !== '') {

                            if (grapesLabel !== '') {
                                grapesLabel += ', ';
                                grapesValue += ',';
                            }

                            // Append auto-calculated percent to the grape.
                            if (option.percent === "") {
                                const calculatePercentGrape = calculatePercentGrapes.find((elem) => elem.row === index)?.percent;
                                if (calculatePercentGrape) {
                                    option.percent = calculatePercentGrape;
                                }
                            }

                            if (option.percent !== "") {
                                grapesLabel += option.grape.label + ": " + option.percent + "%";
                                grapesValue += option.grape.value;
                                sumPercentage += parseInt(String(option.percent as number));
                            }
                        }
                    });

                    if (grapesLabel !== '' && grapesValue !== '') {

                        if (typeof props.wine === "object") {

                            let oldGrapesData: string[] = [];

                            if (props.wine.label !== "" && props.wine.value !== "") {
                                let oldGrapesPercent = props.wine.label.split(",").map((item, index) => {
                                    return item.split(":")[1].replace(/\D/g, "");
                                });
                                let oldGrapesValue = props.wine.value.toString().split(",");

                                oldGrapesValue.forEach((item, index) => {
                                    oldGrapesData.push(item + ":" + oldGrapesPercent[index].trim());
                                });
                                oldGrapesData = oldGrapesData.sort();
                            }

                            let newGrapesData: string[] = [];
                            grapesValue.split(",").forEach((item, index) => {
                                newGrapesData.push(item + ":" + grapesItems[index].percent);
                            });
                            newGrapesData = newGrapesData.sort();
                            if (oldGrapesData.join(",") == newGrapesData.join(",")) {
                                if (typeof props.onCancel === "function") {
                                    props.onCancel(key);
                                }
                                return false;
                            }
                        }

                        if (sumPercentage > 100) {
                            toast.error("Total grapes percentage can't be more than 100%.");
                            return false;
                        }
                        props.onChange(key, {label: grapesLabel, value: grapesValue});
                    }

                    setGrapesItems([defaultGrapes]);
                }
                else {
                    props.onChange(key, {label: "", value: ""});
                }
            }

            const list = props.showEditInput.filter(item => item !== key);
            props.setShowEditInput(list.map(f => f));

            if (selectedValue && selectedValue.hasOwnProperty(key)) {
                props.onChange(key, selectedValue[key]);
            }

            setSelectedValue(undefined);
            setSelectOptions(undefined);
        }
    }

    const getTaxonomyProperty = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, property: EWineProperties) => {
        event.preventDefault();

        if ([EWineProperties.name, EWineProperties.year, EWineProperties.url_image].indexOf(property) !== -1) {
            editField(property);
            return true;
        }

        setSelectOptions(undefined);

        let query = '?property=' + property;
        if (property === EWineProperties.region && props.wineData[EWineProperties.country]) {
            query += '&country=' + props.wineData[EWineProperties.country].value;
        }
        axios.get('/api/taxonomy-property' + query, {
            headers: {
                Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
            }
        }).then(r => {
            let result = r.data;

            if (result.hasOwnProperty('err')) {
                console.log(result.err);
                return false;
            }

            if (result.hasOwnProperty('taxonomy')) {
                let taxonomy = result.taxonomy;
                if (taxonomy.hasOwnProperty(props.item)) {
                    taxonomy[props.item].sort((a: { label: number | string; }, b: { label: number | string; }) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
                }
                setTaxonomy(taxonomy);
                setSelectOptions(taxonomy);
            }

            editField(property);
        });
    }

    const handleKeyPress = (event: { key: string; }, key: EWineProperties) => {
        if (event.key === 'Enter') {
            closeEditRow(key);
        }
    }

    const labelFormatter = (i: TWinery): OptionType => {
        return {
            label: i.name + (i.country ? ' / ' + i.country : ''),
            value: i.id,
            weight: 0
        }
    }

    const loadOptions = (
        inputText: string,
        callback: (options: OptionType[]) => void
    ): void => {
        if (!inputText) {
            setVineyardValue(null);
            return callback([]);
        }
        if (inputText.length < 2) {
            setVineyardValue(null);
            return callback([]);
        }

        axios.post('/api/search-winery', {q: inputText}, {
            headers: {
                Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
            }
        }).then((response) => {
            let data: TWinery[] = response.data.wineries;
            if (data.length) {
                return callback(data.map((result) => {
                    return labelFormatter(result);
                }))
            } else {
                setVineyardValue(null);
                return callback([]);
            }
        });
    }

    const addGrapeItem = () => {

        let percent = calculateEqualGrapesPercentage(CalculatorGrapesAction.ADD);

        grapesItems.push(defaultGrapes);
        setGrapesItems(grapesItems.map(f => f));

        calculatePercentGrapes.push({row: grapesItems.length - 1, percent: percent, fixed: false});
        setCalculatePercentGrapes(calculatePercentGrapes.map(f => f));
    }

    const calculateEqualGrapesPercentage = (action: CalculatorGrapesAction, currentIndex?: number, currentValue?: number) => {

        // Separate percent equally per each grape.
        let editable = calculatePercentGrapes.filter((elem, index) => !elem.fixed && currentIndex != index);
        let fixedPercent = calculatePercentGrapes.filter((elem, index) => elem.fixed && currentIndex != index).reduce((a, b) => a + b.percent, 0);
        let maxPercent = 100 - fixedPercent;
        let length = action === CalculatorGrapesAction.ADD ? (editable.length + 1) : editable.length ;
        let percent = Math.floor(maxPercent / length);

        if (editable.length !== 0 && currentValue !== undefined) {
            maxPercent = 100 - currentValue - fixedPercent;
            length = currentValue === 0 ? editable.length + 1 : editable.length;
            percent = Math.floor(maxPercent / length);
        }

        let totalForCalculate = 0;
        calculatePercentGrapes.forEach((item, index) => {
            if (currentIndex == index) {
                if (action === CalculatorGrapesAction.REMOVE) {
                    delete calculatePercentGrapes[index];
                } else {
                    item.percent = currentValue ? currentValue : percent;
                    item.fixed = currentValue !== 0;
                }
            } else {
                if (!item.fixed) {
                    item.percent = percent;
                }
            }
            totalForCalculate += item.percent;
        });

        if (currentValue == undefined) {
            totalForCalculate += percent;
        }

        // Append remain percent to the last editable item.
        let totalForFixed = maxPercent + (currentValue ? currentValue : 0) + fixedPercent;
        if (totalForCalculate < totalForFixed) {
            let remain = totalForFixed - totalForCalculate;
            editable.forEach((item, index) => {
                if (remain > 0) {
                    item.percent += 1;
                    remain -= 1;
                }
            });
        }

        return percent;
    }

    const removeGrapeItem = (i: number) => {
        let items = grapesItems.filter((elem, index) => index !== i);
        setGrapesItems(items.map(f => f));

        let rows = calculatePercentGrapes.filter((elem, index) => index !== i);
        // Reset row ids.
        rows.forEach((item, index) => {
            item.row = index;
        });
        setCalculatePercentGrapes(rows.map(f => f));

        calculateEqualGrapesPercentage(CalculatorGrapesAction.REMOVE, i);
    }

    const restoreFieldOldValue = (oldValue: string | undefined, oldLabel: string | undefined, key: EWineProperties) => {
        if (oldValue !== undefined && oldLabel !== undefined) {
            props.onChange(key, {label: oldLabel, value: oldValue}, true);
        }
    }

    const filterAndSort = (arr: OptionType[], term: string): OptionType[] => {
        const lowerCaseSearchTerm = term.toLowerCase();

        // Фильтрация массива по поисковому термину
        const filteredOptions = arr.filter(item => item.label.toLowerCase().includes(lowerCaseSearchTerm));

        // Сортировка по убыванию weight и по алфавиту label
        return filteredOptions.sort((a, b) => {
            if (a.weight !== b.weight) {
                return a.weight - b.weight;
            } else {
                return a.label.localeCompare(b.label);
            }
        });
    }

    const renderInputValue = () => {
        let property = initialWineProperty.find(item => item.key === props.item),
            defaultValue: OptionType | null = null;

        if (selectedValue && selectedValue[props.item]) {
            defaultValue = selectedValue[props.item] as OptionType;
        } else if (props.wine && typeof props.wine === "object" && props.wine.value && props.wine.value !== '') {
            defaultValue = {label: props.wine.label, value: props.wine.value} as OptionType;
        }

        if (property && property.custom_input) {
            switch (property.custom_input) {
                case CUSTOM_INPUTS.Text:
                    return (
                        <>
                            <input type="text"
                                   value={selectedValue && selectedValue.hasOwnProperty(props.item) ? selectedValue[props.item].value : (props.wine && typeof props.wine === "object" ? props.wine.value : '')}
                                   onChange={(e) => {
                                       let items = Object.assign({}, selectedValue);
                                       items[props.item] = {label: e.target.value, value: e.target.value};
                                       setSelectedValue(items);
                                   }}
                                   onKeyPress={(e) => handleKeyPress(e, props.item)}
                                   className={"editable-field " + (props.showEditInput.indexOf(props.item) === -1 ? ' d-none' : '')}/>
                        </>
                    );
                case CUSTOM_INPUTS.Number:
                case CUSTOM_INPUTS.Float:
                    return (
                        <>
                            <input type="number"
                                   defaultValue={props.wine && typeof props.wine === "object" ? props.wine.value : ''}
                                   onChange={(e) => {
                                       let items = Object.assign({}, selectedValue),
                                           text = e.target.value;

                                       if (props.item === EWineProperties.alcohol) {
                                           text += '%';
                                       }
                                       if (props.item === EWineProperties.sugar) {
                                           text += ' g/L';
                                       }
                                       items[props.item] = {label: text, value: e.target.value};
                                       setSelectedValue(items);
                                   }}
                                   onKeyPress={(e) => handleKeyPress(e, props.item)}
                                   className={"editable-field " + (props.showEditInput.indexOf(props.item) === -1 ? ' d-none' : '')}/>
                        </>
                    );
                case CUSTOM_INPUTS.Dropdown:
                    return (
                        <>
                            {props.item === EWineProperties.vineyard ?
                                <Async
                                    className={`editable-field ${props.showEditInput.indexOf(props.item) === -1 ? ' d-none' : ''}`}
                                    classNamePrefix="react-select"
                                    styles={customStyles}
                                    loadOptions={loadOptions}
                                    placeholder={"Insert some text..."}
                                    value={vineyardValue}
                                    onChange={(item) => {
                                        let items = Object.assign({}, selectedValue);
                                        if (item) {
                                            items[props.item] = {
                                                label: item.label,
                                                value: item.value.toString()
                                            };
                                        }
                                        setSelectedValue(items);
                                        setVineyardValue(item);
                                    }}
                                />
                                :
                                <Select
                                    className={"editable-field " + (props.showEditInput.indexOf(props.item) === -1 ? ' d-none' : '')}
                                    options={selectOptions && selectOptions.hasOwnProperty(props.item) ? selectOptions[props.item] : []}
                                    value={defaultValue}
                                    classNamePrefix="react-select" styles={customStyles}
                                    placeholder={'- Select value -'}
                                    onChange={(item) => {
                                        if (item) {
                                            let items = Object.assign({}, selectedValue);
                                            items[props.item] = {
                                                label: item.label,
                                                value: item.value.toString()
                                            };
                                            setSelectedValue(items);
                                        }
                                    }}

                                />
                            }
                        </>
                    );
                case CUSTOM_INPUTS.Custom:
                    return (
                        <>
                            {props.item === EWineProperties.grape_type && props.showEditInput.indexOf(props.item) !== -1
                                ? grapesItems.map((option, index) => {
                                    return (
                                        <div key={index} className={"grape-item"}>
                                            <Select
                                                className="w-145p mr-3"
                                                options={selectOptions && selectOptions.hasOwnProperty(props.item) ? selectOptions[props.item] : []}
                                                value={option.grape.value !== '' ? option.grape : null}
                                                classNamePrefix="react-select" styles={customStyles}
                                                placeholder={'- Select value -'}
                                                onInputChange={(value, { action }) => {
                                                    if (action === 'input-change') {
                                                        let filteredOptions = filterAndSort(taxonomy && taxonomy.hasOwnProperty(props.item) ? taxonomy[props.item] : [], value);
                                                        setSelectOptions({[props.item]: filteredOptions});
                                                    } else if (action === 'menu-close') {
                                                        setSelectOptions(taxonomy);
                                                    }
                                                }}
                                                onChange={(item) => {
                                                    if (item) {
                                                        grapesItems[index].grape.value = item.value;
                                                        grapesItems[index].grape.label = item.label;
                                                        setGrapesItems(grapesItems.map(f => f));
                                                    }
                                                }}
                                            />
                                            <input type="number" className="w-100p mr-1" placeholder="%"
                                                   value={grapesItems.hasOwnProperty(index) ? grapesItems[index].percent : ''}
                                                   onChange={(item) => {
                                                       if (item.target.value != "" && (Number(item.target.value) < 1 || Number(item.target.value) > 100)) {
                                                           return false;
                                                       }

                                                       let totalPercent = grapesItems.filter((item, idx) => idx !== index).reduce((a, b) => a + (b.percent ? Number(b.percent) : 0), 0);
                                                       if (totalPercent + (item.target.value != "" ? Number(item.target.value) : 0) > 100) {
                                                            return false;
                                                        }

                                                       grapesItems[index].percent = item.target.value != "" ? Number(item.target.value) : "";
                                                       setGrapesItems(grapesItems.map(f => f));

                                                       calculateEqualGrapesPercentage(CalculatorGrapesAction.ADD, index, item.target.value != "" ? Number(item.target.value) : 0);
                                                   }}/>
                                            <span className="mr-3 grape-calculate-percent">
                                                {(() => {
                                                    let currentRow = calculatePercentGrapes.find((elem) => elem.row === index);
                                                    if (currentRow) {
                                                        return currentRow.percent;
                                                    }
                                                    return 0;
                                                })()}
                                                %
                                            </span>
                                            <div className="align-items-center d-flex action">
                                                {index === 0 ? <PlusSquare
                                                        onClick={addGrapeItem} className="add-btn"/> :
                                                    <XSquare onClick={() => removeGrapeItem(index)}
                                                             className="remove-btn"/>
                                                }
                                                {index === 0 && grapesItems.length === 1 && (
                                                  <XSquare onClick={() => removeGrapeItem(index)}
                                                           className="ml-3 remove-btn"/>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })
                                : ''
                            }
                        </>
                    );
            }
        }
    }

    return (
        <>
            <a
              href="/#"
              onClick={(e) => {
                  e.preventDefault();
                  restoreFieldOldValue(props.edit_history?.old_value, props.edit_history?.old_label, props.item);
              }}
              title={props.edit_history !== undefined ? (!props.edit_history.old_value ? "Empty" : props.edit_history.old_label) : ""}
              className={"restore-history-cell" + (props.showEditInput.indexOf(props.item) !== -1 || props.edit_history === undefined ? " d-none" : "")}
            >
                <ClockHistory/>
            </a>
            <a href="/#"
               className={"edit-cell" + (props.showEditInput.indexOf(props.item) !== -1 ? ' d-none' : '')}
               onClick={(e) => getTaxonomyProperty(e, props.item)}
               title={"Edit row"}><Pencil/></a>
            <a href="/#"
               className={"save-edit-cell" + (props.showEditInput.indexOf(props.item) === -1 ? ' d-none' : '')}
               title={"Save edit row"}
               onClick={(e) => {
                   e.preventDefault();
                   closeEditRow(props.item)
               }}>
                <Save/>
            </a>
            {renderInputValue()}
        </>
    );
}

export default EditMatchingField;
