export type TCompetitions = {
    id: number,
    name: string
}

export type TEvents = {
    id: number,
    year: number
}

export type TCountries = {
    id: number,
    name: string
}

export type TAdditionalRow = {
    key: string,
    value: string
}

export type TWineProperties = {
    key: string,
    multiple_select: boolean,
    custom_input?: CUSTOM_INPUTS
}

export type TTaxonomy = {
    [key: string]: {
        form_input?: string | any,
        taxonomy_vocabulary?: string | any,
        option_values: {
            [key: number]: string
        } | null
    }
}

export type TFieldsContentTypes = {
    file_column: string,
    row_value: string,
    db_columns: string[]
}

export type TErrorObject = {
    [key: string]: string
}

export enum EWineProperties {
    skip = 'SKIP',
    save = 'SAVE',
    name = 'NAME',
    vineyard = 'VINEYARD',
    country = 'COUNTRY',
    region = 'REGION',
    year = 'YEAR',
    award = 'AWARD',
    color = 'COLOR',
    co2 = 'CO2',
    alcohol = 'ALCOHOL',
    sugar = 'SUGAR',
    grape_category = 'GRAPE_CATEGORY',
    grape_type = 'GRAPE_TYPE',
    grape_percent = 'GRAPE_PERCENT',
    under_film_of_yeast = 'UNDER_FILM_OF_YEAST',
    extra_sweetness = 'EXTRA_SWEETNESS',
    production_volume = 'PRODUCTION_VOLUME',
    production_units = 'PRODUCTION_UNITS',
    image = 'IMAGE',
    save_grape = 'SAVE_GRAPE',
    save_trophy = 'SAVE_TROPHY',
    beverage_type = 'BEVERAGE_TYPE',
    status = 'STATUS',
    stage_of_production = 'STAGE_OF_PRODUCTION',
    method = 'METHOD',
    series = 'SERIES',
    igp = 'IGP',
    wine_id = 'WINE_ID',
    vintage_id = 'VINTAGE_ID',
    url_image = 'URL_IMAGE',
    award_points = 'AWARD_POINTS',
}

export enum CUSTOM_INPUTS {
    Dropdown = 'DROPDOWN',
    Text = 'TEXT',
    Checkbox = "CHECKBOX",
    Number = "NUMBER",
    Float = "FLOAT",
    Boolean = "BOOLEAN",
    Custom = "CUSTOM"
}

export type TParsedFile = {
    file_name: string,
    hash: string,
    status: string
} | null

export type TFields = {
    weight: number,
    property: EWineProperties,
    value: string,
    addition_field: boolean,
    rule: null | {
        value: string,
        property?: EWineProperties,
        parent_label?: string
    },
    parent_value?: string,
    parent_dependency?: string,
    disabled: boolean,
    custom_field: boolean
};


export type TUserWinesFilter = {
    competition?: {
        value: number,
        label: string
    },
    event_year?: {
        value: number | Array<number>,
        label: string
    },
    country?: {
        value: number,
        label: string
    },
    winery?: {
        value: number,
        label: string
    },
    status?: {
        value: string,
        label: string
    },
    nameLike?: {
        value: string,
        label: string
    },
}

export type TAutomatchUserFilter = {
    batch?: number,
    match_above?: number,
    match_below?: number,
    match_selected?: boolean,
    new_vintage?: boolean,
    new_wine?: boolean,
    problematic?: number,
    valid?: number,
    merged?: number,
    finished?: number,
}

export enum EWineStatuses {
    new = 'NEW',
    skipped = 'SKIPPED',
    escalated = 'ESCALATED',
    reserved = 'RESERVED',
    processed = 'PROCESSED',
    rejected = 'REJECTED',
    done = 'DONE',
}

export const initialWineProperty: TWineProperties[] = [
    {
        key: EWineProperties.skip,
        multiple_select: true,
    }, {
        key: EWineProperties.save,
        multiple_select: true,
    }, {
        key: EWineProperties.name,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Text
    }, {
        key: EWineProperties.vineyard,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Dropdown
    }, {
        key: EWineProperties.region,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Dropdown
    }, {
        key: EWineProperties.country,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Dropdown
    }, {
        key: EWineProperties.year,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Number
    }, {
        key: EWineProperties.award,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Dropdown
    }, {
        key: EWineProperties.color,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Dropdown
    }, {
        key: EWineProperties.co2,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Dropdown
    }, {
        key: EWineProperties.alcohol,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Float
    }, {
        key: EWineProperties.sugar,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Float
    }, {
        key: EWineProperties.grape_category,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Dropdown
    }, {
        key: EWineProperties.under_film_of_yeast,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Dropdown
    }, {
        key: EWineProperties.extra_sweetness,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Dropdown
    }, {
        key: EWineProperties.production_volume,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Float
    }, {
        key: EWineProperties.production_units,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Dropdown
    }, {
        key: EWineProperties.image,
        multiple_select: false
    }, {
        key: EWineProperties.save_grape,
        multiple_select: false
    }, {
        key: EWineProperties.save_trophy,
        multiple_select: false
    }, {
        key: EWineProperties.stage_of_production,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Dropdown
    }, {
        key: EWineProperties.method,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Dropdown
    }, {
        key: EWineProperties.grape_type,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Custom
    }, {
        key: EWineProperties.url_image,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Text
    }, {
        key: EWineProperties.award_points,
        multiple_select: false,
        custom_input: CUSTOM_INPUTS.Text
    },
]

export type TPagination = {
    current_page: number,
    from: number,
    last_page: number,
    first_page_url: string,
    last_page_url: string,
    next_page_url: string,
    path: string,
    per_page: number,
    prev_page_url: string,
    to: number,
    total: number
} | null

export type BsModalSize = 'sm' | 'lg' | 'xl';

export type TRule = {
    property: EWineProperties,
    id: number,
    value: string,
    name: string,
    synonym_data: {
        name: string
    },
    user_data: {
        email: string
    },
    date: string,
    counters: {
        processed: number,
        merged: number
    },
    is_frozen: number,
    can_delete_vineyard?: boolean,
    vineyard_country?: string
    region_country?: string,
    file_country?: string
    country_locked?: boolean,
    file_initiator?: string
}

export type TNewVineyard = {
    id?: number,
    country?: number,
    name: string,
    status: ENewFieldValueStatus
}


export type TNewRegion = {
    id?: number,
    country?: number,
    name: string,
    status: ENewFieldValueStatus
}

export enum ENewFieldValueStatus {
    NEW = 'new',
    EXISTING = 'existing'
}

export type OptionType = {
    value: string;
    label: string;
};

export enum EWineryStatuses {
    PROBLEMATIC = 'PROBLEMATIC',
    VALID = 'VALID',
    MERGED = 'MERGED',
    FINISHED = 'FINISHED',
}

export type EditHistoryRow = {
    user: string,
    field: EWineProperties,
    old_value: string,
    old_label: string,
    new_value: string,
    created_at: string
}
