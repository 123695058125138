import React, {useContext, useEffect, useRef, useState} from "react";
import { TWinery } from "../pages/WineriesReview";
import ImageGallery from "./ImageGallery";
import {CloudUpload, CloudUploadFill, TrashFill} from "react-bootstrap-icons";
import {AuthUserContext} from "../context/AuthUser";
import axios from "axios";
import {toast} from "react-toastify";
import {TWines} from "../pages/PhotoVivinoReview";

export type statuses = 'new' | 'failed' | 'ok';

type TFile = {
  file: any,
  url: string,
  name: string,
  status: statuses
}

type TProps = {
  wines?: TWines[],
  wineries?: TWinery[]
  format: string,
  extensions: string[],
  uploadUrl: string
}

const PreviewMultipleImages = (props: TProps) => {
  const [images, setImages] = useState<TFile[]>([]);
  const [actionsDisabled, setActionsDisabled] = useState(true);
  const selectedFiles: string[] = [];
  const ref = useRef<HTMLInputElement>(null);
  const authUser = useContext(AuthUserContext);
  const axiosConfig = {
    headers: {
      Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : '',
      'Content-Type': 'multipart/form-data'
    }
  };

  const handleMultipleImages = (event: { target: { files: any; }; }) => {
    const targetFiles = event.target.files;
    const targetFilesObject = [...targetFiles]
    targetFilesObject.map((file) => {
      return selectedFiles.push(URL.createObjectURL(file))
    });

    const tmpFiles: TFile[] = [];
    [...event.target.files].forEach(file => {
      tmpFiles.push({
        file: file,
        url: URL.createObjectURL(file),
        name: file.name,
        status: 'new'
      });
    });

    setImages(images.concat(tmpFiles));
  }

  const uploadImages = () => {
    if (images.length) {
      setActionsDisabled(true);
      const formData = new FormData();
      images.forEach(item => {
        formData.append('files[]', item.file);
      })

      axios.post(props.uploadUrl, formData, {
        ...axiosConfig
      }).then(r => {
        let result = r.data;
        images.forEach(image => {
          if (result.hasOwnProperty('failed') && result.failed.length && result.failed.includes(image.name)) {
            image.status = 'failed';
          } else {
            image.status = 'ok';
          }
        });
        setImages(images.map(f => f));

        if (result.hasOwnProperty('msg')) {
          toast.success(result.msg);
        }

        if (props.wines?.length && result.hasOwnProperty('wine_entity_data')) {
          let wineEntityData: { url: string, status: string, other_wine_entities: number[] }[] = result.wine_entity_data;
          props.wines.map(wine => {
            if (wineEntityData.hasOwnProperty(wine.id)) {
              wine.photo_status = wineEntityData[wine.id].status;
              wine.photo = wineEntityData[wine.id].url;

              if (wineEntityData[wine.id].hasOwnProperty('other_wine_entities')) {
                props.wines?.map(wine2 => {
                  if (wineEntityData[wine.id].other_wine_entities.includes(wine2.id)) {
                    wine2.photo_status = wineEntityData[wine.id].status;
                    wine2.photo = wineEntityData[wine.id].url;
                  }
                  return wine2;
                });
              }
            }
            return wine;
          });
        }

        if (props.wineries?.length && result.hasOwnProperty('wineries_data')) {
          let wineriesData: { fid: number, url: string, status: string }[] = result.wineries_data;
          props.wineries.map(winery => {
            if (wineriesData.hasOwnProperty(winery.id)) {
              winery.logo = { fid: 0, status: "", url: "" };
              winery.logo.fid = wineriesData[winery.id].fid;
              winery.logo.status = wineriesData[winery.id].status;
              winery.logo.url = wineriesData[winery.id].url;
            }
            return winery;
          });
        }
      }).finally(() => {
        setActionsDisabled(false);
      });
    }
  }

  const cleanImages = () => {
    if (images.length) {
      setImages([]);
      setActionsDisabled(true);
      if (null !== ref.current) {
        ref.current.value = '';
      }
    }
  }

  useEffect(() => {
    if (images.length) {
      setActionsDisabled(false);
    }
  }, [images]);

  return (
    <>
      <div className="form-group my-3 mx-3 preview-multiple-images">
        <div className="upload-btn-wrapper">
          <button className="btn"><CloudUpload/> Select files</button>
          <input type="file"
                 onChange={handleMultipleImages}
                 multiple
                 accept={"image/" + props.extensions.join(",image/")}
                 ref={ref}/>
        </div>
        <div className="actions d-flex">
          <button title="Clean" className="clean" disabled={actionsDisabled} onClick={cleanImages}>
            <TrashFill/>
          </button>
          <button title="Upload" className="upload" disabled={actionsDisabled} onClick={uploadImages}>
            <CloudUploadFill/>
          </button>
        </div>
      </div>
      <div className="my-3 mx-3">
        <small>Images name must have format <strong>{props.format}</strong>. Allowed extension: {props.extensions.map((ext, idx) => {
          return (
            <>
              <strong>.{ext}</strong>{props.extensions?.length !== idx+1 ? ", ": ""}
            </>
          );
        })}. </small>
      </div>
      <ImageGallery images={images.map(item => {
        return {url: item.url, status: item.status};
      })}/>
    </>
  );
}

export default PreviewMultipleImages;
