import {Container} from "react-bootstrap";
import "../App.css";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {AlertError, validateEmail} from "../helpers";
import {TErrorObject} from "../properties";
import {AuthUserContext} from "../context/AuthUser";
import {useHistory} from "react-router";
import Preloader from "../components/Preloader";

const Login = () => {

    const authUser = useContext(AuthUserContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | TErrorObject>('');

    const history = useHistory();

    const submitData = (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        setError('');

        if (!validateEmail(email)) {
            setError('Email not valid');
            return false;
        }

        axios.post('/api/login', {
            email: email,
            password: password
        })
            .then(r => {
                let result = r.data;

                if (result.hasOwnProperty('err')) {
                    setError(result.err);
                    return false;
                }

                if (result.hasOwnProperty('api_key')) {
                    let user_data = {
                        api_key: result.api_key,
                        email: result.email,
                        is_admin: result.is_admin,
                        count_wines_today: result.count_wines_today,
                        count_merged_today: result.count_merged_today,
                        roles: result.roles,
                        permissions: result.permissions
                    };

                    localStorage.setItem('auth_user', JSON.stringify(user_data));
                    authUser.setAuthUser(user_data);

                    history.push('/');
                }
            })
            .catch(r => {
                setError(r.toString());
            });
        return false;
    }

    useEffect(() => {
        if (authUser.authUser !== null) {
            history.push('/');
        }
    }, [authUser]);

    return (
        <div className="App">
            <Container fluid>
                <div className="row">
                    <div className="col-md-12">
                        <h4 className="mb-3 text-start">Sign in</h4>
                        <hr/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">

                        <AlertError error={error} callback={() => setError('')}/>
                        {authUser.authUser === null ?
                            <div className="auth-wrapper">
                                <div className="auth-inner">
                                    <form className="login-form">
                                        <div className="form-group mb-3">
                                            <label>Email address</label><br/>
                                            <input type="email" className="custom-input-text w-100"
                                                   placeholder="Enter email" onChange={(e) => {
                                                setEmail(e.target.value)
                                            }}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label>Password</label><br/>
                                            <input type="password" className="custom-input-text w-100"
                                                   placeholder="Enter password" onChange={(e) => {
                                                setPassword(e.target.value)
                                            }}/>
                                        </div>

                                        <button type="submit" className="btn btn-custom" onClick={submitData}
                                                disabled={email === '' || password === '' || !validateEmail(email)}>Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                            : <Preloader show={!authUser.authUser}/>
                        }
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Login;