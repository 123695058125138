import ImageViewer from "react-simple-image-viewer";
import NewGrapeRow from "../components/NewGrapeRow";
import { AlertError, customStyles, hasPermissionTo, highlightText } from "../helpers";
import React, { useContext, useEffect, useState, Fragment, useRef } from "react";
import { Container, Dropdown, Table } from "react-bootstrap";
import {
    BsModalSize,
    ENewFieldValueStatus, EWineProperties,
    TErrorObject,
    TNewVineyard,
    TPagination, TTaxonomy
} from "../properties";
import {AuthUserContext} from "../context/AuthUser";
import Async from "react-select/async";
import axios from "axios";
import moment from "moment";
import Pagination from "react-js-pagination";

import {useHistory, useLocation} from "react-router";
import BsModal from "../components/BsModal";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from "react-select";
import { CardImage, Pencil, Save2, Trash, XSquare } from "react-bootstrap-icons";

type TCompetitions = {
    competition: string,
    medal: string,
    year: number,
    score: string|null,
    trophy: string
}

type TGrapes = {
    tid: number,
    grape: string,
    percent: number,
    idx: number
}

type TWines = {
    id: number,
    vintage: number,
    created: number,
    third_party_award_wine_entity_count: number,
    photo: string,
    photo_status: string,
    region_taxonomy: {
        tid: number,
        name: string
    } | null,
    gustos_wine: {
        id: number,
        name: string,
        co2_gustos: {
            tid: number,
            name: string
        },
        country_taxonomy: {
            tid: number,
            name: string
        },
        color_gustos: {
            tid: number,
            name: string
        }
    },
    competitions: TCompetitions[],
    grapes_count: number,
    grapes: TGrapes[]
}

type TWinery = {
    id: number,
    name: string
};

type OptionType = {
    value: number | string,
    label: string
};

type TEditField = {
    id: number,
    key: string,
    value: string,
    label: string
}

const WinesMergeList = () => {

    const selectLimit = 2;
    const authUser = useContext(AuthUserContext);
    const history = useHistory();
    const location = useLocation();
    const axiosConfig = {
        headers: {
            Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
        }
    };
    let idxRow = useRef(Math.floor(Math.random() * 1000000));
    const pageRangeDisplayed = 8;
    const [wines, setWines] = useState<TWines[]>([]);
    const [pagination, setPagination] = useState<TPagination>(null);
    const [error, setError] = useState<string | TErrorObject>('');
    const [loading, setLoading] = useState(false);
    const [selectedVineyard, setSelectedVineyard] = useState<OptionType | null>(null);
    const [selectedWines, setSelectedWines] = useState<TWines[]>([]);
    const [selectedWinesIds, setSelectedWinesIds] = useState<number[]>([]);
    const [mergeDisabled, setMergeDisabled] = useState(true);
    const [actionsPreloader, setActionsPreloader] = useState(false);

    const [openModalMoveWines, setOpenModalMoveWines] = useState(false);
    const [bsError, setBsError] = useState('');
    const [filtered, setFiltered] = useState(false);

    const [openModalAddVineyard, setOpenModalAddVineyard] = useState<boolean>(false);
    const [newVineyard, setNewVineyard] = useState<TNewVineyard | null>(null);
    const [similarVineyards, setSimilarVineyards] = useState([]);
    const [newVineyardInputDisabled, setNewVineyardInputDisabled] = useState(false);
    const [newVineyardConfirmButton, setNewVineyardConfirmButton] = useState(false);
    const [submitCreateNewVineyard, setSubmitCreateNewVineyard] = useState(false);
    const [moveAllWines, setMoveAllWines] = useState(false);

    const [taxonomyProperties, setTaxonomyProperties] = useState<TTaxonomy>();
    const [moveWinery, setMoveWinery] = useState<OptionType | null>(null);

    const [loadWineryFromQuery, setLoadWineryFromQuery] = useState(false);

    const [openModalEditField, setOpenModalEditField] = useState(false);
    const [editField, setEditField] = useState<TEditField>();
    const [loadingEditField, setLoadingEditField] = useState(false);
    const [editFieldOptions, setEditFieldOptions] = useState<OptionType[]>([]);

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState<string[]>([]);

    const [colors, setColors] = useState<OptionType[]>([]);
    const [co2, setCo2] = useState<OptionType[]>([]);

    const [modalCompetitions, setModalCompetitions] = useState<TCompetitions[]>([]);
    const [openModalCompetitions, setOpenModalCompetitions] = useState(false);

    const [filter, setFilter] = useState<{ [k: string]: string | number }>({});
    const [countAwards, setCountAwards] = useState(0);

    const [modalGrapes, setModalGrapes] = useState<TGrapes[]>([]);
    const [openModalGrapes, setOpenModalGrapes] = useState(false);
    const [editGrapesWineEntity, setEditGrapesWineEntity] = useState(0);

    const [editGrape, setEditGrape] = useState<TGrapes>();
    const [hasEditedGrapes, setHasEditedGrapes] = useState<number[]>([]);
    const [newGrapes, setNewGrapes] = useState<TGrapes[]>([]);

    const [selectedWinename, setSelectedWinename] = useState("");

    const openPreviewImage = (src: string) => {
        setPreviewImage([src]);
        setIsViewerOpen(true);
    }

    const closeImageViewer = () => {
        setPreviewImage([]);
        setIsViewerOpen(false);
    }

    const ModalMoveWinesProps = {
        showModal: openModalMoveWines,
        hideModal: () => {
            hideModalMoveWines();
        },
        title: "Move wines to ...",
        primaryButton: {
            title: 'Move',
            disabled: !moveWinery,
            onClick: () => {
                moveWinesToWinery();
            }
        },
        bsError: bsError,
        confirmButton: false,
        confirmButtonClick: () => {
        },
        size: 'lg' as BsModalSize
    }

    const ModalAddVineyardProps = {
        showModal: openModalAddVineyard,
        hideModal: () => {
            hideModalAddVineyard()
        },
        title: "Add new vineyard",
        primaryButton: {
            title: 'Create',
            onClick: () => {
                checkSimilarVineyard()
            }
        },
        bsError: bsError,
        confirmButton: newVineyardConfirmButton,
        confirmButtonClick: () => {
            storeVineyard()
        }
    }

    let ModalEditField = {
        showModal: openModalEditField,
        hideModal: () => {
            hideModalEditField();
        },
        title: 'Edit field',
        primaryButton: {
            title: 'Submit',
            onClick: () => {
                submitEditField();
            },
            disabled: loadingEditField
        },
    }

    const ModalCompetitionsProps = {
        showModal: openModalCompetitions,
        hideModal: () => {
            setOpenModalCompetitions(false);
            setModalCompetitions([]);
        },
        title: "Wine competitions",
        primaryButton: undefined,
        bsError: bsError,
        confirmButton: false,
        confirmButtonClick: () => {},
        size: 'lg' as BsModalSize
    }

    const ModalGrapesProps = {
        showModal: openModalGrapes,
        hideModal: () => {
            hideModalGrapes();
        },
        title: "Wine grapes",
        primaryButton: {
            title: "Save grapes",
            disabled: !hasEditedGrapes.length,
            onClick: () => {
                submitUpdateGrapes();
            }
        },
        bsError: bsError,
        confirmButton: false,
        confirmButtonClick: () => {},
        size: 'lg' as BsModalSize
    }

    const hideModalGrapes = () => {
        setOpenModalGrapes(false);
        setModalGrapes([]);
        setHasEditedGrapes([].map(n => n));
        setEditGrape(undefined);
        setNewGrapes([].map(v=>v));
        setEditGrapesWineEntity(0);
    }

    const hideModalEditField = () => {
        setOpenModalEditField(false);
        setEditField(undefined);
        setLoadingEditField(false);
    }

    const submitEditField = () => {
        if (typeof editField !== "undefined" && Object.keys(editField).length) {
            setLoadingEditField(true);
            let postData: TEditField & {country?: number} = editField;

            if (editField.key.toUpperCase() === EWineProperties.region) {
                const w: TWines | undefined = wines.find(item => item.id === editField.id);
                if (w) {
                    postData.country = w.gustos_wine.country_taxonomy.tid;
                }
            }
            axios.post('/api/wines-merge/submit-edit-field', postData, {
                ...axiosConfig
            }).then(r => {
                let result = r.data;
                if (result.hasOwnProperty('error')) {
                    toast.error(result.error);
                    return false;
                }

                if (result.hasOwnProperty('success')) {

                    toast.success(result.msg);

                    if (wines) {
                        if (editField.key.toUpperCase() === EWineProperties.region) {
                            let w: TWines[] = wines.map(item => {
                                if (editField.id === item.id) {
                                    item.region_taxonomy = {
                                        name: editField.label,
                                        tid: Number(editField.value)
                                    };
                                }

                                return item;
                            });

                            setWines(w);
                        }
                        // * Update field for all wines.
                        const wineEntity = wines.find(item => item.id === editField.id);
                        if (wineEntity) {
                            let w: TWines[] = wines.map(item => {
                                if (item.gustos_wine.id === wineEntity.gustos_wine.id) {
                                    let editFieldValue = Number(editField.value);
                                    switch (editField.key.toUpperCase()) {
                                        case EWineProperties.country:
                                            if (editFieldValue !== item.gustos_wine.country_taxonomy.tid) {
                                               item.region_taxonomy = null;
                                            }
                                            item.gustos_wine.country_taxonomy = {
                                                name: editField.label,
                                                tid: editFieldValue
                                            };
                                            break;
                                        case EWineProperties.color:
                                            item.gustos_wine.color_gustos = {
                                                name: editField.label,
                                                tid: editFieldValue
                                            };
                                            break;
                                        case EWineProperties.co2:
                                            item.gustos_wine.co2_gustos = {
                                                name: editField.label,
                                                tid: editFieldValue
                                            };
                                            break;
                                        case EWineProperties.name:
                                            item.gustos_wine.name = editField.value;
                                            break;
                                        case EWineProperties.image:
                                            if (item.id === wineEntity.id) {
                                                item.photo_status = result.photo_status;
                                                item.photo = result.photo;
                                            }
                                            break;
                                    }
                                }
                                return item;
                            });

                            setWines(w);
                        }
                    }

                    hideModalEditField();
                }
            }).finally(() => {
                setLoadingEditField(false);
            })
        }
    }

    const showModalEditField = (field: string, value: string, item_id: number, extra?: { url_params?: object }) => {
        if (field !== 'name' && field !== 'image') {
            let queryString = 'property=' + field.toUpperCase();
            if (extra && extra.url_params && Object.keys(extra.url_params).length) {
                Object.entries(extra.url_params).forEach(([key, v]) => {
                    queryString += "&" + key + "=" + v;
                });
            }
            axios.get('/api/taxonomy-property?' + queryString, {
                ...axiosConfig
            }).then(r => {
                let result = r.data;
                if (result.hasOwnProperty('taxonomy') && result.taxonomy.hasOwnProperty(field.toUpperCase())) {
                    let taxonomyData = result.taxonomy[field.toUpperCase()],
                      taxonomyValue = taxonomyData.find((item: { label: string; }) => item.label === value);

                    setEditFieldOptions(taxonomyData);
                    setEditField({id: item_id, key: field, value: taxonomyValue ? taxonomyValue.value : 0, label: taxonomyValue ? taxonomyValue.label : ''});
                    setOpenModalEditField(true);
                }
            })
        } else {
            setEditField({id: item_id, key: field, value: value, label: ''});
            setOpenModalEditField(true);
        }
    }

    const loadOnCreateVineyardData = () => {
        axios.get('/api/taxonomy-property?property=' + EWineProperties.country, {
            ...axiosConfig
        }).then(r => {
            let result = r.data;

            if (result.hasOwnProperty('taxonomy')) {
                setTaxonomyProperties(result.taxonomy);
            }

            setOpenModalAddVineyard(true);
        }).catch(r => {

        });
    }

    const hideModalAddVineyard = () => {
        setSimilarVineyards([]);
        setNewVineyardConfirmButton(false);
        setNewVineyardInputDisabled(false);
        setOpenModalAddVineyard(false);
        setNewVineyard(null);
        setBsError('');
    }

    const checkSimilarVineyard = () => {
        if (newVineyard === null) {
            return false;
        }

        setBsError('');
        axios.post('/api/check-vineyard', {
            vineyard: newVineyard.name
        }, {
            ...axiosConfig
        }).then(r => {
            let result = r.data;

            if (result.hasOwnProperty('err')) {
                setBsError(result.err);
                return false;
            }

            if (result.hasOwnProperty('similar') && result.similar.length) {
                setSimilarVineyards(result.similar);
            } else {
                setSubmitCreateNewVineyard(true);
                return true;
            }

            setNewVineyardConfirmButton(true);
            setNewVineyardInputDisabled(true);
        }).catch(r => {
            setBsError(r.toString());
        });
    }

    const storeVineyard = () => {
        if (newVineyard === null) {
            return false;
        }

        setSubmitCreateNewVineyard(false);
        setBsError('');

        if (newVineyard.status === ENewFieldValueStatus.EXISTING) {
            setMoveWinery({label: newVineyard.name, value: Number(newVineyard.id)});
            hideModalAddVineyard();
        } else if (newVineyard.status === ENewFieldValueStatus.NEW) {

            if (typeof newVineyard.country === "undefined" || !newVineyard.country) {
                setBsError('Country not selected');
                return false;
            }

            axios.post('/api/store-vineyard', {
                vineyard: newVineyard.name,
                country: newVineyard.country
            }, {
                ...axiosConfig
            }).then(r => {
                let result = r.data;

                if (result.hasOwnProperty('err')) {
                    setBsError(result.err);
                    return false;
                }

                setMoveWinery({label: newVineyard.name, value: Number(newVineyard.id)});

                hideModalAddVineyard();
            });
        }
    }

    const loadOptions = (inputText: string, callback: (options: OptionType[]) => void): void => {
        if (!inputText) {
            return callback([]);
        }
        if (inputText.length < 2) {
            return callback([]);
        }
        setLoading(true);

        axios.post('/api/search-winery', {q: inputText}, {
            ...axiosConfig
        }).then((response) => {
            let data: TWinery[] = response.data.wineries;
            setLoading(false);
            if (data.length) {
                return callback(data.map((result) => {
                    return labelFormatter(result);
                }))
            } else {
                return callback([]);
            }
        });
    };

    const loadGrapesOptions = (inputText: string, callback: (options: { label: string; value: number }[]) => void): void => {
        if (!inputText) {
            return callback([]);
        }
        if (inputText.length < 2) {
            return callback([]);
        }

        axios.post("/api/grapes/search", {
            q: inputText,
        }, {
            ...axiosConfig,
        }).then((response) => {
            let data: TWinery[] = response.data.grapes;
            if (data.length) {
                return callback(data.map((result) => {
                    return labelFormatter(result);
                }));
            } else {
                return callback([]);
            }
        });
    };

    const labelFormatter = (i: TWinery): { label: string; value: number } => {
        return {
            label: i.name,
            value: i.id,
        }
    }

    const searchMainWinery = (inputText: string, callback: (options: OptionType[]) => void) => {
        return loadOptions(inputText, callback);
    }

    const searchMoveWinery = (inputText: string, callback: (options: OptionType[]) => void) => {
        return loadOptions(inputText, callback);
    }

    const hideModalMoveWines = () => {
        setOpenModalMoveWines(false);
        setMoveWinery(null);
        setBsError('');
        setMoveAllWines(false);
    }

    const submitFilter = () => {
        setSelectedWines([]);
        setSelectedWinesIds([]);

        setFiltered(true);
        getWinesData();
    }

    const selectWine = (wine_id: number) => {
        let selectedIndex = selectedWinesIds.indexOf(wine_id),
            count: number;
        if (selectedIndex !== -1) {
            let filteredIds = selectedWinesIds.filter(item => item !== wine_id);
            setSelectedWinesIds(filteredIds.map(f => f));
            count = filteredIds.length;

            let filteredWines = selectedWines.filter(item => item.id !== wine_id);
            setSelectedWines(filteredWines.map(f => f));
        } else {
            selectedWinesIds.push(wine_id);
            setSelectedWinesIds(selectedWinesIds.map(f => f));
            count = selectedWinesIds.length;

            let wine = wines.find(item => item.id === wine_id);
            if (wine) {
                selectedWines.push(wine)
                setSelectedWines(selectedWines.map(f => f));
            }
        }

        setMergeDisabled(count !== selectLimit);
    }

    const getWinesData = (page?: number) => {
        if (selectedVineyard) {
            let currentPage = page || 1;

            let postData = {...{page: currentPage}, ...filter};

            axios.post('/api/vineyard-wines/' + selectedVineyard.value, postData, {
                ...axiosConfig
            }).then((response) => {
                let result = response.data;
                if (result.hasOwnProperty('wines')) {
                    let wines = result.wines;
                    setWines(wines.data);

                    let paginationData = {
                        'current_page': wines.current_page,
                        'from': wines.from,
                        'last_page': wines.last_page,
                        'first_page_url': wines.first_page_url,
                        'last_page_url': wines.last_page_url,
                        'next_page_url': wines.next_page_url,
                        'path': wines.path,
                        'per_page': wines.per_page,
                        'prev_page_url': wines.prev_page_url,
                        'to': wines.to,
                        'total': wines.total
                    };

                    setPagination(paginationData);
                }

                if (result.hasOwnProperty('countAwards')) {
                    setCountAwards(result.countAwards);
                }
            }).catch(error => {
                if (error.response) {
                    const responseData = error.response.data;
                    if (responseData.hasOwnProperty('message')) {
                        toast.error(responseData.message);
                    }
                }
            }).finally(() => {
                setSelectedWinename(filter.wine_name as string || "");

                const objString = '?' + Object.keys(filter).map(key => {
                    return `${key}=${encodeURIComponent(filter[key])}`;
                }).join('&');

                history.push({
                    pathname: '/wines-merge/list',
                    search:  (Object.keys(filter).length ? objString : "")
                });
            });
        }
    }

    const mergeCompare = () => {
        let urlIds = selectedWinesIds.join('/');
        history.push('/wines-merge/compare/' + urlIds);
    }

    const editWinery = () => {
        if (selectedVineyard) {
            history.push('/wines-merge/edit-winery/' + selectedVineyard.value);
        }
    }

    const moveWinesToWinery = () => {
        setError('');
        if (selectedVineyard && moveWinery) {
            let fields = {
                from: selectedVineyard.value,
                to: moveWinery.value,
                move_all_wines: moveAllWines,
                selected_wines: selectedWinesIds
            };

            axios.post('/api/wines-merge/move', fields, {
                ...axiosConfig
            }).then(r => {
                let result = r.data;
                if (result.hasOwnProperty('error')) {
                    toast(result.error, {type: "error"});
                    return false;
                }
                if (result.hasOwnProperty('success')) {
                    toast(result.msg, {type: "success"});
                    hideModalMoveWines();

                    setSelectedWinesIds([]);
                    setSelectedWines([]);

                    setMergeDisabled(true);

                    getWinesData(1);
                    return true;
                }

            }).catch(error => {
                if (error.response) {
                    const responseData = error.response.data;
                    if (responseData.hasOwnProperty('message')) {
                        toast.error(responseData.message);
                    }
                }
            });
        }
    }

    const clearMedals = () => {
        if (selectedVineyard) {
            if (!window.confirm('Are you sure want to clear medals?')) {
                return;
            }
            setActionsPreloader(true);
            axios.post('/api/clear-wines-medals', {
                'winery': selectedVineyard.value
            }, {
                ...axiosConfig
            }).then(r => {
                let result = r.data;
                if (result.hasOwnProperty('error')) {
                    toast.error(result.error);
                    return false;
                }
                if (result.hasOwnProperty('msg')) {
                    toast.success(result.msg);
                    return true;
                }
            }).catch(error => {
                if (error.response) {
                    const responseData = error.response.data;
                    if (responseData.hasOwnProperty('message')) {
                        toast.error(responseData.message);
                    }
                }
            }).finally(() => {
                setActionsPreloader(false);
            });
        }
    }

    const submitEditGrape = (row: TGrapes) => {
        let edited = modalGrapes.map((item) => {
            if (editGrape && item.idx === editGrape.idx) {
                return editGrape;
            }
            return item;
        });

        const sumPercentage = edited.reduce((sum, row) => sum + row.percent, 0);
        if (sumPercentage > 100) {
            toast.error('Sum percent for all grapes must be not great than 100.');
            return false;
        }
        setModalGrapes(edited);
        setEditGrape(undefined);

        if (!hasEditedGrapes.includes(row.idx)) {
            hasEditedGrapes.push(row.idx);
            setHasEditedGrapes(hasEditedGrapes.map(n => n));
        }
    }

    const submitNewGrape = (idx: number) => {
        const row = newGrapes.find(item => item.idx === idx);
        if (row) {
            const filtered = newGrapes.filter((r) => r.idx !== idx);
            const sumPercentage = modalGrapes.reduce((sum, row) => sum + row.percent, 0);
            if ((sumPercentage + row.percent) > 100) {
                toast.error("Sum percent for all grapes must be not great than 100.");
                return false;
            }

            if (row.grape === "") {
                return false;
            }

            modalGrapes.push(row as TGrapes);
            setModalGrapes(modalGrapes.map(v => v));
            setNewGrapes(filtered.map(v => v));

            if (!hasEditedGrapes.includes(Number(row.idx))) {
                hasEditedGrapes.push(Number(row.idx));
                setHasEditedGrapes(hasEditedGrapes.map(v => v));
            }
        }
    }

    const submitUpdateGrapes = () => {
        axios.post("/api/wines-merge/update-grapes", { grapes: modalGrapes, wine_entity_id: editGrapesWineEntity }, {
            ...axiosConfig,
        }).then(r => {
            const result = r.data;
            if (result.hasOwnProperty("error")) {
                toast.error(result.error);
                return false;
            }

            if (result.hasOwnProperty("success")) {
                toast.success(result.msg);

                const countGrapes = modalGrapes.length;
                if (wines.length) {
                    const updated = wines.map(wine => {
                        if (wine.id === editGrapesWineEntity) {
                            wine.grapes_count = countGrapes;
                            wine.grapes = modalGrapes;
                        }
                        return wine;
                    });

                    setWines(updated);
                }

                setOpenModalGrapes(false);
                hideModalGrapes();

                return true;
            }
        }).catch(e => {

        });
    };

    useEffect(() => {
        if (location.search) {
            setFilter(Object.fromEntries(new URLSearchParams(location.search)));
            const searchParams = new URLSearchParams(location.search);
            if (searchParams.get("winery")) {
                const isNumber = /^\d+$/.test(searchParams.get("winery") as string);
                if (isNumber) {
                    axios.get("/api/vineyard-by-id/" + searchParams.get("winery"), {
                        ...axiosConfig,
                    }).then(r => {
                        let result = r.data;
                        if (result.hasOwnProperty("id")) {
                            setSelectedVineyard({ value: result.id, label: result.name });
                            setLoadWineryFromQuery(true);
                            setFiltered(true);
                        }
                    }).catch(r => {

                    });
                } else {
                    axios.post("/api/vineyard-by-name", {
                        name: searchParams.get("winery"),
                    }, {
                        ...axiosConfig,
                    }).then(r => {
                        let result = r.data;
                        if (result.hasOwnProperty("id")) {
                            setSelectedVineyard({ value: result.id, label: result.name });
                            setLoadWineryFromQuery(true);
                            setFiltered(true);
                        }
                    }).catch(r => {

                    });
                }
            }
        }

        // Load filter data
        axios.get("/api/wines-merge/init", {
            ...axiosConfig,
        }).then(r => {
            const result = r.data;
            if (result.hasOwnProperty(EWineProperties.color)) {
                setColors(result[EWineProperties.color]);
            }
            if (result.hasOwnProperty(EWineProperties.co2)) {
                setCo2(result[EWineProperties.co2]);
            }
        }).catch(r => {

        });
    }, []);

    useEffect(() => {
        getWinesData();
    }, [loadWineryFromQuery])

    const updateGrapes = (map: TGrapes[]) => {
        setNewGrapes(map);
    };

    return (
        <div className="App">
            <div className="w-100">
                <Container fluid>
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="mb-2 text-start">Merge wines list</h4>
                            <hr/>
                        </div>
                        <div className="col-md-12">
                            <AlertError error={error} callback={() => setError('')}/>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group mb-3">
                                        <label>Winery</label>
                                        <Async isClearable={true} isLoading={loading} loadOptions={searchMainWinery}
                                               onChange={item => {
                                                   setSelectedVineyard(item);
                                                   setFilter({'winery': item ? item.value : ''});

                                               }}
                                               value={selectedVineyard}
                                               placeholder={"Insert some text..."}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div
                                        className="form-group mb-3 mt-4 d-flex align-items-start">
                                        <label>&nbsp;</label>
                                        <button className="btn btn-custom inherit" onClick={submitFilter}>Search
                                            wines
                                        </button>

                                        <Dropdown className="ml-3">
                                            <Dropdown.Toggle className="btn-outline-custom inherit">Actions</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {hasPermissionTo(authUser, "merge_wines merge") &&
                                                  <Dropdown.Item eventKey="1" disabled={mergeDisabled}
                                                                 onClick={mergeCompare}>Merge wines</Dropdown.Item>
                                                }
                                                {hasPermissionTo(authUser, "merge_wines edit_winery") &&
                                                  <Dropdown.Item eventKey="2" onClick={editWinery}
                                                                 disabled={!filtered}>Edit winery</Dropdown.Item>
                                                }
                                                {hasPermissionTo(authUser, "merge_wines move") &&
                                                  <Dropdown.Item eventKey="3"
                                                                 onClick={() => setOpenModalMoveWines(true)}
                                                                 disabled={!wines.length}>Move wines to ...</Dropdown.Item>
                                                }
                                                {hasPermissionTo(authUser, "merge_wines clear_medals") &&
                                                  <Dropdown.Item eventKey="4" onClick={clearMedals}
                                                                 disabled={!filtered}>
                                                      Clear medals </Dropdown.Item>
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        {actionsPreloader &&
                                          <span className="preloader actions-preloader"/>
                                        }
                                    </div>
                                </div>
                                {filtered ?
                                  <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Wine name</label>
                                                <input type="text" className="form-control"
                                                       value={filter.wine_name ?? ""}
                                                       onChange={(item) => {
                                                           setFilter({ ...filter, 'wine_name': item.target.value });
                                                       }}/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Color</label>
                                                <Select options={colors} isClearable={true} value={filter.color ? colors.find(item => item.value == filter.color) : null}
                                                        onChange={(item) => setFilter({
                                                            ...filter,
                                                            'color': item ? item.value : ''
                                                        })}/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Co2</label>
                                                <Select options={co2} isClearable={true} value={filter.co2 ? co2.find(item => item.value == filter.co2) : null}
                                                        onChange={(item) => setFilter({
                                                            ...filter, "co2": item ? item.value : "",
                                                        })}/>
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                  : ""
                                }
                            </div>
                            {wines.length
                                ? (<>
                                        <fieldset className="selected-wines">
                                            <legend>Selected wines ({selectedWines.length ? selectedWines.length : 0})
                                            </legend>
                                            {selectedWines.length ?
                                                <ol>
                                                    {selectedWines.map(item => {
                                                        return (
                                                            <li key={"selected-wine" + item.id}>{item.gustos_wine.name}, {item.gustos_wine.country_taxonomy.name}, {item.gustos_wine.color_gustos ? item.gustos_wine.color_gustos.name + ', ' : ''} {item.vintage}</li>
                                                        )
                                                    })}
                                                </ol>
                                                : ''
                                            }
                                        </fieldset>
                                        <Table striped bordered hover size="sm">
                                            <thead>
                                            <tr>
                                                <th>
                                                    #
                                                </th>
                                                <th>Vintage ID</th>
                                                <th>Photo</th>
                                                <th>Wine Name</th>
                                                <th>Country</th>
                                                <th>Region</th>
                                                <th>Vintage</th>
                                                <th>Color</th>
                                                <th>CO2</th>
                                                <th>Grapes</th>
                                                <th>Awards {countAwards ? `(${countAwards})` : ""}</th>
                                                <th>Created</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {wines.map(item => {
                                                return (
                                                    <Fragment key={item.id}>
                                                        <tr>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={e => selectWine(item.id)} {...(selectedWinesIds.indexOf(item.id) !== -1) ? {checked: true} : ''} />
                                                            </td>
                                                            <td>
                                                                <a href={`/target-revision?target_gustos_vintage_id=${item.id}`} target="_blank" rel="noreferrer">
                                                                    {item.id}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <div className={`d-flex justify-content-center photo-status-` + item.photo_status}>
                                                                    {item.photo ? <a href='/#'
                                                                                     title={item.photo_status.charAt(0).toUpperCase() + item.photo_status.slice(1) + ' image'}
                                                                                     onClick={(e) => {
                                                                                         e.preventDefault();
                                                                                         openPreviewImage(item.photo)
                                                                                     }}><CardImage/></a> :
                                                                      ''}
                                                                    <button className="link-edit" onClick={() => showModalEditField('image', "", item.id)}>
                                                                        <Pencil/>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {selectedWinename !== "" ?
                                                                  <span dangerouslySetInnerHTML={{
                                                                      __html: highlightText(selectedWinename, item.gustos_wine.name),
                                                                  }}></span>
                                                                 : item.gustos_wine.name
                                                                }
                                                                <button className="link-edit"
                                                                        onClick={() => showModalEditField('name', item.gustos_wine.name, item.id)}>
                                                                    <Pencil/>
                                                                </button>
                                                            </td>
                                                            <td>
                                                                {item.gustos_wine.country_taxonomy.name}
                                                                <button className="link-edit"
                                                                        onClick={() => showModalEditField('country', item.gustos_wine.country_taxonomy.name, item.id)}>
                                                                    <Pencil/>
                                                                </button>
                                                            </td>
                                                            <td>
                                                                {item.region_taxonomy ? item.region_taxonomy.name : ''}
                                                                <button className="link-edit"
                                                                        onClick={() => showModalEditField('region', item.region_taxonomy ? item.region_taxonomy.name : '', item.id, {url_params: {country: item.gustos_wine.country_taxonomy.tid}})}>
                                                                    <Pencil/>
                                                                </button>
                                                            </td>
                                                            <td>
                                                                {item.vintage}
                                                            </td>
                                                            <td>
                                                                {item.gustos_wine.color_gustos ? item.gustos_wine.color_gustos.name : ''}
                                                                <button className="link-edit"
                                                                        onClick={() => showModalEditField('color', item.gustos_wine.color_gustos ? item.gustos_wine.color_gustos.name : '', item.id)}>
                                                                    <Pencil/>
                                                                </button>
                                                            </td>
                                                            <td>
                                                                {item.gustos_wine.co2_gustos ? item.gustos_wine.co2_gustos.name : ''}
                                                                <button className="link-edit"
                                                                        onClick={() => showModalEditField('co2', item.gustos_wine.co2_gustos ? item.gustos_wine.co2_gustos.name : '', item.id)}>
                                                                    <Pencil/>
                                                                </button>
                                                            </td>

                                                            <td className="text-center">
                                                                <span className="local-link" onClick={() => {
                                                                    setModalGrapes(item.grapes);
                                                                    setOpenModalGrapes(true);
                                                                    setEditGrapesWineEntity(item.id);
                                                                }}>{item.grapes_count}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="local-link" onClick={() => {
                                                                    setModalCompetitions(item.competitions);
                                                                    setOpenModalCompetitions(true);
                                                                }}>{item.third_party_award_wine_entity_count}</span>
                                                            </td>
                                                            <td>
                                                                {moment(item.created * 1000).fromNow()}
                                                            </td>
                                                        </tr>
                                                    </Fragment>
                                                    );
                                                })}
                                            </tbody>
                                        </Table>
                                        {pagination !== null ?
                                            <Pagination activePage={pagination.current_page}
                                                        itemsCountPerPage={pagination.per_page}
                                                        onChange={(pageNumber) => {
                                                            getWinesData(pageNumber)
                                                        }}
                                                        totalItemsCount={pagination.total}
                                                        pageRangeDisplayed={pageRangeDisplayed}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                        firstPageText="First Page"
                                                        lastPageText="Last Page"
                                                        innerClass="pagination mt-3"
                                            />
                                            : ''
                                        }
                                    </>
                                ) : (filtered ? 'No wines.' : '')}
                        </div>
                    </div>
                    <BsModal {...ModalMoveWinesProps}>
                        <p>Move selected wines to another winery.</p>
                        <div className="form-group mb-2">
                            <label>Winery*</label>
                            <div className="row mb-3">
                                <div className="col-md-11">
                                    <Async placeholder={"Insert some text..."}
                                           loadOptions={searchMoveWinery}
                                           classNamePrefix="react-select"
                                           value={moveWinery}
                                           styles={customStyles}
                                           onChange={(item) => setMoveWinery(item)}
                                    />
                                </div>
                                <div className="col-md-1">
                                    <button className="additional-button" title="Create new winery"
                                            onClick={() => loadOnCreateVineyardData()}>+
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <input type="checkbox" onChange={(e) => setMoveAllWines(e.target.checked)}/> - Move all
                            wines
                        </div>

                        {selectedWines.length ?
                            <div className="form-group mt-3">
                                <div className="alert alert-info">
                                    Selected wines: <strong>{selectedWines.length}</strong>
                                </div>
                            </div>
                            : ''}
                    </BsModal>
                    <BsModal {...ModalAddVineyardProps}>
                        <p>Create new vineyard. Use this form only if vineyard is not listed.</p>
                        <div className="form-group mb-2">
                            <label>Country*</label>
                            <select className="w-100 custom-select" onChange={(e) => {
                                let objVineyard = Object.assign({}, newVineyard)
                                objVineyard.country = parseInt(e.target.value)
                                setNewVineyard(objVineyard)
                            }}>
                                <option value="0">- select country -</option>
                                {taxonomyProperties && taxonomyProperties[EWineProperties.country] ?
                                    Object.entries(taxonomyProperties[EWineProperties.country]).map(([key, item]) => {
                                        return (
                                            <option value={item.value} key={item.value}>{item.label}</option>
                                        )
                                    })
                                    : ''
                                }
                            </select>
                        </div>
                        <div className="form-group mb-2">
                            <label>Vineyard name*</label>
                            <input type="text" className="w-100 custom-input-text"
                                   onChange={(e) => {
                                       let objVineyard = Object.assign({}, newVineyard)
                                       objVineyard.name = e.target.value
                                       objVineyard.status = ENewFieldValueStatus.NEW
                                       setNewVineyard(objVineyard)
                                   }} disabled={newVineyardInputDisabled}/>
                            <small>Max length 60 characters.</small>
                        </div>
                        {similarVineyards.length ?
                            <div className="mt-3 similar-regions">
                                <h6>Similar vineyards</h6>
                                {similarVineyards.map((item: { id: number, name: string, country: number | string }) => {
                                    return (
                                        <div className="mt-3">
                                            <input type="radio" name="existing_region" onChange={(e) => setNewVineyard({
                                                id: item.id,
                                                name: item.name,
                                                status: ENewFieldValueStatus.EXISTING
                                            })}/> {item.name} ({item.country})
                                        </div>
                                    )
                                })}
                                <div className="small mt-3">Select similar vineyards to catch existing region or skip to
                                    create new one.
                                </div>
                            </div>
                            : ''
                        }
                    </BsModal>
                    <BsModal {...ModalEditField}>
                        <div
                          className={"form-group mb-2" + (loadingEditField ? ' loading disabled' + (editField && editField.key === 'country' ? ' select' : '') : '')}>
                            <p>Value</p>
                            <fieldset {...(loadingEditField ? {disabled: true} : '')}>
                                {editField && (editField.key === 'name' || editField.key === 'image') ?
                                  <input type="text" className="custom-input-text w-100" value={editField?.value || ''}
                                         onChange={e => {
                                             if (e) {
                                                 const fieldData = Object.assign({}, editField);
                                                 fieldData.value = e.target.value;
                                                 fieldData.label = e.target.innerText;
                                                 setEditField(fieldData);
                                             }
                                         }}/>
                                  : ''
                                }
                                {editField && editField.key !== 'name' && editField.key !== 'image' ?
                                  <Select options={editFieldOptions}
                                          value={editFieldOptions.filter(option => {
                                              return Number(option.value) === Number(editField.value);
                                          })}
                                          onChange={e => {
                                              if (e) {
                                                  const fieldData = Object.assign({}, editField);
                                                  fieldData.value = e.value.toString();
                                                  fieldData.label = e.label;
                                                  setEditField(fieldData);
                                              }
                                          }}/>
                                  : ''
                                }
                            </fieldset>
                        </div>
                    </BsModal>
                    <BsModal {...ModalCompetitionsProps}>
                        <div className="modal-competitions-body">
                            <table className="modal-competitions-list table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>
                                            Competition
                                        </th>
                                        <th>
                                            Medal
                                        </th>
                                        <th>
                                            Year
                                        </th>
                                        <th>
                                            Score
                                        </th>
                                        <th>
                                            Trophy
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {modalCompetitions.length ?
                                  modalCompetitions.map((row) => {
                                     return (
                                       <tr>
                                           <td>
                                               {row.competition}
                                           </td>
                                           <td>
                                               {row.medal}
                                           </td>
                                           <td>
                                               {row.year}
                                           </td>
                                           <td>
                                               {row.score}
                                           </td>
                                           <td>
                                               {row.trophy}
                                           </td>
                                       </tr>
                                     )
                                  })
                                  : <tr className="empty"><td colSpan={parseInt("5")} className="text-center">No competitions</td></tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    </BsModal>
                    <BsModal {...ModalGrapesProps}>
                        <table className="modal-grapes-list table table-bordered table-striped">
                            <thead>
                            <tr>
                                <th>
                                    Grape
                                </th>
                                <th>
                                    Percent
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {modalGrapes.length ?
                              modalGrapes.map((row) => {
                                  return (
                                    <tr>
                                        <td {...(!editGrape && hasEditedGrapes.includes(row.idx) ? {className: "edited"} : ``)}>
                                            {editGrape && editGrape.idx === row.idx ?
                                              <Async loadOptions={loadGrapesOptions}
                                                     styles={{valueContainer: (provided: any) => ({ ...provided,  gridTemplateColumns: '1fr' }) }}
                                                     defaultValue={{ label: row.grape, value: row.tid }} onChange={item => {
                                                  if (item) {
                                                      let editable = Object.assign({}, editGrape);
                                                      editable.tid = item.value;
                                                      editable.grape = item.label;
                                                      setEditGrape(editable);
                                                  }
                                              }}/>
                                              : row.grape
                                            }
                                        </td>
                                        <td style={{width: '20%'}} {...(hasEditedGrapes.includes(row.idx) ? {className: "edited"} : ``)}>
                                            {editGrape && editGrape.idx === row.idx ?
                                                <input type="number" min="1" max="100" value={editGrape.percent} className="form-control" onChange={(e) => {
                                                    let value = parseInt(e.target.value);

                                                    if (e.target.value === "" || (value >= 1 && value <= 100)) {
                                                        if (e.target.value === "") {
                                                            value = 1;
                                                        }
                                                        let editable = Object.assign({}, editGrape);
                                                        editable.percent = value;
                                                        setEditGrape(editable);
                                                    }
                                                }}/>
                                              : `${row.percent}%`
                                            }
                                        </td>
                                        <td style={{width: '10%'}} className="actions">
                                            {editGrape && editGrape.idx === row.idx ?
                                                <>
                                                    <span onClick={() => submitEditGrape(row)}><Save2 /></span>
                                                    <span className="ml-3 text-danger" onClick={() => {
                                                        setEditGrape(undefined);
                                                    }}><XSquare /></span>
                                                </>
                                              :
                                                <>
                                                    <span onClick={() => setEditGrape(row)}><Pencil /></span>
                                                    <span className="ml-3 text-danger" onClick={() => {
                                                        let filtered = modalGrapes.filter((item) => item.idx !== row.idx);
                                                        setModalGrapes(filtered.map(v=>v));

                                                        if (!hasEditedGrapes.includes(row.idx)) {
                                                            hasEditedGrapes.push(row.idx);
                                                            setHasEditedGrapes(hasEditedGrapes.map(v => v));
                                                        }
                                                    }}><Trash /></span>
                                                </>
                                            }
                                        </td>
                                    </tr>
                                  )
                              })
                              : <tr className="empty"><td colSpan={parseInt("3")} className="text-center">No grapes</td></tr>
                            }
                            {!!(newGrapes.length) &&
                               newGrapes.map((item, idx) => {
                                   return <NewGrapeRow key={item.idx} newGrapes={newGrapes} updateGrapes={updateGrapes} submitNewGrape={submitNewGrape} grape={item} />;
                               })
                            }
                            </tbody>
                        </table>
                        <button className="btn btn-outline-custom" onClick={() => {
                            newGrapes.push({ grape: "", percent: 1, tid: 0, idx: idxRow.current++ });
                            setNewGrapes(newGrapes.map(v => v));
                        }}>
                            Add grape
                        </button>
                    </BsModal>
                    {isViewerOpen && (
                      <ImageViewer
                        src={previewImage}
                        currentIndex={currentImage}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                      />
                    )}
                </Container>
            </div>
        </div>
    )
}

export default WinesMergeList;
