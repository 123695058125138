import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { AuthUserContext } from "../context/AuthUser";
import { customStyles } from "../helpers";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromHTML, ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';

type TCompetition = {
  id: number,
  name: string,
  logo: string,
  description: string,
  region: string,
  google_page_rating: number,
  correction_factor: number,
  logo_path: string|null
}

type TOptionType = {
  label: string,
  value: string
}

const CompetitionEdit = () => {
  let { id } = useParams<{id: string}>();
  let history = useHistory();

  const authUser = useContext(AuthUserContext);
  const [competition, setCompetition] = useState<TCompetition>();
  const [regions, setRegions] = useState<TOptionType[]>();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [updateFields, setUpdateFields] = useState({});
  const [selectedFile, setSelectedFile] = useState<File>();

  const axiosConfig = {
    headers: {
      Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
    }
  };

  const updateCompetition = (field: string, value: string) => {
    if (competition) {
      const data =  {...competition, ...{[field]: value}};
      setCompetition(data);

      setUpdateFields({...updateFields, ...{[field]: value}})
    }
  }

  const [editorValue, setEditorValue] = useState("");
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const submitEdit = () => {
    setDisableSubmit(true);

    if (!Object.keys(updateFields).length && typeof selectedFile === undefined) {
      history.push("/competitions");
      return true;
    }

    const formData = new FormData();
    if (selectedFile) {
      formData.append('file_logo', selectedFile);
    }

    Object.entries(updateFields).forEach(([k, v], i) => {
      formData.append(k, v as string);
    });

    if (competition) {
      axios.post(`/api/competition/${competition.id}/save`, formData, {
        ...axiosConfig,
      }).then(r => {
        let result = r.data;
        if (result.hasOwnProperty('error')) {
          toast.error(result.error);
          return false;
        }

        if (result.hasOwnProperty('success')) {
          history.push("/competitions");
          return true;
        }
      }).catch(e => {

      }).finally(() => {
        setDisableSubmit(false);
      });
    }
  }

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    } else {
      setSelectedFile(undefined);
    }
  };

  useEffect(() => {
    if (id) {
      axios.get("/api/competition/" + id, {
        ...axiosConfig
      }).then(r => {
        let result = r.data;
        if (result.hasOwnProperty('competition')) {
          setCompetition(result.competition);

          setEditorValue(result.competition.description);
        }

        if (result.hasOwnProperty('regions')) {
          setRegions(result.regions);
        }
      }).catch(e => {

      });
    }
  }, [id]);

  useEffect(() => {
    const obj = convertFromHTML(editorValue);
    const value = ContentState.createFromBlockArray(
      obj.contentBlocks,
      obj.entityMap
    );

    setEditorState(EditorState.createWithContent(value));
  }, [editorValue]);

  return (
    <div className="App">
      <div className="w-100">
        <Container fluid>
          <div className="row">
            <div className="col-md-12">
              <h4 className="mb-2 text-start">Competitions Edit</h4>
              <hr/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-2">
              <label>Name</label>
              <input type="text" defaultValue={competition?.name} className="custom-input-text w-100" onChange={e => updateCompetition('name', e.target.value)}/>
            </div>
            <div className="col-md-4 mt-3">
              <label>Region</label>
              <Select className="w-100"
                      classNamePrefix="react-select"
                      styles={customStyles}
                      placeholder={'- Select value -'}
                      options={regions}
                      onChange={item => updateCompetition('region', item ? item.value : "")}
                      value={regions && competition ? regions.filter(item => item.value === competition.region) : null}
              />
            </div>
            <div className="col-md-4 mt-3">
              <label>Google PR</label>
              <input type="number" className="custom-input-text w-100" defaultValue={competition?.google_page_rating} onChange={e => updateCompetition('google_page_rating', e.target.value)}/>
            </div>
            <div className="col-md-4 mt-3">
              <label>Correction Factor</label>
              <input type="number" className="custom-input-text w-100" defaultValue={competition?.correction_factor} onChange={e => updateCompetition('correction_factor', e.target.value)}/>
            </div>
            <div className="col-md-4 mt-3">
              <label>Logo url <em>(attached file has priority)</em></label>
              <input type="text" className="custom-input-text w-100" onChange={e => updateCompetition('logo_url', e.target.value)}/>
              <input type="file" className="mt-2" accept="image/jpg,image/png,image/jpeg,image/webp" onChange={handleChangeFile}/>

              <div className="competition-preview-logo mt-3">
                {competition && competition.logo_path ?
                  <img src={competition.logo_path} alt="" />
                   : 'No logo'
                }
              </div>
              <label className="mt-3">
                <input type="checkbox" onChange={e => updateCompetition('delete_logo', e.target.checked.toString())}/> - Delete logo
              </label>
            </div>
            <div className="col-md-8 mt-3">
              <label>Description</label>
              <Editor
                editorState={editorState}
                editorClassName="editorCompetition"
                onEditorStateChange={editorState => {
                  setEditorState(editorState);
                  const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
                  updateCompetition('description', value);
                }}
              />
              <div className="d-flex justify-content-end">
                <Link to={"/competitions"} className="btn btn-outline-custom mt-3">
                  &#x2190; Back
                </Link>
                <button className="btn btn-custom mt-3 ml-3" onClick={submitEdit} disabled={disableSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default CompetitionEdit;