import React, {Fragment, useContext, useEffect, useState} from "react";
import {Button, Container} from "react-bootstrap";
import axios from "axios";
import {useTranslation} from 'react-i18next';
import TaxonomyInput from "../components/TaxonomyInput";
import {AlertError, capitalize, useInterval} from "../helpers";
import TaxonomyValue from "../components/TaxonomyValue";
import BsModal from "../components/BsModal";
import Preloader from "../components/Preloader";
import {
    ENewFieldValueStatus,
    EWineProperties,
    TErrorObject,
    TFields,
    TNewRegion,
    TNewVineyard,
    TTaxonomy
} from "../properties";
import {useHistory, useLocation, useParams} from "react-router";
import {AuthUserContext} from "../context/AuthUser";
import DataMatchingItemValue from "../components/DataMatchingItemValue";

export type TFormData = {
    property: EWineProperties,
    content: string,
    synonym: string,
    weight: number | null,
    create_rule: boolean,
    parent_dependency?: string,
    disabled: boolean,
    custom_field: boolean
}

type TForm = {
    [key: string]: any
}

type TParams = {
    file_hash: string,
    row_token?: string
}

const DataMatching = () => {
    const authUser = useContext(AuthUserContext);
    const axiosConfig = {
        headers: {
            Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
        }
    };

    const [error, setError] = useState<string | TErrorObject>('');
    const [fields, setFields] = useState<TFields[]>([]);
    const [taxonomyProperties, setTaxonomyProperties] = useState<TTaxonomy>();
    const {t} = useTranslation();
    const [formFields, setFormFields] = useState<TFormData[]>([]);
    const [similarRegions, setSimilarRegions] = useState([]);
    const [newRegion, setNewRegion] = useState<TNewRegion | null>(null);
    const [newRegionConfirmButton, setNewRegionConfirmButton] = useState(false);
    const [newRegionInputDisabled, setNewRegionInputDisabled] = useState(false);
    const [submitCreateNewRegion, setSubmitCreateNewRegion] = useState(false);
    const [submitCreateNewVineyard, setSubmitCreateNewVineyard] = useState(false);
    const [countryLocked, setCountryLocked] = useState(false);
    const [automatchActive, setAutomatchActive] = useState(false);

    const [newVineyard, setNewVineyard] = useState<TNewVineyard | null>(null);
    const [similarVineyards, setSimilarVineyards] = useState([]);
    const [newVineyardInputDisabled, setNewVineyardInputDisabled] = useState(false);
    const [newVineyardConfirmButton, setNewVineyardConfirmButton] = useState(false);

    const [bsError, setBsError] = useState('');
    const dataImport: TForm = {};

    const [openModalAddRegion, setOpenModalAddRegion] = useState<boolean>(false);
    const [openModalAddVineyard, setOpenModalAddVineyard] = useState<boolean>(false);
    const [disabledAccept, setDisabledAccept] = useState<boolean>(true);
    const [competition, setCompetition] = useState('');
    const [event, setEvent] = useState('');
    const [canSubmitForm, setCanSubmitForm] = useState(false);

    const history = useHistory();
    const location = useLocation();
    const {file_hash, row_token} = useParams<TParams>();

    let countryField = formFields.find(item => item.property === EWineProperties.country);

    let copy_clipboard_fields = [EWineProperties.region, EWineProperties.vineyard];

    let ModalAddRegionProps = {
        showModal: openModalAddRegion,
        hideModal: () => {
            hideModalAddRegion()
        },
        title: "Add new region",
        primaryButton: {
            title: 'Create',
            onClick: () => {
                checkSimilarRegion()
            }
        },
        confirmButton: newRegionConfirmButton,
        bsError: bsError,
        confirmButtonClick: () => {
            storeRegion()
        }
    }

    let ModalAddVineyardProps = {
        showModal: openModalAddVineyard,
        hideModal: () => {
            hideModalAddVineyard()
        },
        title: "Add new vineyard",
        primaryButton: {
            title: 'Create',
            onClick: () => {
                checkSimilarVineyard()
            }
        },
        bsError: bsError,
        confirmButton: newVineyardConfirmButton,
        confirmButtonClick: () => {
            storeVineyard()
        }
    }

    const skip_properties = [EWineProperties.skip, EWineProperties.save, EWineProperties.name, EWineProperties.image, EWineProperties.save_grape, EWineProperties.save_trophy, EWineProperties.url_image, EWineProperties.award_points];
    let TOUCH_INTERVAL = 60000; //milliseconds

    const getParsedFileRow = () => {
        if (file_hash) {
            let url = '/api/matching-manual-row/' + file_hash;
            if (row_token) {
                url += '/' + row_token;
            }
            axios.get(url, {
                ...axiosConfig
            }).then((response) => {
                const result = response.data;

                if (result.hasOwnProperty('clean_data') && result.clean_data) {
                    setFields([])
                    setTaxonomyProperties({})
                    setFormFields([])
                }

                if (result.hasOwnProperty('error')) {
                    setError(result.error);
                    return false;
                }

                if (result.hasOwnProperty('row_token') && !row_token) {
                    history.replace('/data-matching/' + file_hash + '/' + result.row_token);
                }

                if (result.hasOwnProperty('fields')) {
                    setFields(result.fields);

                    initializeFormFields(result.fields);
                }

                if (result.hasOwnProperty('taxonomy_properties')) {
                    let properties = Object.assign({}, result.taxonomy_properties)
                    setTaxonomyProperties(properties);
                }
                if (result.hasOwnProperty('competition')) {
                    setCompetition(result.competition);
                }
                if (result.hasOwnProperty('event')) {
                    setEvent(result.event);
                }

                if (result.hasOwnProperty('country_locked')) {
                   setCountryLocked(result.country_locked);
                }

                if (result.hasOwnProperty('automatch_active')) {
                    setAutomatchActive(result.automatch_active);
                }

                setDisabledAccept(false);
            }).catch((error) => {
                if (error.response.status === 401) {
                    authUser.setAuthUser(null);
                    localStorage.removeItem('auth_user');
                }
                setError(error.toString());
            });
        }
    }

    const initializeFormFields = (fields: TFields[]) => {
        let items: Array<TFormData> = [];

        fields.map(item => {
            if (skip_properties.indexOf(item.property) !== -1) {
                return false;
            }

            let itemContent: string | number = item.rule !== null ? item.rule.value : '',
                matchedNumber = '';
            if ([EWineProperties.alcohol, EWineProperties.sugar, EWineProperties.year].indexOf(item.property) !== -1 && item.value !== '' && item.rule === null) {
                let matchNumber = item.value.match(/[0-9]+([.,]?[0-9]+)?/g);
                if (matchNumber) {
                    matchedNumber = parseFloat(matchNumber[0].replace(',', '.')).toString();
                }
            }

            items.push({
                property: item.property,
                weight: item.weight,
                content: matchedNumber ? matchedNumber : itemContent,
                synonym: item.value,
                create_rule: true,
                parent_dependency: item.parent_dependency ? item.parent_dependency : '',
                disabled: item.disabled || false,
                custom_field: item.custom_field || false,
            } as TFormData)

            changeInputValue(matchedNumber, item.property);
            return true;
        })

        let uncompleted = items.filter(item => item.content === '' && item.synonym !== '');
        if (uncompleted.length) {
            setCanSubmitForm(false);
        }

        setFormFields(items);
    }

    const checkCreateRule = (event: React.ChangeEvent<HTMLInputElement>, property: string) => {
        let fieldsList = formFields,
            index = fieldsList.findIndex(item => item.property === property);

        if (index !== -1) {
            fieldsList[index].create_rule = !event.target.checked;
            setFormFields(fieldsList.map(f => f));
        }
    }

    const changeInputValue = (value: string, property: string) => {
        let fieldsList = formFields,
            index = fieldsList.findIndex(item => item.property === property);

        if (index !== -1) {
            fieldsList[index].content = value;

            if (fieldsList[index].synonym === '') {
                if (value === 'EMPTY') {
                    fieldsList[index].synonym = value;
                } else {
                    fieldsList[index].synonym = '';
                }
            }

            setFormFields(fieldsList.map(f => f));

            if (typeof error === "object"
                && error.hasOwnProperty(property)) {
                setError('');
            }

            // * enable input dependency
            if (value !== '' && value !== 'EMPTY') {
                let parent_dependency = fields.find(item => item.parent_dependency === property);
                if (parent_dependency) {
                    if (parent_dependency.property === EWineProperties.region) {
                        getRegionByCountry(value, true);

                        // * reset selected value
                        updateFormFields(EWineProperties.region, 'content')
                    }
                } else {
                    if (property === EWineProperties.country) {
                        let index = fields.findIndex(item => item.property === EWineProperties.region);
                        if (index !== -1 && fields[index].value !== '') {
                            getRegionByCountry(value);
                        }
                    }
                }
            } else {
                if (property === EWineProperties.country) {
                    updateFormFields(EWineProperties.region, 'content');
                    updateTaxonomyPropertiesOptionValues(EWineProperties.region, []);
                }
            }
        }
    }

    const updateFormFields = (field: string, attribute: string, content?: string | boolean) => {
        let field_property_index = formFields.findIndex(item => item.property === field);
        if (field_property_index !== -1 && formFields[field_property_index].hasOwnProperty(attribute)) {
            (formFields[field_property_index] as any)[attribute] = typeof content !== "undefined" ? content : '';
            setFormFields(formFields.map(f => f));
            return true;
        }
        return false;
    }

    const updateTaxonomyPropertiesOptionValues = (property: string, values: []) => {
        let taxonomies = Object.assign({}, taxonomyProperties);

        if (taxonomies && taxonomies[property]) {
            taxonomies[property].option_values = values;
            setTaxonomyProperties(taxonomies);
        }
    }

    const getRegionByCountry = (country_id: string, check_rules?: boolean) => {

        let fieldRegionIndex = formFields.findIndex(item => item.property === EWineProperties.region),
            queryParams = '';

        if (check_rules) {
            queryParams = '?rule=true&file=' + file_hash;
            if (fieldRegionIndex !== -1) {
                queryParams += '&synonym=' + formFields[fieldRegionIndex].synonym;
            }
        }

        axios.get('/api/get-country-regions/' + country_id + queryParams, {
            ...axiosConfig
        }).then(r => {
            let result = r.data;
            if (result.hasOwnProperty('regions')) {
                updateTaxonomyPropertiesOptionValues(EWineProperties.region, result.regions);

                let index = fields.findIndex(item => item.property === EWineProperties.region);
                if (index !== -1) {
                    fields[index].disabled = false;
                    setFields(fields.map(f => f));
                }
            }

            if (check_rules && result.hasOwnProperty('rule') && result.rule) {
                formFields[fieldRegionIndex].content = result.rule.value;
                setFormFields(formFields.map(f => f));

                let index = fields.findIndex(item => item.property === EWineProperties.region);
                if (index !== -1) {
                    fields[index].rule = result.rule.length ? result.rule : null;
                    setFields(fields.map(f => f));
                }
            } else {

                let resetField = false;
                let index = fields.findIndex(item => item.property === EWineProperties.region);
                if (index !== -1) {
                    const rule = fields[index].rule?.value;
                    const taxonomyRegionOptionValues = taxonomyProperties ? taxonomyProperties[EWineProperties.region].option_values : {};

                    if (taxonomyRegionOptionValues && rule) {
                        const value = taxonomyRegionOptionValues[parseInt(rule)];
                        if (!value) {
                            resetField = true;
                        }
                    } else {
                        resetField = true;
                    }
                }

                if (resetField) {
                    // * reset field
                    updateFormFields(EWineProperties.region, "content");

                    fields[index].rule = null;
                    setFields(fields.map(f => f));
                }
            }

            if (result.hasOwnProperty('country_locked')) {
                setCountryLocked(result.country_locked);
            }
        })
            .catch(r => {

            });
    }

    const submitData = (formData?: TFormData[]) => {
        // * check if all content completed
        let fields = formData && formData.length ? formData : formFields,
            uncompleted = fields.filter(item => item.content === '' && item.synonym !== ''),
            error_text = '',
            errorObj: TErrorObject = {}

        if (uncompleted.length) {
            // * get first uncompleted
            error_text = t('errors.uncompleted_field') + capitalize(uncompleted[0].property)
            errorObj[uncompleted[0].property] = error_text;

            setError(errorObj)

            return false;
        }

        setDisabledAccept(true);

        dataImport['fields'] = fields
        dataImport['file_hash'] = file_hash
        dataImport['work_row_token'] = row_token

        setError('');

        axios.post('/api/save-import-data-row', dataImport, {
            ...axiosConfig
        }).then(r => {
            setDisabledAccept(false);

            let result = r.data;
            if (result.hasOwnProperty('error')) {
                setError(result.error);
                return false;
            }

            setFields([]);
            setFormFields([]);

            if (result.hasOwnProperty('no_more_data') && result.no_more_data) {
                setTaxonomyProperties({});
                setError('No more data to be matched');
                return false;
            }

            if (result.hasOwnProperty('msg')) {
                history.replace('/data-matching/' + file_hash);
            }
        }).catch(r => {
            setDisabledAccept(false);
            setError(r.toString());
        });
    }

    const vineyardDefaultCountry = () => {
        let vineyard = Object.assign({}, newVineyard);

        if (typeof vineyard.country === 'undefined' && countryField && countryField.content !== '') {
            vineyard['country'] = parseInt(countryField.content);
            setNewVineyard(vineyard);

            return countryField.content;
        }
        return '';
    }

    const regionDefaultCountry = () => {
        let region = Object.assign({}, newRegion);

        if (typeof region.country === 'undefined' && countryField && countryField.content !== '') {
            region['country'] = parseInt(countryField.content);
            setNewRegion(region);

            return countryField.content;
        }
        return '';
    }

    const hideModalAddRegion = () => {
        setSimilarRegions([]);
        setNewRegionConfirmButton(false);
        setNewRegionInputDisabled(false);
        setOpenModalAddRegion(false);
        setNewRegion(null);
        setBsError('');
    }

    const hideModalAddVineyard = () => {
        setSimilarVineyards([]);
        setNewVineyardConfirmButton(false);
        setNewVineyardInputDisabled(false);
        setOpenModalAddVineyard(false);
        setNewVineyard(null);
        setBsError('');
    }

    const checkSimilarVineyard = () => {
        if (newVineyard === null) {
            return false;
        }

        vineyardDefaultCountry();

        setBsError('');
        axios.post('/api/check-vineyard', {
            vineyard: newVineyard.name
        }, {
            ...axiosConfig
        }).then(r => {
            let result = r.data;

            if (result.hasOwnProperty('err')) {
                setBsError(result.err);
                return false;
            }

            if (result.hasOwnProperty('similar') && result.similar.length) {
                setSimilarVineyards(result.similar);
            } else {
                setSubmitCreateNewVineyard(true);
                return true;
            }

            setNewVineyardConfirmButton(true);
            setNewVineyardInputDisabled(true);
        }).catch(r => {
            setBsError(r.toString());
        });
    }

    const storeVineyard = () => {
        if (newVineyard === null) {
            return false;
        }

        setSubmitCreateNewVineyard(false);
        setBsError('');

        if (newVineyard.status === ENewFieldValueStatus.EXISTING) {
            updateFormFields(EWineProperties.vineyard, 'content', newVineyard.id ? newVineyard.id.toString() : '');
            hideModalAddVineyard();
        } else if (newVineyard.status === ENewFieldValueStatus.NEW) {

            if (typeof newVineyard.country === "undefined" || !newVineyard.country) {
                setBsError('Country not selected');
                return false;
            }

            axios.post('/api/store-vineyard', {
                vineyard: newVineyard.name,
                country: newVineyard.country
            }, {
                ...axiosConfig
            }).then(r => {
                let result = r.data;

                if (result.hasOwnProperty('err')) {
                    setBsError(result.err);
                    return false;
                }

                if (result.hasOwnProperty('vineyard')) {
                    let properties = Object.assign({}, taxonomyProperties);

                    if (properties.VINEYARD.option_values !== null) {
                        properties.VINEYARD.option_values = {};
                        properties.VINEYARD.option_values[result.vineyard.id] = result.vineyard.name;

                        setTaxonomyProperties(properties);

                        // * pre-select
                        updateFormFields(EWineProperties.vineyard, 'content', result.vineyard.id.toString());
                    }
                }

                hideModalAddVineyard();
            });
        }
    }

    const checkSimilarRegion = () => {
        if (newRegion === null) {
            return false;
        }

        regionDefaultCountry();

        setBsError('');
        axios.post('/api/check-region', {
            region: newRegion.name
        }, {
            ...axiosConfig
        }).then(r => {
            let result = r.data;

            if (result.hasOwnProperty('err')) {
                setBsError(result.err);
                return false;
            }

            if (result.hasOwnProperty('similar') && result.similar.length) {
                setSimilarRegions(result.similar);
            } else {
                setSubmitCreateNewRegion(true);
                return true;
            }

            setNewRegionConfirmButton(true);
            setNewRegionInputDisabled(true);
        }).catch(r => {
            setBsError(r.toString());
        });
    }

    const storeRegion = () => {
        if (newRegion === null) {
            return false;
        }
        setSubmitCreateNewRegion(false);

        setBsError('');

        if (newRegion.status === ENewFieldValueStatus.EXISTING) {
            updateFormFields(EWineProperties.region, 'content', newRegion.id ? newRegion.id.toString() : '')

            hideModalAddRegion();
        } else if (newRegion.status === ENewFieldValueStatus.NEW) {

            if (typeof newRegion.country === "undefined" || !newRegion.country) {
                setBsError('Country not selected');
                return false;
            }

            axios.post('/api/store-region', {
                region: newRegion.name,
                country: newRegion.country
            }, {
                ...axiosConfig
            }).then(r => {
                let result = r.data;

                if (result.hasOwnProperty('err')) {
                    setBsError(result.err);
                    return false;
                }

                if (result.hasOwnProperty('region')) {
                    let properties = Object.assign({}, taxonomyProperties);

                    if (properties.REGION.option_values !== null) {
                        properties.REGION.option_values[result.region.id] = result.region.name;
                        setTaxonomyProperties(properties);

                        // * pre-select
                        updateFormFields(EWineProperties.region, 'content', result.region.id.toString())
                    }
                }

                hideModalAddRegion();
            }).catch(r => {
                setBsError(r.toString());
            });
        }
    }

    /**
     * Use interval to touch working parsed file row
     */
    useInterval(async () => {
        if (file_hash && row_token) {
            axios.get('/api/touch-matching-row/' + row_token, {
                ...axiosConfig
            })
                .then(response => {
                    let result = response.data;
                    if (result.hasOwnProperty('error')) {
                        TOUCH_INTERVAL = 0;
                        console.log(result.error);
                        return false;
                    }
                })
                .catch(response => {

                })
        }
    }, TOUCH_INTERVAL)

    useEffect(() => {
        if (!row_token || history.action === 'POP') {
            getParsedFileRow();
        }

    }, [location]);

    useEffect(() => {
        if (submitCreateNewRegion) {
            storeRegion();
        }
    }, [submitCreateNewRegion]);

    useEffect(() => {
        if (submitCreateNewVineyard) {
            storeVineyard();
        }
    }, [submitCreateNewVineyard]);

    useEffect(() => {
        if (canSubmitForm && formFields.length) {
            submitData();
        }
    }, [canSubmitForm, formFields]);

    return (
        <div className="App">
            <div className="data-matching-page w-100">
                <Container fluid>
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="mb-3 text-start">Import data</h4>
                            <hr/>
                            <div className="row">
                                <div className="col-md-12">
                                    <h4>Line 3 - unknown data</h4>
                                </div>
                            </div>
                            <hr/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <AlertError error={error} callback={() => setError('')}/>
                            <div className="contest">
                                <Preloader hide={!!fields.length}/>
                                {fields.length
                                    ?
                                    <div className="row">
                                        <div className="col-md-5 offset-md-2">
                                            <p><strong>{competition}</strong></p>
                                            <p><strong>{event}</strong></p>
                                            <Button variant="custom" className="mb-3 mt-3"
                                                    onClick={() => setCanSubmitForm(true)}
                                                    disabled={disabledAccept}>
                                                ACCEPT
                                            </Button>
                                        </div>
                                        {automatchActive && (
                                            <div className="col-md-5 text-end">
                                                <em>Automatch active</em>
                                            </div>
                                        )}
                                    </div>
                                    : ''
                                }
                                <div className="row">
                                    {fields.length
                                        ?
                                        fields.map((item, key) => {
                                            return (
                                                <Fragment key={key}>
                                                    <div
                                                        className={"col-md-2 column-name text-end d-flex justify-content-end align-items-top property-" + item.property.toLowerCase()}>
                                                        {t(item.property)}
                                                    </div>
                                                    <div
                                                        className={"col-md-5 column-value position-relative property-" + item.property.toLowerCase()}>
                                                        {!item.addition_field
                                                            ?
                                                            <div className="cell">
                                                                <DataMatchingItemValue item={item} copy_clipboard_fields={copy_clipboard_fields}/>
                                                            </div>
                                                            :
                                                            <div className="empty-cell">

                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div className="d-flex align-items-center h-39">
                                                            {item.rule === null
                                                            && skip_properties.indexOf(item.property) === -1
                                                            && typeof taxonomyProperties !== "undefined"
                                                            && taxonomyProperties.hasOwnProperty(item.property)
                                                                ?
                                                                <TaxonomyInput
                                                                    item={item}
                                                                    form_fields={formFields}
                                                                    taxonomy_properties={taxonomyProperties}
                                                                    taxonomy={taxonomyProperties[item.property]}
                                                                    onChange={changeInputValue}
                                                                />
                                                                : ''
                                                            }
                                                            {item.rule === null && item.property === EWineProperties.region && item.value !== '' && !countryLocked ?
                                                                <button className="additional-button mr-3"
                                                                        onClick={(e) => setOpenModalAddRegion(true)}
                                                                        title="Add new region">+</button> : ''}
                                                            {item.rule === null && item.property === EWineProperties.vineyard && item.value !== '' ?
                                                                <button className="additional-button mr-3"
                                                                        onClick={(e) => setOpenModalAddVineyard(true)}
                                                                        title="Add new vineyard">+</button> : ''}
                                                            {item.rule === null
                                                            && skip_properties.indexOf(item.property) === -1
                                                            && item.value !== ''
                                                                ?
                                                                <label className="checkbox">
                                                                    <input type="checkbox" value="true"
                                                                           name="dont_create_rule"
                                                                           onChange={(e) => checkCreateRule(e, item.property)}/> Private
                                                                    rule
                                                                </label>
                                                                : ''
                                                            }

                                                            {item.rule !== null
                                                                ? <TaxonomyValue rule={item.rule}
                                                                                 taxonomy={taxonomyProperties ? taxonomyProperties[item.property] : null}/>
                                                                : ''
                                                            }
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            )
                                        })
                                        : ''
                                    }
                                </div>
                                {fields.length
                                    ?
                                    <div className="row">
                                        <div className="col-md-5 offset-md-2">
                                            <Button variant="custom" className="mt-3"
                                                    onClick={() => setCanSubmitForm(true)}
                                                    disabled={disabledAccept}>
                                                ACCEPT
                                            </Button>
                                        </div>
                                    </div>
                                    : ''
                                }
                            </div>
                        </div>
                    </div>
                </Container>
                <BsModal {...ModalAddRegionProps}>
                    <p>Create new region. Use this form only if region is not listed.</p>
                    <div className="form-group mb-2">
                        <label>Country*</label>
                        <select className="w-100 custom-select" onChange={(e) => {
                            let objRegion = Object.assign({}, newRegion)
                            objRegion.country = parseInt(e.target.value)
                            setNewRegion(objRegion)
                        }} defaultValue={countryField && countryField.content !== '' ? countryField.content : ''}>
                            <option value="0">- select country -</option>
                            {taxonomyProperties && taxonomyProperties.hasOwnProperty(EWineProperties.country) && taxonomyProperties.COUNTRY.option_values
                                ?
                                Object.entries(taxonomyProperties.COUNTRY.option_values).map(([key, value]) => {
                                    return (
                                        <option key={key} value={key}>{value}</option>
                                    )
                                })
                                : ''
                            }
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Region name*</label>
                        <input type="text" className="w-100 custom-input-text"
                               onChange={(e) => {
                                   let objRegion = Object.assign({}, newRegion)
                                   objRegion.name = e.target.value
                                   objRegion.status = ENewFieldValueStatus.NEW
                                   setNewRegion(objRegion)
                               }} disabled={newRegionInputDisabled}/>
                    </div>
                    {similarRegions.length ?
                        <div className="mt-3 similar-regions">
                            <h6>Similar regions</h6>
                            {similarRegions.map((item: { tid: number, name: string }) => {
                                return (
                                    <div className="mt-3">
                                        <input type="radio" name="existing_region" onChange={(e) => setNewRegion({
                                            id: item.tid,
                                            name: item.name,
                                            status: ENewFieldValueStatus.EXISTING
                                        })}/> {item.name}
                                    </div>
                                )
                            })}
                            <div className="small mt-3">Select similar regions to catch existing region or skip to
                                create new one.
                            </div>
                        </div>
                        : ''
                    }
                </BsModal>
                <BsModal {...ModalAddVineyardProps}>
                    <p>Create new vineyard. Use this form only if vineyard is not listed.</p>
                    <div className="form-group mb-2">
                        <label>Country*</label>
                        <select className="w-100 custom-select" onChange={(e) => {
                            let objVineyard = Object.assign({}, newVineyard)
                            objVineyard.country = parseInt(e.target.value)
                            setNewVineyard(objVineyard)
                        }} defaultValue={countryField && countryField.content !== '' ? countryField.content : ''}>
                            <option value="0">- select country -</option>
                            {taxonomyProperties && taxonomyProperties.hasOwnProperty(EWineProperties.country) && taxonomyProperties.COUNTRY.option_values
                                ? Object.entries(taxonomyProperties.COUNTRY.option_values).map(([key, value]) => {
                                    return (
                                        <option key={key} value={key}>{value}</option>
                                    )
                                })
                                : ''
                            }
                        </select>
                    </div>
                    <div className="form-group mb-2">
                        <label>Vineyard name*</label>
                        <input type="text" className="w-100 custom-input-text"
                               onChange={(e) => {
                                   let objVineyard = Object.assign({}, newVineyard)
                                   objVineyard.name = e.target.value
                                   objVineyard.status = ENewFieldValueStatus.NEW
                                   setNewVineyard(objVineyard)
                               }} disabled={newVineyardInputDisabled}/>
                        <small>Max length 60 characters.</small>
                    </div>
                    {similarVineyards.length ?
                        <div className="mt-3 similar-regions">
                            <h6>Similar vineyards</h6>
                            {similarVineyards.map((item: { id: number, name: string, country: number | string }) => {
                                return (
                                    <div className="mt-3">
                                        <input type="radio" name="existing_region" onChange={(e) => setNewVineyard({
                                            id: item.id,
                                            name: item.name,
                                            status: ENewFieldValueStatus.EXISTING
                                        })}/> {item.name} ({item.country})
                                    </div>
                                )
                            })}
                            <div className="small mt-3">Select similar vineyards to catch existing region or skip to
                                create new one.
                            </div>
                        </div>
                        : ''
                    }
                </BsModal>
            </div>
        </div>
    );
};

export default DataMatching;
