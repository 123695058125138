import React from "react";
import {TWineProperties, TTaxonomy, CUSTOM_INPUTS} from "../properties";
import {capitalize} from "../helpers";

type Props = {
    item: TWineProperties | undefined,
    taxonomy: TTaxonomy | undefined,
    onChange: (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void
}
const CustomInput = ({item, taxonomy, onChange}: Props) => {
    if (item) {
        switch (item.custom_input) {
            case CUSTOM_INPUTS.Dropdown:
                return (
                    <>
                        {taxonomy !== undefined
                        && taxonomy.hasOwnProperty(item.key)
                        && taxonomy[item.key].option_values
                            ?
                            <select className="w-100 mb-2"
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                            >
                                <option value="">{"- Select " + capitalize(item.key.replace('_', ' ')) + " -"}</option>
                                {Object.entries(taxonomy[item.key].option_values as string[]).map(([key, value]) => {
                                    return (
                                        <option key={key} value={key}>{value}</option>
                                    );
                                })}
                            </select>
                            : <input type="text" className="form-control w-100 mb-2" onChange={(e) => {
                                onChange(e)
                            }}/>
                        }

                    </>
                )
            case CUSTOM_INPUTS.Number:
            case CUSTOM_INPUTS.Float:
                return (
                    <>
                        <input type="number" className="form-control w-100 mb-2" onChange={(e) => {
                            onChange(e)
                        }}/>
                    </>
                )
            case CUSTOM_INPUTS.Text:
                return (
                    <>
                        <input type="text" className="form-control w-100 mb-2" onChange={(e) => {
                            onChange(e)
                        }}/>
                    </>
                )
            case CUSTOM_INPUTS.Boolean:
                return (
                    <>
                        <select className="w-100 mb-2"
                                onChange={(e) => {
                                    onChange(e)
                                }}
                        >
                            <option value="">- Select Value -</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </>
                )
            default:
                return (
                    <>
                    </>
                )
        }
    }

    return (
        <>
            <input type="text" className="form-control w-100 mb-2"/>
        </>
    )
}

export default CustomInput;