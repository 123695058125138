import {useParams} from "react-router";
import React, {useContext, useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import axios from "axios";
import SynonymIcon from "../components/SynonymIcon";
import { EWineProperties, TErrorObject } from "../properties";
import {Link} from "react-router-dom";
import { AlertError } from "../helpers";
import {AuthUserContext} from "../context/AuthUser";
import WineActions from "../components/WineActions";

type TParams = {
    wine_id: string
}

export type TWine = {
    id: number,
    name: string,
    country_gustos: {
        name?: string
    },
    region_gustos: {
        name?: string
    },
    color_gustos: {
        name?: string
    },
    co2_gustos: {
        name?: string
    },
    vineyard_gustos: {
        name?: string
    },
    created_at: string,
    grape_variety: 'AROMATIC' | 'NON_AROMATIC',
    sweet: 'NO' | 'NATURALLY_SWEET' | 'LIQUEUR',
    vintage: {
        id: number,
        add_info: string,
        alcohol: string,
        grape_type: string,
        image: string,
        sugar: string,
        trophy: string,
        year: number,
        award_gustos: {
            name: string
        } | null
    },
    gustos_wine_id?: number
    user?: {
        email: string
    },
    wine_action?: {
        user?: {
            email: string,
            is_bot: boolean
        },
        action: string,
        gustos_vintage_id: number,
        gustos_wine_entity: {
            wine: number
        }
    },
    parsed_file_row: {
        parsed_file: {
            event: {
                competition: {
                    name: string
                },
                year: number
            }
        }
    }
}

type TSynonym = {
    [k in EWineProperties]: string
}

function WineProfile() {
    let {wine_id} = useParams<TParams>();

    const authUser = useContext(AuthUserContext);
    const [wine, setWine] = useState<TWine>();
    const [synonym, setSynonym] = useState<TSynonym>()
    const [error, setError] = useState<string | TErrorObject>('');
    const axiosConfig = {
        headers: {
            Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
        }
    };

    useEffect(() => {
        const getWineData = () => {
            axios.get('/api/wine-profile/' + wine_id, {
                ...axiosConfig
            }).then(r => {
                let result = r.data;
                if (result.hasOwnProperty('err')) {
                    setError(result.err);
                    return false;
                }

                if (result.hasOwnProperty('wine')) {
                    setWine(result.wine);
                }

                if (result.hasOwnProperty('synonyms')) {
                    setSynonym(result.synonyms);
                }
            })
                .catch(r => {
                    setError(r.toString());
                    return false;
                });
        }

        getWineData()
    }, [wine_id]);

    return (
        <div className="App wine-profile">
            <div className="w-100">
                <Container fluid>
                    <div className="row">
                        <div className="col-md-12 header position-relative">
                            <h4 className="mb-2 text-start">Wine {wine ? wine.name : ''}</h4>
                            <hr/>
                            <Link to="/wines" className="link-custom back"> {"<"} Back to wines</Link>
                        </div>
                        <div className="col-md-12">
                            <AlertError error={error} callback={() => setError('')}/>
                            {wine ?
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="wine-image mt-5">
                                            <img
                                                src={`${process.env.REACT_APP_GUSTOS_APP_URL}/sites/all/themes/gustos/svg/default/bottle.svg`}
                                                alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="wine-content">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <ul className="two-columns">
                                                        <li>
                                                            <span className="title">Name</span>
                                                            <div className="d-flex align-items-center">
                                                                <SynonymIcon synonyms={synonym} property={EWineProperties.name}/>
                                                                {wine.name}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <span className="title">Country</span>
                                                            <div className="d-flex align-items-center">
                                                                <SynonymIcon synonyms={synonym} property={EWineProperties.country}/>
                                                                {wine.country_gustos ? wine.country_gustos.name : '-'}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <span className="title">Region</span>
                                                            <div className="d-flex align-items-center">
                                                                <SynonymIcon synonyms={synonym} property={EWineProperties.region}/>
                                                                {wine.region_gustos ? wine.region_gustos.name : '-'}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <span className="title">Color</span>
                                                            <div className="d-flex align-items-center">
                                                                <SynonymIcon synonyms={synonym} property={EWineProperties.color}/>
                                                                {wine.color_gustos ? wine.color_gustos.name : '-'}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <span className="title">CO2</span>
                                                            <div className="d-flex align-items-center">
                                                                <SynonymIcon synonyms={synonym} property={EWineProperties.co2}/>
                                                                {wine.co2_gustos ? wine.co2_gustos.name : '-'}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <span className="title">Vineyard</span>
                                                            <div className="d-flex align-items-center">
                                                                <SynonymIcon synonyms={synonym} property={EWineProperties.vineyard}/>
                                                                {wine.vineyard_gustos ? wine.vineyard_gustos.name : '-'}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <span className="title">Grape variety</span>
                                                            <div className="d-flex align-items-center">
                                                                <SynonymIcon synonyms={synonym} property={EWineProperties.grape_category}/>
                                                                {wine.grape_variety || '-'}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <span className="title">Sweet</span>
                                                            <div className="d-flex align-items-center">
                                                                <SynonymIcon synonyms={synonym} property={EWineProperties.extra_sweetness}/>
                                                                {wine.sweet || '-'}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <span className="title">Year</span>
                                                            <div className="d-flex align-items-center">
                                                                <SynonymIcon synonyms={synonym} property={EWineProperties.year}/>
                                                                {wine.vintage.year || '-'}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <span className="title">Alcohol</span>
                                                            <div className="d-flex align-items-center">
                                                                <SynonymIcon synonyms={synonym} property={EWineProperties.alcohol}/>
                                                                {wine.vintage.alcohol ? wine.vintage.alcohol + " %" : '-'}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <span className="title">Sugar</span>
                                                            <div className="d-flex align-items-center">
                                                                <SynonymIcon synonyms={synonym} property={EWineProperties.sugar}/>
                                                                {wine.vintage.sugar ? wine.vintage.sugar + " g/L" : '-'}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <span className="title">Grape type</span>
                                                            <div className="d-flex align-items-center">
                                                                <SynonymIcon synonyms={synonym} property={EWineProperties.grape_type}/>
                                                                {wine.vintage.grape_type || '-'}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <span className="title">Trophy</span>
                                                            <div className="d-flex align-items-center">
                                                                <SynonymIcon synonyms={synonym} property={EWineProperties.save_trophy}/>
                                                                {wine.vintage.trophy || '-'}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <span className="title">Award</span>
                                                            <div className="d-flex align-items-center">
                                                                <SynonymIcon synonyms={synonym} property={EWineProperties.award}/>
                                                                {wine.vintage.award_gustos ? wine.vintage.award_gustos.name : '-'}
                                                            </div>
                                                        </li>
                                                        <WineActions wine={wine}/>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <span className="title">Image</span>
                                                            <div className="d-flex align-items-center">
                                                                <SynonymIcon synonyms={synonym} property={EWineProperties.image}/>
                                                                {wine.vintage.image || '-'}
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul className="two-columns">
                                                        <li>
                                                            <span className="title">Competition</span>
                                                            {wine.parsed_file_row.parsed_file.event?.competition.name}
                                                        </li>
                                                        <li>
                                                            <span className="title">Event</span>
                                                            {wine.parsed_file_row.parsed_file.event?.year}
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <span className="title">Add info</span>
                                                            {wine.vintage.add_info ? JSON.stringify(wine.vintage.add_info) : '-'}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default WineProfile;
