import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { CardImage } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Select from "react-select";
import ImageViewer from "react-simple-image-viewer";
import { AuthUserContext } from "../context/AuthUser";
import Preloader from "../components/Preloader";
import { removeHtmlTags } from "../helpers";
import { TPagination } from "../properties";

type TOptionType = {
  label: string,
  value: string
}

type TCompetition = {
  id: number,
  name: string,
  logo_id: string,
  description: string,
  region: string,
  google_page_rating: number,
  correction_factor: number,
}

const CompetitionReview = () => {
  const authUser = useContext(AuthUserContext);
  const axiosConfig = {
    headers: {
      Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
    }
  };
  const pageRangeDisplayed = 8;

  const [regions, setRegions] = useState<TOptionType[]>([]);
  const [problems, setProblems] = useState<TOptionType[]>([]);
  const [filter, setFilter] = useState<{ [k: string]: string }>({});
  const [loading, setLoading] = useState(false);
  const [competitions, setCompetitions] = useState<TCompetition[]>();
  const [pagination, setPagination] = useState<TPagination>(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [previewImage, setPreviewImage] = useState<string[]>([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const updateFilter = (key: string, value: string) => {
    if (value) {
      setFilter({...filter, [key]: value});
    } else {
      const {[key]: value, ...rest} = filter;
      setFilter(rest);
    }
  }

  const submitFilter = (page?: number) => {
    setLoading(true);
    let query = filter;
    if (page) {
      query = { ...filter, ...{ page: page.toString() } };
    }

    axios.post("/api/competition/filter", query, {
        ...axiosConfig,
      }).then(r => {
        let result = r.data;
        if (result.hasOwnProperty('data')) {
          let competitions = result.data;

          setCompetitions(competitions.data);

          let paginationData = {
            'current_page': competitions.current_page,
            'from': competitions.from,
            'last_page': competitions.last_page,
            'first_page_url': competitions.first_page_url,
            'last_page_url': competitions.last_page_url,
            'next_page_url': competitions.next_page_url,
            'path': competitions.path,
            'per_page': competitions.per_page,
            'prev_page_url': competitions.prev_page_url,
            'to': competitions.to,
            'total': competitions.total
          };

          setPagination(paginationData);
        }
      })
      .catch(r => {
      }).finally(() => {
        setLoading(false);
      });
  }

  const shortDescription = (description: string) => {
    description = removeHtmlTags(description);

    if (description) {
      if (description.length > 40) {
        return description.substring(0, 40) + "...";
      }
    }

    return description;
  };

  const humanReadableRegion = (region: string) => {
    if (regions) {
      const regionOption = regions.find(item => item.value === region);
      if (regionOption) {
        return regionOption.label;
      }
    }
    return region;
  }

  const openPreviewImage = (src: string) => {
    setPreviewImage([src]);
    setIsViewerOpen(true);
  }

  const closeImageViewer = () => {
    setPreviewImage([]);
    setIsViewerOpen(false);
  }

  useEffect(() => {
    axios
      .get('/api/competition/init', {
        ...axiosConfig
      }).then(r => {
        let result = r.data;

        if (result.hasOwnProperty("regions")) {
          setRegions(result.regions);
        }

        if (result.hasOwnProperty("problems")) {
          setProblems(result.problems);
        }
      })
      .catch(r => {
      });
  }, []);

  return (
    <div className="App">
      <div className="w-100">
        <Container fluid>
          <div className="row">
            <div className="col-md-12">
              <h4 className="mb-2 text-start">Competitions Review</h4>
              <hr/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>Name</label>
              <input type="text" className="form-control" onChange={e => updateFilter("name", e.target.value)}/>
            </div>
            <div className="col-md-4">
              <label>Region</label>
              <Select options={regions} onChange={item => updateFilter('region', item ? item.value : "")} isClearable={true} placeholder={"All"}/>
            </div>
            <div className="col-md-4">
              <label>Problems</label>
              <Select options={problems} onChange={item => updateFilter('problem', item ? item.value : "")} isClearable={true} placeholder={"All"}/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 offset-md-5  mb-3">
              <label>&nbsp;</label>
              <button className="btn btn-custom w-100" onClick={() => submitFilter(1)}>
                Search
              </button>
            </div>
          </div>
          <div className="row">
              <div className="col-md-12">
                {loading ? <Preloader show={true}/>
                  : !!(competitions && competitions.length) &&
                    (
                        <>
                        <Table striped hover className="mt-3">
                          <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Logo</th>
                            <th>Description</th>
                            <th>Region</th>
                            <th>Google PR</th>
                            <th>Correction Factor</th>
                            <th/>
                          </tr>
                          </thead>
                          <tbody>
                            {competitions.map(item=> {
                              return (
                                <tr key={item.id}>
                                  <td>{item.id}</td>
                                  <td>{item.name}</td>
                                  <td className="text-center">
                                    {item.logo_id &&
                                      <a href='/#'
                                         title={'Logo'}
                                         onClick={(e) => {
                                           e.preventDefault();
                                           openPreviewImage('/api/competition/logo/'+item.id)
                                         }}><CardImage/></a>
                                    }
                                  </td>
                                  <td>
                                    {shortDescription(item.description)}
                                  </td>
                                  <td>{humanReadableRegion(item.region)}</td>
                                  <td>{item.google_page_rating}</td>
                                  <td>{item.correction_factor}</td>
                                  <td>
                                    <Link to={`/competition/${item.id}/edit`} className="btn btn-custom inherit">Edit</Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>

                        {pagination !== null ?
                          <Pagination activePage={pagination.current_page}
                            itemsCountPerPage={pagination.per_page}
                            onChange={(pageNumber) => {
                              submitFilter(pageNumber);
                            }}
                            totalItemsCount={pagination.total}
                            pageRangeDisplayed={pageRangeDisplayed}
                            itemClass="page-item"
                            linkClass="page-link"
                            firstPageText="First Page"
                            lastPageText="Last Page"
                            innerClass="pagination mt-3"
                          />
                          : ''
                        }

                          {isViewerOpen && (
                            <ImageViewer
                              src={previewImage}
                              currentIndex={0}
                              disableScroll={false}
                              closeOnClickOutside={true}
                              onClose={closeImageViewer}
                            />
                          )}
                      </>
                    )
                }
                {!loading && competitions && !competitions.length &&
                  <div>No data.</div>
                }
              </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default CompetitionReview;