import React, {useContext, useEffect, useState} from "react";
import {AuthUserContext} from "../context/AuthUser";
import {useHistory, useParams} from "react-router";
import {AlertError, customStyles} from "../helpers";
import {TErrorObject} from "../properties";
import {Container} from "react-bootstrap";
import axios from "axios";
import Select from "react-select";

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";

type TWineryProps = {
    name: string,
    country: {
        name: string,
        id: number
    },
    description: string,
    phone: string,
    region: string,
    website: string,
    user: {
        name: string
    },
    logo: {
        uri: string,
        url: string
    }
}

type TCountries = {
    label: string,
    value: number
}

const WineryEdit = () => {
    const authUser = useContext(AuthUserContext);
    const history = useHistory();
    const {winery_id} = useParams<{ winery_id: string }>();
    const axiosConfig = {
        headers: {
            Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
        }
    };
    const [error, setError] = useState<string | TErrorObject>('');
    const [winery, setWinery] = useState<TWineryProps>();
    const [countries, setCountries] = useState<TCountries[]>();
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [canBeDeleted, setCanBeDeleted] = useState(false);

    const submitForm = () => {
        setSubmitDisabled(true);
        setError('');
        let data = {
            ...winery,
            winery_id: Number(winery_id)
        }
        axios.post('/api/vineyard-update', data, {
            ...axiosConfig
        }).then(r => {
            let result = r.data;
            if (result.hasOwnProperty('error')) {
                toast.error(result.error);
                return false;
            }
            if (result.hasOwnProperty('success')) {
                toast.success(result.msg);
            }
            setSubmitDisabled(false);
        }).catch(error => {
            if (error.response) {
                const responseData = error.response.data;
                if (responseData.hasOwnProperty('message')) {
                    toast.error(responseData.message);
                }
            }
        }).finally(() => {
            setSubmitDisabled(false);
        });
    }

    const deleteWinery = () => {
        setError('');

        if (!window.confirm('Are you sure want to delete winery?')) {
            return false;
        }

        axios.post('/api/vineyard-delete', {
            winery_id: Number(winery_id)
        }, {
            ...axiosConfig
        }).then(r => {
            let result = r.data;
            if (result.hasOwnProperty('error')) {
                setError(result.error);
                return false;
            }
            if (result.hasOwnProperty('success')) {
                history.push('/wines-merge/list');
            }
        }).catch(r => {
            setError(r.toString());
        });
    }

    useEffect(() => {

        axios.get('/api/vineyard-profile/' + winery_id, {
            ...axiosConfig
        }).then(r => {
            let result = r.data;
            if (result.hasOwnProperty('winery')) {
                setWinery(result.winery);
            }

            if (result.hasOwnProperty('countries')) {
                setCountries(result.countries);
            }
            if (result.hasOwnProperty('can_be_deleted')) {
                setCanBeDeleted(result.can_be_deleted);
            }
        }).catch(r => {

        });
    }, [winery_id]);

    return (
        <div className="App">
            <div className="w-100">
                <Container fluid>
                    <div className="row">
                        <div className="col-md-12 header position-relative">
                            <h4 className="mb-2 text-start">Edit winery {winery ? winery.name : ''}</h4>
                            <hr/>
                            <Link to={`/wines-merge/list?winery=${winery_id}`} className="link-custom back"> {"<"} Back to list</Link>
                        </div>
                        <div className="col-md-12">
                            <AlertError error={error} callback={() => setError('')}/>
                            {winery ?
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <label>Winery name*</label>
                                                <input type="text" className="custom-input-text mt-2 w-100"
                                                       onChange={(e) => {
                                                           let obj = Object.assign({}, winery);
                                                           obj.name = e.target.value;
                                                           setWinery(obj);
                                                       }}
                                                       defaultValue={winery.name}/>
                                            </div>
                                            <div className="col-md-4">
                                                <label>Country*</label>
                                                <Select className="mt-2 w-100"
                                                        classNamePrefix="react-select" styles={customStyles}
                                                        placeholder={'- Select value -'}
                                                        options={countries}
                                                        value={countries ? countries.filter(item => item.label === winery.country.name) : null}
                                                        onChange={(item) => {
                                                            if (item) {
                                                                let obj = Object.assign({}, winery);
                                                                obj.country.name = item.label;
                                                                obj.country.id = Number(item.value);
                                                                setWinery(obj);
                                                            }
                                                        }}/>
                                            </div>
                                            <div className="col-md-4">
                                                <label>Phone*</label>
                                                <input type="text" className="custom-input-text mt-2 w-100"
                                                       onChange={(e) => {
                                                           let obj = Object.assign({}, winery);
                                                           obj.phone = e.target.value;
                                                           setWinery(obj);
                                                       }}
                                                       defaultValue={winery.phone}/>
                                            </div>
                                        </div>
                                        <div className="row mb-5">
                                            <div className="col-md-4">
                                                <label>Account name</label>
                                                <input type="text" className="custom-input-text mt-2 w-100"
                                                       onChange={(e) => {
                                                           let obj = Object.assign({}, winery);
                                                           obj.user.name = e.target.value;
                                                           setWinery(obj);
                                                       }}
                                                       defaultValue={winery.user ? winery.user.name : ''}/>
                                            </div>
                                            <div className="col-md-4">
                                                <label>Region</label>
                                                <input type="text" className="custom-input-text mt-2 w-100"
                                                       onChange={(e) => {
                                                           let obj = Object.assign({}, winery);
                                                           obj.region = e.target.value;
                                                           setWinery(obj);
                                                       }}
                                                       defaultValue={winery.region}/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                {winery.logo ?
                                                    <img
                                                        src={winery.logo.url}
                                                        alt="winery logo" className="img-thumbnail"/>
                                                    :
                                                    <div
                                                        className="empty-winery-image d-flex align-items-center justify-content-center">
                                                        no logo
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-md-8">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label>Web page</label>
                                                        <input type="text" className="custom-input-text mt-2 w-100"
                                                               onChange={(e) => {
                                                                   let obj = Object.assign({}, winery);
                                                                   obj.website = e.target.value;
                                                                   setWinery(obj);
                                                               }}
                                                               defaultValue={winery.website}/>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label className="mt-3">Description</label>
                                                        <textarea className="custom-input-text mt-2 h-200 w-100"
                                                                  onChange={(e) => {
                                                                      let obj = Object.assign({}, winery);
                                                                      obj.description = e.target.value;
                                                                      setWinery(obj);
                                                                  }}
                                                                  defaultValue={winery.description}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 text-center mt-4">
                                                <button className="btn btn-custom" onClick={submitForm}
                                                        disabled={submitDisabled}>Save
                                                </button>

                                                <button className="custom-default mb-3 ml-3" disabled={!canBeDeleted}
                                                        onClick={deleteWinery}>Delete winery
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default WineryEdit;