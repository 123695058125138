import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { AuthUserContext } from "../context/AuthUser";

const AdminSettings = () => {
  const authUser = useContext(AuthUserContext);
  const axiosConfig = {
    headers: {
      Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : "",
    },
  };

  const fields = ["automatch_matching_data", "automatch_log_bot"];
  const [settings, setSettings] = useState<Record<string, string | number | boolean>>({});
  const [bots, setBots] = useState<Array<{id: string, email: string}>>([]);

  const saveSettings = () => {
    if (Object.keys(settings).length) {
      axios.post("/api/settings/save-admin", {
        fields: settings,
      }, {
        ...axiosConfig,
      }).then((response) => {
        const data = response.data;
        if (data.message) {
          toast.success(data.message);
          return;
        }
      });
    }
  };

  useEffect(() => {
    axios.post("/api/settings/load-admin", {
      fields: fields,
    }, {
      ...axiosConfig,
    }).then((response) => {
      const data = response.data;
      setSettings(data.fields);

      if (data.hasOwnProperty('bots')) {
        setBots(data.bots);
      }
    });
  }, []);

  return (
    <div className="App">
      <div className="w-100">
        <Container fluid>
          <div className="row">
            <div className="col-md-12">
              <h4 className="mb-2 text-start">Administrator Settings</h4>
              <hr/>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3 text-end">
                  Automatch after matching data (1st step):
                </div>
                <div className="col-md-9">
                  <label>
                    <input
                      type="checkbox" name="automatch_matching_data" value="1"
                      checked={"automatch_matching_data" in settings && !!settings['automatch_matching_data']}
                      onChange={(e) => {
                        setSettings({ ...settings, automatch_matching_data: e.target.checked });
                      }}
                    /> Active
                  </label>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-3 text-end">
                  Automatch log bot:
                </div>
                <div className="col-md-9">
                  {bots.length ? (
                    <select
                      name="automatch_log_bot"
                      defaultValue={"automatch_log_bot" in settings ? settings["automatch_log_bot"].toString() : ""}
                      onChange={(e) => {
                        setSettings({ ...settings, automatch_log_bot: e.target.value });
                      }}
                    >
                      <option value="">-</option>
                      {bots.map((bot) => (
                        <option
                          key={bot.id}
                          value={bot.id}
                        >
                          {bot.email}
                        </option>
                      ))}
                    </select>
                  ) : "-"}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mt-4 text-center">
                  <button className="btn btn-custom" onClick={saveSettings}>Save</button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default AdminSettings;
