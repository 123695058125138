import React, {useContext} from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../logo.svg";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import NavDropdown from "react-bootstrap/NavDropdown";
import {
    Link
} from "react-router-dom";
import '../App.css';
import {AuthUserContext} from "../context/AuthUser";
import {AwardFill, PersonFill} from "react-bootstrap-icons";
import {hasPermissionTo} from "../helpers";

export default function Header() {

    const authUser = useContext(AuthUserContext);

    return (
        <Navbar bg="light" expand="lg">
            <div className="container">
                <Container fluid className="d-flex">
                    <Navbar.Brand>
                        <Link to="/">
                            <img src={logo} alt="Logo"/>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll"/>
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{maxHeight: '100px'}}
                            navbarScroll
                        >
                            <Link to="/" className="nav-link">Home</Link>
                            <Link to="/search" className="nav-link">Search</Link>
                            <Link to="/wines" className="nav-link">Wines</Link>
                            {hasPermissionTo(authUser, "merge_wines view") &&
                              <Link to="/wines-merge/list" className="nav-link">Merge wines</Link>
                            }
                            <Dropdown className="dropdown-default">
                                <Dropdown.Toggle> More
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {!!(authUser.authUser && authUser.authUser.is_admin) &&
                                      (<>
                                        <Dropdown.Item as={Link} to="/presets">Presets</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/wines-history">History</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/grapes">Grapes</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/regions">Regions</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/daily-reports">Daily reports</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/target-revision">Target revision</Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/competitions">Competitions Review</Dropdown.Item>
                                        </>
                                      )
                                    }
                                    {hasPermissionTo(authUser, "rules view") &&
                                      <Dropdown.Item as={Link} to="/rules">Rules</Dropdown.Item>
                                    }
                                    {hasPermissionTo(authUser, "photo_vivino_review view") &&
                                      <Dropdown.Item as={Link} to="/photo-vivino-review">Photo and Vivino review</Dropdown.Item>
                                    }
                                    <Dropdown.Item as={Link} to="/wineries-review">Wineries review</Dropdown.Item>
                                    {hasPermissionTo(authUser, "wines merge") &&
                                      <Dropdown.Item as={Link} to="/job-batches">Job batches</Dropdown.Item>
                                    }
                                    {!!(authUser.authUser && authUser.authUser.is_admin) &&
                                      (
                                        <>
                                            <Dropdown.Item as={Link} to="/gwmr-calculator">GWMR calculator</Dropdown.Item>
                                        </>
                                      )
                                    }
                                </Dropdown.Menu>
                            </Dropdown>

                        </Nav>
                        <Nav
                            style={{maxHeight: '100px'}}
                            navbarScroll
                        >
                            {authUser.authUser ?
                              <Link to="/#" className="nav-link static"><AwardFill /> Today: <span title="Parsed wines">{authUser.authUser.count_wines_today ?? 0 }</span> / <span title="Merged wines">{authUser.authUser.count_merged_today ?? 0 }</span></Link>
                                : ''
                            }
                            {authUser.authUser === null
                                ? <Link to="/login" className="nav-link">Login</Link>
                                : <Dropdown className="dropdown-default">
                                    <Dropdown.Toggle><PersonFill/> Hello {authUser.authUser.email ? authUser.authUser.email.substring(0, authUser.authUser.email.lastIndexOf("@")) : 'friend'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {authUser.authUser.is_admin && (
                                            <Dropdown.Item as={Link} to="/admin-settings">Admin settings</Dropdown.Item>
                                        )}
                                        <Dropdown.Item as={Link} to="/" onClick={(e) => {
                                            authUser.setAuthUser(null);
                                            localStorage.removeItem('auth_user')
                                        }}>Logout</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                            <NavDropdown title="Ru" id="navbarScrollingDropdown2">
                                <NavDropdown.Item href="#action3">Ro</NavDropdown.Item>
                                <NavDropdown.Item href="#action4">En</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </div>
        </Navbar>
    );
}
