import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Pagination from "react-js-pagination";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import Preloader from "../components/Preloader";
import { AuthUserContext } from "../context/AuthUser";
import { hasPermissionTo, highlightText } from "../helpers";
import { TPagination } from "../properties";

enum categories {
  gustos = 'gustos',
  parsedwine = 'parsedwine',
  parsedwine_wines = 'parsedwine_wines',
}

enum entityType {
  winery = 'winery',
  wine_name = 'wine name',
  winery_web = 'winery website',
  region = 'region',
  co2 = 'co2',
  color = 'color',
  vineyard = 'vineyard',
  country = 'country',
  alcohol = 'alcohol',
  sugar = 'sugar',
  year = 'year',
  award = 'award',
  winery_comment = 'comment to winery',
  region_name = 'region name'
}

type TData = {
  result: string,
  type: string,
  details: string,
  id?: number
}

const Search = () => {
  const authUser = useContext(AuthUserContext);
  const pageRangeDisplayed = 8;
  const location = useLocation();
  const axiosConfig = {
    headers: {
      Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
    }
  };

  const [searchValue, setSearchValue] = useState('');
  const [searchedValue, setSearchedValue] = useState('');
  const [parsedwinePagination, setParsedwinePagination] = useState<TPagination>(null);
  const [gustosPagination, setGustosPagination] = useState<TPagination>(null);
  const [parsedwineWinePagination, setParsedwineWinePagination] = useState<TPagination>(null);
  const [parsedwineData, setParsedwineData] = useState<TData[]>();
  const [parsedwineWineData, setParsedwineWineData] = useState<TData[]>();
  const [gustosData, setGustosData] = useState<TData[]>();
  const [page, setPage] = useState(1);
  const [gustosTabLoading, setGustosTabLoading] = useState(false);
  const [parsedwineTabLoading, setParsedwineTabLoading] = useState(false);
  const [parsedwineWinesTabLoading, setParsedwineWinesTabLoading] = useState(false);

  const searchData = (to_page?: number, category?: string, searchText?: string) => {

    if (searchValue !== '' || (searchText!== undefined && searchText !== '')) {
      setSearchedValue(searchText || searchValue);
      to_page = to_page || 1;
      setPage(to_page);
      axios.post('/api/global-search', {q: searchText || searchValue, category: category, page: to_page}, {
        ...axiosConfig
      }).then(r => {
        let response = r.data;

        if (response.hasOwnProperty('data')) {

          let paginationData = null;

          if (Object.keys(response.data).length) {
            paginationData = {
              'current_page': response.current_page,
              'from': response.from,
              'last_page': response.last_page,
              'first_page_url': response.first_page_url,
              'last_page_url': response.last_page_url,
              'next_page_url': response.next_page_url,
              'path': response.path,
              'per_page': response.per_page,
              'prev_page_url': response.prev_page_url,
              'to': response.to,
              'total': response.total
            };
          }

          if (category === categories.parsedwine) {
            setParsedwineData(response.data);
            setParsedwinePagination(paginationData);
          } else if (category === categories.gustos) {
            setGustosData(response.data);
            setGustosPagination(paginationData);
          } else if (category === categories.parsedwine_wines) {
            setParsedwineWineData(response.data);
            setParsedwineWinePagination(paginationData);
          }
        }
      }).catch(r => {
        toast.error('Oops, something went wrong');
      }).finally(() => {
        if (category === categories.gustos) {
          setGustosTabLoading(false);
        }
        if (category === categories.parsedwine) {
          setParsedwineTabLoading(false);
        }
        if (category === categories.parsedwine_wines) {
          setParsedwineWinesTabLoading(false);
        }

        window.history.pushState({}, '', '/search?term=' + (searchText || searchValue));
      });
    }
  }

  const renderUrl = (type: string, value?:string) => {
    let url = '#';

    switch (type.toLowerCase()) {
      case entityType.winery:
        url = '/wineries-review?page=1&wineryLike=' + (value ?? searchedValue);
        break;
      case entityType.wine_name:
        url = '/photo-vivino-review?page=1&wineNameLike=' + (value ?? searchedValue);
        break;
      case entityType.co2:
      case entityType.color:
      case entityType.vineyard:
      case entityType.country:
      case entityType.alcohol:
      case entityType.sugar:
      case entityType.award:
      case entityType.year:
      case entityType.region:
        url = '/rules?page=1&term=synonym&field=' + type + '&search=' + (value ?? searchedValue);
        break;
      case entityType.winery_comment:
        url = '/wineries-review?page=1&wineryLike=' + (value ?? searchedValue);
        break;
    }

    return url;
  }

  const searchTabContent = (searchText?: string) => {
    Object.entries(categories).forEach(([value, index]) => {
      if (value === categories.gustos) {
        setGustosTabLoading(true);
      }
      if (value === categories.parsedwine) {
        setParsedwineTabLoading(true);
      }
      if (value === categories.parsedwine_wines) {
        setParsedwineWinesTabLoading(true);
      }
      searchData(1, value, searchText);
    });

    return false;
  }

  const renderItemDetails = (item: TData) => {
    if (item.type.toLowerCase() === entityType.winery) {
      return (
        <a
          href={'/wineries-review?wineryLike=' + encodeURIComponent(item.result)}
          target={"_blank"}
          rel="noreferrer">
          {item.details}
        </a>
      );
    } else if (item.type.toLowerCase() === entityType.wine_name) {
      return (
        <a
          href={'/wines-merge/list?winery=' + encodeURIComponent(item.details) + '&wine_name=' + encodeURIComponent(item.result)}
          target={"_blank"}
          rel="noreferrer">
          {item.details}
        </a>
      );
    } else {
      return item.details;
    }
  };

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.get("term")) {
        setSearchValue(searchParams.get("term") as string);
        searchTabContent(searchParams.get("term") as string);
      }
    }
  }, [location]);

  return (
    <div>
      <div className="App global-search">
        <div className="w-100">
          <Container fluid>
            <div className="row">
              <div className="col-md-12">
                <h4 className="mb-2 text-start">Search</h4>
                <hr/>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-9">
                    <div className="form-group mb-3">
                        <input type="text" className="form-control" defaultValue={searchValue} onChange={e => {
                          setSearchValue(e.target.value)
                        }}/>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <button className="btn btn-custom w-100" disabled={!(searchValue.length)}
                            onClick={() => searchTabContent()}>
                      Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-3 contest">
                <Tabs
                  defaultActiveKey="gustos"
                  id="fill-tab-example"
                  className="mb-3"
                  // @ts-ignore
                  fill
                >
                  <Tab eventKey={categories.gustos} title="Wineries, Wines and Regions">
                    <fieldset>
                      <Table striped bordered hover size="sm">
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>Result</th>
                          <th>Type</th>
                          <th>Details</th>
                          <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {gustosData && Object.keys(gustosData).length ?
                          Object.entries(gustosData).map(([k, item]) => {
                            return (
                              <tr key={k}>
                                <td>{parseInt(k) + ((page - 1) * 20)}</td>
                                <td dangerouslySetInnerHTML={{
                                  __html: highlightText(searchedValue, item.result)
                                }}/>
                                <td>{item.type}</td>
                                <td>
                                  {renderItemDetails(item)}
                                </td>
                                <td className="text-center">
                                  {![entityType.region_name, entityType.winery_web].includes(item.type.toLowerCase() as entityType) && hasPermissionTo(authUser, 'photo_vivino_review view') &&
                                    <a href={renderUrl(item.type)} className="btn btn-custom inherit" target={"_blank"}
                                    rel="noreferrer">Review</a>
                                  }
                                </td>
                              </tr>
                            )
                          })
                          : <tr/>
                        }
                        </tbody>
                      </Table>
                      {gustosPagination !== null ?
                        <>
                          <Pagination activePage={gustosPagination.current_page}
                                      itemsCountPerPage={gustosPagination.per_page}
                                      onChange={(pageNumber) => {
                                        searchData(pageNumber, categories.gustos);
                                      }}
                                      totalItemsCount={gustosPagination.total}
                                      pageRangeDisplayed={pageRangeDisplayed}
                                      itemClass="page-item"
                                      linkClass="page-link"
                                      firstPageText="First Page"
                                      lastPageText="Last Page"
                                      innerClass="pagination mt-3 inline float-left"
                          />
                        </>
                        : ''
                      }
                      <Preloader hide={!(gustosTabLoading)}/>
                    </fieldset>
                  </Tab>
                  <Tab eventKey={categories.parsedwine} title="Rules and Winery Comments">
                    <fieldset>
                      <Table striped bordered hover size="sm">
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>Result</th>
                          <th>Type</th>
                          <th>Details</th>
                          <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {parsedwineData && Object.keys(parsedwineData).length ?
                          Object.entries(parsedwineData).map(([k, item]) => {
                            return (
                              <tr key={k}>
                                <td>{parseInt(k) + ((page - 1) * 20)}</td>
                                <td dangerouslySetInnerHTML={{
                                  __html: highlightText(searchedValue, item.result)
                                }}/>
                                <td>{item.type.toLowerCase() !== entityType.winery_comment ? 'Rule:' : ''} {item.type}</td>
                                <td>{item.details}</td>
                                <td className="text-center">
                                  {item.type.toLowerCase() === entityType.winery_comment &&
                                    <a
                                      href={renderUrl(item.type, item.details)}
                                      className="btn btn-custom inherit" target={"_blank"} rel="noreferrer">Review</a>
                                  }
                                  {item.type.toLowerCase() !== entityType.winery_comment && hasPermissionTo(authUser, 'rules view') &&
                                    <a
                                      href={renderUrl(item.type)}
                                      className="btn btn-custom inherit" target={"_blank"} rel="noreferrer">Review</a>
                                  }
                                </td>
                              </tr>
                            )
                          })
                          : <tr/>
                        }
                        </tbody>
                      </Table>
                      {parsedwinePagination !== null ?
                        <>
                          <Pagination activePage={parsedwinePagination.current_page}
                                      itemsCountPerPage={parsedwinePagination.per_page}
                                      onChange={(pageNumber) => {
                                        searchData(pageNumber, categories.parsedwine);
                                      }}
                                      totalItemsCount={parsedwinePagination.total}
                                      pageRangeDisplayed={pageRangeDisplayed}
                                      itemClass="page-item"
                                      linkClass="page-link"
                                      firstPageText="First Page"
                                      lastPageText="Last Page"
                                      innerClass="pagination mt-3"
                          />
                        </>
                        : ''
                      }
                      <Preloader hide={!(parsedwineTabLoading)}/>
                    </fieldset>
                  </Tab>
                  <Tab eventKey={categories.parsedwine_wines} title="Parsedwine wines">
                    <fieldset>
                      <Table striped bordered hover size="sm">
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>Result</th>
                          <th>Type</th>
                          <th>Details</th>
                          <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {parsedwineWineData && Object.keys(parsedwineWineData).length ?
                          Object.entries(parsedwineWineData).map(([k, item]) => {
                            return (
                              <tr key={k}>
                                <td>{parseInt(k) + ((page - 1) * 20)}</td>
                                <td dangerouslySetInnerHTML={{
                                  __html: highlightText(searchedValue, item.result)
                                }}/>
                                <td>{item.type}</td>
                                <td>{item.details}</td>
                                <td className="text-center">
                                  <a
                                    href={item.id ? '/wine/' + item.id : ''}
                                    className="btn btn-custom inherit" target={"_blank"} rel="noreferrer">View</a>
                                </td>
                              </tr>
                            )
                          })
                          : <tr/>
                        }
                        </tbody>
                      </Table>
                      {parsedwineWinePagination !== null ?
                        <>
                          <Pagination activePage={parsedwineWinePagination.current_page}
                                      itemsCountPerPage={parsedwineWinePagination.per_page}
                                      onChange={(pageNumber) => {
                                        searchData(pageNumber, categories.parsedwine_wines);
                                      }}
                                      totalItemsCount={parsedwineWinePagination.total}
                                      pageRangeDisplayed={pageRangeDisplayed}
                                      itemClass="page-item"
                                      linkClass="page-link"
                                      firstPageText="First Page"
                                      lastPageText="Last Page"
                                      innerClass="pagination mt-3"
                          />
                        </>
                        : ''
                      }
                      <Preloader hide={!(parsedwineWinesTabLoading)}/>
                    </fieldset>
                  </Tab>

                </Tabs>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default Search;
