import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { AuthUserContext } from "../context/AuthUser";

type Batches = {
  id: number;
  name: string;
  total_jobs: number;
  processed_jobs: number;
  progress: number;
  created_at: string;
  finished_at: string;
  failed_jobs: number;
}

const JobBatches = () => {
  const authUser = useContext(AuthUserContext);
  const axiosConfig = {
    headers: {
      Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : "",
    },
  };

  const [jobBatches, setJobBatches] = useState<Batches[]>([]);

  useEffect(() => {
    axios.get("/api/job-batches", axiosConfig)
      .then((response) => {
        setJobBatches(response.data.batches);
      });
  }, []);

  return (
    <div>
      <div className="App global-search">
        <div className="w-100">
          <Container fluid>
            <div className="row">
              <div className="col-md-12">
                <h4 className="mb-2 text-start">Job batches</h4>
                <hr/>
              </div>
              <div className="col-md-12 contest">
                {jobBatches.length > 0 ? (
                    jobBatches.map((batch) => (
                      <div key={batch.id} className="mb-5">
                        <div className="d-flex justify-content-between">
                          <div>Created: {batch.created_at}</div>
                          {batch.finished_at && (
                            <div>Finished: {batch.finished_at}</div>
                          )}
                        </div>
                        <h5>{batch.name}</h5>
                        {batch.processed_jobs} completed out of {batch.total_jobs}
                        &nbsp;({batch.progress}%)
                        {batch.failed_jobs > 0 && (
                          <p>
                            Failed jobs: {batch.failed_jobs}
                          </p>
                        )}
                      </div>
                    ))
                  ) : 'No job batches found'}
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default JobBatches;
