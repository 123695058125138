import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import Select from "react-select";
import Async from "react-select/async";
import { toast } from "react-toastify";
import Preloader from "../components/Preloader";
import { AuthUserContext } from "../context/AuthUser";
import { colorContrast, stringToColor } from "../helpers";
import { TPagination } from "../properties";

type EFilter = {
  [k:string]: string | number
}

type TWinery = {
  id: number,
  name: string
};

type OptionType = {
  value: number,
  label: string
};

type TWine = {
  parsed_wine: {
    action: string,
    id: number,
    wine: string,
    winery: string,
    year: number,
    competition: string,
    event: number,
    wine_name_modified: boolean
  },
  gustos: {
    wine_entity_id: number,
    wine: string,
    winery: string,
    year: number
  },
  user: {
    acronym: string,
    email: string
  },
  created_at: string
};

type TOptionType = {
  value: number,
  label: string
};

const TargetRevision = () => {
  const authUser = useContext(AuthUserContext);
  const axiosConfig = {
    headers: {
      Authorization: authUser.authUser !== null ? "Bearer " + authUser.authUser.api_key : ''
    }
  };

  let lastSearchData = {};

  const pageRangeDisplayed = 8;
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<EFilter>({});
  const [selectedGustosWinery, setSelectedGustosWinery] = useState<OptionType>();
  const [selectedParsedWinery, setSelectedParsedWinery] = useState<OptionType>();
  const [wines, setWines] = useState<TWine[]>();
  const [pagination, setPagination] = useState<TPagination>(null);
  const [revertItems, setRevertItems] = useState<number[]>([]);
  const [contentLoading, setContentLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [users, setUsers] = useState<TOptionType[]>([]);
  const [competitionOptions, setCompetitionOptions] = useState<TOptionType[]>([]);
  const [eventsOptions, setEventsOptionss] = useState<TOptionType[]>([]);

  const loadOptions = (
    inputText: string,
    callback: (options: OptionType[]) => void
  ): void => {
    if (!inputText) {
      return callback([]);
    }
    if (inputText.length < 2) {
      return callback([]);
    }
    setLoading(true);

    axios.post('/api/search-winery', {q: inputText}, {
      ...axiosConfig
    }).then((response) => {
      let data: TWinery[] = response.data.wineries;
      setLoading(false);
      if (data.length) {
        return callback(data.map((result) => {
          return labelFormatter(result);
        }))
      } else {
        return callback([]);
      }
    });
  };

  const labelFormatter = (i: TWinery): OptionType => {
    return {
      label: i.name,
      value: i.id,
    }
  }

  const submitFilter = (page?: number, filterData?: EFilter) => {
    setWines(undefined);
    setRevertItems([]);
    lastSearchData = {};
    setContentLoading(true);
    setTotalRows(0);

    let query = filterData ?? filter;
    if (page) {
      query = { ...query, ...{ page: page } };
    }

    cleanUrl(query);

    axios
      .post("/api/target-revision/filter", query, { ...axiosConfig })
      .then((response) => {
        let result = response.data;
        if (result.hasOwnProperty("data")) {
          let data = result.data;

          setWines(data.data);

          let paginationData = {
            "current_page": data.current_page,
            "from": data.from,
            "last_page": data.last_page,
            "first_page_url": data.first_page_url,
            "last_page_url": data.last_page_url,
            "next_page_url": data.next_page_url,
            "path": data.path,
            "per_page": data.per_page,
            "prev_page_url": data.prev_page_url,
            "to": data.to,
            "total": data.total,
          };
          setPagination(paginationData);
        } else {
          setWines([]);
        }

        if (result.hasOwnProperty("parsed_winery")) {
          setSelectedParsedWinery(result.parsed_winery);
        }

        if (result.hasOwnProperty('count')) {
          setTotalRows(result.count);
        }

        lastSearchData = filter;
      }).catch(error => {
        let hasError = false;
        if (error.response) {
          const responseData = error.response.data;
          if (responseData.hasOwnProperty("message")) {
            let message = responseData.message !== "" ? responseData.message : error.toString();
            toast.error(message);
            hasError = true;
          }
        }

        if (!hasError) {
          toast.error(error.toString());
        }
      }).finally(() => {
        setContentLoading(false);
      });
  };

  const cleanUrl = (query: { gustos_wine_entity_id?: number, parsed_winery_id?: number }) => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has("target_gustos_vintage_id") && (!query.hasOwnProperty("gustos_wine_entity_id") || (query.hasOwnProperty("gustos_wine_entity_id") && query.gustos_wine_entity_id != parseInt(searchParams.get("target_gustos_vintage_id") as string)))) {
      window.history.replaceState(null, "", "/target-revision");
    }

    if (searchParams.has("parsed_winery_id") && (!query.hasOwnProperty("parsed_winery_id") || (query.hasOwnProperty("parsed_winery_id") && query.parsed_winery_id != parseInt(searchParams.get("parsed_winery_id") as string)))) {
      window.history.replaceState(null, "", "/target-revision");
    }
  };

  const revertData = () => {
    if (revertItems.length) {
      axios.post("/api/target-revision/revert", {ids: revertItems}, { ...axiosConfig })
        .then((response) => {
          let result = response.data;
          if (result.hasOwnProperty('error')) {
            toast.error(result.error);
            return false;
          }
          if (result.hasOwnProperty('success')) {
            toast.success(result.msg);

            submitFilter(1);
          }
        });
    }
  }

  const compareDataStyleColor = (value1: string | number, value2: string | number) => {
    value1 = value1 ?? "";
    value2 = value2 ?? "";

    if (value1.toString() !== value2.toString()) {
      return "text-danger";
    }
    return "";
  };

  const toggleCheckboxRevert = (id: number, checked: boolean) => {
    if (checked) {
      let items:number[] = revertItems;
      items.push(id);

      setRevertItems(items.map(f => f));
    } else {
      let filtered = revertItems.filter(e => e !== id);
      setRevertItems(filtered);
    }
  };

  const toggleAllRevertCheckboxes = (checked: boolean) => {
    if (checked && wines && wines.length) {
      let items:number[] = wines.map(e => {
        return e.parsed_wine.id;
      });
      setRevertItems(items.map(f => f));
    } else {
      setRevertItems([]);
    }
  };

  const updateFilter = (key: string, value: string | number) => {
    if (value) {

      const newFilter = {...filter, [key]: value};
      if (key === "gustos_wine_entity_id") {
        delete newFilter['gustos_winery_id'];
        delete newFilter['parsed_winery_id'];
      } else if (key === "gustos_winery_id") {
        delete newFilter['gustos_wine_entity_id'];
        delete newFilter['parsed_winery_id'];
      } else if (key === "parsed_winery_id") {
        delete newFilter['gustos_wine_entity_id'];
        delete newFilter['gustos_winery_id'];
      }

      setFilter(newFilter);

    } else {
      const {[key]: value, ...rest} = filter;
      setFilter(rest);
    }
  }

  useEffect(() => {
    axios.get("/api/target-revision/init", {
      ...axiosConfig,
    }).then((response) => {
      let result = response.data;
      if (result.hasOwnProperty("users")) {
        let data:TOptionType[] = [];
        for (let i in result.users) {
          data.push({
            label: result.users[i].toString(),
            value: parseInt(i)
          });
        }
        setUsers(data);
      }

      if (result.hasOwnProperty('competitions')) {
        let data:TOptionType[] = [];
        for (let i in result.competitions) {
          data.push({
            label: result.competitions[i],
            value: parseInt(i)
          });
        }
        setCompetitionOptions(data);
      }

      if (result.hasOwnProperty('events')) {
        let data:TOptionType[] = [];
        for (let i in result.events) {
          data.push({
            label: result.events[i],
            value: parseInt(i)
          });
        }
        setEventsOptionss(data);
      }
    });

    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("target_gustos_vintage_id")) {
      const vintageId = parseInt(searchParams.get("target_gustos_vintage_id") as string);
      updateFilter("gustos_wine_entity_id", vintageId);
      submitFilter(1, {'gustos_wine_entity_id': vintageId});
    } else if (searchParams.has("parsed_winery_id")) {
      const vineyardId = parseInt(searchParams.get("parsed_winery_id") as string);
      updateFilter("parsed_winery_id", vineyardId);
      submitFilter(1, {'parsed_winery_id': vineyardId});
    }
  }, []);

  return (
    <div className="App">
      <div className="w-100">
        <Container fluid>
          <div className="row">
            <div className="col-md-12">
              <h4 className="mb-2 text-start">Target revision({totalRows})</h4>
              <hr/>
            </div>
            <div className="col-md-12">
              <h5>Filter</h5>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label>Target gustos vintage ID</label>
                    <input type="text" className="form-control" onChange={(e) => {
                      setSelectedGustosWinery(undefined);
                      setSelectedParsedWinery(undefined);
                      updateFilter('gustos_wine_entity_id', parseInt(e.target.value));
                    }} value={filter && filter.gustos_wine_entity_id ? filter.gustos_wine_entity_id : ''}/>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label>Target gustos winery</label>
                    <Async isClearable={true} loadOptions={loadOptions} isLoading={loading}
                           placeholder={"Insert some text..."}
                           value={selectedGustosWinery ? selectedGustosWinery : null}
                           onChange={(item) => {
                             setSelectedParsedWinery(undefined);
                             setSelectedGustosWinery(item ? item : undefined);

                             updateFilter('gustos_winery_id', item ? item.value : "");
                           }}/>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label>Source parsed winery</label>
                    <Async isClearable={true} loadOptions={loadOptions} isLoading={loading}
                           placeholder={"Insert some text..."}
                           value={selectedParsedWinery ? selectedParsedWinery : null}
                           onChange={(item) => {
                             setSelectedGustosWinery(undefined);
                             setSelectedParsedWinery(item ? item : undefined);

                             updateFilter('parsed_winery_id', item ? item.value : "");
                           }}/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group mb-3">
                    <label>User</label>
                    <Select className="w-100" options={users} isClearable={true} onChange={(e) => {
                      updateFilter('user_id', e ? e.value : "");
                    }}/>
                  </div>
                </div>
                <div className="col-md-4">
                  <label>Date start</label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      updateFilter("start_date", date ? date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + (date.getDate().toString()).padStart(2, "0") : "");
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={new Date()}
                    className="w-100"
                    dateFormat="dd/MM/yyyy"
                    calendarStartDay={1}
                    isClearable={!!startDate}
                  />
                </div>
                <div className="col-md-4">
                  <label>Date end</label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                      updateFilter("end_date", date ? date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + (date.getDate().toString()).padStart(2, "0") : "");
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={new Date()}
                    className="w-100"
                    dateFormat="dd/MM/yyyy"
                    calendarStartDay={1}
                    isClearable={!!endDate}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label>Competition</label>
                  <Select isClearable={true} options={competitionOptions} onChange={(e) => {
                    updateFilter("competition_id", e ? e.value : "");
                  }}/>
                </div>
                <div className="col-md-6">
                  <label>Event</label>
                  <Select isClearable={true} options={eventsOptions} onChange={(e) => {
                    updateFilter("event_id", e ? e.value : "");
                  }}/>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="form-group mb-3 text-center mt-4">
                    <button className="btn btn-custom mr-4" disabled={!(Object.keys(filter).length)} onClick={() => submitFilter()}>Search</button>
                    <button className="btn btn-outline-custom" disabled={!(revertItems.length)} onClick={revertData}>Revert</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              {contentLoading ? <Preloader show={true}/> : ''}
              {wines ?
                wines.length ?
                  <>
                    <Table striped bordered hover size="sm">
                      <thead>
                      <tr>
                        <th><input type="checkbox" onChange={e => {
                          toggleAllRevertCheckboxes(e.target.checked);
                        }} checked={revertItems.length === wines.length}/></th>
                        <th>#</th>
                        <th>Parsed Winery</th>
                        <th>Parsed Wine Name</th>
                        <th>Parsed Year</th>
                        <th>Action</th>
                        <th>Gustos ID</th>
                        <th>Gustos Winery</th>
                        <th>Gustos Wine Name</th>
                        <th>Gustos Year</th>
                        <th>User</th>
                      </tr>
                      </thead>
                      <tbody>
                      {wines.map((item, idx) => {
                        return (
                          <tr key={item.parsed_wine.id}>
                            <td><input type="checkbox" onChange={e => {
                              toggleCheckboxRevert(item.parsed_wine.id, e.target.checked);
                            }} checked={!!(revertItems.length && revertItems.find(e => e === item.parsed_wine.id) !== undefined)}/></td>
                            <td>
                              <a href={`/wine/`+item.parsed_wine.id} target={"_blank"} title={`${item.parsed_wine.competition} / ${item.parsed_wine.event}`}>
                                {item.parsed_wine.id}
                              </a>
                            </td>
                            <td className={compareDataStyleColor(item.parsed_wine.winery, item.gustos.winery)}>{item.parsed_wine.winery}</td>
                            <td><span className={item.parsed_wine.wine_name_modified ? `edited` : ''}>{item.parsed_wine.wine}</span></td>
                            <td className={compareDataStyleColor(item.parsed_wine.year, item.gustos.year)}>{item.parsed_wine.year}</td>
                            <td>{item.parsed_wine.action}</td>
                            <td>{item.gustos.wine_entity_id}</td>
                            <td className={compareDataStyleColor(item.parsed_wine.winery, item.gustos.winery)}>{item.gustos.winery}</td>
                            <td>{item.gustos.wine}</td>
                            <td className={compareDataStyleColor(item.parsed_wine.year, item.gustos.year)}>{item.gustos.year}</td>
                            <td>
                              <div className="user-acronym" style={{backgroundColor: stringToColor(item.user.email), color: colorContrast(stringToColor(item.user.email))}} title={`${item.user.email} @ ${item.created_at}`}>
                                {item.user.acronym}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                      </tbody>
                    </Table>
                    {pagination !== null ?
                      <Pagination activePage={pagination.current_page}
                                  itemsCountPerPage={pagination.per_page}
                                  onChange={(pageNumber) => {
                                    submitFilter(pageNumber);
                                  }}
                                  totalItemsCount={pagination.total}
                                  pageRangeDisplayed={pageRangeDisplayed}
                                  itemClass="page-item"
                                  linkClass="page-link"
                                  firstPageText="First Page"
                                  lastPageText="Last Page"
                                  innerClass="pagination mt-3"
                      />
                      : ''
                    }
                  </>
                  : <div>No data.</div>
                :''
              }
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default TargetRevision;
