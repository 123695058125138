import { Robot } from "react-bootstrap-icons";
import {TWine} from "../pages/WineProfile";
import React, {useContext} from "react";
import {AuthUserContext} from "../context/AuthUser";

type TProps = {
    wine: TWine
}

const WineActions = (props: TProps) => {
    const authUser = useContext(AuthUserContext);

    if (props.wine.wine_action) {
        return (<>
            <li>
                <span className="title">Action</span>
                {props.wine.wine_action.action}
            </li>
            {props.wine.wine_action.user
                ?
                <li>
                    <span className="title">User</span>
                    <div className="d-flex align-items-center gap-2">
                        {props.wine.wine_action.user.is_bot ?<Robot />:""} {props.wine.wine_action.user.email}
                    </div>
                </li>
                : ''
            }
            {props.wine.wine_action.gustos_wine_entity
                ?
                <li>
                    <span className="title">Gustos wine</span>
                    <a href={`${process.env.REACT_APP_GUSTOS_APP_URL}/wine/${props.wine.wine_action.gustos_wine_entity.wine}/show`}
                       target="_blank">
                        View gustos wine
                    </a>
                </li>
                : ''
            }
            {authUser.authUser && authUser.authUser.is_admin && props.wine.wine_action.action === 'MERGE_SELECTED'
                ?
                <li>
                    <span className="title">Compare versions</span>
                    <a href={"/compare-versions/" + props.wine.vintage.id}>
                        View last version
                    </a>
                </li>
                : ''
            }
        </>);
    }
    return (<>

    </>);
}

export default WineActions;
